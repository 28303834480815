import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentFile } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { PAYMENT_FILE_DETAILS_ROWS, PAYMENT_FILE_DETAILS_ROW_CUSTOMIZERS } from './PaymentFileDetails.page.const'

export const PaymentFileDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentFile(uuid)

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_ORDER} permission={PERMISSION.FILES_VIEW_FILES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError || isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<PaymentFile>
                                    data={query.data}
                                    rows={PAYMENT_FILE_DETAILS_ROWS}
                                    rowCustomizers={PAYMENT_FILE_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
