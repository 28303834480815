import { ToastId, UseToastOptions } from '@chakra-ui/toast'

export const enum TOAST_VARIANTS {
    INFO = 'numeralAccent',
    SUCCESS = 'numeralSuccess',
    WARNING = 'numeralWarning',
    ERROR = 'numeralError'
}

export type ToastsProviderContextValue = {
    items?: Record<ToastId, UseToastOptions>
    onAdd(options: UseToastOptions, onClose?: (...args: any) => void): void
    onUpdate(id: ToastId, options: UseToastOptions): void
    onClose(id: ToastId): void
    onCloseAll(): void
}
