import { ApiObjectTypeExtended } from '@/types'

export enum AuditTrailFilterByOption {
    OBJECT = 'object_id',
    AUTHOR = 'author_id'
}

export interface AuditTrailPageProps {
    objectType?: ApiObjectTypeExtended
    filterBy?: AuditTrailFilterByOption
}
