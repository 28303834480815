import { ARROW_SEPARATOR } from '@/constants'
import { useActivatedFeatures, usePermissions } from '@/hooks'
import { contactSupport } from '@/utils'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { EmptyContent } from '../EmptyContent'
import restrictedSection from './@assets/restrictedSection.svg'
import { RestrictedContentProps } from './RestrictedSection.types'

export const RestrictedSection: React.FC<PropsWithChildren<RestrictedContentProps>> = ({
    children,
    feature,
    permission
}) => {
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()
    const intl = useIntl()

    if (feature && !isFeatureActivated(feature)) {
        return (
            <EmptyContent
                imageSrc={restrictedSection}
                title={intl.formatMessage({ id: 'app.common.feature_disabled.title' })}
                content={intl.formatMessage({ id: 'app.common.feature_disabled.message' })}
                propsButton={{
                    onClick: contactSupport,
                    rightIcon: <>{ARROW_SEPARATOR}</>,
                    children: intl.formatMessage({ id: 'app.common.actions.contact_us' })
                }}
            />
        )
    }

    if (permission && !hasPermission(permission)) {
        return (
            <EmptyContent
                imageSrc={restrictedSection}
                title={intl.formatMessage({ id: 'app.common.unauthorized.title' })}
                content={intl.formatMessage({ id: 'app.common.unauthorized.message' })}
            />
        )
    }

    return <>{children}</>
}
