import { PropsWithChildren, useState } from 'react'
import { StickyFooter } from '@/components'
import { Box, Flex } from '@chakra-ui/react'

export const UserInviteFormStickyFooter: React.FC<PropsWithChildren> = ({ children }) => {
    const [marginBottom, setMarginBottom] = useState<number>(0)

    return (
        <Box className="UserInviteFormStickyFooter" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Flex gap="16px" flex="1" paddingY="16px" justifyContent="end">
                    {children}
                </Flex>
            </StickyFooter>
        </Box>
    )
}
