import { Duration, PaymentType } from '@/components/@misc'
import { Tag } from '@chakra-ui/react'
import { Direction, PaymentOrderType } from '@webapps/numeral-ui-core'

export function getWaitDurationTag(retryAfter: string) {
    return (
        <Tag title={retryAfter} color="numeralAccent.500" backgroundColor="gray.100">
            <Duration duration={retryAfter} />
        </Tag>
    )
}

export function getPaymentOrderTypeTag(direction: Direction, paymentType: PaymentOrderType) {
    return (
        <Tag title={paymentType} color="numeralAccent.500" backgroundColor="gray.100">
            <PaymentType direction={direction} type={paymentType} />
        </Tag>
    )
}
