import { Form, FormPageFooter } from '@/components'
import { CustomFieldsSection } from '@/pages'
import { ApiObjectTypeSchema, CounterpartyAccount } from '@webapps/numeral-ui-core'
import { chain, isEmpty, noop } from 'lodash'
import React, { memo, useCallback } from 'react'

interface CounterpartyAccountEditFormProps {
    data?: CounterpartyAccount
    disabled?: boolean
    loading?: boolean
    onSubmit?(data: CounterpartyAccount): void
}

export const CounterpartyAccountEditForm: React.FC<CounterpartyAccountEditFormProps> = memo(
    ({ data, loading, disabled, onSubmit = noop }) => {
        const onSubmitWithCustomFields = useCallback(
            (item: typeof data) => {
                const customFields = chain(item).pick('custom_fields').value()

                onSubmit(customFields)
            },
            [onSubmit]
        )

        return (
            <Form initialValues={data} onSubmit={onSubmitWithCustomFields}>
                {({ handleSubmit, dirty }) => {
                    const isDisabled = loading || disabled
                    return (
                        <>
                            <CustomFieldsSection<typeof data>
                                objectType={ApiObjectTypeSchema.enum.counterparty_account}
                                isDisabled={isDisabled}
                                isExpanded={!isEmpty(data?.custom_fields)}
                            />
                            <FormPageFooter
                                onSubmit={handleSubmit}
                                isDisabled={disabled || !dirty}
                                isLoading={loading}
                                showUnsavedChangesWarning={true}
                            />
                        </>
                    )
                }}
            </Form>
        )
    }
)
