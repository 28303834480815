import { FlexProps } from '@chakra-ui/layout/dist/flex'

export const BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION = '14px'

export const BULK_ACTIONS_TABLE_CELL_CONTAINER_STYLE: FlexProps = {
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    marginX: `-${BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION}`,
    marginY: `-${BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION}`,
    padding: BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION
}
