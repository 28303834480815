import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import {
    useMutationRetryFailedEvents,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllEvents,
    useQueryParams,
    useToasts
} from '@/hooks'
import { PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ButtonProps, Link } from '@chakra-ui/react'
import { EventsServiceFindAllQueryOptions, NumeralEvent, Uuid } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    EVENTS_DOCUMENTATION_LINK,
    EVENTS_FILTERS,
    EVENTS_TABLE_COLUMN_CUSTOMIZERS,
    EVENTS_TABLE_STATIC_STATE
} from './EventsList.page.const'
import { TupleFromObjectValues } from '@/types'

interface EventsListPageProps {
    webhookId?: Uuid
}

export const EventsListPage: React.FC<EventsListPageProps> = ({ webhookId }) => {
    const intl = useIntl()
    const { filterBy } = useQueryParams<EventsServiceFindAllQueryOptions>({
        filterBy: {
            configuration: {
                filters: EVENTS_FILTERS
            }
        }
    })
    const { hasPermission } = usePermissions()
    const { onAdd } = useToasts()
    const { paths } = useNavigationRoutes()
    const query = useQueryFindAllEvents({
        ...filterBy.parsedState,
        webhook_id: webhookId
    })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo(() => isEmpty(data), [data])

    const retryFailed = webhookId
        ? undefined
        : useMutationRetryFailedEvents({
              onSuccess() {
                  onAdd({
                      variant: TOAST_VARIANTS.SUCCESS,
                      status: 'success',
                      isClosable: true,
                      title: <FormattedMessage id="app.developers.events.retry_send.success.toast.title" />,
                      description: <FormattedMessage id="app.developers.events.retry_send.success.toast.description" />
                  })
              },
              onError() {
                  onAdd({
                      variant: TOAST_VARIANTS.ERROR,
                      status: 'error',
                      isClosable: true,
                      title: <FormattedMessage id="app.developers.events.retry_send.error.toast.title" />,
                      description: <FormattedMessage id="app.developers.events.retry_send.error.toast.description" />
                  })
              }
          })

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.developers.events.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={EVENTS_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.developers.events.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.developers.events.empty_content.subtitle' }, { learnMoreLink })
            }
        }
    }, [intl, isDataEmpty, filterBy])

    const actions = useMemo<ButtonProps[] | undefined>(() => {
        if (!hasPermission(PERMISSION.DEVELOPERS_RESEND_FAILED_EVENTS) || webhookId) {
            return
        }

        return [
            {
                isDisabled: query.isLoading,
                onClick() {
                    retryFailed?.mutate()
                },
                children: intl.formatMessage({ id: 'app.developers.events.actions.retry_send.label' })
            }
        ]
    }, [hasPermission, query, intl, retryFailed, webhookId])
    const tableRowClickConfiguration = useMemo(() => {
        const result = []
        if (webhookId) {
            const absoluteEventPath = paths.DEVELOPERS.EVENTS
            result.push(absoluteEventPath, true)
        }

        return result
    }, [webhookId, paths])
    const onTableRowClick = useTableRowNavigation<NumeralEvent>(
        'id',
        ...(tableRowClickConfiguration as TupleFromObjectValues<any>)
    )

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_EVENTS}>
                <TableHeader<EventsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.event}
                    isLoading={query.isLoading}
                    actions={actions}
                    filterBy={filterBy}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isDataEmpty:
                        case query.isError: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<NumeralEvent>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    columnCustomizers={EVENTS_TABLE_COLUMN_CUSTOMIZERS}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={EVENTS_TABLE_STATIC_STATE}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
