import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useMutationDisableByIdUser } from '@/hooks'
import { useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

interface UserDisableConfirmationModalProps extends UseDisclosureProps {
    uuid?: Uuid
}

export const UserDisableConfirmationModal: React.FC<UserDisableConfirmationModalProps> = ({
    uuid,
    isOpen,
    onClose = noop
}) => {
    const cancelRef = useRef(null)
    const mutation = useMutationDisableByIdUser(uuid, {
        onSuccess: onClose
    })
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])

    const onDisableUser = useCallback(() => {
        mutation.mutate()
    }, [mutation])

    return (
        <AlertDialog isOpen={isOpen as boolean} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage id="app.settings.users.disable_modal.title" />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage id="app.settings.users.disable_modal.description" />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            disabled={mutation.isPending}>
                            <FormattedMessage id="app.settings.users.disable_modal.button.close.label" />
                        </Button>
                        <Button onClick={onDisableUser} isLoading={mutation.isPending}>
                            <FormattedMessage id="app.settings.users.disable_modal.button.primary.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
