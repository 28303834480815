import { Icon, IconProps } from '@chakra-ui/react'

export const WarningIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" data-testid="warning-icon" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99984 18.3337C5.39734 18.3337 1.6665 14.6028 1.6665 10.0003C1.6665 5.39783 5.39734 1.66699 9.99984 1.66699C14.6023 1.66699 18.3332 5.39783 18.3332 10.0003C18.3332 14.6028 14.6023 18.3337 9.99984 18.3337ZM9.1665 12.5003V14.167H10.8332V12.5003H9.1665ZM9.1665 5.83366V10.8337H10.8332V5.83366H9.1665Z"
        />
    </Icon>
)
