import { Box, Flex, Heading } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { FormattedMessage } from 'react-intl'

interface FormSectionProps extends PropsWithChildren {
    titleId?: string
    title?: React.ReactNode
}

export const FormSection: React.FC<FormSectionProps> = ({ titleId, title, children }) => {
    return (
        <Box width="100%">
            <Flex justifyContent="space-between" alignItems="center">
                {titleId && (
                    <Heading size="md" fontWeight="semibold" fontSize="16px">
                        <FormattedMessage id={titleId} />
                    </Heading>
                )}
                {title && <Flex>{title}</Flex>}
            </Flex>
            <Flex direction="column" marginTop="24px" gap="16px">
                {children}
            </Flex>
        </Box>
    )
}
