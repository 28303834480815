import { FilesIcon } from '@/components/@icons'
import { setTimeout } from '@/utils'
import { FormatterFunction } from '@/utils/@formatters'
import { Button, ButtonProps, Text, Tooltip } from '@chakra-ui/react'
import { noop } from '@tanstack/react-table'
import { Fragment, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

interface CopyToClipboardButtonProps {
    value: string | number | readonly string[]
    formatter?: FormatterFunction
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ value, formatter }) => {
    const intl = useIntl()
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)

    const title = useRef<string>(
        intl.formatMessage({
            id: 'app.common.actions.copy.title'
        })
    ).current

    const label = useMemo<string>(() => intl.formatMessage({ id: 'app.common.actions.copy.label' }), [intl])
    const confirmation = useRef<string>(
        intl.formatMessage({
            id: 'app.common.actions.copy.confirmation'
        })
    ).current
    const ariaLabelText = useRef<string>(
        intl.formatMessage({
            id: 'app.common.actions.copy.aria_label'
        })
    ).current

    const CopyToClipboardWrapper = useMemo(() => {
        const TooltipWithProps: React.FC<PropsWithChildren> = ({ children }) => (
            <Tooltip hasArrow={true} label={confirmation} aria-label={ariaLabelText} placement="top">
                {children}
            </Tooltip>
        )

        return isTooltipVisible ? TooltipWithProps : Fragment
    }, [ariaLabelText, isTooltipVisible, confirmation])

    const onClick = useCallback(() => {
        const text = formatter?.(value) || value?.toString()

        if (!text?.length) {
            return
        }

        globalThis.navigator.clipboard
            .writeText(text)
            .then(() => setIsTooltipVisible(true))
            .catch(noop)
    }, [value, formatter])

    const propsButton = useMemo<ButtonProps>(() => {
        return {
            width: '100px',
            height: 'auto',
            padding: 0,
            _hover: {
                color: 'gray.800'
            },
            leftIcon: <FilesIcon />
        }
    }, [])

    useEffect(() => {
        let cancelTimeout: CallableFunction

        if (isTooltipVisible) {
            cancelTimeout = setTimeout(setIsTooltipVisible, 500)
        }

        return () => cancelTimeout?.()
    }, [isTooltipVisible])

    return (
        <CopyToClipboardWrapper>
            <Button {...propsButton} title={title} color="gray.500" onClick={onClick} variant="ghost">
                <Text>{label}</Text>
            </Button>
        </CopyToClipboardWrapper>
    )
}
