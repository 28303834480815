import { RestrictedSection } from '@/components'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { Box } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useMutationCreateCustomField, useNavigationRoutes, useToasts } from '@/hooks'
import { useMemo } from 'react'
import { TOAST_VARIANTS } from '@/providers'
import { CustomFieldErrorNotification, CustomFieldForm } from '../@components'
import { chain } from 'lodash'

export const CustomFieldCreatePage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.settings.custom_fields.create.title' }), [intl])
    const mutation = useMutationCreateCustomField({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { SETTINGS } = paths
            const path = [SETTINGS.CUSTOM_FIELDS, response.id, relativePaths.SETTINGS.DETAILS].join('/')

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.custom_fields.create.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.custom_fields.create.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.custom_fields.create.form.validation.error.toast.title" />,
                description: <CustomFieldErrorNotification error={error} />
            })
        }
    })

    return (
        <PageLayout className="CustomFieldCreate">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.SETTINGS_CREATE_CUSTOM_FIELDS}>
                <Box marginTop="8px">
                    <CustomFieldForm
                        onSubmit={mutation.mutate}
                        disabled={mutation.isPending}
                        loading={mutation.isPending}
                        submitLabelMessageId="app.settings.custom_fields.create.form.actions.submit.label"
                    />
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
