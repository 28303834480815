import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex } from '@chakra-ui/react'
import { CounterpartyAccount, CounterpartyAccountVerificationService } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getVerificationMessageIconFromResult } from './CounterpartyAccountVerificationResultDetails.utils'

type CounterpartyAccountVerificationResultDetailsProps = {
    counterpartyAccount: CounterpartyAccount
    verificationService: CounterpartyAccountVerificationService
}

export const CounterpartyAccountVerificationResultDetails: React.FC<CounterpartyAccountVerificationResultDetailsProps> =
    memo(({ counterpartyAccount, verificationService }) => {
        const verification = counterpartyAccount?.account_verification?.[verificationService]
        const result = verification?.result
        const reason = verification?.details.reason
        const expectedValue = verification?.details?.expected_value
        const accountNumber = counterpartyAccount?.account_number
        const message = verification?.details.message

        const icon = getVerificationMessageIconFromResult(result)
        let messageKey = `app.counterparties.counterparty_accounts.tabs.verifications.${verificationService}.${result}`

        if (!counterpartyAccount?.account_verification || !result) {
            return <>{EMPTY_VALUE_PLACEHOLDER} </>
        }

        if (reason) {
            messageKey += `.${reason}`
        }

        return (
            <Flex alignItems="center" gap="8px">
                {icon}
                <FormattedMessage id={messageKey} defaultMessage={message} values={{ expectedValue, accountNumber }} />
            </Flex>
        )
    })
