import { Icon, IconProps } from '@chakra-ui/react'

export const FilterIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="16px" viewBox="0 -4 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z"
        />
    </Icon>
)
