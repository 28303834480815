import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdAPIKey } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { APIKeyDetailsForm } from './@components'

export const APIKeysDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdAPIKey(uuid)

    return (
        <PageLayout className="APIKeyDetails">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_API_KEYS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError || isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return <APIKeyDetailsForm data={query?.data} />
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
