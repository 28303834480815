import { memo, useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { FormattedAmount } from '../FormattedAmount/FormattedAmount'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { Separator, SeparatorVariant } from '../Separator'
import { If } from '@/components/@misc'

export interface AmountRangeProps {
    from: number
    to: number
    currency: CurrencyCode
}

export const AmountRange: React.FC<AmountRangeProps> = memo(({ from, to, currency }) => {
    const areAmountsDifferent = useMemo(() => {
        return from !== to
    }, [from, to])

    return (
        <Text as="span">
            <FormattedAmount amount={from} currency={currency} />
            <If condition={areAmountsDifferent}>
                <Separator variant={SeparatorVariant.Dash} />
                <FormattedAmount amount={to} currency={currency} showCurrencyCode={false} />
            </If>
        </Text>
    )
})
