import { TableHeaderActionProps } from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    If,
    PaymentType,
    Separator,
    SeparatorVariant
} from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import {
    useMutationCancelByIdPaymentOrder,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdConnectedAccount,
    useQueryFindByIdPaymentOrder
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import {
    isPaymentOrderReturnAvailable,
    issPaymentOrderApprovedStatus,
    issPaymentOrderPendingApprovalStatus,
    PERMISSION
} from '@/services'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseReconcileEntityHeaderProps } from '@/pages'

export type PaymentOrderHeaderProps = BaseReconcileEntityHeaderProps

export const PaymentOrderHeader: React.FC<PaymentOrderHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdPaymentOrder(uuid || id)
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(query.data?.connected_account_id, {
        enabled: globalThis.Boolean(query.data?.connected_account_id)
    })
    const mutationCancel = useMutationCancelByIdPaymentOrder(uuid || id)
    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS)) {
            return null
        }

        const displayDate = query.data.created_at
        const connectedAccountId = query.data.connected_account_id
        const showConnectedAccount = hasPermission(PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS) && !!connectedAccountId
        const accountHolderName = query.data.receiving_account?.holder_name
        const hasPaymentType = globalThis.Boolean(query.data.type && query.data.direction)

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <Text as="span">
                    <FormattedAmount amount={query.data.amount} currency={query.data.currency} />
                </Text>
                <If condition={hasPaymentType}>
                    {SPACE_SYMBOL}
                    <PaymentType type={query.data.type} direction={query.data.direction} />
                </If>
                <If condition={showConnectedAccount}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <ConnectedAccountName connectedAccountId={connectedAccountId} asPlainText={true} />
                </If>
                <If condition={!!accountHolderName}>
                    <Separator variant={SeparatorVariant.Arrow} />
                    <Text as="span">{accountHolderName}</Text>
                </If>
                <If condition={!!displayDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={displayDate} />
                </If>
            </>
        )
    }, [isReconciling, query, hasPermission])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []

        if (isReconciling) {
            return
        }

        const hasReturnAvailable = isPaymentOrderReturnAvailable(query.data, queryConnectedAccount.data)
        const hasPendingApprovalStatus = issPaymentOrderPendingApprovalStatus(query.data)
        const hasApprovedStatus = issPaymentOrderApprovedStatus(query.data)

        if (hasPermission(PERMISSION.PAYMENTS_CREATE_RETURNS) && hasReturnAvailable) {
            actions.push({
                onClick() {
                    const path = [paths.PAYMENTS.PAYMENT_ORDERS, uuid, relativePaths.PAYMENTS.RETURN].join('/')
                    navigate(path)
                },
                isDisabled: !hasReturnAvailable,
                children: intl.formatMessage({ id: 'app.payments.payment_orders.tabs.details.actions.return.label' })
            })
        }

        if (hasPermission(PERMISSION.PAYMENTS_EDIT_PAYMENT_ORDERS)) {
            actions.push({
                onClick() {
                    const path = [paths.PAYMENTS.PAYMENT_ORDERS, uuid, relativePaths.PAYMENTS.EDIT].join('/')
                    navigate(path)
                },
                children: intl.formatMessage({
                    id: 'app.common.actions.edit.label'
                })
            })
        }

        if (
            hasPermission(PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS) &&
            (hasPendingApprovalStatus || hasApprovedStatus)
        ) {
            actions.push({
                onClick() {
                    mutationCancel.mutate()
                },
                children: intl.formatMessage({
                    id: 'app.common.actions.cancel.label'
                }),
                isDisabled: mutationCancel.isPending
            })
        }

        return actions
    }, [
        isReconciling,
        query,
        queryConnectedAccount,
        mutationCancel,
        hasPermission,
        navigate,
        intl,
        paths,
        relativePaths
    ])

    return <PageHeaderLayout {...props} title={title} isLoading={query.isLoading} actions={actions} />
}
