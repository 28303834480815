import { AlertMessage, Form, FormPageFooter } from '@/components'
import { DenyReasonInput } from '@/components/@inputs'
import { ReturnRequestDenyData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import React, { useRef } from 'react'

interface ReturnRequestDenyFormProps {
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: ReturnRequestDenyData): void
}

export const ReturnRequestDenyForm: React.FC<ReturnRequestDenyFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useRef<Partial<ReturnRequestDenyData>>({
        return_reason: undefined
    }).current

    return (
        <Form<Partial<ReturnRequestDenyData>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, dirty }) => {
                return (
                    <>
                        <AlertMessage
                            titleKey="app.payments.return_requests.deny.alert.title"
                            descriptionKey="app.payments.return_requests.deny.alert.description"
                            isClosable={false}
                        />
                        <DenyReasonInput
                            name="return_reason"
                            isPlaceholderDisplayed={true}
                            isRequired={true}
                            isDisabled={disabled}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isLoading={loading}
                            isDisabled={disabled || !dirty}
                            submitLabelMessageId="app.payments.return_requests.deny.form.actions.submit.label"
                            showUnsavedChangesWarning={false}
                        />
                    </>
                )
            }}
        </Form>
    )
}
