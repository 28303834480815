import {
    CounterpartyAccountVerification,
    CounterpartyAccountVerificationResult,
    CounterpartyAccountVerificationResultSchema
} from '@webapps/numeral-ui-core'
import { CounterpartyAccountVerificationStatusValue } from './CounterpartyAccountVerificationStatus.types'
import { chain, isObjectLike } from 'lodash'
import { Nullable } from '@/types'

export function getCounterpartyAccountVerificationResults(
    accountVerification: Nullable<CounterpartyAccountVerification>
): CounterpartyAccountVerificationResult[] {
    return chain(accountVerification).values().filter(isObjectLike).map('result').value()
}

export function getTotalValidCounterpartyAccountVerifications(
    results: CounterpartyAccountVerificationResult[]
): [number, number] {
    let valid = 0
    let total = 0

    for (const result of results) {
        if (result !== CounterpartyAccountVerificationResultSchema.enum.not_verified) {
            total++
        }

        if (result === CounterpartyAccountVerificationResultSchema.enum.valid) {
            valid++
        }
    }

    return [valid, total]
}

function getTextColorAndBackgroundColor(numberOfValidVerifications: number, numberOfTotalVerifications: number) {
    switch (true) {
        case numberOfValidVerifications === numberOfTotalVerifications: {
            return {
                color: 'green.600',
                backgroundColor: 'green.100'
            }
        }

        case numberOfValidVerifications === 0: {
            return {
                color: 'red.600',
                backgroundColor: 'red.100'
            }
        }

        default: {
            return { color: 'orange.600', backgroundColor: 'orange.100' }
        }
    }
}

export function getCounterpartyAccountVerificationStatusTagProps(
    accountVerification?: Nullable<CounterpartyAccountVerification>
): {
    messageKey: string
    color: string
    backgroundColor: string
    numberOfValidVerifications?: number
    numberOfTotalVerifications?: number
} {
    const messageKeyPrefix = 'app.common.counterparty_account.verification_status.'
    const messageKeySuffix = '.label'

    if (!accountVerification) {
        return {
            messageKey: `${messageKeyPrefix}${CounterpartyAccountVerificationStatusValue.NOT_VERIFIED}${messageKeySuffix}`,
            color: 'gray.600',
            backgroundColor: 'gray.100'
        }
    }

    const applicableVerifications = getCounterpartyAccountVerificationResults(accountVerification)
    const [valid, total] = getTotalValidCounterpartyAccountVerifications(applicableVerifications)

    return {
        messageKey: `${messageKeyPrefix}${CounterpartyAccountVerificationStatusValue.VERIFIED}${messageKeySuffix}`,
        ...getTextColorAndBackgroundColor(valid, total),
        numberOfValidVerifications: valid,
        numberOfTotalVerifications: total
    }
}
