import {
    EmptyContent,
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    LoadingSpinner,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdIncomingPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import {
    CounterpartyCustomRows,
    getPaymentCounterpartyDetailRowCustomizers,
    getPaymentCounterpartyDetailRows,
    pickPaymentAccounts
} from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const IncomingPaymentCounterpartyPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdIncomingPayment(uuid)
    const rows = useMemo<EntityDetailRow<CounterpartyCustomRows>[]>(() => {
        return getPaymentCounterpartyDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<CounterpartyCustomRows>>(() => {
        return getPaymentCounterpartyDetailRowCustomizers(query.data)
    }, [query])
    const data = useMemo(() => {
        return pickPaymentAccounts(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<CounterpartyCustomRows>
                                    data={data}
                                    rows={rows}
                                    rowCustomizers={rowCustomizers}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
