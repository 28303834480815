import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

export const ExpectedPaymentDescriptions: React.FC<{ value?: string[] }> = ({ value }) => {
    if (!value?.length) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Flex direction="column" gap="8px">
            {value.filter(globalThis.Boolean).map((item, index) => (
                <Text key={index}>{item}</Text>
            ))}
        </Flex>
    )
}
