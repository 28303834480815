import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdReturn } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Return } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { RETURN_DETAILS_ROWS, RETURN_DETAILS_ROW_CUSTOMIZERS } from './ReturnDetails.page.const'
import { ReturnDetailsCustomRows } from './ReturnDetails.types'

export const ReturnDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdReturn(uuid)

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.RETURN} permission={PERMISSION.PAYMENTS_VIEW_RETURNS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError || isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<Return, ReturnDetailsCustomRows>
                                    data={query.data}
                                    rows={RETURN_DETAILS_ROWS}
                                    rowCustomizers={RETURN_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
