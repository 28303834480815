import { Icon, IconProps } from '@chakra-ui/react'

export const FilesIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <path
            d="M5.17448 11.9491H10.5078V10.6158H5.17448V11.9491ZM5.17448 9.28247H10.5078V7.94914H5.17448V9.28247ZM3.84115 14.6158C3.47448 14.6158 3.1607 14.4854 2.89981 14.2245C2.63848 13.9631 2.50781 13.6491 2.50781 13.2825V2.6158C2.50781 2.24914 2.63848 1.93514 2.89981 1.6738C3.1607 1.41292 3.47448 1.28247 3.84115 1.28247H9.17448L13.1745 5.28247V13.2825C13.1745 13.6491 13.044 13.9631 12.7831 14.2245C12.5218 14.4854 12.2078 14.6158 11.8411 14.6158H3.84115ZM8.50781 5.94914V2.6158H3.84115V13.2825H11.8411V5.94914H8.50781Z"
            fill="currentColor"
        />
    </Icon>
)
