import { FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { CounterpartyAccountsInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { approvedCounterpartyAccountsFilter } from '@/services'
import { Switch, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentOrderReceivingAccountSectionAutoReset } from './PaymentOrderReceivingAccountSectionAutoReset'
import { PaymentOrderReceivingAccountSectionDetails } from './PaymentOrderReceivingAccountSectionDetails'

export interface PaymentOrderReceivingAccountSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderReceivingAccountSection: React.FC<PaymentOrderReceivingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps } = useFormikContext<PaymentOrder>()
    const { value: receivingAccountId } = getFieldProps('receiving_account_id')
    const { value: directDebitMandateId } = getFieldProps('direct_debit_mandate_id')

    const hasDirectDebitMandateId = useMemo(() => globalThis.Boolean(directDebitMandateId), [directDebitMandateId])
    const [useExistingCounterparty, setUseExistingCounterparty] = useState(hasDirectDebitMandateId)
    const onToggleUseExistingCounterparty = useCallback(() => {
        setUseExistingCounterparty((prevState) => {
            return !prevState
        })
    }, [])

    useEffect(() => {
        if (hasDirectDebitMandateId) {
            setUseExistingCounterparty(false)
        }
    }, [hasDirectDebitMandateId])

    return (
        <FormSection
            titleId="app.payments.payment_orders.create.form.sections.receiving_account.title"
            title={
                <Switch
                    data-testid="toggle-existing-receiving-account-switch"
                    size="sm"
                    isChecked={useExistingCounterparty}
                    onChange={onToggleUseExistingCounterparty}
                    disabled={isDisabled || hasDirectDebitMandateId}>
                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.receiving_account.title.alternative" />
                </Switch>
            }>
            <If condition={hasDirectDebitMandateId}>
                <Text color="gray.400">
                    <FormattedMessage id="app.payments.payment_orders.create.form.sections.receiving_account.has_direct_debit_mandate_id.title" />
                </Text>
            </If>

            <If condition={useExistingCounterparty && !hasDirectDebitMandateId}>
                <CounterpartyAccountsInput
                    name="receiving_account_id"
                    value={receivingAccountId}
                    isPlaceholderDisplayed={true}
                    isAccountVerificationStatusDisplayed={true}
                    customPlaceholderKey="app.payments.payment_orders.create.form.inputs.receiving_account_id.placeholder"
                    queryDataFilter={approvedCounterpartyAccountsFilter}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <PaymentOrderReceivingAccountSectionDetails id={receivingAccountId} />
            </If>
            <If condition={!useExistingCounterparty && !hasDirectDebitMandateId}>
                <AccountDetailsForm
                    inputsNamePrefix="receiving_account"
                    isLookupAvailable={true}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <OptionalAccountHolderAddressForm sectionName="receiving_account" isDisabled={isDisabled} />
            </If>
            <PaymentOrderReceivingAccountSectionAutoReset useExistingCounterparty={useExistingCounterparty} />
        </FormSection>
    )
}
