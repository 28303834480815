import { chain, isString } from 'lodash'

export function tableRowNavigationURLBuilder(isAppend: boolean, ...urlFragments: any[]) {
    const result = chain([...urlFragments])
        .filter(isString)
        .value()

    if (isAppend) {
        result.reverse()
    }
    return result.join('/')
}
