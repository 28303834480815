import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdEvent } from '@/hooks'
import { PageLayout } from '@/layouts'
import { EventForm } from '@/pages'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { EventDetailsHeader } from './@components'

export const EventDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const query = useQueryFindByIdEvent(uuid)

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_EVENTS}>
                <EventDetailsHeader />
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError: {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.error.title'
                                    })}
                                />
                            )
                        }

                        case isEmpty(query?.data): {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.not_found.title'
                                    })}
                                />
                            )
                        }

                        default: {
                            return <EventForm data={query?.data} readonly={true} />
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
