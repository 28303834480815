import { QueryParamStateFilterBy, usePage, usePersistedLocationState } from '@/hooks'
import { RECONCILE_ENTITY_PAGE_CONTEXT, useReconciliation } from '@/pages'
import { Payment } from '@/types'
import { Transaction } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { PERSISTED_STORE_QUICK_FILTER_STATE } from './QuickFilterProvider.const'
import { QuickFilterContext } from './QuickFilterProvider.context'
import { QuickFilterParamTypes, QuickFilterProviderProps } from './QuickFilterProvider.types'

export function QuickFilterProvider<T>({ children, filterBy }: QuickFilterProviderProps<T>) {
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const { targetQuickFilter, onCancelReconciliation } = useReconciliation()
    const [persistence, setPersistence] = usePersistedLocationState<QueryParamStateFilterBy<T>>(
        PERSISTED_STORE_QUICK_FILTER_STATE,
        QuickFilterParamTypes.QuickFilter
    )
    const initialState = useMemo<typeof persistence>(() => {
        const hasPreselectedFilterBy = !isEmpty(filterBy.configuration?.preselected)

        if (isEmpty(persistence) && hasPreselectedFilterBy) {
            return filterBy.configuration?.preselected
        }

        return persistence
    }, [persistence, filterBy.configuration])

    const [state, setState] = useState<typeof initialState>(initialState)
    const onChange = useCallback(
        (newValue?: typeof persistence) => {
            if (chain(state).isEqual(newValue).value()) {
                return
            }

            const isNewValueUnreconciledQuickFilterState = chain(newValue).isEqual(targetQuickFilter).value()

            if (reconcileEntityNonPersistedState && !isNewValueUnreconciledQuickFilterState) {
                onCancelReconciliation()
            }

            setState(newValue)
            setPersistence(newValue)

            filterBy.setState(newValue)
        },
        [state, filterBy, reconcileEntityNonPersistedState]
    )
    const onReset = useCallback(() => onChange(undefined), [onChange])

    return (
        <QuickFilterContext.Provider
            value={{
                quickFilters: filterBy?.configuration?.quickFilters,
                state,
                onChange,
                onReset
            }}>
            {children}
        </QuickFilterContext.Provider>
    )
}
