import React, { PropsWithChildren, ReactElement, useRef } from 'react'
import { Fade } from '@chakra-ui/react'

export const FileUploadIconWrapper: React.FC<PropsWithChildren & { color?: string }> = ({ children, ...props }) => {
    const iconDimension = useRef('33px').current

    return (
        <Fade in={true}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child as ReactElement, {
                    ...props,
                    width: iconDimension,
                    height: iconDimension,
                    marginY: '16px'
                })
            })}
        </Fade>
    )
}
