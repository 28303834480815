import { DetailPageLink } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { isCorporateConnectedAccount } from '@/services'
import {
    ApiObjectTypeSchema,
    ConnectedAccount,
    DirectDebitMandate,
    ExpectedPayment,
    IncomingPayment,
    PaymentOrder
} from '@webapps/numeral-ui-core'

interface CounterpartyRelatedObjectsProps {
    accountKey?: 'originating_account' | 'receiving_account'
    value?: PaymentOrder | IncomingPayment | ExpectedPayment | DirectDebitMandate
    connectedAccount?: ConnectedAccount
}

export const CounterpartyRelatedObjects: React.FC<CounterpartyRelatedObjectsProps> = ({
    accountKey,
    value,
    connectedAccount
}) => {
    const paymentOrder = value as PaymentOrder
    const isCorporateAccount = isCorporateConnectedAccount(connectedAccount)
    const hasReceivingAccount = globalThis.Boolean(paymentOrder?.receiving_account_id)

    if (isCorporateAccount) {
        return (
            <DetailPageLink
                objectId={connectedAccount?.id as string}
                objectType={ApiObjectTypeSchema.enum.connected_account}
            />
        )
    }

    if (hasReceivingAccount && accountKey === 'receiving_account') {
        return (
            <DetailPageLink
                objectId={paymentOrder?.receiving_account_id as string}
                objectType={ApiObjectTypeSchema.enum.counterparty_account}
            />
        )
    }

    return <>{EMPTY_VALUE_PLACEHOLDER}</>
}
