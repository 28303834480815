import { ToastId, ToastPosition, UseToastOptions } from '@chakra-ui/toast'
import { chain, noop } from 'lodash'
import { toast, TOAST_CONTAINER_STYLE, TOAST_DURATIONS, TOAST_MESSAGES } from './ToastsProvider.const'
import { TOAST_VARIANTS } from './ToastsProvider.types'

export function toastOptionsFactory(
    options: Partial<UseToastOptions>,
    onCloseComplete: (...args: any) => any = noop
): UseToastOptions {
    const debouncedOnCloseComplete = chain(onCloseComplete).debounce(250).value()
    return {
        variant: TOAST_VARIANTS.INFO,
        duration: TOAST_DURATIONS.MAX,
        isClosable: true,
        ...options,
        position: 'top-right' as ToastPosition,
        containerStyle: TOAST_CONTAINER_STYLE,
        orientation: 'vertical',
        onCloseComplete: debouncedOnCloseComplete
    }
}

/**
 * @description
 * - Public API to `add` application toasters;
 * - Can be used both in declarative (React APIs) and imperative direct calls;
 * - Every message is subscribed to the same `Toast` instance;
 */
export function addToast(options: UseToastOptions, onClose?: (...args: any) => void): ToastId {
    const toastOptions = toastOptionsFactory(options, () => {
        onClose?.(id)
        closeToast(id)
    })
    const id: ToastId = toast(toastOptions)

    TOAST_MESSAGES.addOrUpdateItem({
        id,
        ...toastOptions
    })

    return id
}

/**
 * @description
 * - Public API to `update` application toasters;
 */
export function updateToast(id: ToastId, options: UseToastOptions): void {
    if (toast.isActive(id)) {
        TOAST_MESSAGES.addOrUpdateItem(options)
        toast.update(id, { ...options })
    }
}

/**
 * @description
 * - Public API to `remove/close`  application toasters;
 */
export function closeToast(id?: ToastId): void {
    if (!id) {
        return
    }

    TOAST_MESSAGES.removeItem(id)
    toast.close(id)
}

/**
 * @description
 * - Public API to `remove all` application toasters;
 */
export function closeAllToasts() {
    TOAST_MESSAGES.removeAll()
    toast.closeAll()
}
