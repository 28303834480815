import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdRole } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { RoleDetailsContent, RoleDetailsHeader } from './@components'

export const RoleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdRole(uuid)

    return (
        <PageLayout className="RoleDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_ROLES}>
                <RoleDetailsHeader />
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return <RoleDetailsContent data={query.data} />
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
