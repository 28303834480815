export function isValidJSONString(value?: string) {
    try {
        if (value) {
            globalThis.JSON.parse(value)
        }
    } catch (error) {
        return false
    }
    return true
}
