import { Form, FormPageFooter, InputWithCopyToClipboard } from '@/components'
import { Webhook } from '@/services'
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { Field, FormikValues } from 'formik'
import { noop } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
    getWebhookFormInitialValues,
    getWebhookFormValidationSchemaWithIntl,
    getWebhookRulesModeInitialValue,
    isAllWebhookRulesEnabled
} from './WebhookCreateOrEdit.form.utils'
import { WEBHOOK_RULES_MODES } from './WebhookCreateOrEdit.form.const'

interface WebhookCreateOrEditFormProps {
    data?: Webhook
    isLoading?: boolean
    disabled?: boolean
    onSubmit(data: Partial<Webhook>): void
}

export const WebhookCreateOrEditForm: React.FC<WebhookCreateOrEditFormProps> = ({
    data,
    disabled,
    isLoading,
    onSubmit = noop
}) => {
    const intl = useIntl()
    const [rulesMode, setRulesMode] = useState<string>(getWebhookRulesModeInitialValue(data))
    const isCreateMode = useRef<boolean>(Boolean(!data?.id)).current
    const initialValues = useRef(getWebhookFormInitialValues(data)).current
    const validationSchemaWithIntl = useMemo(() => getWebhookFormValidationSchemaWithIntl(intl), [intl])
    const onFormSubmit = useCallback(async (values: FormikValues) => {
        const data: Partial<Webhook> = {
            ...values,
            rules: {
                [WEBHOOK_RULES_MODES.ALL]: [WEBHOOK_RULES_MODES.ALL]
            }
        }

        onSubmit(data)
    }, [])
    const isRulesEnabled = useMemo<boolean>(() => isAllWebhookRulesEnabled(rulesMode), [rulesMode])
    const submitLabelMessageId = useMemo<string | undefined>(() => {
        return isCreateMode ? 'app.developers.webhooks.form.actions.submit.label' : undefined
    }, [isCreateMode])

    return (
        <Form<Webhook>
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validationSchema={validationSchemaWithIntl}
            validateOnChange={false}>
            {({ errors, touched, handleSubmit, dirty, setFieldValue }) => {
                const isInputDisabled = !isCreateMode || isLoading
                /*
                const onToggleRulesMode = (value: string) => {
                    const rulesValue = isAllWebhookRulesEnabled(value) ? null : values?.rules

                    setFieldValue('rules', rulesValue)
                    setRulesMode(value)
                }*/

                return (
                    <>
                        <FormControl hidden={isCreateMode}>
                            <FormLabel htmlFor="id">
                                {intl.formatMessage({
                                    id: 'app.developers.webhooks.form.id.label'
                                })}
                            </FormLabel>
                            <InputWithCopyToClipboard
                                id="id"
                                name="id"
                                defaultValue={data?.id}
                                isDisabled={isInputDisabled}
                            />
                        </FormControl>

                        <FormControl isInvalid={!!errors.name && !!touched.name}>
                            <FormLabel htmlFor="name">
                                {intl.formatMessage({
                                    id: 'app.developers.webhooks.form.name.label'
                                })}
                            </FormLabel>
                            <Field
                                as={Input}
                                id="name"
                                name="name"
                                placeholder={intl.formatMessage({
                                    id: 'app.developers.webhooks.form.name.placeholder'
                                })}
                                isDisabled={isLoading}
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.url && !!touched.url}>
                            <FormLabel htmlFor="url">
                                {intl.formatMessage({
                                    id: 'app.developers.webhooks.form.url.label'
                                })}
                            </FormLabel>
                            <Field
                                as={isCreateMode ? Input : InputWithCopyToClipboard}
                                id="url"
                                name="url"
                                placeholder={intl.formatMessage({
                                    id: 'app.developers.webhooks.form.url.placeholder'
                                })}
                                isDisabled={isInputDisabled}
                            />
                            <FormErrorMessage>{errors.url}</FormErrorMessage>
                        </FormControl>

                        {/* <FormControl>
                            <FormLabel htmlFor="topicsFilterMode">
                                {intl.formatMessage({
                                    id: 'app.developers.webhooks.form.rules_mode.label'
                                })}
                            </FormLabel>
                            <RadioGroup
                                id="rulesMode"
                                name="rulesMode"
                                value={rulesMode}
                                onChange={onToggleRulesMode}
                                isDisabled={disabled}>
                                <Flex gap="16px">
                                    <Radio value={WEBHOOK_RULES_MODES.ALL}>
                                        {intl.formatMessage({
                                            id: 'app.developers.webhooks.form.rules_mode.all.label'
                                        })}
                                    </Radio>
                                    <Radio value={WEBHOOK_RULES_MODES.INDIVIDUAL}>
                                        {intl.formatMessage({
                                            id: 'app.developers.webhooks.form.rules_mode.individual.label'
                                        })}
                                    </Radio>
                                </Flex>
                            </RadioGroup>
                        </FormControl>

                        {!isRulesEnabled && <WebhookRulesFilter value={values?.rules} disabled={disabled} />} */}

                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={isLoading || !dirty}
                            isLoading={isLoading}
                            submitLabelMessageId={submitLabelMessageId}
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
