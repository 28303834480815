import { Button, Center, Heading, HeadingProps, Image, Link, ScaleFade, Text, TextProps } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { EMPTY_CONTENT_IMAGES, EMPTY_CONTENT_IMAGE_DIMENSION } from './EmptyContent.const'
import { EmptyContentProps } from './EmptyContent.types'

export const EmptyContent: React.FC<EmptyContentProps> = ({
    title,
    content,
    propsButton,
    propsLink,
    imageSrc = EMPTY_CONTENT_IMAGES.DEFAULT,
    imageDimension = EMPTY_CONTENT_IMAGE_DIMENSION
}) => {
    const intl = useIntl()
    const defaultTitle = useMemo<string>(() => {
        return intl.formatMessage({ id: 'app.common.error.title' })
    }, [intl])
    const propsGeneric: HeadingProps = useRef<HeadingProps | TextProps>({
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        lineHeight: 'lg'
    }).current

    return (
        <Center display="flex" flexGrow={2} flexDirection="column" gap="1rem">
            <ScaleFade in={true}>
                <Image src={imageSrc} height={imageDimension} width={imageDimension} pointerEvents="none" />
            </ScaleFade>
            <Heading {...propsGeneric}>{title || defaultTitle}</Heading>
            {content && (
                <Text {...propsGeneric} color="gray.500">
                    {content}
                </Text>
            )}
            {propsButton && <Button {...propsButton} />}
            {propsLink && <Link textDecoration="underline" target="_blank" rel="noopener noreferrer" {...propsLink} />}
        </Center>
    )
}
