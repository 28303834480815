import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { getUserFullName, User } from '@/services'

interface TableCellUserFullNameProps {
    value?: User
}

export const TableCellUserFullName: React.FC<TableCellUserFullNameProps> = ({ value }) => {
    const intl = useIntl()
    const fullName = useMemo<string>(() => {
        const emptyPlaceholder = intl.formatMessage({ id: 'app.table.cell.user_full_name_placeholder' })
        return getUserFullName(value, emptyPlaceholder)
    }, [intl, value])

    return (
        <Flex lineHeight="32px" alignItems="center">
            <Avatar name={fullName} src={value?.picture} marginRight="var(--numeral-ui-primary-spacing)" />
            <Text as="span" noOfLines={1} display="inline-block" title={fullName}>
                {fullName}
            </Text>
        </Flex>
    )
}
