import { useContext } from 'react'
import { ToastContext } from '@/providers'

export function useToasts() {
    const context = useContext(ToastContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${ToastContext.displayName}`)
    }

    return context
}
