import { PageLayout } from '@/layouts'
import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { CounterpartyAccountEditForm } from './@components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    useMutationUpdateByIdCounterpartyAccount,
    useNavigationRoutes,
    useQueryFindByIdCounterpartyAccount
} from '@/hooks'
import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { isEmpty } from 'lodash'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { CounterpartyAccountHeader } from '@/pages'
import { updateUUIDRelativeActionPathname } from '@/providers'

export const CounterpartyAccountEditPage: React.FC = () => {
    const { uuid } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const mutation = useMutationUpdateByIdCounterpartyAccount(uuid, {
        onSuccess() {
            const { ACCOUNTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, ACCOUNTS.DETAILS)

            navigate(path)
        }
    })
    const intl = useIntl()
    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.counterparty_accounts.edit.title' }),
        [intl]
    )

    return (
        <PageLayout className="CounterpartyAccountEdit">
            <CounterpartyAccountHeader />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS}>
                <Box marginTop="8px">
                    {(() => {
                        switch (true) {
                            case query?.isLoading: {
                                return <LoadingSpinner />
                            }

                            case isEmpty(query?.data):
                            case query?.isError: {
                                return <EmptyContent />
                            }

                            default: {
                                return (
                                    <CounterpartyAccountEditForm
                                        onSubmit={mutation.mutate}
                                        disabled={mutation.isPending}
                                        loading={mutation.isPending}
                                        data={query.data}
                                    />
                                )
                            }
                        }
                    })()}
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
