import { UuidSchema } from '@webapps/numeral-ui-core'
import { z } from 'zod'
import { PERMISSION } from '../User'

export const RolePermissionsSchema = z.nativeEnum(PERMISSION).array()

export const RoleNameSchema = z.enum(['Admin', 'Manager', 'Developer', 'Requester', 'Approver', 'Controller'])

export const RoleSchema = z.object({
    id: UuidSchema,
    name: RoleNameSchema,
    description: z.string(),
    permissions: RolePermissionsSchema,
    is_default: z.boolean()
})
