import { FilterInputs, TableCellShortDate, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { BooleanInput, ConnectedAccountsInput, DateInput, TextInput } from '@/components/@inputs'
import { DetailPageLink, StatusLabel } from '@/components/@misc'
import { DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { uuidValidator } from '@/utils/@validators'
import { InquiriesServiceFindAllQueryOptions, Inquiry } from '@webapps/numeral-ui-core'
import { getInquiryStatusMapper } from './Inquiries.page.utils'

export const INQUIRY_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/the-inquiry-object`

export const INQUIRY_TABLE_STATIC_STATE: TableStateWithEntity<Inquiry> = {
    columnOrder: Object.freeze(['created_at', 'related_request_id', 'deny_reason', 'denied_at'])
}

export const INQUIRY_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Inquiry> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    related_request_id: {
        cell(info) {
            const { related_request_id, related_request_type } = info.row.original
            return <DetailPageLink objectId={related_request_id} objectType={related_request_type} />
        }
    },
    denied_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            const value = getInquiryStatusMapper(info.row.original)

            return <StatusLabel value={value} />
        }
    }
}

export const INQUIRY_FILTERS: FilterInputs<InquiriesServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    denied: <BooleanInput name="denied" />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />
}
