import { IntlShape } from 'react-intl'
import { z, ZodSchema } from 'zod'
import { DEFAULT_INPUT_TEXT_MIN_LENGTH } from '@/constants'
import { UserInfoDepartmentSchema, UserInfoSchema } from '@webapps/numeral-ui-core'

export function getUserInfoFormValidationSchema(intl: IntlShape): ZodSchema {
    return UserInfoSchema.extend({
        first_name: z
            .string()
            .min(
                DEFAULT_INPUT_TEXT_MIN_LENGTH,
                intl.formatMessage({ id: `app.user_info_modal.form.first_name.validation.required.message` })
            ),
        last_name: z
            .string()
            .min(
                DEFAULT_INPUT_TEXT_MIN_LENGTH,
                intl.formatMessage({ id: `app.user_info_modal.form.last_name.validation.required.message` })
            ),
        department: z.enum(UserInfoDepartmentSchema.options, {
            errorMap: (issue, ctx) => {
                return {
                    message: intl.formatMessage({ id: `app.common.form.validation.required` })
                }
            }
        })
    })
}
