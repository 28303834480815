import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { Transaction, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdTransaction(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<Transaction, Error>>
) {
    const { transactionsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.TRANSACTION) &&
        hasPermission(PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<Transaction, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [transactionsService.url, { id }],
        queryFn({ signal }) {
            return transactionsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
