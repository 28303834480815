import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdUser } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { UserDetailsContent } from './@components'

export const UserDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdUser(uuid)

    return (
        <PageLayout className="UserDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_USERS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }
                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return <UserDetailsContent data={query.data} />
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
