/**
 * @usage Leave value as is.
 * @description
 * `react-router-dom` implementation detail to store location state under `usr`.
 *  Check `node_modules/@remix-run/router/dist/router.js:182`.
 */
const REACT_ROUTER_STATE_USER_OBJECT = 'usr'

/**
 * @description
 * Cleanup state of the current location without triggering any re-render
 * @param key clean a specific key from the user state, if the key is not provided the entire user state is cleaned
 */
export function locationStateCleanup(stateKey?: string) {
    const locationState = globalThis.window.history.state
    const updatedUserState = stateKey
        ? {
              ...locationState?.[REACT_ROUTER_STATE_USER_OBJECT],
              [stateKey]: undefined
          }
        : undefined

    globalThis.window.history.replaceState(
        {
            ...locationState,
            [REACT_ROUTER_STATE_USER_OBJECT]: updatedUserState
        },
        ''
    )
}
