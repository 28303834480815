import { StatusLabel } from '@/components/@misc'
import { HStack } from '@chakra-ui/react'
import { PaymentOrderStatus } from '@webapps/numeral-ui-core'
import { memo } from 'react'

type PaymentRetryRuleStatusesProps = {
    statuses: PaymentOrderStatus[]
}

export const PaymentRetryRuleStatuses: React.FC<PaymentRetryRuleStatusesProps> = memo(({ statuses }) => {
    return (
        <HStack spacing="8px">
            {statuses.map((status) => {
                return <StatusLabel key={status} value={status} />
            })}
        </HStack>
    )
})
