import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { CustomField } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

/**
 * @description
 * 1. `CustomFieldValue` keys are unique at CustomField level.
 * 2. As such we need to have an auto incremented suffix "_1, _2, _3" for each new key.
 * @usage
 * Optional, drop in a Formik context.
 */
export const CustomFieldValuesInputDuplicateKeyValues: React.FC<{
    name: string
}> = ({ name }) => {
    const { values, setFieldValue } = useFormikContext<CustomField>()

    useEffect(() => {
        const accumulator = { result: [], counts: Object.create(null) }
        const snakeCaseJoinSymbol = '_'

        chain(values)
            .get('values')
            .reduce((acc, item) => {
                const match = item?.key.match(/^(.*?)(_\d+)?$/) // Match the value and the optional suffix
                const value = match ? match[1] : item.key // Extract the value
                let suffix = ''

                acc.counts[value] = (acc.counts[value] || 0) + 1 // Increment already found number
                suffix = acc.counts[value] > 1 ? `${snakeCaseJoinSymbol}${acc.counts[value]}` : ''

                acc.result.push((value + suffix) as never)

                return acc
            }, accumulator)
            .get('result')
            .forEach((updatedKeyValue, index) => {
                const currentKeyValueFieldName = `${name}.${index}.key`

                setFieldValue(currentKeyValueFieldName, updatedKeyValue)
            })
            .value()
    }, [name, setFieldValue, values, values.values])

    return null
}
