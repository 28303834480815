import { isString } from 'lodash'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { isUKAccountNumber } from './accountNumber.formatter.utils'
import { digitsFormatter } from '../digits'
import { ibanFormatter } from '../iban'

export function accountNumberFormatter(value?: string): string {
    if (!isString(value)) {
        return EMPTY_CHAR_SYMBOL
    }

    const digitsOnlyValue = digitsFormatter(value)

    switch (true) {
        case isUKAccountNumber(digitsOnlyValue): {
            return digitsOnlyValue
        }

        default: {
            return ibanFormatter(value)
        }
    }
}
