export enum AlertMessageType {
    WARNING = 'warning',
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
    LOADING = 'loading'
}

export interface AlertMessageProps {
    titleKey?: string
    descriptionKey?: string
    type?: AlertMessageType
    isClosable?: boolean
}
