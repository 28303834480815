import { Icon, IconProps } from '@chakra-ui/react'

export const PaymentsIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <g clipPath="url(#clip0_4340_245184)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M2.44442 3C2.44442 3.30706 2.69292 3.55556 2.99998 3.55556H13.5555V8.55556H12.4444V4.66667H2.99998C2.80517 4.66667 2.61818 4.63327 2.44442 4.57189V12.4444C2.44442 13.0582 2.9418 13.5556 3.55554 13.5556H12.4444V9.66667H13.5555V14.6667H3.55554C2.32815 14.6667 1.33331 13.6718 1.33331 12.4444V3H2.44442Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M1.33331 3.00001C1.33331 2.0793 2.07927 1.33334 2.99998 1.33334H11.3333V3.00001H10.2222V2.44445H2.99998C2.69292 2.44445 2.44442 2.69295 2.44442 3.00001C2.44442 3.30707 2.69292 3.55557 2.99998 3.55557H3.55554V4.66668H2.99998C2.07927 4.66668 1.33331 3.92072 1.33331 3.00001Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M11.3333 8.55557C11.0262 8.55557 10.7777 8.80406 10.7777 9.11112C10.7777 9.41819 11.0262 9.66668 11.3333 9.66668H13.5555V8.55557H11.3333ZM9.66663 9.11112C9.66663 8.19041 10.4126 7.44446 11.3333 7.44446H14.6666V10.7778H11.3333C10.4126 10.7778 9.66663 10.0318 9.66663 9.11112Z"
            />
        </g>
    </Icon>
)
