import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    FormattedDateTime,
    FormattedTextIntl,
    ReconciliationStatusLabel
} from '@/components/@misc'
import { prettyPrintCasedWords } from '@/utils'
import { PaymentCapture } from '@webapps/numeral-ui-core'
import { Text } from '@chakra-ui/react'

export const PAYMENT_CAPTURE_DETAILS_ROWS: EntityDetailRow<PaymentCapture>[] = [
    'id',
    'created_at',
    'type',
    'amount',
    'fee_amount',
    'network',
    'connected_account_id',
    'payment_reference',
    'authorization_id',
    'value_date',
    'remittance_date',
    'reconciliation_status',
    'reconciled_amount',
    'source',
    // TODO add related object here once API implemented file ID
    'canceled_at',
    'metadata',
    'idempotency_key'
]

export const PAYMENT_CAPTURE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<PaymentCapture> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    type: {
        cell(item) {
            return <Text>{prettyPrintCasedWords(item.type)}</Text>
        }
    },
    amount: {
        cell(item) {
            return <FormattedAmount currency={item.currency} amount={item.amount} />
        }
    },
    fee_amount: {
        cell(item) {
            return <FormattedAmount currency={item.currency} amount={item.fee_amount} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    value_date: {
        cell(item) {
            return <FormattedDate value={item.value_date} />
        }
    },
    reconciliation_status: {
        cell(item) {
            return <ReconciliationStatusLabel value={item.reconciliation_status} />
        }
    },
    reconciled_amount: {
        cell(item) {
            return <FormattedAmount currency={item.currency} amount={item.reconciled_amount} />
        }
    },
    source: {
        cell(item) {
            return <FormattedTextIntl messageId={`app.payments.payment_captures.source.${item.source}.label`} />
        }
    },
    canceled_at: {
        cell(item) {
            return <FormattedDateTime value={item.canceled_at} />
        }
    },
    metadata: {
        nested: true
    }
    // bank_data: {
    //     nested: true
    // }
}
