import { ReactElement, useMemo } from 'react'
import { chain } from 'lodash'
import { GroupByTableEntry } from './@components'
import { GroupByTableProps } from './GroupByTable.types'
import { Group } from '@/types'

export function GroupByTable<T, G = void>({
    data,
    depth = 0,
    index = 0,
    parentGroupBy = 'currency', // Default group
    ...props
}: GroupByTableProps<T, G>) {
    const groupWithEmptyRecordsFilter = useMemo(() => {
        return (group: Group<T, G>) => {
            const hasGroups = !!group.groups?.length
            const hasRecords = !!group.records?.length

            if (hasGroups || hasRecords) {
                return true
            }

            return props?.isGroupByWithEmptyRecordsVisible ? !hasRecords || true : hasRecords || false
        }
    }, [props?.isGroupByWithEmptyRecordsVisible])
    const groupByTableEntryMapper = (group: Group<T, G>, index: number, groups: Group<T, G>[]) => {
        return (
            <GroupByTableEntry<T, G>
                {...props}
                parentGroupBy={parentGroupBy}
                groups={groups}
                group={group}
                depth={depth}
                index={index}
                key={index}
            />
        )
    }

    return chain<Group<T, G>>(data?.groups)
        .filter(groupWithEmptyRecordsFilter)
        .sortBy('group')
        .reverse()
        .map(groupByTableEntryMapper)
        .value() as unknown as ReactElement
}
