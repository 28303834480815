import { LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '@/providers'
import { PaymentOrdersServiceFindAllQueryOptions, Uuid } from '@webapps/numeral-ui-core'
import React from 'react'

interface FileDetailsRelatedObjectsProps {
    paymentFileId: Uuid
}

export const PaymentFileDetailsRelatedObjects: React.FC<FileDetailsRelatedObjectsProps> = React.memo(
    ({ paymentFileId }) => {
        const { PAYMENTS } = NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS

        if (!paymentFileId) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        return (
            <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions>
                labelKey="app.payments.payment_files.tabs.details.related_objects.payment_orders_link.title"
                path={PAYMENTS.PAYMENT_ORDERS}
                filterBy={{ payment_file_id: paymentFileId }}
            />
        )
    }
)
