import { Button, ButtonProps } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface FormToggleOptionalsProps extends ButtonProps {
    labelId?: string
    condition?: boolean
}

export const FormToggleOptionals: React.FC<FormToggleOptionalsProps> = ({ condition, labelId, ...props }) => {
    const intl = useIntl()
    const formattedLabel = useMemo(() => {
        const partialMessageId = 'app.common.form.actions'
        const state = intl.formatMessage({
            id: condition
                ? `${partialMessageId}.toggle_optional_props_hide.label`
                : `${partialMessageId}.toggle_optional_props_show.label`
        })

        return intl.formatMessage(
            {
                id: labelId ? labelId : 'app.common.form.actions.toggle_optional_props.label'
            },
            {
                state
            }
        )
    }, [condition, intl, labelId])

    return (
        <Button
            className="FormToggleOptionals"
            fontWeight="normal"
            variant="ghost"
            padding="unset"
            alignSelf="self-start"
            _hover={{ color: 'gray.500' }}
            data-testid="form-toggle-optionals"
            {...props}>
            {formattedLabel}
        </Button>
    )
}
