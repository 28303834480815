import { PageLayout } from '@/layouts'
import { ReconcileEntityHeader, ReconcileTransactionForm, ReconcileTransactionFormData } from './@components'
import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { ENTITY_FEATURE, isCreateReconciliationAvailable, PERMISSION } from '@/services'
import React, { useCallback, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { useReconciliation } from './@hooks'
import { ReconcileEntityPageProps } from './ReconcileEntity.page.types'

export const ReconcileEntityPage: React.FC<ReconcileEntityPageProps> = ({ queryItem }) => {
    const { onStartReconciliation } = useReconciliation()
    const isDisabled = useMemo<boolean>(() => {
        const canCreateReconciliation = isCreateReconciliationAvailable(queryItem.data?.reconciliation_status)
        return !canCreateReconciliation
    }, [queryItem])
    const onSubmit = useCallback(
        (data: ReconcileTransactionFormData) => {
            if (!data.payment_type) {
                return
            }

            onStartReconciliation(data.payment_type, queryItem?.data)
        },
        [queryItem]
    )

    return (
        <PageLayout className="ReconcileEntity">
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS}>
                <ReconcileEntityHeader queryItem={queryItem} />
                {(() => {
                    switch (true) {
                        case queryItem?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(queryItem?.data):
                        case queryItem?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <ReconcileTransactionForm
                                    data={queryItem?.data}
                                    onSubmit={onSubmit}
                                    loading={queryItem.isLoading}
                                    disabled={isDisabled}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
