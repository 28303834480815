import { getFormButtonLoadingLeftIcon } from '@/components'
import { useBulkActions, useMutationCreateBulkAction, useToasts } from '@/hooks'
import { TOAST_VARIANTS, useBulkActionsPluralization } from '@/providers'
import { prettyPrintCasedWords } from '@/utils'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { ApiObjectType, RequestActionTypes } from '@webapps/numeral-ui-core'
import { chain, noop } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getFormattedActionPastTense } from './PaymentOrderPendingApprovalBulkActionsFooterActions.utils'

interface PaymentOrderBulkActionsApproveConfirmationModalProps extends Omit<UseDisclosureProps, 'onClose'> {
    targetObject: ApiObjectType
    action: RequestActionTypes
    onClose(action: RequestActionTypes): void
}

export const PaymentOrderPendingApprovalBulkActionsConfirmationModal: React.FC<
    PaymentOrderBulkActionsApproveConfirmationModalProps
> = ({ targetObject, action, isOpen, onClose = noop }) => {
    const intl = useIntl()
    const containerRef = useRef(globalThis.document.getElementById('root'))
    const { selection, isLoading, onResetSelection } = useBulkActions()
    const { onAdd } = useToasts()
    const cancelRef = useRef(null)
    const selectionSize = useMemo(() => chain(selection).get('size', 0).value(), [selection])
    const formattedAction = useMemo(() => prettyPrintCasedWords(action), [action])
    const formattedActionPastTense = useMemo(() => getFormattedActionPastTense(action), [action])
    const { formattedObjectType, formattedPluralObjectType } = useBulkActionsPluralization(targetObject, selectionSize)

    const mutation = useMutationCreateBulkAction({
        onMutate() {
            onClose(action)
        },
        onSuccess(response) {
            onResetSelection(response)
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                isClosable: true,
                title: (
                    <FormattedMessage
                        id="app.common.bulk_actions.create.success.toast.title"
                        values={{
                            targetObject: formattedObjectType,
                            action: formattedActionPastTense
                        }}
                    />
                ),
                description: (
                    <FormattedMessage
                        id="app.common.bulk_actions.create.success.toast.description"
                        values={{
                            targetObject: formattedPluralObjectType.toLowerCase(),
                            action: formattedActionPastTense
                        }}
                    />
                )
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                isClosable: true,
                title: <FormattedMessage id="app.common.error.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.bulk_actions.create.error.toast.description"
                        values={{
                            targetObject: formattedPluralObjectType.toLowerCase(),
                            action: formattedActionPastTense
                        }}
                    />
                )
            })
        }
    })
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])
    const onSubmit = useCallback(() => {
        if (!selection) {
            return
        }

        mutation.mutate({
            target_object: targetObject,
            items: Array.from(selection),
            action
        })
    }, [action, mutation, selection, targetObject])
    const title = useMemo(() => {
        return intl.formatMessage(
            {
                id: 'app.payments.payment_orders.bulk_actions.confirmation.title'
            },
            {
                action: formattedAction,
                targetObject: formattedObjectType.toLowerCase()
            }
        )
    }, [formattedAction, formattedObjectType])

    const description = useMemo(() => {
        const formattedSelectionSize = intl.formatNumber(selectionSize)
        return intl.formatMessage(
            {
                id: 'app.payments.payment_orders.bulk_actions.confirmation.description'
            },
            {
                action: formattedAction.toLowerCase(),
                selectionSize: formattedSelectionSize,
                targetObject: formattedObjectType.toLowerCase()
            }
        )
    }, [selectionSize, formattedObjectType, formattedAction])
    const isDisabled = useMemo(() => {
        return mutation.isPending || isLoading
    }, [mutation, isLoading])

    return (
        <AlertDialog
            isOpen={isOpen as boolean}
            leastDestructiveRef={cancelRef}
            onClose={onCloseWithLoading}
            portalProps={{ containerRef }}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>{title}</AlertDialogHeader>
                    <AlertDialogBody>{description}</AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            isDisabled={isDisabled}>
                            <FormattedMessage id="app.common.form.actions.cancel.label" />
                        </Button>
                        <Button
                            onClick={onSubmit}
                            leftIcon={getFormButtonLoadingLeftIcon(mutation.isPending)}
                            isLoading={isDisabled}>
                            <FormattedMessage id="app.common.form.actions.confirm.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
