import { Amount, CurrencyCode } from '@webapps/numeral-ui-core'
import { getDineroCurrency } from '../getDineroCurrency'

/**
 * This util allows to convert a float number into its minor currency amount
 * Currencies with multiple subdivisions are not supported (none are currently in use in the world)
 */
export function getMinorCurrencyAmountFromFloat(decimalAmount: Amount, currencyCode: CurrencyCode): Amount | undefined {
    const currency = getDineroCurrency(currencyCode)

    if (!currency) {
        return
    }

    const factor = (currency.base as number) ** currency.exponent

    return globalThis.Math.round(decimalAmount * factor)
}
