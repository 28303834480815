import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdCustomField } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import React from 'react'
import {
    CUSTOM_FIELD_ENTITY_DETAIL_ROW_CUSTOMIZERS,
    CUSTOM_FIELD_ENTITY_DETAIL_ROWS
} from './CustomFieldDetails.page.const'
import { CustomField } from '@webapps/numeral-ui-core'

export const CustomFieldDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdCustomField(uuid)

    return (
        <PageLayout className="CustomFieldDetails">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<CustomField>
                                    data={query.data}
                                    rows={CUSTOM_FIELD_ENTITY_DETAIL_ROWS}
                                    rowCustomizers={CUSTOM_FIELD_ENTITY_DETAIL_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
