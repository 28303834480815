import React, { PropsWithChildren, SyntheticEvent, useCallback, useRef, useState } from 'react'
import { Button, Flex, Popover, PopoverBody, PopoverContent, PopoverProps, PopoverTrigger } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from '@/hooks'
import { If } from '@/components/@misc'

type TableCellOptionsMenuProps<T> = PropsWithChildren & PopoverProps & { item?: T }

const tableCellOptionsMenuButtonStyle = {
    width: '24px',
    height: '24px',
    minWidth: 'unset',
    padding: '8px!important',
    backgroundColor: 'gray.50',
    borderColor: 'gray.200',
    color: 'gray.500',
    variant: 'outline',
    margin: '0 auto',
    _hover: {
        backgroundColor: 'gray.100'
    }
}

export function TableCellOptionsMenu<T>({ children, isLazy = true }: TableCellOptionsMenuProps<T>) {
    const popoverBodyRef = useRef<HTMLDivElement>(null)
    const [isMounted, setIsMounted] = useState(!isLazy)
    const [isOpen, setIsOpen] = useState(false)

    const onStopPropagation = useCallback((event: SyntheticEvent) => {
        event.stopPropagation()
    }, [])
    const onOpenMenu = useCallback((event: SyntheticEvent) => {
        event.stopPropagation()
        setIsOpen(true)
    }, [])
    const onCloseMenu = useCallback(() => setIsOpen(false), [])
    const onMountPopoverMenu = useCallback((event: SyntheticEvent) => {
        event.stopPropagation()

        setIsOpen((prevState) => !prevState)
        setIsMounted((prevState) => !prevState)
    }, [])

    useClickOutside(popoverBodyRef, onCloseMenu, !isMounted)

    return (
        <Flex className="Table-Cell-Options-Menu">
            <If condition={isMounted}>
                <Popover placement="bottom-end" lazyBehavior="keepMounted" isLazy={isLazy} isOpen={isOpen}>
                    <PopoverTrigger>
                        <Button {...tableCellOptionsMenuButtonStyle} onClick={onOpenMenu}>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent boxShadow="lg">
                        <PopoverBody
                            ref={popoverBodyRef}
                            onClick={onStopPropagation}
                            onClickCapture={onCloseMenu}
                            overflowY="auto">
                            {children}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </If>
            <If condition={!isMounted}>
                <Button {...tableCellOptionsMenuButtonStyle} onClick={onMountPopoverMenu}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </Button>
            </If>
        </Flex>
    )
}
