import { caf, raf } from '@essentials/raf'

export function setTimeout(callback: CallableFunction, delay = 0): CallableFunction {
    const startedAt = globalThis.Date.now()
    let animationFrame = raf(tick)
    function tick() {
        if (globalThis.Date.now() - startedAt >= delay) {
            callback()
        } else {
            animationFrame = raf(tick)
        }
    }
    return () => {
        caf(animationFrame)
    }
}
