import { IntlShape } from 'react-intl'
import { ZodError, ZodIssueCode } from 'zod'
import { parseZodErrorValidationMessage } from '@/utils/@validators/validators.utils'

export function augmentValidationMessageWithIntl<T>(intl: IntlShape, zodError?: ZodError<T>): string {
    const error = parseZodErrorValidationMessage<T>(zodError)

    switch (error.code) {
        case ZodIssueCode.too_small: {
            return intl.formatMessage({ id: 'app.common.form.validation.amount.invalid.too_small' })
        }

        default: {
            return intl.formatMessage({ id: 'app.common.form.validation.amount.invalid' })
        }
    }
}
