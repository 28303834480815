import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { DateISOFormatSchema, MetadataSchema, UuidSchema } from '@webapps/numeral-ui-core'
import { z } from 'zod'

const AuditTrailAuthorIdSchema = UuidSchema.or(z.string().email())
export const AuditTrailAuthorTypeSchema = z.enum(['dashboard', 'system', 'user', 'api_key', 'internal_api_key'])

export const AuditTrailSchema = z.object({
    id: UuidSchema.optional(),
    correlation_id: UuidSchema,
    author_type: AuditTrailAuthorTypeSchema,
    author_id: AuditTrailAuthorIdSchema,
    author_name: z.string(),
    type: z.string(),
    public_name: z.string(),
    object_id: UuidSchema,
    object_type: ApiObjectTypeExtendedSchema,
    object_data: z.any(),
    metadata: MetadataSchema.optional(),
    created_at: DateISOFormatSchema.optional(),
    disabled_at: DateISOFormatSchema.nullable().optional()
})
