import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { usePermissions, useQueryFindByIdReturnRequest } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ReturnRequest } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { ReturnRequestApproval } from './@components'
import { RETURN_REQUEST_DETAILS_ROW_CUSTOMIZERS, RETURN_REQUEST_DETAILS_ROWS } from './ReturnRequestDetails.page.const'
import { ReturnRequestDetailsCustomRows } from './ReturnRequestDetails.types'
import { If } from '@/components/@misc'

export const ReturnRequestDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdReturnRequest(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RETURN_REQUEST}
                permission={PERMISSION.PAYMENTS_VIEW_RETURN_REQUESTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }
                        default: {
                            const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_RETURN_REQUESTS)

                            return (
                                <>
                                    <EntityDetail<ReturnRequest, ReturnRequestDetailsCustomRows>
                                        data={query.data}
                                        rows={RETURN_REQUEST_DETAILS_ROWS}
                                        rowCustomizers={RETURN_REQUEST_DETAILS_ROW_CUSTOMIZERS}
                                    />
                                    <If condition={hasApprovalPermission}>
                                        <ReturnRequestApproval data={query.data} />
                                    </If>
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
