import { Icon, IconProps } from '@chakra-ui/react'

export const LockIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M3.75 13.75C3.40625 13.75 3.11198 13.6276 2.86719 13.3828C2.6224 13.138 2.5 12.8438 2.5 12.5V6.25C2.5 5.90625 2.6224 5.61198 2.86719 5.36719C3.11198 5.1224 3.40625 5 3.75 5H4.375V3.75C4.375 2.88542 4.67969 2.14844 5.28906 1.53906C5.89844 0.929687 6.63542 0.625 7.5 0.625C8.36458 0.625 9.10156 0.929687 9.71094 1.53906C10.3203 2.14844 10.625 2.88542 10.625 3.75V5H11.25C11.5937 5 11.888 5.1224 12.1328 5.36719C12.3776 5.61198 12.5 5.90625 12.5 6.25V12.5C12.5 12.8438 12.3776 13.138 12.1328 13.3828C11.888 13.6276 11.5937 13.75 11.25 13.75H3.75ZM3.75 12.5H11.25V6.25H3.75V12.5ZM7.5 10.625C7.84375 10.625 8.13802 10.5026 8.38281 10.2578C8.6276 10.013 8.75 9.71875 8.75 9.375C8.75 9.03125 8.6276 8.73698 8.38281 8.49219C8.13802 8.2474 7.84375 8.125 7.5 8.125C7.15625 8.125 6.86198 8.2474 6.61719 8.49219C6.3724 8.73698 6.25 9.03125 6.25 9.375C6.25 9.71875 6.3724 10.013 6.61719 10.2578C6.86198 10.5026 7.15625 10.625 7.5 10.625ZM5.625 5H9.375V3.75C9.375 3.22917 9.19271 2.78646 8.82812 2.42188C8.46354 2.05729 8.02083 1.875 7.5 1.875C6.97917 1.875 6.53646 2.05729 6.17187 2.42188C5.80729 2.78646 5.625 3.22917 5.625 3.75V5Z"
        />
    </Icon>
)
