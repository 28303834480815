import { QUERY_PARAMS_RESERVED_NAMES, QueryParamTypes } from '@/hooks'
import { Box, Collapse, Flex } from '@chakra-ui/react'
import { Formik } from 'formik'
import { useCallback, useMemo } from 'react'
import { If } from '../@misc'
import {
    TableHeaderActions,
    TableHeaderFilterBy,
    TableHeaderFilterByButton,
    TableHeaderFormAutoSubmit,
    TableHeaderGroupBy,
    TableHeaderGroupByButton,
    TableHeaderQuickFilter,
    TableHeaderSearch
} from './@components'
import { TABLE_HEADER_COLLAPSABLE_PANEL_PROPS } from './TableHeader.const'
import './TableHeader.scss'
import { TableHeaderProps } from './TableHeader.types'
import { pickFilterByValues, pickQueryParamValues } from './TableHeader.utils'

export function TableHeader<T>({ objectType, isLoading, actions, filterBy, groupBy, search }: TableHeaderProps<T>) {
    const hasSearch = useMemo(() => !!search?.configuration?.isEnabled, [search])
    const hasFilterBy = useMemo(() => !!filterBy?.configuration?.filters, [filterBy])
    const hasQuickFilters = useMemo(() => !!filterBy?.configuration?.quickFilters, [filterBy])
    const hasGroupBy = useMemo(() => !!groupBy?.configuration?.groups, [groupBy])
    const hasActions = useMemo(() => !!actions, [actions])

    const initialValues = useMemo(() => {
        return {
            ...search?.initialState,
            ...groupBy?.initialState,
            ...filterBy?.initialState
        }
    }, [search, groupBy, filterBy])
    const onSubmit = useCallback(
        (values: typeof initialValues) => {
            if (hasSearch) {
                const value = pickQueryParamValues<QueryParamTypes.Search>(values, search)
                search?.setState(value)
            }

            if (hasGroupBy) {
                const value = pickQueryParamValues<QueryParamTypes.GroupBy>(values, groupBy)
                groupBy?.setState(value)
            }

            if (hasFilterBy) {
                const filtersValues = pickFilterByValues<T>(values)
                filterBy?.setState(filtersValues)
            }
        },
        [search, filterBy, groupBy, hasSearch, hasGroupBy, hasFilterBy]
    )

    return (
        <Formik<typeof initialValues> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values }) => {
                const filterByValues = pickFilterByValues<T>(values)
                const groupByValues = pickQueryParamValues<QueryParamTypes.GroupBy>(values, groupBy)[
                    QUERY_PARAMS_RESERVED_NAMES.GROUP_BY
                ]

                return (
                    <Box className="TableHeader">
                        <Flex gap="16px">
                            <If condition={hasSearch}>
                                <TableHeaderSearch objectType={objectType} />
                            </If>
                            <If condition={hasFilterBy}>
                                <TableHeaderFilterByButton
                                    value={filterByValues}
                                    isLoading={isLoading}
                                    isVisible={filterBy?.isVisible}
                                    onToggle={filterBy?.toggleIsVisible}
                                />
                            </If>
                            <If condition={hasGroupBy}>
                                <TableHeaderGroupByButton
                                    {...groupBy?.configuration}
                                    {...groupBy}
                                    value={groupByValues}
                                    isLoading={isLoading}
                                    onToggle={groupBy?.toggleIsVisible}
                                />
                            </If>
                            <If condition={hasActions}>
                                <TableHeaderActions marginLeft="auto" actions={actions} />
                            </If>
                        </Flex>

                        <If condition={hasFilterBy}>
                            <Collapse
                                {...TABLE_HEADER_COLLAPSABLE_PANEL_PROPS}
                                in={filterBy?.isVisible}
                                unmountOnExit={true}>
                                <TableHeaderFilterBy<T> {...filterBy?.configuration} value={filterByValues} />
                            </Collapse>
                        </If>
                        <If condition={hasGroupBy}>
                            <Collapse
                                {...TABLE_HEADER_COLLAPSABLE_PANEL_PROPS}
                                in={groupBy?.isVisible}
                                unmountOnExit={true}>
                                <TableHeaderGroupBy
                                    {...groupBy?.configuration}
                                    {...groupBy}
                                    value={groupByValues}
                                    isLoading={isLoading}
                                    isDisabled={isLoading}
                                />
                            </Collapse>
                        </If>
                        <If condition={hasQuickFilters}>
                            <TableHeaderQuickFilter {...filterBy?.configuration} value={filterByValues} />
                        </If>
                        <TableHeaderFormAutoSubmit />
                    </Box>
                )
            }}
        </Formik>
    )
}
