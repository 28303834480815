import { UltimateOriginatorOrganizationIdentification } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { getFormattedOrganizationIdentification } from './CounterpartyOrganizationIdentification.utils'

type CounterpartyOrganizationIdentificationProps = {
    organizationIdentification: UltimateOriginatorOrganizationIdentification
}

export const CounterpartyOrganizationIdentification: React.FC<CounterpartyOrganizationIdentificationProps> = memo(
    ({ organizationIdentification }) => {
        const formattedOrganizationIdentification = getFormattedOrganizationIdentification(organizationIdentification)
        return <>{formattedOrganizationIdentification}</>
    }
)
