import { Select } from '@/components/@misc'
import { FormikInput } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Direction, DirectionSchema } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const PaymentDirectionInput: React.FC<FormikInput> = ({
    name,
    value,
    isPlaceholderDisplayed = false,
    validator = anythingValidator,
    isRequired,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<Direction>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const placeholder = useMemo<string | undefined>(() => {
        if (!isPlaceholderDisplayed) {
            return
        }
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [intl, isPlaceholderDisplayed])

    return (
        <FormControl key={name} isInvalid={meta.touched && !!meta.error}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<Direction>
                {...inputProps}
                {...field}
                id={name}
                isRequired={isRequired}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={DirectionSchema.options}
                getOptionLabel={(option) => {
                    return intl.formatMessage({ id: `api.commons.direction.${option}` })
                }}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
