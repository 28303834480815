import { useRef } from 'react'
import { Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Header, SortDirection } from '@tanstack/react-table'
import { TABLE_COLUMN_RESERVED_KEYS } from '../Table.const'

const TableHeaderSorterIndicators = {
    asc: <FontAwesomeIcon icon={faCaretUp} />,
    desc: <FontAwesomeIcon icon={faCaretDown} />
}

interface TableHeaderSorterProps<T, V> {
    header: Header<T, V>
    disabled?: boolean
}

export function TableHeaderSorter<T, V = void>({ header, disabled }: TableHeaderSorterProps<T, V>) {
    const isDisabled = useRef<boolean>(disabled || header.column.id === TABLE_COLUMN_RESERVED_KEYS.OPTIONS).current
    const canSort: boolean = useRef<boolean>(header.column?.getCanSort()).current
    const sortDirection: SortDirection = header.column.getIsSorted() as unknown as SortDirection

    if (!canSort || isDisabled) {
        return null
    }

    return (
        <Text as="span" className="Table-Header-Sorter" marginLeft="8px">
            {TableHeaderSorterIndicators[sortDirection]}
        </Text>
    )
}
