import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useMutationCreateReturn, useNavigationRoutes, useQueryFindByIdIncomingPayment } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { hasStatusReceived } from '@/pages/Payments/IncomingPayments/IncomingPayments.page.utils'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { IncomingPaymentReturnForm } from './@components'

export const IncomingPaymentReturnPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(
        () =>
            intl.formatMessage({
                id: 'app.payments.incoming_payments.return.title'
            }),
        [intl]
    )
    const query = useQueryFindByIdIncomingPayment(uuid)
    const mutation = useMutationCreateReturn({
        onSuccess(response) {
            if (!response?.id) {
                return
            }

            const { PAYMENTS } = paths
            const { DETAILS } = relativePaths.PAYMENTS
            const path = [PAYMENTS.RETURNS, response.id, DETAILS].join('/')

            navigate(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        const isStatusReceived = hasStatusReceived(query.data?.status)
        return !isStatusReceived || query.isLoading || mutation.isPending
    }, [query, mutation])

    return (
        <PageLayout className="IncomingPaymentReturn">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_CONFIRM_INCOMING_PAYMENTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }
                        default: {
                            return (
                                <IncomingPaymentReturnForm
                                    data={query.data}
                                    onSubmit={mutation.mutate}
                                    loading={mutation.isPending}
                                    disabled={isDisabled}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
