import { PropsWithChildren, useMemo, useState } from 'react'
import { DEFAULT_LOCALE, LANGUAGES } from './LocaleSwitcherProvider.const'
import { LocaleSwitcherContext } from './LocaleSwitcherProvider.context'
import { SUPPORTED_LOCALE } from './LocaleSwitcherProvider.types'

export const LocaleSwitcherProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [locale, setLocale] = useState<SUPPORTED_LOCALE>(DEFAULT_LOCALE)
    const localeName = useMemo(() => {
        return LANGUAGES[locale]?.localeName
    }, [locale])
    const messages = useMemo(() => {
        return LANGUAGES[locale]?.messages
    }, [locale])

    const value = {
        locale,
        localeName,
        messages,
        setLocale
    }

    return <LocaleSwitcherContext.Provider value={value}>{children}</LocaleSwitcherContext.Provider>
}
