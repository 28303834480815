import { Icon, IconProps } from '@chakra-ui/react'

export const SettingsIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99984 8.33329C9.07936 8.33329 8.33317 9.07948 8.33317 9.99996C8.33317 10.9204 9.07936 11.6666 9.99984 11.6666C10.9203 11.6666 11.6665 10.9204 11.6665 9.99996C11.6665 9.07948 10.9203 8.33329 9.99984 8.33329ZM6.6665 9.99996C6.6665 8.15901 8.15889 6.66663 9.99984 6.66663C11.8408 6.66663 13.3332 8.15901 13.3332 9.99996C13.3332 11.8409 11.8408 13.3333 9.99984 13.3333C8.15889 13.3333 6.6665 11.8409 6.6665 9.99996Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.59752 0H12.4021L12.7817 3.03592C13.4476 3.30367 14.0716 3.66577 14.6345 4.11108L17.4587 2.91958L19.861 7.08034L17.4212 8.92768C17.4728 9.282 17.4991 9.63977 17.4998 9.99829L16.6665 10L17.4998 10.0017C17.4991 10.3602 17.4728 10.718 17.4212 11.0723L19.861 12.9197L17.4587 17.0804L14.6345 15.8889C14.0716 16.3342 13.4476 16.6963 12.7817 16.9641L12.4021 20H7.59752L7.21792 16.9641C6.552 16.6963 5.92801 16.3342 5.36514 15.8889L2.54095 17.0804L0.138672 12.9197L2.57784 11.0728C2.47385 10.3614 2.47385 9.63862 2.57784 8.92724L0.138672 7.08034L2.54095 2.91958L5.36514 4.11108C5.92801 3.66577 6.552 3.30367 7.21792 3.03592L7.59752 0ZM15.8331 10C15.8322 9.58015 15.7862 9.16161 15.6959 8.75156L15.5813 8.23031L17.682 6.63966L16.751 5.02708L14.3168 6.05404L13.9225 5.6926C13.302 5.12376 12.5656 4.69622 11.7639 4.43945L11.2573 4.27716L10.9309 1.66667H9.06877L8.74236 4.27716L8.23568 4.43945C7.43403 4.69622 6.6976 5.12376 6.07712 5.6926L5.68286 6.05404L3.24867 5.02708L2.31762 6.63966L4.4193 8.23103L4.30332 8.7532C4.12091 9.57439 4.12091 10.4256 4.30332 11.2468L4.4193 11.769L2.31762 13.3603L3.24867 14.9729L5.68286 13.946L6.07712 14.3074C6.6976 14.8762 7.43403 15.3038 8.23568 15.5606L8.74236 15.7228L9.06877 18.3333H10.9309L11.2573 15.7228L11.7639 15.5606C12.5656 15.3038 13.302 14.8762 13.9225 14.3074L14.3168 13.946L16.751 14.9729L17.682 13.3603L15.5813 11.7697L15.6959 11.2484C15.7862 10.8384 15.8322 10.4199 15.8331 10Z"
        />
    </Icon>
)
