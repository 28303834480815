import { defineStyleConfig } from '@chakra-ui/react'

export const Checkbox = defineStyleConfig({
    defaultProps: {
        variant: 'solidAlternative'
    },
    variants: {
        solidAlternative: {
            parts: 'control',
            control: {
                _indeterminate: {
                    backgroundColor: 'numeralAccent.500',
                    borderColor: 'numeralAccent.500'
                },
                _checked: {
                    backgroundColor: 'numeralAccent.500',
                    borderColor: 'numeralAccent.500'
                }
            }
        }
    }
})
