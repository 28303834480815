import { CURRENCIES } from '@/constants'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { Currency } from 'dinero.js'

export function getDineroCurrency(currencyCode?: CurrencyCode): Currency<number> | undefined {
    if (!currencyCode) {
        return
    }

    return CURRENCIES[currencyCode]
}
