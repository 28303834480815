import { dinero, toDecimal } from 'dinero.js'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { getDineroCurrency } from '@/utils'
import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { isNil } from 'lodash'
import { IntlShape } from 'react-intl'

export const amountFormatter = (
    intl: IntlShape,
    amount: number,
    currency: CurrencyCode,
    showCurrencyCode = true
): string => {
    if (isNil(intl) || isNil(amount) || isNil(currency)) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    const dineroCurrency = getDineroCurrency(currency)

    if (isNil(dineroCurrency)) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    const dineroWrapper = dinero({ amount, currency: dineroCurrency })
    const decimalAmount = toDecimal(dineroWrapper)
    const formattedAmount = intl.formatNumber(Number(decimalAmount), {
        minimumFractionDigits: dineroCurrency.exponent,
        maximumFractionDigits: dineroCurrency.exponent
    })

    if (showCurrencyCode) {
        return dineroCurrency.code + SPACE_SYMBOL + formattedAmount
    } else {
        return formattedAmount
    }
}
