import { EMPTY_VALUE_PLACEHOLDER, SPACE_SYMBOL } from '@/constants'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { SUPPORTED_DURATION_FORMAT_REGEX } from './Duration.const'

type DurationProps = {
    duration?: string
}

/**
 * Render a formatted duration
 * @param duration - Duration with its unit in a format like '2s', '2m', '2D'
 */
export const Duration: React.FC<DurationProps> = memo(({ duration }) => {
    if (!duration) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    const sanitizedDuration = duration.toLowerCase()

    // For unsupported format the duration is passed through
    if (!SUPPORTED_DURATION_FORMAT_REGEX.test(sanitizedDuration)) {
        return duration
    }

    // Wait unit should be the last character of the duration string
    const waitTime = sanitizedDuration.slice(0, -1)
    const waitUnit = sanitizedDuration.slice(-1)

    return (
        <>
            {waitTime}
            {SPACE_SYMBOL}
            <FormattedMessage
                id={`app.common.time_unit.${waitUnit}`}
                values={{ value: waitTime }}
                defaultMessage={waitUnit}
            />
        </>
    )
})
