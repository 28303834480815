import { useNavigationRoutes } from '@/hooks'
import { isFunction } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * @usage
 * Drop anywhere (in a page or component) to do a redirection to the root of the app '/'.
 */
export function useNavigationRouteGuard(predicateOrCondition: CallableFunction | boolean, redirectTo?: string) {
    const navigate = useNavigate()
    const { paths } = useNavigationRoutes()

    useEffect(() => {
        const condition = isFunction(predicateOrCondition) ? predicateOrCondition() : predicateOrCondition

        if (!condition) {
            navigate(redirectTo || paths.NOT_FOUND, { replace: true })
        }
    }, [predicateOrCondition, navigate, paths, redirectTo])
}
