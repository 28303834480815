import { useMutationCancelByIdPaymentCapture, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

interface PaymentCaptureCancelConfirmationModalProps extends UseDisclosureProps {
    uuid?: Uuid
}

export const PaymentCaptureCancelConfirmationModal: React.FC<PaymentCaptureCancelConfirmationModalProps> = ({
    uuid,
    isOpen,
    onClose = noop
}) => {
    const { onAdd } = useToasts()
    const cancelRef = useRef(null)
    const mutation = useMutationCancelByIdPaymentCapture(uuid, {
        onSuccess(response) {
            onClose(response)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.payments.payment_captures.actions.cancel.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.payments.payment_captures.actions.cancel.success.toast.description" />
                )
            })
        }
    })
    const onCloseWithLoading = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        onClose()
    }, [mutation, onClose])

    const onCancelPaymentCapture = useCallback(() => {
        mutation.mutate()
    }, [mutation])

    return (
        <AlertDialog isOpen={isOpen as boolean} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage id="app.payments.payment_captures.cancel_modal.title" />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage id="app.payments.payment_captures.cancel_modal.description" />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button
                            variant="secondary"
                            ref={cancelRef}
                            onClick={onCloseWithLoading}
                            disabled={mutation.isPending}>
                            <FormattedMessage id="app.payments.payment_captures.cancel_modal.button.close.label" />
                        </Button>
                        <Button onClick={onCancelPaymentCapture} isLoading={mutation.isPending}>
                            <FormattedMessage id="app.payments.payment_captures.cancel_modal.button.primary.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
