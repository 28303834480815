import { Icon, IconProps } from '@chakra-ui/react'

export const IndeterminateCheckBoxIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 14 14" {...props}>
        <path
            d="M1.97714 12.0229C2.16089 12.2066 2.37754 12.3 2.625 12.3H11.375C11.6225 12.3 11.8391 12.2066 12.0229 12.0229C12.2066 11.8391 12.3 11.6225 12.3 11.375V2.625C12.3 2.37754 12.2066 2.16089 12.0229 1.97714C11.8391 1.7934 11.6225 1.7 11.375 1.7H2.625C2.37754 1.7 2.16089 1.7934 1.97714 1.97714C1.7934 2.16089 1.7 2.37754 1.7 2.625V11.375C1.7 11.6225 1.7934 11.8391 1.97714 12.0229ZM11.325 2.675V11.325H2.675V2.675H11.325Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
        />
        <rect
            x="2.28337"
            y="5.88301"
            width="0.975"
            height="9.14167"
            transform="rotate(-90 2.28337 5.88301)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
        />
        <rect
            x="2.28337"
            y="9.09199"
            width="0.975"
            height="9.14167"
            transform="rotate(-90 2.28337 9.09199)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.1"
        />
    </Icon>
)
