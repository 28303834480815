import { UploadFileData } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'

export function getPaymentOrderUploadFormInitialValues(): Partial<UploadFileData<PaymentOrder>> {
    return {
        connected_account_id: undefined,
        file: undefined,
        type: undefined,
        direction: undefined,
        treasury_option: undefined,
        retry_details: {
            payment_retry_rule_id: undefined
        } as any // @todo Decide how we handle nullable fields for creation forms
    }
}
