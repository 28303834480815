import { Link, LinkProps } from '@chakra-ui/react'
import React, { RefObject, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

interface FileInputBrowseLinkProps extends LinkProps {
    refInput: RefObject<HTMLInputElement>
}

export const FileUploadInputBrowseLink: React.FC<FileInputBrowseLinkProps> = ({ refInput, ...props }) => {
    const onClick = useCallback(() => {
        refInput?.current?.click()
    }, [refInput])

    return (
        <Link {...props} onClick={onClick}>
            <FormattedMessage id="browse" />
        </Link>
    )
}
