import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType } from 'zod'
import { isEmpty } from 'lodash'
import { asOptionalField, sanitiseValidatorValue } from '../validators.utils'
import { SortCodeSchema } from './sortCode.schema'

export function sortCodeValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    return (value: string): string | void => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (isRequired) {
            validationResult = SortCodeSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(SortCodeSchema).safeParse(sanitisedValue)
        }

        if (isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.sort_code.invalid' })
        }
    }
}
