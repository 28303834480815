import { createUseQueryParam, getQueryConfigurationByType, queryParamArgTypeResolver } from './useQueryParams.utils'
import {
    ExtractQueryType,
    UseQueryParamsResult,
    QueryParamStateMap,
    QueryParamTypes,
    UseQueryParamsArg
} from './useQueryParams.types'
import { get } from 'lodash'

/**
 * @usage
 * const { search, filterBy } = useQueryParams<PaymentOrdersServiceFindAllQueryOptions>('search', {
 *      filterBy: { configuration: { filters: PAYMENT_ORDER_FILTERS } }
 * })
 */
export function useQueryParams<T = void>(...args: UseQueryParamsArg<T>[]) {
    type UseQuery = Record<
        QueryParamTypes,
        UseQueryParamsResult<ExtractQueryType<any /* @todo extract type from item*/, QueryParamStateMap<T>>>
    >
    return args?.reduce<UseQuery>((acc, item) => {
        const type = queryParamArgTypeResolver<T>(item)
        const config = getQueryConfigurationByType<T>(type, get(item, `${type}.configuration`))

        if (type) {
            acc[type] = createUseQueryParam<ExtractQueryType<typeof type, QueryParamStateMap<T>>>(type, acc)(config)
        }

        return acc
    }, Object.create(null))
}
