import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { FormattedDateTime } from '@/components/@misc'
import { CounterpartyAccount, CounterpartyAccountVerificationServiceSchema } from '@webapps/numeral-ui-core'
import { CounterpartyAccountVerificationResultDetails } from './@components'
import { CounterpartyAccountVerificationCustomRows } from './CounterpartyAccountVerifications.types'

export const COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROWS: EntityDetailRow<
    CounterpartyAccount,
    CounterpartyAccountVerificationCustomRows
>[] = [
    CounterpartyAccountVerificationServiceSchema.enum.valid_account_number,
    CounterpartyAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code,
    CounterpartyAccountVerificationServiceSchema.enum.matching_account_holder,
    CounterpartyAccountVerificationServiceSchema.enum.active_account,
    'account_verification_created_at'
]

export const COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    CounterpartyAccount,
    CounterpartyAccountVerificationCustomRows
> = {
    [CounterpartyAccountVerificationServiceSchema.enum.valid_account_number]: {
        cell(item) {
            return (
                <CounterpartyAccountVerificationResultDetails
                    verificationService={CounterpartyAccountVerificationServiceSchema.enum.valid_account_number}
                    counterpartyAccount={item}
                />
            )
        }
    },
    [CounterpartyAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code]: {
        cell(item) {
            return (
                <CounterpartyAccountVerificationResultDetails
                    verificationService={
                        CounterpartyAccountVerificationServiceSchema.enum.matching_account_number_and_bank_code
                    }
                    counterpartyAccount={item}
                />
            )
        }
    },
    [CounterpartyAccountVerificationServiceSchema.enum.matching_account_holder]: {
        cell(item) {
            return (
                <CounterpartyAccountVerificationResultDetails
                    verificationService={CounterpartyAccountVerificationServiceSchema.enum.matching_account_holder}
                    counterpartyAccount={item}
                />
            )
        }
    },
    [CounterpartyAccountVerificationServiceSchema.enum.active_account]: {
        cell(item) {
            return (
                <CounterpartyAccountVerificationResultDetails
                    verificationService={CounterpartyAccountVerificationServiceSchema.enum.active_account}
                    counterpartyAccount={item}
                />
            )
        }
    },
    account_verification_created_at: {
        cell(item) {
            return <FormattedDateTime value={item.account_verification?.created_at} />
        }
    }
}
