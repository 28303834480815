import { getNavigationRoutes, NavigationRoutesContext, NavigationRoutesContextValue } from '@/providers'
import { PropsWithChildren, useRef } from 'react'
import {
    NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS,
    NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
} from './NavigationRoutesProvider.const'

export const NavigationRoutesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const value: NavigationRoutesContextValue = useRef({
        routes: getNavigationRoutes(),
        paths: NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS,
        relativePaths: NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    }).current

    return <NavigationRoutesContext.Provider value={value}>{children}</NavigationRoutesContext.Provider>
}
