import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes, useQueryFindByIdPaymentRetryRule } from '@/hooks'
import { Link, Skeleton, Text } from '@chakra-ui/react'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

interface PaymentRetryRuleNameProps {
    paymentRetryRuleId?: string
    asPlainText?: boolean
}

export const PaymentRetryRuleName: React.FC<PaymentRetryRuleNameProps> = React.memo(
    ({ paymentRetryRuleId, asPlainText }) => {
        const navigate = useNavigate()
        const { paths } = useNavigationRoutes()
        const query = useQueryFindByIdPaymentRetryRule(paymentRetryRuleId, {
            enabled: globalThis.Boolean(paymentRetryRuleId)
        })
        const onClick = useCallback(
            (event: MouseEvent<HTMLAnchorElement>) => {
                const path = `${paths.SETTINGS.PAYMENT_RETRY_RULES}/${paymentRetryRuleId}/`
                event.stopPropagation()
                navigate(path)
            },
            [paths, navigate, paymentRetryRuleId]
        )
        const formattedRuletName = useMemo(() => {
            return query.data?.name
        }, [query.data])

        switch (true) {
            case !paymentRetryRuleId:
            case query.isError:
            case query.isSuccess && !query.data?.name: {
                return EMPTY_VALUE_PLACEHOLDER
            }

            case query.isLoading: {
                return <Skeleton height="14px" width="50%" />
            }

            case asPlainText: {
                return <Text as="span">{formattedRuletName}</Text>
            }

            default: {
                return (
                    <Link onClick={onClick} title={formattedRuletName}>
                        {formattedRuletName}
                    </Link>
                )
            }
        }
    }
)
