export function flattenDeep(object?: Record<string, any>) {
    const result = Object.create(null)

    if (!object) {
        return result
    }

    Object.entries(object).forEach(([key, value]) => {
        if (!!value && typeof value === 'object' && !Array.isArray(value)) {
            const nestedItem = flattenDeep(value)
            Object.keys(nestedItem).forEach((nestedKey) => {
                result[key + '.' + nestedKey] = nestedItem[nestedKey]
            })
        } else {
            result[key] = value
        }
    })

    return result
}
