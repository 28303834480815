import { chain, identity } from 'lodash'
import { SPACE_SYMBOL } from '@/constants'

export function prettyPrintCasedWords(value?: string, isUpperCase = true): string {
    const capitalizeFirstWordOnlyMapper = (word: string, index: number) => {
        if (index === 0 && chain(word).isString()) {
            return word.charAt(0).toUpperCase() + word.slice(1)
        }
        return word
    }

    if (!value) {
        return ''
    }

    const wordMapper = isUpperCase ? capitalizeFirstWordOnlyMapper : identity

    return chain([value]).words().map(wordMapper).join(SPACE_SYMBOL).value()
}
