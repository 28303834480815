import { chain } from 'lodash'
import { CustomFieldValue } from '@webapps/numeral-ui-core'

export function transformCustomFieldNameToSnakeCase(name: string) {
    return chain(name).trim().deburr().snakeCase().value()
}

export function isCustomFieldKeyValueDuplicated(
    value: CustomFieldValue[],
    key: keyof CustomFieldValue,
    keyValue: string
) {
    if (!value?.length || !key || !keyValue) {
        return false
    }

    const occurrencesByKey = chain(value)
        .countBy((customFieldValue: CustomFieldValue) => customFieldValue[key])
        .value()

    return chain(occurrencesByKey)
        .pickBy((count) => count > 1)
        .get(keyValue)
        .gt(0)
        .value()
}

export function isAddNewCustomFieldValueDisabled(value: CustomFieldValue[]) {
    const valueWrapper = chain(value)
    return valueWrapper.size().gt(0).value() && !valueWrapper.last().get('name').size().gt(0).value()
}

export function hasUniqueCustomFieldKeyValues(value: CustomFieldValue[], key: keyof CustomFieldValue = 'key') {
    return value && !value.some((item) => isCustomFieldKeyValueDuplicated(value, key, item?.key))
}
