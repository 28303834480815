import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentRetryRule } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE } from '@/services'
import { PaymentRetryRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    PAYMENT_RETRY_RULE_DETAILS_ROWS,
    PAYMENT_RETRY_RULE_DETAILS_ROW_CUSTOMIZERS
} from './PaymentRetryRuleDetails.page.const'
import { PaymentOrderDetailsCustomRows } from './PaymentRetryRuleDetails.page.type'

export const PaymentRetryRuleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentRetryRule(uuid)

    return (
        <PageLayout className="PaymentRetryRuleDetailsDetails">
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_RETRY_RULE}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<PaymentRetryRule, PaymentOrderDetailsCustomRows>
                                    data={query.data}
                                    rows={PAYMENT_RETRY_RULE_DETAILS_ROWS}
                                    rowCustomizers={PAYMENT_RETRY_RULE_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
