import { bicValidator, sortCodeValidator } from '@/utils/@validators'
import { asOptionalField, sanitiseValidatorValue } from '@/utils/@validators/validators.utils'
import { FieldValidator } from 'formik'
import { isEmpty } from 'lodash'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType, z } from 'zod'

/**
 * @description
 * The `bank_code` can have multiple shapes/value patterns.
 * These shapes/value patterns have dedicated validators like "sortCodeValidator" or "bicValidator".
 * This is a way to aggregate multiple such validators under one field like `account_number`,
 * as it is represented in the Numeral API.
 */
export function bankCodeValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    const bankCodeSchema = z.string().refine((value) => {
        const resultOne = bicValidator(intl, isRequired)(value)
        const resultTwo = sortCodeValidator(intl, isRequired)(value)

        const hasValidResultOne = !globalThis.Boolean(resultOne)
        const hasValidResultTwo = !globalThis.Boolean(resultTwo)

        return hasValidResultOne || hasValidResultTwo
    })

    return (value: unknown): string | void => {
        const sanitisedValue = sanitiseValidatorValue(value as string)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (isRequired) {
            validationResult = bankCodeSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(bankCodeSchema).safeParse(sanitisedValue)
        }

        if (isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage({
                id: 'app.common.form.bank_code.validation.invalid'
            })
        }
    }
}
