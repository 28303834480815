import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdCounterpartyAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { CounterpartyAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { CounterpartyAccountApproval } from './@components'
import {
    COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    COUNTERPARTY_ACCOUNT_DETAILS_ROWS
} from './CounterpartyAccountDetails.page.const'
import { useMemo } from 'react'
import { getCustomFieldsRowCustomizers } from '@/pages'

export const CounterpartyAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const rowCustomizers = useMemo(() => {
        return {
            ...COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(
                query.data,
                PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS
            )
        }
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            const hasApprovalPermission = hasPermission(
                                PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS
                            )
                            return (
                                <>
                                    <EntityDetail<CounterpartyAccount>
                                        data={query.data}
                                        rows={COUNTERPARTY_ACCOUNT_DETAILS_ROWS}
                                        rowCustomizers={rowCustomizers}
                                    />
                                    <If condition={hasApprovalPermission}>
                                        <CounterpartyAccountApproval data={query.data} />
                                    </If>
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
