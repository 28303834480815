import { AxiosHeaders } from 'axios'
import { set } from 'lodash'
import { BulkActionCreateData } from '@webapps/numeral-ui-core'

export function bulkActionHiddenTrueTransform<T>(data: BulkActionCreateData<T>, headers?: AxiosHeaders) {
    const value = true

    set(data, 'hidden', value)

    return data
}
