import { Icon, IconProps } from '@chakra-ui/react'

export const ExpandIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="-6.5 -2.5 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.77781 7.55594H0.888916V6.44482H9.77781V7.55594Z"
        />
        <path
            fill="#718096"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.44437 2.88135L10.5634 7.00036L6.44437 11.1194L5.65869 10.3337L8.99203 7.00036L5.65869 3.66702L6.44437 2.88135Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1111 0.88916V13.1114H12V0.88916H13.1111Z"
        />
    </Icon>
)
