import {
    EmptyContent,
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    LoadingSpinner,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdDirectDebitMandate } from '@/hooks'
import { PageLayout } from '@/layouts'
import {
    CounterpartyCustomRows,
    getPaymentCounterpartyDetailRowCustomizers,
    getPaymentCounterpartyDetailRows,
    pickPaymentAccounts
} from '@/pages/Common'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const DirectDebitMandatesCounterpartiesPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdDirectDebitMandate(uuid)

    const rows = useMemo<EntityDetailRow<CounterpartyCustomRows>[]>(() => {
        return getPaymentCounterpartyDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<CounterpartyCustomRows>>(() => {
        return getPaymentCounterpartyDetailRowCustomizers(query.data)
    }, [query])

    const data = useMemo(() => {
        return pickPaymentAccounts(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.DIRECT_DEBIT_MANDATE}
                permission={PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES}>
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<DirectDebitMandate>
                                    data={query.data}
                                    rows={rows}
                                    rowCustomizers={rowCustomizers}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
