import { EntityAction } from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    If,
    Separator,
    SeparatorVariant
} from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { useNavigationRoutes, usePermissions, useQueryFindByIdTransaction } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { updateUUIDRelativeActionPathname } from '@/providers'
import { PERMISSION } from '@/services'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BaseReconcileEntityHeaderProps } from '@/pages/Common'

export type TransactionHeaderProps = BaseReconcileEntityHeaderProps

export const TransactionHeader: React.FC<TransactionHeaderProps> = ({ id, isReconciling, ...props }) => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigate = useNavigate()
    const location = useLocation()
    const { paths, relativePaths } = useNavigationRoutes()

    const query = useQueryFindByIdTransaction(uuid || id)

    const title = useMemo<ReactNode>(() => {
        if (!query.data || !hasPermission(PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS)) {
            return null
        }

        const displayDate = query.data.value_date
        const connectedAccountId = query.data.connected_account_id
        const showConnectedAccount = hasPermission(PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS) && !!connectedAccountId
        const counterPartyHolderName = query.data.counterparty_account.holder_name

        return (
            <>
                <If condition={isReconciling}>
                    <FormattedMessage id="app.reconcile.title" />
                    {SPACE_SYMBOL}
                </If>
                <If condition={!!query.data?.amount}>
                    <FormattedAmount amount={query.data.amount} currency={query.data.currency} />
                    {SPACE_SYMBOL}
                    {query.data.direction}
                </If>
                <If condition={!!counterPartyHolderName}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    {counterPartyHolderName}
                </If>
                <If condition={showConnectedAccount}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <ConnectedAccountName connectedAccountId={connectedAccountId} asPlainText={true} />
                </If>
                <If condition={!!displayDate}>
                    <Separator variant={SeparatorVariant.Bullet} />
                    <FormattedDate value={displayDate} />
                </If>
            </>
        )
    }, [isReconciling, query, hasPermission])

    const actions = useMemo<EntityAction[] | undefined>(() => {
        const actions = []

        if (isReconciling) {
            return
        }

        if (hasPermission(PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS)) {
            actions.push({
                onClick() {
                    const path = updateUUIDRelativeActionPathname(location.pathname, relativePaths.ACCOUNTS.EDIT)

                    navigate(path)
                },
                children: intl.formatMessage({
                    id: 'app.common.actions.edit.label'
                })
            })
        }
        return actions
    }, [isReconciling, hasPermission, uuid, paths, relativePaths, navigate, location])

    return <PageHeaderLayout {...props} title={title} isLoading={query.isLoading} actions={actions} />
}
