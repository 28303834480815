import { BoxProps } from '@chakra-ui/react'

export const PAYMENT_ORDER_REFERENCE_MAX_LENGTH = 140
export const PAYMENT_ORDER_MANDATE_REFERENCE_MAX_LENGTH = 35
export const PAYMENT_CUSTOM_COUNTRY_CODES = ['GB', 'FR']

export const PAYMENT_ORDER_FORM_DETAILS_STYLES: Record<string, BoxProps> = {
    DETAILS_CONTAINER: {
        borderWidth: '1px',
        borderRadius: 'var(--numeral-ui-primary-border-radius)',
        marginY: '8px',
        overflow: 'hidden',
        width: 'inherit'
    },
    ENTITY_DETAILS_OVERRIDES: { border: 'unset', margin: 'unset', padding: 'unset' }
}
