import { If } from '@/components/@misc'
import {
    useActivatedFeatures,
    useMutationVerifyCounterpartyAccount,
    useNavigationRoutes,
    usePermissions,
    useQueryFindByIdCounterpartyAccount,
    useToasts
} from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { TOAST_VARIANTS, updateUUIDRelativeActionPathname } from '@/providers'
import {
    canDisableCounterpartyAccountByStatus,
    checkCounterpartyAccountVerificationResult,
    ENTITY_FEATURE,
    isCounterpartyAccountPendingVerificationResult,
    PERMISSION
} from '@/services'
import { CounterpartyAccountVerificationResultSchema } from '@webapps/numeral-ui-core'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { CounterpartyAccountDisableConfirmationModal } from './CounterpartyAccountDisableConfirmationModal'

export const CounterpartyAccountHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const { hasPermission } = usePermissions()
    const { isFeatureActivated } = useActivatedFeatures()
    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false)
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const mutationVerify = useMutationVerifyCounterpartyAccount(uuid, {
        onSuccess({ account_verification }) {
            const { invalid, impossible_to_verify } = CounterpartyAccountVerificationResultSchema.enum
            const hasSomeInvalidResults = checkCounterpartyAccountVerificationResult(account_verification, [invalid])
            const hasSomeImpossibleToVerifyResults = checkCounterpartyAccountVerificationResult(account_verification, [
                impossible_to_verify
            ])
            const hasPendingVerificationResult = isCounterpartyAccountPendingVerificationResult(account_verification)

            switch (true) {
                case hasSomeInvalidResults: {
                    onAdd({
                        variant: TOAST_VARIANTS.WARNING,
                        status: 'warning',
                        title: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.warning.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.warning.toast.description" />
                        )
                    })

                    break
                }

                case hasSomeImpossibleToVerifyResults: {
                    onAdd({
                        variant: TOAST_VARIANTS.ERROR,
                        status: 'error',
                        title: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.error.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.error.toast.description" />
                        )
                    })
                    break
                }

                case hasPendingVerificationResult: {
                    onAdd({
                        variant: TOAST_VARIANTS.INFO,
                        status: 'info',
                        title: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.info.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.info.toast.description" />
                        )
                    })
                    break
                }

                default: {
                    onAdd({
                        variant: TOAST_VARIANTS.SUCCESS,
                        status: 'success',
                        title: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.success.toast.title" />
                        ),
                        description: (
                            <FormattedMessage id="app.counterparties.counterparty_accounts.verify.success.toast.description" />
                        )
                    })
                }
            }
        },
        onError(response) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.error.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.counterparty_accounts.verify.error.toast.description" />
                )
            })
        }
    })

    const title = useMemo<ReactNode>(() => {
        if (query.isError) {
            const notFoundTitle = intl.formatMessage({
                id: 'app.common.not_found.title'
            })
            return notFoundTitle
        }
        return query.data?.name || query?.data?.holder_name || uuid
    }, [intl, query, uuid])

    const actions = []
    const isEditAvailable = hasPermission(PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS)
    const isVerifyAvailable =
        isFeatureActivated(ENTITY_FEATURE.COUNTERPARTY_ACCOUNT_VERIFICATION) &&
        hasPermission(PERMISSION.COUNTERPARTIES_VERIFY_COUNTERPARTY_ACCOUNTS)
    const isDisableAvailable =
        hasPermission(PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS) &&
        canDisableCounterpartyAccountByStatus(query.data?.status)

    if (isEditAvailable) {
        actions.push({
            onClick() {
                const path = updateUUIDRelativeActionPathname(location.pathname, relativePaths.COUNTERPARTIES.EDIT)

                navigate(path)
            },
            children: intl.formatMessage({
                id: 'app.common.actions.edit.label'
            })
        })
    }

    if (isVerifyAvailable) {
        actions.push({
            onClick() {
                mutationVerify.mutate()
            },
            isDisabled: query.isLoading || mutationVerify.isPending,
            isLoading: mutationVerify.isPending,
            children: intl.formatMessage({
                id: 'app.counterparties.counterparty_accounts.tabs.details.actions.verify.label'
            })
        })
    }

    if (isDisableAvailable) {
        actions.push({
            onClick() {
                setIsDisableModalOpen(true)
            },
            color: 'red.500',
            isDisabled: query.isLoading,
            children: intl.formatMessage({
                id: 'app.counterparties.counterparty_accounts.tabs.details.actions.disable.label'
            })
        })
    }

    const onCloseDisableModal = useCallback(() => {
        setIsDisableModalOpen(false)
    }, [])

    if (!hasPermission(PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS)) {
        return null
    }

    return (
        <>
            <PageHeaderLayout title={title} actions={actions} isLoading={query.isLoading} disabled={query.isError} />
            <If condition={hasPermission(PERMISSION.COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS)}>
                <CounterpartyAccountDisableConfirmationModal
                    uuid={uuid}
                    isOpen={isDisableModalOpen}
                    onClose={onCloseDisableModal}
                />
            </If>
        </>
    )
}
