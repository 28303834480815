import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Nullable } from '@/types'
import { ReconciliationRuleRelationship } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface ReconciliationRuleTypeProps {
    reconcileWithPayments: boolean
    relationship: Nullable<ReconciliationRuleRelationship>
}

export const ReconciliationRuleType: React.FC<ReconciliationRuleTypeProps> = memo(
    ({ reconcileWithPayments, relationship }) => {
        if (!reconcileWithPayments) {
            return <FormattedMessage id="app.common.reconciliation_rule.type.exclusion" />
        }

        if (!relationship) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        return (
            <FormattedMessage
                id={`app.common.reconciliation_rule.type.${relationship}`}
                defaultMessage={relationship}
            />
        )
    }
)
