import { Account, AccountSchema, DirectDebitMandate, IncomingPayment, PaymentOrder } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function hasAccountType(
    item?: PaymentOrder | IncomingPayment | DirectDebitMandate,
    accountType?: 'originating_account' | 'receiving_account'
): boolean {
    if (!item || !accountType) {
        return false
    }
    const account = item?.[accountType]

    return !chain<Account>(account).isEmpty().value() && AccountSchema.safeParse(account).success
}
