import { withCalleeName } from '../withCalleeName'

/**
 * @description
 * - Small utility which combines multiple filters into a single one;
 * - Works with a single filter predicate, but asserts if length is not the expected one;
 * @usage
 * const exampleCombinedFilter = combineFilters<ConnectedAccount>(
 *     enabledConnectedAccountsFilter,
 *     createPaymentOrderConnectedAccountServicesFilter
 * )
 * //...
 * // Combined higher-order filter:
 * data.filter(exampleCombinedFilter)
 *
 * // Chained filter function yields the same result as the above:
 * data
 *  .filter(enabledConnectedAccountsFilter)
 *  .filter(createPaymentOrderConnectedAccountServicesFilter)
 */

type Filters<T> = ((value: T, index: number, array: T[]) => unknown)[]
export function combineFilters<T = any>(...args: Filters<T>) {
    const { assert } = globalThis.console
    const filterResultPredicate = (result: boolean): boolean => result
    const hasValidNumberOfFilters = args?.length !== 1

    assert(
        hasValidNumberOfFilters,
        `The filters are of length one (1).\n
        Please use the filter predicate directly instead of this "${combineFilters.name}" utility.`
    )

    return withCalleeName(combineFilters.name, (item: T) => {
        const filterPredicateMapper = (filter: CallableFunction) => filter?.(item)

        return args?.map(filterPredicateMapper).every(filterResultPredicate)
    })
}
