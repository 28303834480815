import { useEffect } from 'react'
import { generatePaymentOrderDefaultDirectDebitMandate } from '@/services'
import { useFormikContext } from 'formik'
import { PaymentOrder } from '@webapps/numeral-ui-core'

export const PaymentOrderDirectDebitMandateSectionAutoReset: React.FC<{ useExistingDirectDebitMandate: boolean }> = ({
    useExistingDirectDebitMandate
}) => {
    const { setValues } = useFormikContext<Partial<PaymentOrder>>()

    useEffect(() => {
        setValues((values) => {
            if (!useExistingDirectDebitMandate) {
                return {
                    ...values,
                    direct_debit_mandate_id: undefined,
                    direct_debit_mandate: generatePaymentOrderDefaultDirectDebitMandate(values.type, values.direction)
                }
            }

            return {
                ...values,
                direct_debit_mandate_id: undefined,
                direct_debit_mandate: undefined
            }
        })
    }, [useExistingDirectDebitMandate, setValues])

    return null
}
