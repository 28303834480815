import { SyntheticEvent, useCallback } from 'react'
import { noop } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { Flex, Link } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { LeftArrowIcon } from '@/components/@icons'

interface PageHeaderLayoutBackButtonProps {
    onBeforeNavigate?(event: SyntheticEvent): void
    onAfterNavigate?(event: SyntheticEvent): void
}

export const PageHeaderLayoutBackButton: React.FC<PageHeaderLayoutBackButtonProps> = ({
    onBeforeNavigate = noop,
    onAfterNavigate = noop
}) => {
    const navigate = useNavigate()
    const onClickBack = useCallback(
        (event: SyntheticEvent) => {
            onBeforeNavigate(event)
            navigate(-1)
            onAfterNavigate(event)
        },
        [navigate, onBeforeNavigate, onAfterNavigate]
    )

    return (
        <Flex color="numeralAccent.500" data-testid="page-header-layout-back-button">
            <Link textDecoration="none" lineHeight="16px" onClick={onClickBack}>
                <LeftArrowIcon marginRight="4px" />
                <FormattedMessage id="app.common.actions.back" />
            </Link>
        </Flex>
    )
}
