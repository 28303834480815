import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellShortDate
} from '@/components'
import { ConnectedAccountName, Direction, FormattedAmount, FormattedDateTime } from '@/components/@misc'
import { Balance } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'
import { BalanceDetailsCustomRows } from './BalanceDetails.types'

export const BALANCE_DETAILS_ROWS: EntityDetailRow<Balance, BalanceDetailsCustomRows>[] = [
    'id',
    'created_at',
    'connected_account_id',
    'type',
    'direction',
    'amount',
    'date',
    'related_objects',
    'bank_data'
]

export const BALANCE_ENTITY_DETAIL_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<Balance, BalanceDetailsCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    type: {
        cell(item) {
            return <FormattedMessage id={`api.balance.type.${item.type}`} defaultMessage={item.type} />
        }
    },
    direction: {
        cell(item) {
            return <Direction value={item.direction} />
        }
    },
    amount: {
        cell(item) {
            return <FormattedAmount amount={item.amount} currency={item.currency} />
        }
    },
    date: {
        label() {
            return <FormattedMessage id="app.page.details.fields.balance_date.label" />
        },
        cell(item) {
            return <TableCellShortDate value={item.date} />
        }
    },
    related_objects: {
        cell(item) {
            return <EntityDetailCellRelatedObjectsLinks relatedFileId={item.bank_data?.file_id} />
        }
    },
    bank_data: {
        nested: true
    }
}
