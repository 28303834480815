import { z } from 'zod'

const urlMaxSize = 1024
const [urlSegmentMinSize, urlSegmentMaxSize] = [1, 96]

// nosemgrep RegEx expression has a range set. It is highly unlikely to cause ReDOS.
const URL_SEGMENT_PATTERN = new RegExp(`^[a-zA-Z0-9_.~-]{${urlSegmentMinSize},${urlSegmentMaxSize}}$`)
const RELATIVE_URL_PATTERN = new RegExp(`^/?(?:[^/]+\\/[^/].*)+$`)

export const RelativeURLSchema = z.string().refine((value) => {
    return value.length < urlMaxSize && (URL_SEGMENT_PATTERN.test(value) || RELATIVE_URL_PATTERN.test(value))
})
