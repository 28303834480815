import { PropsWithChildren } from 'react'

type IfProps = {
    condition?: boolean
} & PropsWithChildren

export const If: React.FC<IfProps> = ({ condition, children }) => {
    if (!condition) {
        return null
    }

    return <>{children}</>
}
