import { TransactionCategory as TransactionCategoryType } from '@webapps/numeral-ui-core'
import { prettyPrintCasedWords } from '@/utils'
import { useMemo } from 'react'
import { FormattedTextIntl } from '@/components/@misc'

interface TransactionCategoryProps {
    value: TransactionCategoryType
}

export const TransactionCategory: React.FC<TransactionCategoryProps> = ({ value }) => {
    const propsFormattedTextIntl = useMemo(() => {
        const defaultMessage = prettyPrintCasedWords(value)

        return {
            messageId: `api.accounts.transactions.category.${value}`,
            defaultMessage
        }
    }, [value])

    return <FormattedTextIntl {...propsFormattedTextIntl} />
}
