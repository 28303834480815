import { LogoutModal } from '@/components'
import { HelpCenterIcon, LogoutIcon } from '@/components/@icons'
import { SearchInput } from '@/components/@misc'
import { useAuth } from '@/hooks'
import { UserAccess } from '@/services'
import { contactSupport } from '@/utils'
import {
    Button,
    Flex,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MAIN_NAVIGATION_ACCOUNT_DROPDOWN_MENU_DIMENSION } from '../../../MainNavigation.const'
import { MIN_NUMBER_OF_ACCESSES_TO_DISPLAY_SEARCH } from './MainNavigationAccountDropdown.const'

export const MainNavigationAccountDropdownMenu: React.FC<PropsWithChildren> = ({ children }) => {
    const intl = useIntl()
    const { userAccesses, selectedUserAccess, selectUserAccess } = useAuth()
    const [search, setSearch] = useState<string>('')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const hasSearchInput = useMemo<boolean>(() => {
        return !!userAccesses?.length && userAccesses?.length >= MIN_NUMBER_OF_ACCESSES_TO_DISPLAY_SEARCH
    }, [userAccesses])

    const inputSearchPlaceholder = useMemo<string | undefined>(
        () =>
            hasSearchInput ? intl.formatMessage({ id: 'app.nav.user_accesses.form.search.placeholder' }) : undefined,
        [hasSearchInput, intl]
    )

    const onSearch = useCallback((event: React.FormEvent<HTMLInputElement>) => {
        setSearch(event.currentTarget.value)
    }, [])

    const filteredAccesses = useMemo<UserAccess[]>(() => {
        return userAccesses?.filter((item) => !search || item.name.toLowerCase().includes(search.toLowerCase())) || []
    }, [userAccesses, search])

    const footerButtonProps = useRef({
        variant: 'ghost',
        width: '100%',
        justifyContent: 'left',
        padding: '8px 12px',
        color: 'gray.500',
        fontWeight: 'medium',
        _hover: {
            color: 'black'
        }
    }).current

    return (
        <>
            <Popover
                placement="bottom"
                matchWidth={true}
                isLazy={true}
                data-testid="main-navigation-account-dropdown-menu">
                <PopoverTrigger>{children}</PopoverTrigger>
                <PopoverContent
                    width={MAIN_NAVIGATION_ACCOUNT_DROPDOWN_MENU_DIMENSION.WIDTH}
                    boxShadow="lg"
                    borderRadius="var(--numeral-ui-primary-border-radius)"
                    border="none">
                    {hasSearchInput && (
                        <SearchInput
                            inputMargin="16px 12px 0px 12px"
                            placeholder={inputSearchPlaceholder}
                            value={search}
                            onInputChange={onSearch}
                        />
                    )}
                    <PopoverBody
                        maxHeight={MAIN_NAVIGATION_ACCOUNT_DROPDOWN_MENU_DIMENSION.MAX_HEIGHT}
                        padding="16px 0"
                        overflowY="auto">
                        {(() => {
                            if (isEmpty(filteredAccesses)) {
                                return (
                                    <Text padding="4px 16px" textAlign="center" color="gray.500">
                                        <FormattedMessage
                                            id="app.nav.user_accesses.search.none.content"
                                            values={{ name: search }}
                                        />
                                    </Text>
                                )
                            }

                            return filteredAccesses.map((item: UserAccess) => {
                                const isActive = item.id === selectedUserAccess?.id
                                const isEnvDisplayed = item.environment !== 'production'
                                return (
                                    <Flex
                                        as="button"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        title={item.name}
                                        width="100%"
                                        padding="6px 12px"
                                        _hover={{
                                            backgroundColor: 'blue.50'
                                        }}
                                        key={item.id}
                                        onClick={() => selectUserAccess(item)}>
                                        <Text
                                            as="span"
                                            noOfLines={1}
                                            display="block"
                                            whiteSpace="nowrap"
                                            marginRight="18px"
                                            color={isActive ? 'numeralBlue.500' : 'gray.500'}
                                            fontWeight={isActive ? 'semibold' : 'normal'}>
                                            {item.name}
                                        </Text>
                                        {isEnvDisplayed && (
                                            <Text
                                                as="span"
                                                padding="2px 4px"
                                                borderRadius="var(--numeral-ui-primary-border-radius)"
                                                fontSize="smaller"
                                                fontWeight="semibold"
                                                color="gray.500"
                                                backgroundColor="gray.100"
                                                textTransform="capitalize">
                                                {item.environment}
                                            </Text>
                                        )}
                                    </Flex>
                                )
                            })
                        })()}
                    </PopoverBody>
                    <PopoverFooter padding="8px 0px" marginX="0">
                        <Button {...footerButtonProps} leftIcon={<HelpCenterIcon />} onClick={contactSupport}>
                            <FormattedMessage id="app.nav.help.label" />
                        </Button>
                        <Button {...footerButtonProps} leftIcon={<LogoutIcon />} onClick={onOpen}>
                            <FormattedMessage id="app.common.actions.logout" />
                        </Button>
                    </PopoverFooter>
                </PopoverContent>
            </Popover>
            <LogoutModal isOpen={isOpen} onClose={onClose} />
        </>
    )
}
