import { Group } from '@/types'
import { AccordionItemProps } from '@chakra-ui/react'
import { chain, kebabCase, toString, uniqueId } from 'lodash'

export function generateGroupByUniqueKey(group: string, ...args: number[]): string {
    const mostSignificantKey = uniqueId(`${group}-`)

    return chain([...args])
        .map(toString)
        .map(kebabCase)
        .push(mostSignificantKey)
        .join('-')
        .value()
}

export function getGroupByAccordionItemStyle<T, G>(
    groups?: Group<T, G>[],
    depth?: number,
    index?: number
): Partial<AccordionItemProps> | undefined {
    const backgroundColor = depth && depth % 2 ? 'gray.50' : 'white'
    const borderRadius = 'var(--numeral-ui-primary-border-radius)'

    const isFirstGroup = depth === 0
    const isFirstInGroup = index === 0

    const groupsLength = groups?.length || 0
    const isSingleEntry = groupsLength === 1
    const isLastInGroup = groupsLength - 1 === index
    const isMultiEntry = groupsLength > 1

    switch (true) {
        case isSingleEntry:
        case isFirstGroup: {
            return {
                backgroundColor,
                borderRadius
            }
        }

        // Last entry
        case isLastInGroup && !isFirstInGroup: {
            return {
                backgroundColor,
                borderTopRadius: 'unset',
                borderBottomRadius: borderRadius,
                borderTop: isMultiEntry ? 'unset' : '1px solid inherit'
            }
        }

        // First entry
        case isFirstInGroup && !isLastInGroup: {
            return {
                backgroundColor,
                borderBottomRadius: 'unset',
                borderTopRadius: borderRadius,
                borderBottom: isMultiEntry ? '1px solid inherit' : 'unset'
            }
        }

        // Middle entry
        case !isFirstInGroup || !isLastInGroup: {
            return {
                backgroundColor,
                borderTop: 'unset',
                borderBottom: '1px solid inherit'
            }
        }
    }
}
