import { PERMISSION, RolePermissions } from '@/services'
import { PERMISSION_GROUPING, PERMISSIONS_GROUP_NAME } from './RoleUserPermissions.const'

function generateAdminPermissionName(groupName: PERMISSIONS_GROUP_NAME) {
    return `${groupName}.admin` as PERMISSION
}

export function getRolePermissionsInGroup(
    groupName: PERMISSIONS_GROUP_NAME,
    permissions?: RolePermissions
): PERMISSION[] {
    if (!permissions || permissions.length === 0) {
        return []
    }

    const groupAdminName = generateAdminPermissionName(groupName)
    const hasAdminAccesses = permissions.includes(groupAdminName)

    if (hasAdminAccesses) {
        return PERMISSION_GROUPING[groupName]
    } else {
        return PERMISSION_GROUPING[groupName].filter((permission) => permissions.includes(permission))
    }
}
