import { PageLayout } from '@/layouts'
import { Box } from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutationUpdateByIdTransaction, useNavigationRoutes, useQueryFindByIdTransaction } from '@/hooks'
import { TransactionEditForm } from './@components'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { isEmpty } from 'lodash'
import { TransactionHeader } from '@/pages'
import { updateUUIDRelativeActionPathname } from '@/providers'

export const TransactionEditPage: React.FC = () => {
    const { uuid } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdTransaction(uuid)
    const mutation = useMutationUpdateByIdTransaction(uuid, {
        onSuccess() {
            const { ACCOUNTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, ACCOUNTS.DETAILS)

            navigate(path)
        }
    })

    return (
        <PageLayout className="TransactionEditPage">
            <TransactionHeader />
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS}>
                <Box marginTop="8px">
                    {(() => {
                        switch (true) {
                            case query?.isLoading: {
                                return <LoadingSpinner />
                            }

                            case isEmpty(query?.data):
                            case query?.isError: {
                                return <EmptyContent />
                            }

                            default: {
                                return (
                                    <TransactionEditForm
                                        onSubmit={mutation.mutate}
                                        disabled={mutation.isPending}
                                        loading={mutation.isPending}
                                        data={query.data}
                                    />
                                )
                            }
                        }
                    })()}
                </Box>
            </RestrictedSection>
        </PageLayout>
    )
}
