import { EntityAction, EntityActionsMenu } from '@/components'
import { useMutationCancelByIdReconciliation, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Reconciliation } from '@webapps/numeral-ui-core'

interface ReconciliationTableRowOptionsProps {
    item: Reconciliation
}

export const ReconciliationTableRowOptions: React.FC<ReconciliationTableRowOptionsProps> = ({ item }) => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const mutationCancel = useMutationCancelByIdReconciliation(item?.id, {
        onSuccess() {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.reconciliations.actions.cancel.success.toast.title" />,
                description: <FormattedMessage id="app.reconciliations.actions.cancel.success.toast.description" />
            })
        }
    })
    const actions = useMemo<EntityAction[]>(() => {
        const isDisabled = !item || !!item?.canceled_at

        return [
            {
                onClick() {
                    mutationCancel.mutate()
                },
                children: intl.formatMessage({
                    id: 'app.reconciliations.actions.cancel.label'
                }),
                color: 'red.500',
                disabled: isDisabled || mutationCancel.isPending,
                isLoading: mutationCancel.isPending
            }
        ]
    }, [intl, item, mutationCancel])

    return <EntityActionsMenu actions={actions} />
}
