import { FilterInputs, TableCellShortDate, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { APIKeyPermissionInput, TextInput } from '@/components/@inputs'
import { EnabledStatus } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { APIKey, APIKeysServiceFindAllQueryOptions } from '@/services'
import { getAPIKeyLast4CharsPlaceholderTemplate } from '@/services/APIKeys/APIKeys.service.utils'
import { last4CharsValidator } from '@/utils/@validators'

export const API_KEY_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/authentication`

export const APIKEY_TABLE_STATIC_STATE: TableStateWithEntity<APIKey> = {
    columnOrder: Object.freeze(['created_at', 'name', 'last_4_chars', 'permission', 'disabled_at'])
}

export const APIKEY_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<APIKey> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        size: COLUMN_WIDTH_RATIO.API_KEY_NAME
    },
    last_4_chars: {
        cell(info) {
            return getAPIKeyLast4CharsPlaceholderTemplate(info.row.original)
        }
    },
    disabled_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            const isDisabled = !!info.getValue()
            return <EnabledStatus isDisabled={isDisabled} />
        }
    }
}

export const APIKEY_FILTERS: FilterInputs<APIKeysServiceFindAllQueryOptions> = {
    last_4_chars: <TextInput name="last_4_chars" validator={last4CharsValidator} />,
    permission: <APIKeyPermissionInput name="permission" />
}
