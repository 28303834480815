import {
    StorageServiceKeyOrKeyResolver,
    StorageServiceTransformationStrategy
} from '@/services/Storage/Storage.service.types'
import { chain, isFunction } from 'lodash'
import { getDefaultStorageTransformationStrategy } from './Storage.service.utils'

export class StorageService {
    private static storage: Storage = globalThis.localStorage

    private static transform: StorageServiceTransformationStrategy = getDefaultStorageTransformationStrategy()

    static setStorageStrategy(strategy: 'localStorage' | 'sessionStorage') {
        const storage: Storage = global[strategy]

        if (!(storage instanceof Storage)) {
            return
        }

        StorageService.storage = global[strategy]
    }

    static setTransformStrategy(strategy: StorageServiceTransformationStrategy) {
        const hasCorrectShape: boolean = chain<StorageServiceTransformationStrategy>(strategy)
            .values()
            .every(isFunction)
            .value()

        if (!hasCorrectShape) {
            return
        }

        StorageService.transform = strategy
    }

    static setItem(key: StorageServiceKeyOrKeyResolver, value: string) {
        if (!key || !value) {
            return
        }

        try {
            const resolvedKey: string = StorageService.getStorageKey(key)
            const transformedValue = StorageService.transform.input(value)

            StorageService.storage.setItem(resolvedKey, transformedValue)
        } catch (error) {
            StorageService.genericStorageErrorHandler(error)
        }
    }

    static removeItem(key: StorageServiceKeyOrKeyResolver) {
        try {
            const resolvedKey: string = StorageService.getStorageKey(key)
            StorageService.storage.removeItem(resolvedKey)
        } catch (error) {
            StorageService.genericStorageErrorHandler(error)
        }
    }

    static getItem(key: StorageServiceKeyOrKeyResolver): string | undefined | null {
        try {
            const resolvedKey: string = StorageService.getStorageKey(key)
            const value = StorageService.storage.getItem(resolvedKey)

            return StorageService.transform.output(value)
        } catch (error) {
            StorageService.genericStorageErrorHandler(error)
        }
    }

    static clear(): void {
        try {
            StorageService.storage.clear()
        } catch (error) {
            StorageService.genericStorageErrorHandler(error)
        }
    }

    private static getStorageKey(keyOrKeyResolver: StorageServiceKeyOrKeyResolver) {
        return isFunction(keyOrKeyResolver) ? keyOrKeyResolver() : keyOrKeyResolver
    }

    private static genericStorageErrorHandler(error: unknown) {
        /** @todo Handle error **/
    }
}
