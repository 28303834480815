export const ENVIRONMENT_VARIABLES: EnvironmentVariables = Object.freeze({
    BASE_URL: import.meta.env.BASE_URL,
    MODE: import.meta.env.MODE,
    DEV: import.meta.env.DEV,
    PROD: import.meta.env.PROD,
    SSR: import.meta.env.SSR,

    VITE_PACKAGE_NAME: import.meta.env.VITE_PACKAGE_NAME,
    VITE_APP_NAME: import.meta.env.VITE_APP_NAME,
    VITE_SENTRY_URL: import.meta.env.VITE_SENTRY_URL,
    VITE_DEFAULT_API_TIMEOUT: import.meta.env.VITE_DEFAULT_API_TIMEOUT,
    VITE_DEFAULT_PAGINATION_LIMIT: import.meta.env.VITE_DEFAULT_PAGINATION_LIMIT,

    VITE_PRODUCTION_API_URL: import.meta.env.VITE_PRODUCTION_API_URL,
    VITE_PRODUCTION_FLOA_API_URL: import.meta.env.VITE_PRODUCTION_FLOA_API_URL,
    VITE_SANDBOX_API_URL: import.meta.env.VITE_SANDBOX_API_URL,
    VITE_STAGING_API_URL: import.meta.env.VITE_STAGING_API_URL,
    VITE_STAGING_PAYMENT_API_URL: import.meta.env.VITE_STAGING_PAYMENT_API_URL,
    VITE_STAGING_PLATFORM_API_URL: import.meta.env.VITE_STAGING_PLATFORM_API_URL,
    VITE_STAGING_WEBAPPS_API_URL: import.meta.env.VITE_STAGING_WEBAPPS_API_URL,
    VITE_LOCAL_API_URL: import.meta.env.VITE_LOCAL_API_URL,

    VITE_GIT_SHORT_HASH: import.meta.env.VITE_GIT_SHORT_HASH
})

export enum SupportedEnvironment {
    PRODUCTION = 'app.numeral.io',
    PRODUCTION_FLOA = 'app.floa.numeral.io',

    STAGING = 'app.staging.numeral.io',
    STAGING_PAYMENT = 'app.staging-payment.numeral.io',
    STAGING_PLATFORM = 'app.staging-platform.numeral.io',
    STAGING_WEBAPPS = 'app.staging-webapps.numeral.io',

    LEGACY_LOCAL = 'app.numeral.local',
    LOCAL = 'app.local.numeral.io',
    TEST = 'localhost'
}

export enum SupportedApiEnvironmentName {
    PRODUCTION = 'production',
    SANDBOX = 'sandbox',
    PRODUCTION_FLOA = 'floa',

    STAGING = 'staging',
    STAGING_PAYMENT = 'staging-payment',
    STAGING_PLATFORM = 'staging-platform',
    STAGING_WEBAPPS = 'staging-webapps',

    LOCAL = 'local',
    TEST = 'test'
}

// List of supported API environments for each supported app environment
export const API_ENVIRONMENTS_PER_APP_ENVIRONMENT: Readonly<
    Record<SupportedEnvironment, Array<SupportedApiEnvironmentName>>
> = Object.freeze({
    // Production environments
    [SupportedEnvironment.PRODUCTION]: [SupportedApiEnvironmentName.PRODUCTION, SupportedApiEnvironmentName.SANDBOX],
    [SupportedEnvironment.PRODUCTION_FLOA]: [SupportedApiEnvironmentName.PRODUCTION_FLOA],

    // Staging environments
    [SupportedEnvironment.STAGING]: [SupportedApiEnvironmentName.STAGING],
    [SupportedEnvironment.STAGING_PAYMENT]: [
        SupportedApiEnvironmentName.STAGING_PAYMENT,
        SupportedApiEnvironmentName.STAGING
    ],
    [SupportedEnvironment.STAGING_PLATFORM]: [
        SupportedApiEnvironmentName.STAGING_PLATFORM,
        SupportedApiEnvironmentName.STAGING
    ],
    [SupportedEnvironment.STAGING_WEBAPPS]: [
        SupportedApiEnvironmentName.STAGING_WEBAPPS,
        SupportedApiEnvironmentName.STAGING
    ],

    // Local environments
    [SupportedEnvironment.LEGACY_LOCAL]: [SupportedApiEnvironmentName.LOCAL],
    [SupportedEnvironment.LOCAL]: [SupportedApiEnvironmentName.LOCAL],
    [SupportedEnvironment.TEST]: [SupportedApiEnvironmentName.TEST]
})

// List of API base URLs
export const API_BASE_URLS: Readonly<Record<SupportedApiEnvironmentName, string>> = Object.freeze({
    [SupportedApiEnvironmentName.PRODUCTION_FLOA]: ENVIRONMENT_VARIABLES.VITE_PRODUCTION_FLOA_API_URL,
    [SupportedApiEnvironmentName.PRODUCTION]: ENVIRONMENT_VARIABLES.VITE_PRODUCTION_API_URL,

    [SupportedApiEnvironmentName.SANDBOX]: ENVIRONMENT_VARIABLES.VITE_SANDBOX_API_URL,

    [SupportedApiEnvironmentName.STAGING]: ENVIRONMENT_VARIABLES.VITE_STAGING_API_URL,
    [SupportedApiEnvironmentName.STAGING_PAYMENT]: ENVIRONMENT_VARIABLES.VITE_STAGING_PAYMENT_API_URL,
    [SupportedApiEnvironmentName.STAGING_PLATFORM]: ENVIRONMENT_VARIABLES.VITE_STAGING_PLATFORM_API_URL,
    [SupportedApiEnvironmentName.STAGING_WEBAPPS]: ENVIRONMENT_VARIABLES.VITE_STAGING_WEBAPPS_API_URL,

    [SupportedApiEnvironmentName.LOCAL]: ENVIRONMENT_VARIABLES.VITE_LOCAL_API_URL,
    [SupportedApiEnvironmentName.TEST]: 'https://bff.fake.url'
})
