import { UserInfo } from '@webapps/numeral-ui-core'
import { differenceWith, fromPairs, isEqual, omit, some, toPairs } from 'lodash'
import { USER_INFO_OPTIONAL_KEYS } from './IncompleteUserInfoModal.const'

export function getUserInfoUpdatedFields<T>(userInfo?: Partial<T>, submittedUserInfo?: Partial<T>) {
    return fromPairs(differenceWith(toPairs(submittedUserInfo), toPairs(userInfo), isEqual)) as T
}

export function isUserInfoIncomplete(userInfo: UserInfo): boolean {
    return some(omit(userInfo, USER_INFO_OPTIONAL_KEYS), (value) => value === '')
}
