import { useAuth } from '@/hooks'
import { STORAGE_KEYS, StorageService, UserAccess } from '@/services'
import { withCalleeName } from '@/utils'
import { Box } from '@chakra-ui/react'
import { find, head, isNil } from 'lodash'
import { JSXElementConstructor, PropsWithoutRef } from 'react'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '../NavigationRoutesProvider'

export function isFindAllUserAccessesQueryEnabled(
    userAccess: UserAccess | undefined,
    isUserLoggingOut: boolean,
    currentPath: string
) {
    return (
        isNil(userAccess) &&
        !isUserLoggingOut &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.CALLBACK) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.INITIAL_CALLBACK) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.EXTRA_LOGOUT) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.ACCOUNT.LOGIN) &&
        !currentPath.includes(NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.ACCOUNT.ERROR)
    )
}

export function setActiveAccessIdToStorage(value: string) {
    return StorageService.setItem(STORAGE_KEYS.ACTIVE_ACCESS_ID, value)
}

export function getActiveAccessIdFromStorage() {
    return StorageService.getItem(STORAGE_KEYS.ACTIVE_ACCESS_ID)
}

export function getUserAccessByAccessId(list?: UserAccess[]): UserAccess | undefined {
    const savedUserAccessId = getActiveAccessIdFromStorage()
    const defaultUserAccess = head(list)
    const savedActiveAccessId = find(list, ({ id }) => id === savedUserAccessId)

    if (isNil(savedActiveAccessId)) {
        return defaultUserAccess
    }

    return savedActiveAccessId
}

export function withUserAccess(Component: JSXElementConstructor<any>) {
    const name = `${withUserAccess?.name}(${Component?.name})`

    return withCalleeName(name, (props?: PropsWithoutRef<any>) => {
        const { selectedUserAccess } = useAuth()

        return (
            <Box hidden={!selectedUserAccess?.id}>
                <Component {...props} />
            </Box>
        )
    })
}

export function hasUserMatchingRole(role: string, roles: string[]): boolean {
    return roles?.includes(role)
}
