import { FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { createAccountFromConnectedAccount, isFinancialInstitutionConnectedAccount, isSDDPayment } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { computePartialPaymentOrderFormFields } from '../PaymentOrderCreate.form.utils'

export interface PaymentOrderOriginatingAccountSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderOriginatingAccountSection: React.FC<PaymentOrderOriginatingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<Partial<PaymentOrder>>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: type } = getFieldProps('type')
    const { value: direction } = getFieldProps('direction')

    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const hasFinancialInstitutionConnectedAccount = useMemo(
        () => isFinancialInstitutionConnectedAccount(queryConnectedAccount.data),
        [queryConnectedAccount]
    )
    const hasSDDPaymentType = useMemo(() => isSDDPayment(type, direction), [type, direction])

    useEffect(() => {
        const { originating_account } = computePartialPaymentOrderFormFields(queryConnectedAccount.data)
        const originatingAccount = hasFinancialInstitutionConnectedAccount
            ? createAccountFromConnectedAccount(queryConnectedAccount.data, hasSDDPaymentType, originating_account)
            : undefined

        setFieldValue('originating_account', originatingAccount)
    }, [hasFinancialInstitutionConnectedAccount, hasSDDPaymentType, queryConnectedAccount.data])

    return (
        <If condition={queryConnectedAccount.isLoading || hasFinancialInstitutionConnectedAccount}>
            <FormSection titleId="app.payments.payment_orders.create.form.sections.originating_account.title">
                <AccountDetailsForm
                    inputsNamePrefix="originating_account"
                    isCreditorIdentifierInputDisplayed={hasSDDPaymentType}
                    isBankCodeInputReadOnly={true}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <OptionalAccountHolderAddressForm sectionName="originating_account" isDisabled={isDisabled} />
            </FormSection>
        </If>
    )
}
