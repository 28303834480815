import {
    EmptyContent,
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    LoadingSpinner,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdConnectedAccount, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import {
    CounterpartyCustomRows,
    getPaymentCounterpartyDetailRowCustomizers,
    getPaymentCounterpartyDetailRows,
    pickPaymentAccounts
} from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const PaymentOrderCounterpartyPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(query.data?.connected_account_id, {
        enabled: globalThis.Boolean(query.data?.connected_account_id)
    })
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<CounterpartyCustomRows>>(() => {
        return getPaymentCounterpartyDetailRowCustomizers(query.data, queryConnectedAccount.data)
    }, [query, queryConnectedAccount])
    const data = useMemo(() => {
        return pickPaymentAccounts(query.data)
    }, [query])

    const rows = useMemo<EntityDetailRow<CounterpartyCustomRows>[]>(() => {
        return getPaymentCounterpartyDetailRows(query.data)
    }, [query])

    return (
        <PageLayout className="PaymentOrderCounterparty">
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<CounterpartyCustomRows>
                                    data={data}
                                    rows={rows}
                                    rowCustomizers={rowCustomizers}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
