import { LocaleSwitcherContext } from '@/providers'
import { useContext } from 'react'

export const useLocaleSwitcher = () => {
    const context = useContext(LocaleSwitcherContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${LocaleSwitcherContext.displayName}`)
    }

    return context
}
