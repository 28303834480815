import { Icon, IconProps } from '@chakra-ui/react'

export const CollapseIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="-1 -2 16 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.81547 1.24469C1.81547 0.925367 1.48382 0.666504 1.07472 0.666504C0.665625 0.666504 0.333984 0.925367 0.333984 1.24469V10.4957C0.333984 10.815 0.665625 11.0739 1.07472 11.0739C1.48382 11.0739 1.81547 10.815 1.81547 10.4957V1.24469ZM12.9266 5.14948C13.3357 5.14948 13.6673 5.48112 13.6673 5.89022C13.6673 6.29932 13.3357 6.63096 12.9266 6.63096H5.82829L9.00641 9.80908C9.29569 10.0984 9.29569 10.5674 9.00641 10.8567C8.71714 11.1459 8.24813 11.1459 7.95885 10.8567L3.5498 6.4476C3.39482 6.31183 3.29695 6.11245 3.29695 5.89022C3.29695 5.81382 3.30851 5.74013 3.32999 5.67079C3.36132 5.56859 3.41532 5.4717 3.49215 5.38789L7.56622 0.943443C7.84266 0.641873 8.31123 0.6215 8.6128 0.897939C8.91437 1.17438 8.93474 1.64295 8.6583 1.94452L5.72042 5.14948H12.9266Z"
        />
    </Icon>
)
