import { FormattedComperator, If } from '@/components/@misc'
import { Nullable } from '@/types'
import { Flex, Tag, Text, VStack } from '@chakra-ui/react'
import { ReconciliationRuleCondition, ReconciliationRuleFilter } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

interface ReconciliationRuleConditionsProps {
    filters: Nullable<ReconciliationRuleFilter[]>
    conditions: Nullable<ReconciliationRuleCondition[]>
}

export const ReconciliationRuleConditions: React.FC<ReconciliationRuleConditionsProps> = memo(
    ({ filters, conditions }) => {
        const andTag = (
            <Tag backgroundColor="gray.100">
                <FormattedMessage id="app.settings.reconciliation_rules.tag.and.label" />
            </Tag>
        )

        const formattedFilters = filters?.map((filter, index) => {
            const isEndTagDisplayed = index !== 0
            return (
                <Flex key={filter.attribute} alignItems="center" gap="4px">
                    <If condition={isEndTagDisplayed}> {andTag} </If>
                    <FormattedMessage
                        id={`app.settings.reconciliation_rules.filter.attribute.${filter.attribute}.label`}
                        defaultMessage={filter.attribute}
                    />
                    <Tag backgroundColor="gray.100">
                        <FormattedComperator comparator={filter.comparator} />
                    </Tag>
                    <Text as="span">{filter.value}</Text>
                </Flex>
            )
        })

        const formattedConditions = conditions?.map((condition, index) => {
            const isEndTagDisplayed = index !== 0 || (index === 0 && !!filters?.length)
            return (
                <Flex key={condition.name} alignItems="center" gap="4px">
                    <If condition={isEndTagDisplayed}>{andTag}</If>
                    <FormattedMessage
                        id={`app.settings.reconciliation_rules.condition.${condition.name}.label`}
                        defaultMessage={condition.name}
                    />
                </Flex>
            )
        })

        return (
            <VStack spacing="8px" alignItems="flex-start">
                {formattedFilters}
                {formattedConditions}
            </VStack>
        )
    }
)
