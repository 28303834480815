import { Form, FormPageFooter, InputWithCopyToClipboard } from '@/components'
import { APIKey } from '@/services'
import { APIKeyPermissionSchema } from '@/services/APIKeys/APIKey.schema'
import { getAPIKeyStatusMapper, isAPIKeyDisabled } from '@/services/APIKeys/APIKeys.service.utils'
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, Radio, RadioGroup } from '@chakra-ui/react'
import { Field } from 'formik'
import { noop } from 'lodash'
import { useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { getAPIKeyFormInitialValues, getAPIKeyFormValidationSchemaWithIntl } from './APIKey.form.utils'

interface APIKeyFormProps {
    data?: APIKey
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: Partial<APIKey>): void
}

export const APIKeyForm: React.FC<APIKeyFormProps> = ({ data, loading, disabled, onSubmit = noop }) => {
    const intl = useIntl()
    const isCreateMode = useRef<boolean>(Boolean(!data?.id)).current
    const initialValues = useRef<APIKey>(getAPIKeyFormInitialValues(data)).current
    const validationSchemaWithIntl = useMemo(() => {
        return getAPIKeyFormValidationSchemaWithIntl(intl)
    }, [intl])
    const onFormSubmit = useCallback(onSubmit, [onSubmit])
    const submitLabelMessageId = useMemo<string | undefined>(() => {
        return isCreateMode ? 'app.developers.api_keys.form.actions.submit.label' : undefined
    }, [isCreateMode])
    const isDisabled = useRef<boolean>(isAPIKeyDisabled(data)).current

    return (
        <Form<APIKey> initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={validationSchemaWithIntl}>
            {({ values, errors, touched, handleSubmit, dirty, handleChange, isSubmitting }) => {
                const isInputDisabled = !isCreateMode || disabled

                return (
                    <>
                        <FormControl hidden={isCreateMode}>
                            <FormLabel htmlFor="id">
                                {intl.formatMessage({
                                    id: 'app.developers.webhooks.form.id.label'
                                })}
                            </FormLabel>
                            <InputWithCopyToClipboard
                                id="id"
                                name="id"
                                defaultValue={data?.id}
                                isDisabled={isInputDisabled}
                            />
                        </FormControl>
                        <FormControl isInvalid={!!errors.name && !!touched.name}>
                            <FormLabel htmlFor="name">
                                {intl.formatMessage({
                                    id: 'app.developers.api_keys.form.name.label'
                                })}
                            </FormLabel>
                            <Field
                                placeholder={intl.formatMessage({
                                    id: 'app.developers.api_keys.form.name.label'
                                })}
                                as={Input}
                                id="name"
                                name="name"
                                isDisabled={disabled}
                            />
                            <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!errors.permission && !!touched.permission}>
                            <FormLabel htmlFor="permission">
                                {intl.formatMessage({
                                    id: 'app.developers.api_keys.form.permission.label'
                                })}
                            </FormLabel>

                            {isCreateMode ? (
                                <RadioGroup
                                    id="permission"
                                    name="permission"
                                    value={values.permission}
                                    onChange={handleChange('permission')}
                                    isDisabled={disabled}>
                                    <Flex gap="16px">
                                        {APIKeyPermissionSchema.options.map((item) => (
                                            <Radio value={item} key={item}>
                                                {intl.formatMessage({
                                                    id: `app.developers.api_keys.form.permissions.${item}.label`
                                                })}
                                            </Radio>
                                        ))}
                                    </Flex>
                                </RadioGroup>
                            ) : (
                                <Input
                                    id="permission"
                                    name="permission"
                                    disabled={!isCreateMode || isDisabled || disabled}
                                    defaultValue={intl.formatMessage({
                                        id: `app.developers.api_keys.form.permissions.${data?.permission}.label`
                                    })}
                                />
                            )}
                            <FormErrorMessage>{errors.permission as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl hidden={isCreateMode}>
                            <FormLabel htmlFor="status">
                                {intl.formatMessage({
                                    id: 'app.developers.api_keys.form.status.label'
                                })}
                            </FormLabel>
                            <Input
                                id="status"
                                name="status"
                                disabled={!isCreateMode || isDisabled || disabled}
                                defaultValue={getAPIKeyStatusMapper(data)}
                            />
                        </FormControl>
                        {/* @todo Retouch this block on V2 implementation
                        <FormControl>
                            <FormLabel htmlFor="ips">Restrict to specific IPs</FormLabel>
                            <FieldArray name="ips">
                                {({ remove, push }) => (
                                    <Box marginBottom="16px">
                                        <Flex direction="column">
                                            {values.ips.map((value: string, index: number) => {
                                                const fieldName = `ips.${index}`
                                                const onRemove = () => remove(index)
                                                const RightElementComponent = () => (
                                                    <Button colorScheme="gray" variant="outline" onClick={onRemove}>
                                                        𝗑
                                                    </Button>
                                                )
                                                const hasFieldError = errors.ips?.[index]
                                                const isFieldTouched = touched.ips?.[index]
                                                const isInvalid = !!hasFieldError && isFieldTouched

                                                return (
                                                    <FormControl isInvalid={isInvalid} marginBottom="16px" key={index}>
                                                        <Field
                                                            name={fieldName}
                                                            placeholder="Enter IP address"
                                                            as={InputWithRightElement}
                                                            RightElementComponent={RightElementComponent}
                                                            isDisabled={disabled}
                                                        />
                                                        <FormErrorMessage>{hasFieldError}</FormErrorMessage>
                                                    </FormControl>
                                                )
                                            })}
                                        </Flex>
                                        <Button
                                            leftIcon={'+' as any}
                                            colorScheme="gray"
                                            variant="outline"
                                            onClick={() => push('')}>
                                            Add IP
                                        </Button>
                                    </Box>
                                )}
                            </FieldArray>
                        </FormControl>*/}
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId={submitLabelMessageId}
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
