import { SPACE_SYMBOL } from '@/constants'
import { FormikInput } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { FieldValidator, useField, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

interface DateInputProps extends Omit<FormikInput, 'min' | 'max'> {
    min?: string
    max?: string
}

export const DateInput: React.FC<DateInputProps> = ({
    name,
    value,
    customLabelKey,
    customPlaceholderKey,
    validator = anythingValidator,
    isRequired = false,
    ...inputProps
}) => {
    const intl = useIntl()
    const { values } = useFormikContext()
    const validate = useMemo<FieldValidator>(
        () => validator(intl, isRequired, name, values),
        [intl, isRequired, name, values, validator]
    )
    const [field, meta] = useField({ name, value, validate })
    const labelKey = useMemo<string>(
        () => customLabelKey || `app.common.form.input.${name}.label`,
        [customLabelKey, name]
    )
    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.${name}.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL })
    }, [intl, customPlaceholderKey, name])

    return (
        <FormControl key={name} isInvalid={meta.touched && !!meta.error}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Input {...inputProps} {...field} id={name} placeholder={placeholder} type="date" />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
