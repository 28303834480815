import { Balance, Uuid } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { usePermissions, useQueryFindAllBalances } from '@/hooks'
import { PERMISSION } from '@/services'
import { prettyPrintCasedWords, queryDataAggregation } from '@/utils'
import { FormattedAmount, FormattedDate, RestrictedData, Separator, SeparatorVariant } from '../'
import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { isBalanceNegative } from './ConnectedAccountLatestBalances.utils'

interface ConnectedAccountLatestBalancesProps {
    connectedAccountId: Uuid
}

export const ConnectedAccountLatestBalances: React.FC<ConnectedAccountLatestBalancesProps> = ({
    connectedAccountId
}) => {
    const hasConnectedAccountId = useMemo(() => {
        return globalThis.Boolean(connectedAccountId)
    }, [connectedAccountId])
    const { hasPermission } = usePermissions()
    const hasViewBalancesPermissions = useMemo(() => hasPermission(PERMISSION.ACCOUNTS_VIEW_BALANCES), [hasPermission])
    const query = useQueryFindAllBalances(
        {
            connected_account_id: connectedAccountId,
            latest: true
        },
        { enabled: hasConnectedAccountId }
    )
    const data = useMemo(() => queryDataAggregation<Balance>(query.data), [query])

    switch (true) {
        case !hasViewBalancesPermissions: {
            return <RestrictedData />
        }

        case query.isLoading: {
            return <Skeleton height="14px" width="50%" />
        }

        case isEmpty(data):
        case query.isError: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        default: {
            return (
                <Flex direction="column" gap="8px">
                    {data.map((item) => {
                        const hasNegativeBalance = isBalanceNegative(item.direction)
                        const formattedType = prettyPrintCasedWords(item.type)

                        return (
                            <Box key={item.id}>
                                <FormattedAmount
                                    asNegative={hasNegativeBalance}
                                    amount={item.amount}
                                    currency={item.currency}
                                />
                                <Separator variant={SeparatorVariant.Bullet} />
                                {formattedType}
                                <Separator variant={SeparatorVariant.Bullet} />
                                <FormattedDate value={item.date} />
                            </Box>
                        )
                    })}
                </Flex>
            )
        }
    }
}
