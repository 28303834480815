import { useAuth, useServices } from '@/hooks'
import { APIKey, filterUserAccessQueryParams } from '@/services'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UseMutationOptions } from '@tanstack/react-query/src/types'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationRevokeByIdAPIKey(id?: Uuid, options?: UseMutationOptions<Partial<APIKey> | void, Error>) {
    const { selectedUserAccess } = useAuth()
    const { apiKeysService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }
    return useMutation<APIKey | void, Error>({
        ...options,
        mutationKey: [apiKeysService.url, { id }],
        mutationFn(data) {
            return apiKeysService.revokeById(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(data, variables, context) {
            options?.onSuccess?.(data, variables, context)

            queryClient.setQueryData([apiKeysService.url, { id }], data)

            await queryClient
                .invalidateQueries({
                    queryKey: [apiKeysService.url]
                })
                .catch(noop)
        }
    })
}
