import { chain } from 'lodash'
import { TABLE_HEADER_GROUP_BY_FORM_ITEM_TEMPLATE } from './TableHeaderGroupBy.const'

export function countActiveGroupByValues(values?: readonly string[]) {
    return chain(values).uniq().filter(globalThis.Boolean).size().value()
}

export function getTableHeaderGroupByFormItemCompiledTemplate() {
    return chain(TABLE_HEADER_GROUP_BY_FORM_ITEM_TEMPLATE).template().value()
}

export function getGroupByButtonColor(condition?: boolean) {
    return condition ? 'gray.500' : 'numeralAccent.500'
}
