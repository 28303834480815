import { useContext } from 'react'
import { NavigationRoutesContext } from '@/providers'

export function useNavigationRoutes() {
    const context = useContext(NavigationRoutesContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${NavigationRoutesContext.displayName}`)
    }

    return context
}
