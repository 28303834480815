import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { SUPPORT_EMAIL } from '@/constants'
import { useNavigationRoutes, useQueryFindAllConnectedAccountsExpanded, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ConnectedAccountExpanded, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { ConnectedAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    CONNECTED_ACCOUNTS_FILTERS,
    CONNECTED_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS,
    CONNECTED_ACCOUNTS_TABLE_STATIC_STATE
} from './ConnectedAccounts.page.const'

export const ConnectedAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { search, filterBy } = useQueryParams('search', {
        filterBy: {
            configuration: {
                filters: CONNECTED_ACCOUNTS_FILTERS
            }
        }
    })

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.accounts.connected_accounts.title' }), [intl])

    const query = useQueryFindAllConnectedAccountsExpanded({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.accounts.connected_accounts.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const supportEmailMailtoLink = (
                <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                    {SUPPORT_EMAIL}
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.accounts.connected_accounts.empty_content.title' }),
                content: intl.formatMessage(
                    { id: 'app.accounts.connected_accounts.empty_content.subtitle' },
                    { supportEmailMailtoLink }
                )
            }
        }
    }, [isDataEmpty, search.isActive, filterBy.isActive, intl, query])
    const onTableRowClick = useTableRowNavigation<ConnectedAccountExpanded>('id', relativePaths.ACCOUNTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS}>
                <TableHeader<ConnectedAccountsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.connected_account}
                    isLoading={query.isLoading}
                    filterBy={filterBy}
                    search={search}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isDataEmpty:
                        case query.isError: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<ConnectedAccountExpanded>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={CONNECTED_ACCOUNTS_TABLE_STATIC_STATE}
                                    columnCustomizers={CONNECTED_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
