import { useAuth, usePermissions, useServices } from '@/hooks'
import { PERMISSION, Role, filterUserAccessQueryParams } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdRole(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<Role, Error>>) {
    const { rolesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_ROLES) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<Role, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [rolesService.url, { id }],
        queryFn({ signal }) {
            return rolesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
