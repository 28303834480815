import { z } from 'zod'
import { DateISOFormatSchema, UuidSchema } from '@webapps/numeral-ui-core'

export const WebhookRulesSchema = z.record(z.string(), z.array(z.string()).optional())
export const WebhookSchema = z.object({
    id: UuidSchema.optional(),
    name: z.string().min(1),
    url: z.string().url(),
    enabled: z.boolean().optional(),
    rules: WebhookRulesSchema.nullable(),
    created_at: DateISOFormatSchema.optional()
})
