export function getUrlParameter(name: string): string {
    const defaultValue = ''
    try {
        const urlParams = new URLSearchParams(globalThis.location.search)

        if (urlParams.has(name)) {
            return urlParams.get(name) || defaultValue
        }
    } catch (error) {
        /** Handle error */
    }

    return defaultValue
}
