import { ENTITY_FEATURE, PERMISSION, UserAccess } from '@/services'

export type AuthContextValue = {
    isUserLoggedIn: boolean
    onLogin: () => void
    isUserLoggingOut: boolean
    onLogout: () => void
    userAccesses?: UserAccess[]
    selectedUserAccess?: UserAccess
    selectUserAccess(userAccess: UserAccess): void
    activatedFeatures?: Set<ENTITY_FEATURE>
    userPermissions?: Set<PERMISSION>
}

export enum AUTH_QUERY_PARAMS {
    CODE = 'code',
    STATE = 'state',
    ERROR = 'error',
    ERROR_DESCRIPTION = 'error_description'
}

export enum AUTH_ERROR_TYPES {
    UNAUTHORIZED = 'unauthorized',
    INVALID_REQUEST = 'invalid_request',
    ACCESS_DENIED = 'access_denied'
}
