import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

interface EntityDetailCellBooleanProps {
    value: boolean
}

export const EntityDetailCellBoolean: React.FC<EntityDetailCellBooleanProps> = ({ value = false }) => {
    const messageKey = useMemo<string>(() => {
        return value ? 'app.page.details.cells.common.true.label' : 'app.page.details.cells.common.false.label'
    }, [value])

    return <FormattedMessage id={messageKey} defaultMessage={EMPTY_VALUE_PLACEHOLDER} />
}
