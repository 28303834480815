import { FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { createAccountFromConnectedAccount, isFinancialInstitutionConnectedAccount } from '@/services'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'

export interface DirectDebitMandateOriginatingAccountSection {
    isDisabled?: boolean
}

export const DirectDebitMandateOriginatingAccountSection: React.FC<DirectDebitMandateOriginatingAccountSection> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<DirectDebitMandate>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')

    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const hasFinancialInstitutionConnectedAccount = useMemo(
        () => isFinancialInstitutionConnectedAccount(queryConnectedAccount.data),
        [queryConnectedAccount]
    )

    useEffect(() => {
        if (connectedAccountId) {
            const originatingAccount = hasFinancialInstitutionConnectedAccount
                ? createAccountFromConnectedAccount(queryConnectedAccount.data, true)
                : undefined

            setFieldValue('originating_account', originatingAccount)
        }
    }, [connectedAccountId, hasFinancialInstitutionConnectedAccount, queryConnectedAccount.data])

    return (
        <If condition={queryConnectedAccount.isLoading || hasFinancialInstitutionConnectedAccount}>
            <FormSection titleId="app.counterparties.direct_debit_mandates.create.form.sections.originating_account.title">
                <AccountDetailsForm
                    inputsNamePrefix="originating_account"
                    isCreditorIdentifierInputDisplayed={true}
                    isBankCodeInputReadOnly={true}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <OptionalAccountHolderAddressForm sectionName="originating_account" isDisabled={isDisabled} />
            </FormSection>
        </If>
    )
}
