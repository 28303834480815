import { FilterInputs } from '@/components'
import { CustomFieldInput } from '@/components/@inputs'
import { queryDataAggregation } from '@/utils'
import { ApiObjectType, CustomField } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useQueryFindAllCustomFields } from '../api'

export function useCustomFieldFilters(objectType: ApiObjectType, staticFilters?: FilterInputs<unknown>) {
    const query = useQueryFindAllCustomFields({
        object_type: objectType
    })
    const data = useMemo<CustomField[]>(() => queryDataAggregation(query.data), [query])

    const hasStaticFilters = staticFilters && Object.keys(staticFilters).length > 0
    const filters: FilterInputs<any> = hasStaticFilters ? { ...staticFilters } : Object.create(null)
    data.forEach((customField) => {
        const filterName = `custom_fields.${customField?.key}`
        filters[filterName] = (
            <CustomFieldInput
                customField={customField}
                customPlaceholderKey="app.common.form.input.select.placeholder"
            />
        )
    })

    return filters
}
