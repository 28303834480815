import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useMutationUpdateByIdAPIKey, useNavigationRoutes, useQueryFindByIdAPIKey, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { APIKeyForm } from './@components'

export const APIKeyEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { onAdd } = useToasts()
    const { paths } = useNavigationRoutes()

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.developers.api_keys.edit.title' }), [intl])
    const query = useQueryFindByIdAPIKey(uuid)
    const mutation = useMutationUpdateByIdAPIKey(uuid, {
        onSuccess() {
            const path = [paths.DEVELOPERS.API_KEYS, uuid].join('/')
            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.api_keys.edit.success.toast.title" />,
                description: <FormattedMessage id="app.developers.api_keys.edit.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.developers.api_keys.edit.error.toast.title" />,
                description: <FormattedMessage id="app.developers.api_keys.edit.error.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="APIKeyEdit">
            <PageHeaderLayout title={title} />
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_API_KEYS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError: {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.error.title'
                                    })}
                                />
                            )
                        }
                        case isEmpty(query?.data): {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.not_found.title'
                                    })}
                                />
                            )
                        }

                        default: {
                            return (
                                <APIKeyForm
                                    data={query.data}
                                    onSubmit={mutation.mutate}
                                    disabled={mutation.isPending}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
