import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useNavigationRoutes, usePermissions, useQueryFindByIdConnectedAccount } from '@/hooks'
import { PERMISSION } from '@/services'
import { Link, Skeleton, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RestrictedData } from '../RestrictedData'
import { connectedAccountNameFormatter } from '@/utils/@formatters'

interface ConnectedAccountNameProps {
    connectedAccountId?: string
    asPlainText?: boolean
}

export const ConnectedAccountName: React.FC<ConnectedAccountNameProps> = React.memo(
    ({ connectedAccountId, asPlainText }) => {
        const navigate = useNavigate()
        const { paths, relativePaths } = useNavigationRoutes()
        const { hasPermission } = usePermissions()
        const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
            enabled: globalThis.Boolean(connectedAccountId)
        })
        const onClick = useCallback(
            (event: MouseEvent<HTMLAnchorElement>) => {
                const path = `${paths.ACCOUNTS.CONNECTED_ACCOUNTS}/${connectedAccountId}/${relativePaths.ACCOUNTS.DETAILS}`

                event.stopPropagation()
                navigate(path)
            },
            [paths, relativePaths, navigate, connectedAccountId]
        )
        const formattedAccountName = useMemo(() => {
            return connectedAccountNameFormatter(query.data, true)
        }, [query.data])

        if (!connectedAccountId) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        switch (true) {
            case !hasPermission(PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS): {
                return <RestrictedData />
            }
            case query.isLoading: {
                return <Skeleton height="14px" width="50%" />
            }

            case query.isError:
            case isEmpty(query.data?.name): {
                return <>{EMPTY_VALUE_PLACEHOLDER}</>
            }

            case asPlainText: {
                return (
                    <Text as="span" title={formattedAccountName}>
                        {formattedAccountName}
                    </Text>
                )
            }

            default: {
                return (
                    <Link onClick={onClick} title={formattedAccountName}>
                        {formattedAccountName}
                    </Link>
                )
            }
        }
    }
)
