import { useMemo } from 'react'
import { FormattedDate } from '../FormattedDate'
import { Separator, SeparatorVariant } from '../Separator'

interface DateRangeProps {
    from: string
    to: string
}

export const DateRange: React.FC<DateRangeProps> = ({ from, to }) => {
    const areDatesDifferent = useMemo(() => {
        return from !== to
    }, [from, to])

    return (
        <>
            <FormattedDate value={from} />
            {areDatesDifferent && (
                <>
                    <Separator variant={SeparatorVariant.Dash} />
                    <FormattedDate value={to} />{' '}
                </>
            )}
        </>
    )
}
