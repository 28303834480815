import { DateISOFormatSchema, UuidSchema } from '@webapps/numeral-ui-core'
import { z } from 'zod'

export const APIKeyPermissionSchema = z.enum(['read', 'modify', 'write'])

export const APIKeySchema = z.object({
    id: UuidSchema.optional(),
    name: z.string().min(1).max(256),
    api_key_plain: z.string().optional(),
    hashed_key: z.string().optional(),
    legal_entity_id: z.string().optional(),
    last_4_chars: z.string().min(4).max(4).optional(),
    permission: APIKeyPermissionSchema,
    created_at: DateISOFormatSchema.optional(),
    disabled_at: DateISOFormatSchema.nullable().optional()
})
