import { FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { CounterpartyAccountsInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { approvedCounterpartyAccountsFilter } from '@/services'
import { Switch } from '@chakra-ui/react'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { DirectDebitMandateReceivingAccountSectionAutoReset } from './DirectDebitMandateReceivingAccountSectionAutoReset'
import { DirectDebitMandateReceivingAccountSectionDetails } from './DirectDebitMandateReceivingAccountSectionDetails'

export interface DirectDebitMandateReceivingAccountSectionProps {
    isDisabled?: boolean
}

export const DirectDebitMandateReceivingAccountSection: React.FC<DirectDebitMandateReceivingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps } = useFormikContext<DirectDebitMandate>()
    const { value: receivingAccountId } = getFieldProps('receiving_account_id')

    const [useExistingCounterparty, setUseExistingCounterparty] = useState(true)
    const onToggleUseExistingCounterparty = useCallback(() => {
        setUseExistingCounterparty((prevState) => {
            return !prevState
        })
    }, [])

    return (
        <FormSection
            titleId="app.counterparties.direct_debit_mandates.create.form.sections.receiving_account.title"
            title={
                <Switch
                    data-testid="toggle-existing-receiving-account-switch"
                    size="sm"
                    isChecked={useExistingCounterparty}
                    onChange={onToggleUseExistingCounterparty}
                    disabled={isDisabled}>
                    <FormattedMessage id="app.counterparties.direct_debit_mandates.create.form.sections.receiving_account.title.alternative" />
                </Switch>
            }>
            <If condition={useExistingCounterparty}>
                <CounterpartyAccountsInput
                    name="receiving_account_id"
                    value={receivingAccountId}
                    isPlaceholderDisplayed={true}
                    isAccountVerificationStatusDisplayed={true}
                    customPlaceholderKey="app.counterparties.direct_debit_mandates.create.form.inputs.receiving_account_id.placeholder"
                    queryDataFilter={approvedCounterpartyAccountsFilter}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <DirectDebitMandateReceivingAccountSectionDetails id={receivingAccountId} />
            </If>
            <If condition={!useExistingCounterparty}>
                <AccountDetailsForm
                    inputsNamePrefix="receiving_account"
                    isLookupAvailable={true}
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <OptionalAccountHolderAddressForm sectionName="receiving_account" isDisabled={isDisabled} />
            </If>
            <DirectDebitMandateReceivingAccountSectionAutoReset useExistingCounterparty={useExistingCounterparty} />
        </FormSection>
    )
}
