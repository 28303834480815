import { BoxProps } from '@chakra-ui/react'

export const ERROR_BOUNDARY_ERROR_TEXT_BOX_PROPS: BoxProps = {
    as: 'pre',
    background: 'gray.50',
    paddingX: '16px',
    paddingY: '8px',
    borderRadius: 'var(--numeral-ui-primary-border-radius)',
    borderColor: 'gray.200',
    borderWidth: '1px',
    whiteSpace: 'revert',
    overflow: 'auto'
}
