import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { ConnectedAccountsInput, DateInput } from '@/components/@inputs'
import { ConnectedAccountName, StatusLabel } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { PaymentFile, PaymentFilesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { PaymentFileCustomColumns } from './PaymentFile.type'

export const PAYMENT_FILE_TABLE_STATIC_STATE: TableStateWithEntity<PaymentFile, PaymentFileCustomColumns> = {
    columnOrder: Object.freeze(['created_at', 'connected_account_id', 'filename', 'status', 'sent_over_total_payments'])
}

export const PAYMENT_FILE_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<PaymentFile, PaymentFileCustomColumns> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    filename: {
        size: COLUMN_WIDTH_RATIO.FILE_NAME,
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <StatusLabel value={info.getValue()} />
        }
    },
    sent_over_total_payments: {
        cell(info) {
            const { payments_sent, total_payments } = info.row.original
            return (
                <span>
                    {payments_sent} / {total_payments}
                </span>
            )
        }
    }
}

export const PAYMENT_FILE_FILTERS: FilterInputs<PaymentFilesServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />
}
