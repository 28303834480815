import { FormContainer, InputWithCopyToClipboard, InputWithCustomComponent } from '@/components'
import { FormattedDateTime, StatusLabel } from '@/components/@misc'
import { Webhook } from '@/services'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useRef } from 'react'
import { useIntl } from 'react-intl'

interface WebhookDetailsFormProps {
    data?: Webhook
}

export const WebhookDetailsForm: React.FC<WebhookDetailsFormProps> = ({ data }) => {
    const intl = useIntl()
    const propsInputCommon = useRef({
        size: 'md',
        readOnly: true,
        variant: 'flushed',
        focusBorderColor: 'none'
    }).current

    return (
        <FormContainer>
            <FormControl>
                <FormLabel htmlFor="id" variant="readonly">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.id.label'
                    })}
                </FormLabel>
                <InputWithCopyToClipboard id="id" name="id" defaultValue={data?.id} {...propsInputCommon} />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="created_at" variant="readonly">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.created_at.label'
                    })}
                </FormLabel>
                <InputWithCustomComponent id="created_at" name="created_at" {...propsInputCommon}>
                    <FormattedDateTime value={data?.created_at} />
                </InputWithCustomComponent>
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="name" variant="readonly">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.name.label'
                    })}
                </FormLabel>
                <Input id="name" name="name" defaultValue={data?.name} {...propsInputCommon} />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="url" variant="readonly">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.url.label'
                    })}
                </FormLabel>
                <InputWithCopyToClipboard id="url" name="url" defaultValue={data?.url} {...propsInputCommon} />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="status">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.status.label'
                    })}
                </FormLabel>
                <InputWithCustomComponent id="status" name="status" {...propsInputCommon}>
                    <StatusLabel value={data?.enabled ? 'enabled' : 'disabled'} />
                </InputWithCustomComponent>
            </FormControl>

            {/* <FormControl>
                <FormLabel htmlFor="rules" variant="readonly">
                    {intl.formatMessage({
                        id: 'app.developers.webhooks.form.rules.label'
                    })}
                </FormLabel>
                <WebhookRulesFilterReadonly value={data?.rules as any} />
            </FormControl> */}
        </FormContainer>
    )
}
