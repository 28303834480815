import { components, DropdownIndicatorProps } from 'react-select'

export const SelectDropdownIndicator: React.FC<DropdownIndicatorProps<any>> = ({ children, ...props }) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg viewBox="0 0 22 22" width="16px" role="presentation" focusable="false" aria-hidden="true">
                <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
            </svg>
        </components.DropdownIndicator>
    )
}
