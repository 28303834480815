import { EntityDetailRow, EntityDetailRowCustomizers, EntityRowCustomizerDef } from '@/components'
import { DetailPageLink, FormattedDate, FormattedTextIntl } from '@/components/@misc'
import { isPaymentOrderMandateAvailable } from '@/services'
import { ApiObjectTypeSchema, DirectDebitMandate, IncomingPayment, PaymentOrder } from '@webapps/numeral-ui-core'
import { get, isObjectLike } from 'lodash'

export function isPaymentMandateEntityRowVisible(payment?: PaymentOrder | IncomingPayment): boolean {
    return (
        !!payment &&
        isPaymentOrderMandateAvailable(payment.type, payment.direction) &&
        isObjectLike(payment.direct_debit_mandate)
    )
}

export function getDirectDebitMandateRowCustomizers(
    payment: PaymentOrder | IncomingPayment
): EntityRowCustomizerDef<typeof payment> {
    const paymentDirectDebitMandate = get(payment, 'direct_debit_mandate') as typeof payment.direct_debit_mandate
    const rows: EntityDetailRow<DirectDebitMandate>[] = ['id', 'reference', 'type', 'sequence', 'signature_date']
    const rowCustomizers: EntityDetailRowCustomizers<DirectDebitMandate> = {
        id: {
            label(item) {
                return <FormattedTextIntl messageId="app.page.details.fields.related_direct_debit_mandate.label" />
            },
            cell(item) {
                return (
                    <DetailPageLink
                        objectId={payment?.direct_debit_mandate_id}
                        objectType={ApiObjectTypeSchema.enum.direct_debit_mandate}
                    />
                )
            }
        },
        reference: {
            cell(item) {
                return paymentDirectDebitMandate?.reference
            }
        },
        type: {
            cell(item) {
                return (
                    <FormattedTextIntl messageId={`api.direct_debit_mandate.type.${paymentDirectDebitMandate?.type}`} />
                )
            }
        },
        sequence: {
            cell(item) {
                return (
                    <FormattedTextIntl
                        messageId={`api.direct_debit_mandate.sequence.${paymentDirectDebitMandate?.sequence}`}
                    />
                )
            }
        },
        signature_date: {
            cell(item) {
                return <FormattedDate value={paymentDirectDebitMandate?.signature_date} />
            }
        }
    }

    return {
        nested: true,
        rows,
        rowCustomizers
    }
}
