import { usePage } from '@/hooks'
import { ApiObjectTypeSchema, Transaction } from '@webapps/numeral-ui-core'
import {
    ExpectedPaymentHeader,
    IncomingPaymentHeader,
    PaymentCaptureHeader,
    PaymentOrderHeader,
    RECONCILE_ENTITY_PAGE_CONTEXT,
    ReturnHeader,
    TransactionHeader
} from '@/pages'
import { BaseReconcileEntityHeaderProps } from './ReconcileEntityDynamic.header.types'
import { Payment } from '@/types'
import React, { PropsWithChildren, ReactNode } from 'react'
import { ReconcileEntityCancelConfirmation } from './@components'

export const ReconcileEntityDynamicHeader: React.FC<PropsWithChildren> = ({ children }) => {
    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const propsCommon: BaseReconcileEntityHeaderProps = {
        isReconciling: true,
        showBackButton: false,
        id: reconcileEntityNonPersistedState?.id
    }
    let header: ReactNode

    switch (reconcileEntityNonPersistedState?.object) {
        case ApiObjectTypeSchema.enum.transaction: {
            header = <TransactionHeader {...propsCommon} />
            break
        }

        case ApiObjectTypeSchema.enum.payment_order: {
            header = <PaymentOrderHeader {...propsCommon} />
            break
        }

        case ApiObjectTypeSchema.enum.incoming_payment: {
            header = <IncomingPaymentHeader {...propsCommon} />
            break
        }

        case ApiObjectTypeSchema.enum.expected_payment: {
            header = <ExpectedPaymentHeader {...propsCommon} />
            break
        }

        case ApiObjectTypeSchema.enum.payment_capture: {
            header = <PaymentCaptureHeader {...propsCommon} />
            break
        }

        case ApiObjectTypeSchema.enum.return: {
            header = <ReturnHeader {...propsCommon} />
            break
        }

        default: {
            header = children
        }
    }

    return (
        <>
            {header}
            <ReconcileEntityCancelConfirmation />
        </>
    )
}
