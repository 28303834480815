import { Box, BoxProps, Button, ButtonProps, Flex, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { isPathActive } from '../../MainNavigation.utils'
import { MainNavigationSectionListItemProps } from './MainNavigationSectionListItem.types'

export const MainNavigationSectionListItem: React.FC<MainNavigationSectionListItemProps> = (props) => {
    const { isCollapsed, route, children, completePath, onClick, title } = props
    const intl = useIntl()
    const location = useLocation()
    const formattedMessageTitle = useMemo(() => {
        return (
            title ||
            intl.formatMessage({
                id: route?.title
            })
        )
    }, [title, route, intl])
    const isActive = useMemo<boolean>(
        () => isPathActive(location.pathname, completePath || route?.path),
        [completePath, route, location]
    )
    const propsButton = useMemo<ButtonProps>(() => {
        const className = classNames('MainNavigation-Section-List-Item', {
            Active: isActive,
            Collapsed: isCollapsed,
            Disabled: route?.configuration?.isDisabled
        })
        return {
            className,
            title: formattedMessageTitle,
            'aria-label': formattedMessageTitle,
            variant: 'ghost',
            async onClick(event) {
                event.stopPropagation()

                onClick?.(completePath || route?.path)
            }
        }
    }, [isActive, isCollapsed, formattedMessageTitle])
    const propsBox = useMemo<BoxProps>(() => {
        return {
            noOfLines: 1,
            flex: 1,
            color: isActive ? 'numeralBlue.500' : 'gray.500'
        }
    }, [isActive])

    const content = useMemo<ReactNode>(() => {
        if (!children) {
            const collapsedContent = route?.icon || formattedMessageTitle?.substring(0, 2)

            return isCollapsed ? (
                collapsedContent
            ) : (
                <Flex flex="1" alignItems="center">
                    {route?.icon}
                    <Text>{formattedMessageTitle}</Text>
                </Flex>
            )
        }

        return children
    }, [children, isCollapsed, route, formattedMessageTitle])

    return (
        <Button {...propsButton} key={route?.path}>
            <Box {...propsBox}>{content}</Box>
        </Button>
    )
}
