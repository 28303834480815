import { CopyToClipboardButton } from '@/components/@misc'
import { Flex, Text } from '@chakra-ui/react'
import { FormatterFunction, StringToStringFormatter } from '@/utils/@formatters'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

interface EntityDetailCellTextWithCopyButtonProps {
    value?: string | number
    formatter?: FormatterFunction<StringToStringFormatter>
}

export const EntityDetailCellTextWithCopyButton: React.FC<EntityDetailCellTextWithCopyButtonProps> = ({
    value,
    formatter
}) => {
    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Flex>
            <Text>{value}</Text>
            <CopyToClipboardButton value={value} formatter={formatter} />
        </Flex>
    )
}
