import { noop, throttle } from 'lodash'
import { setTimeout } from './setTimeout'

/**
 * CancelTimeout needs to be a global variable to avoid losing its value during rerenders
 */
let cancelTimeout: CallableFunction

export function timeoutCallbackRunner(callback = noop, timeout = 600000, callbackWait = 1000) {
    const throttledCallbackWithTimeout = throttle(function callbackWithTimeout() {
        cancelTimeout?.()
        cancelTimeout = setTimeout(callback, timeout)
    }, callbackWait)

    globalThis.onload = throttledCallbackWithTimeout
    globalThis.onmousemove = throttledCallbackWithTimeout
    globalThis.onmousedown = throttledCallbackWithTimeout
    globalThis.onclick = throttledCallbackWithTimeout
    globalThis.onscroll = throttledCallbackWithTimeout
    globalThis.onkeydown = throttledCallbackWithTimeout

    return function cancelTimeoutCallbackRunner() {
        throttledCallbackWithTimeout.cancel()
        cancelTimeout?.()
    }
}
