import { Icon, IconProps } from '@chakra-ui/react'

export const UploadIcon: React.FC<IconProps> = (props) => (
    <Icon width="40" height="40" viewBox="0 0 40 40" {...props}>
        <path
            d="M17.3337 27.3334H9.83366C7.30588 27.3334 5.14644 26.4584 3.35533 24.7084C1.5631 22.9584 0.666992 20.8195 0.666992 18.2917C0.666992 16.1251 1.31977 14.1945 2.62533 12.5001C3.93088 10.8056 5.63921 9.7223 7.75033 9.25008C8.44477 6.69453 9.83366 4.62508 11.917 3.04175C14.0003 1.45842 16.3614 0.666748 19.0003 0.666748C22.2503 0.666748 25.007 1.79841 27.2703 4.06175C29.5348 6.32619 30.667 9.08341 30.667 12.3334C32.5837 12.5556 34.1742 13.3817 35.4387 14.8117C36.702 16.2429 37.3337 17.9167 37.3337 19.8334C37.3337 21.9167 36.6048 23.6879 35.147 25.1467C33.6881 26.6045 31.917 27.3334 29.8337 27.3334H20.667V15.4167L23.3337 18.0001L25.667 15.6667L19.0003 9.00008L12.3337 15.6667L14.667 18.0001L17.3337 15.4167V27.3334Z"
            fill="currentColor"
        />
    </Icon>
)
