import { Form, FormPageFooter, FormSection } from '@/components'
import { useMemo } from 'react'
import { noop } from 'lodash'
import { ReconcileTransactionFormProps, ReconcileTransactionFormData } from './ReconcileTransaction.form.types'
import { ReconcilablePaymentTypeInput } from './@components'

export const ReconcileTransactionForm: React.FC<ReconcileTransactionFormProps> = ({
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useMemo(() => {
        return {
            payment_type: undefined
        }
    }, [])

    return (
        <Form<ReconcileTransactionFormData> initialValues={initialValues} onSubmit={onSubmit}>
            {({ dirty, handleSubmit }) => {
                return (
                    <FormSection>
                        <ReconcilablePaymentTypeInput name="payment_type" isDisabled={disabled} />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.reconcile.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </FormSection>
                )
            }}
        </Form>
    )
}
