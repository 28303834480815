import { PropsWithChildren, useMemo } from 'react'
import {
    Box,
    Button,
    Flex,
    Heading,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal
} from '@chakra-ui/react'
import { PageHeaderLayoutBackButton, PageHeaderLayoutLoader } from './@components'
import { PageHeaderLayoutProps } from './PageHeader.layout.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { EntityActionsMenu } from '@/components/EntityActionsMenu'

export const PageHeaderLayout: React.FC<PropsWithChildren<PageHeaderLayoutProps>> = ({
    children,
    title,
    actions,
    isLoading,
    disabled,
    onBeforeNavigate,
    onAfterNavigate,
    showBackButton = true
}) => {
    const hasActions = useMemo<boolean>(() => !!actions?.length, [actions])

    return (
        <Box className="PageHeaderLayout" marginBottom="22px" data-testid="page-header-layout">
            {showBackButton && (
                <PageHeaderLayoutBackButton onBeforeNavigate={onBeforeNavigate} onAfterNavigate={onAfterNavigate} />
            )}
            <Flex marginTop="16px">
                {isLoading ? (
                    <PageHeaderLayoutLoader />
                ) : (
                    <Flex alignItems="center">
                        <Heading color="gray.800" fontSize={26} fontWeight="semibold">
                            {title}
                        </Heading>
                        {hasActions && (
                            <Popover placement="bottom-end" isLazy={true}>
                                {({ onClose }) => {
                                    return (
                                        <>
                                            <PopoverTrigger>
                                                <Button
                                                    alignSelf="start"
                                                    flex="1 0 auto"
                                                    marginLeft="32px"
                                                    paddingX="18px"
                                                    rightIcon={
                                                        <Box marginLeft="4px">
                                                            <FontAwesomeIcon icon={faChevronDown} size="xs" />
                                                        </Box>
                                                    }
                                                    disabled={disabled}>
                                                    <FormattedMessage id="app.common.actions.label" />
                                                </Button>
                                            </PopoverTrigger>

                                            <Portal>
                                                <PopoverContent minWidth="180px" width="auto" boxShadow="lg">
                                                    <PopoverBody
                                                        maxHeight="180px"
                                                        overflowY="auto"
                                                        paddingX="8px"
                                                        onClick={onClose}>
                                                        <EntityActionsMenu actions={actions} />
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Portal>
                                        </>
                                    )
                                }}
                            </Popover>
                        )}
                    </Flex>
                )}
                {children}
            </Flex>
        </Box>
    )
}
