import { Icon, IconProps } from '@chakra-ui/react'

export const ErrorIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" data-testid="error-icon" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99984 14.167L9.99984 11.167L12.9998 14.167L14.1665 13.0003L11.1665 10.0003L14.1665 7.00033L12.9998 5.83366L9.99984 8.83366L6.99984 5.83366L5.83317 7.00033L8.83317 10.0003L5.83317 13.0003L6.99984 14.167ZM9.99984 18.3337C8.84706 18.3337 7.76373 18.1148 6.74984 17.677C5.73595 17.2398 4.854 16.6462 4.104 15.8962C3.354 15.1462 2.76039 14.2642 2.32317 13.2503C1.88539 12.2364 1.6665 11.1531 1.6665 10.0003C1.6665 8.84755 1.88539 7.76422 2.32317 6.75033C2.76039 5.73644 3.354 4.85449 4.104 4.10449C4.854 3.35449 5.73595 2.7606 6.74984 2.32283C7.76373 1.8856 8.84706 1.66699 9.99984 1.66699C11.1526 1.66699 12.2359 1.8856 13.2498 2.32283C14.2637 2.7606 15.1457 3.35449 15.8957 4.10449C16.6457 4.85449 17.2393 5.73644 17.6765 6.75033C18.1143 7.76422 18.3332 8.84755 18.3332 10.0003C18.3332 11.1531 18.1143 12.2364 17.6765 13.2503C17.2393 14.2642 16.6457 15.1462 15.8957 15.8962C15.1457 16.6462 14.2637 17.2398 13.2498 17.677C12.2359 18.1148 11.1526 18.3337 9.99984 18.3337Z"
        />
    </Icon>
)
