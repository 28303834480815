import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useMutationUpdateByIdCustomField, useNavigationRoutes, useQueryFindByIdCustomField, useToasts } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { chain, isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomFieldErrorNotification, CustomFieldForm } from '../@components'

export const CustomFieldEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdCustomField(uuid)
    const mutation = useMutationUpdateByIdCustomField(uuid, {
        onSuccess() {
            const path = [paths.SETTINGS.CUSTOM_FIELDS, uuid, relativePaths.SETTINGS.DETAILS].join('/')

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.custom_fields.edit.form.validation.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.settings.custom_fields.edit.form.validation.success.toast.description" />
                )
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.custom_fields.edit.form.validation.error.toast.title" />,
                description: <CustomFieldErrorNotification error={error} />
            })
        }
    })

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const title = intl.formatMessage({
            id: 'app.settings.custom_fields.edit.title'
        })

        return {
            title,
            isLoading: query.isLoading
        }
    }, [intl, query])

    return (
        <PageLayout className="CustomFieldEdit">
            <PageHeaderLayout {...propsPageHeaderLayout} />
            <RestrictedSection permission={PERMISSION.SETTINGS_EDIT_CUSTOM_FIELDS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <CustomFieldForm
                                    data={query?.data}
                                    loading={query.isLoading}
                                    disabled={query.isLoading}
                                    onSubmit={mutation.mutate}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
