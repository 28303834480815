import { Theme } from '@chakra-ui/react'
import { WithCSSVar } from '@chakra-ui/styled-system'
import { add, get, identity, isEqual, isNil } from 'lodash'
import React, { MouseEvent } from 'react'
import { CSSObjectWithLabel, GetOptionLabel, GetOptionValue, OptionsOrGroups } from 'react-select'
import { PortalStyleArgs } from 'react-select/dist/declarations/src/components/Menu'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'

export function normaliseSelectOption<T>(
    value: T,
    getOptionLabel: GetOptionLabel<any> = identity,
    getOptionValue: GetOptionValue<any> = identity
) {
    return {
        label: getOptionLabel(value),
        value: getOptionValue(value)
    }
}

export function normaliseSelectOptions<T>(
    data: OptionsOrGroups<T, any> = [],
    getOptionLabel: GetOptionLabel<any> = identity,
    getOptionValue: GetOptionValue<any> = identity
) {
    return [...data].map((value, index) => ({
        ...normaliseSelectOption(value, getOptionLabel, getOptionValue),
        original: data?.[index] as T,
        id: index
    }))
}

export function getSelectMenuPortalStyleFn(): (base: CSSObjectWithLabel, props: PortalStyleArgs) => CSSObjectWithLabel {
    return (baseStyles) => {
        const compensatedLeft = add(globalThis.window.scrollX, baseStyles.left as number)

        return {
            ...baseStyles,
            marginBottom: '16px',
            left: compensatedLeft
        }
    }
}

export function getSelectOptionStyleFnChakraUI(
    theme: WithCSSVar<Theme>
): (base: CSSObjectWithLabel, props: OptionProps<any, boolean, any>) => CSSObjectWithLabel {
    return (baseStyles, { isSelected, isFocused }) => {
        return {
            ...baseStyles,
            color: isSelected || isFocused ? theme.colors.gray['500'] : theme.colors.gray['700'],
            backgroundColor: (() => {
                switch (true) {
                    case isSelected: {
                        return theme.colors.gray['100']
                    }

                    case isFocused: {
                        return theme.colors.blue['50']
                    }

                    default: {
                        return 'transparent'
                    }
                }
            })(),
            ':hover': {
                ...baseStyles[':hover'],
                color: theme.colors.gray['500'],
                backgroundColor: theme.colors.blue['50']
            }
        }
    }
}

export function isEnterKeyPressed(event: React.KeyboardEvent<HTMLElement>) {
    return event?.key === 'enter' || event?.code === 'Enter'
}

export function stopEventPropagation(event: MouseEvent<HTMLElement>) {
    event?.stopPropagation()
}

/**
 * @knownIssue
 * For react-select this is an open issue
 * https://github.com/JedWatson/react-select/discussions/5447
 * https://github.com/JedWatson/react-select/pull/4080
 * @description
 * Prevents the key up and down option from being focused when the select is opened.
 */
export function areArrowUpOrArrowDownKeysPressed(event: React.KeyboardEvent<HTMLElement>) {
    return (
        // Up arrow key
        event?.key === 'up arrow' ||
        event?.code === 'ArrowUp' ||
        // Down arrow key
        event?.key === 'down arrow' ||
        event?.code === 'ArrowDown'
    )
}

/**
 * @knownIssue
 * For react-select this is an open issue
 * https://github.com/JedWatson/react-select/discussions/5447
 * https://github.com/JedWatson/react-select/pull/4080
 * @description
 * Prevents the first option from being focused when the select is opened.
 */
export function isFirstSelectOptionSelected<T>(props: Omit<OptionProps<T>, 'innerProps' | 'children' | 'innerRef'>) {
    const currentSelectedValue = get(props.getValue(), '[0].value')
    const optionValue = get(props.data, 'value')
    //@ts-ignore - this is a known issue
    const isFirstOption = props.options.map(({ value }) => value).indexOf(optionValue) === 0

    switch (true) {
        case isFirstOption && !currentSelectedValue:
        case !currentSelectedValue && isFirstOption: {
            return false
        }

        default: {
            return false // @todo return props.isFocused
        }
    }
}

/**
 * @description
 * 1. Sometimes reset values are either an empty string or `undefined` or `null` (Referred to as nil);
 * 2. This should run in an effect as values can be set from the outside of the Select;
 * 3. Edge case examples: group by, filtering.
 */
export function shouldPopCurrentSelectValue(nextValue?: string | number | null, currentOption?: any): boolean {
    const isEmptyStringValue = nextValue === ''
    const isNilValue = isNil(nextValue)
    const areNotEqual = !isEqual(nextValue, currentOption?.value)

    return areNotEqual && (isEmptyStringValue || isNilValue)
}
