import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { ConnectedAccountName, FormattedDateTime, StatusLabel } from '@/components/@misc'
import { PaymentFile } from '@webapps/numeral-ui-core'
import { FormattedNumber } from 'react-intl'
import { PaymentFileDetailsRelatedObjects } from './@components/PaymentFileDetailsRelatedObjects'
import { PaymentFileDetailsTotalAmounts } from './@components/PaymentFileDetailsTotalAmounts'
import { PaymentFileDetailsCustomRows } from './PaymentFileDetails.type'

export const PAYMENT_FILE_DETAILS_ROWS: EntityDetailRow<PaymentFile, PaymentFileDetailsCustomRows>[] = [
    'id',
    'created_at',
    'filename',
    'connected_account_id',
    'status',
    'status_details',
    'total_amounts',
    'total_payments',
    'payments_sent',
    'payments_pending',
    'payments_canceled',
    'related_objects'
]

export const PAYMENT_FILE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    PaymentFile,
    PaymentFileDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    total_amounts: {
        cell(item) {
            return <PaymentFileDetailsTotalAmounts totalAmounts={item.total_amounts} />
        }
    },
    total_payments: {
        cell(item) {
            return <FormattedNumber value={item.total_payments} />
        }
    },
    payments_sent: {
        cell(item) {
            return <FormattedNumber value={item.payments_sent} />
        }
    },
    payments_pending: {
        cell(item) {
            return <FormattedNumber value={item.payments_pending} />
        }
    },
    payments_canceled: {
        cell(item) {
            return <FormattedNumber value={item.payments_canceled} />
        }
    },
    related_objects: {
        cell(item) {
            return <PaymentFileDetailsRelatedObjects paymentFileId={item.id} />
        }
    }
}
