import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdPaymentCapture } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentCapture } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    PAYMENT_CAPTURE_DETAILS_ROWS,
    PAYMENT_CAPTURE_DETAILS_ROW_CUSTOMIZERS
} from './PaymentCaptureDetails.page.const'

export const PaymentCaptureDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentCapture(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_CAPTURE}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_CAPTURES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<PaymentCapture>
                                    data={query.data}
                                    rows={PAYMENT_CAPTURE_DETAILS_ROWS}
                                    rowCustomizers={PAYMENT_CAPTURE_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
