import { NumeralFileFormat } from '@webapps/numeral-ui-core'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

interface FileFormatProps {
    value: NumeralFileFormat
}

export const FileFormat: React.FC<FileFormatProps> = ({ value }) => {
    const messageId = useMemo(() => {
        return `api.files.format.${value}`
    }, [value])

    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <FormattedMessage id={messageId} defaultMessage={value} />
}
