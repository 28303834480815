import { ScreenIcon } from '@/components/@icons'
import { Logo } from '@/components/Logo'
import { Image, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import unsupportedDeviceBackground from './@assets/unsupportedDeviceBackground.svg'
import './UnsupportedDevice.page.scss'

export const UnsupportedDevicePage: React.FC = () => {
    const intl = useIntl()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.unsupported.content' }), [intl])
    return (
        <div className="UnsupportedDevice">
            <Logo />
            <Text className="UnsupportedDevice-Message">{title}</Text>
            <ScreenIcon />
            <Image className="UnsupportedDevice-Illustration" src={unsupportedDeviceBackground} />
        </div>
    )
}
