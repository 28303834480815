import { chain } from 'lodash'
import { Account } from '@webapps/numeral-ui-core'
import { TableCellText } from '@/components'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { TableCellAccountProps } from './TableCellAccount.types'

export const TableCellAccount: React.FC<TableCellAccountProps> = ({ value, selectKey = 'holder_name' }) => {
    const formattedValue = chain<Partial<Account>>(value).get(selectKey, EMPTY_VALUE_PLACEHOLDER).value() as string
    return <TableCellText value={formattedValue} />
}
