import { EntityDetail, EntityDetailCellHolderAddress, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { BankCode, CounterpartyAccountVerificationStatus } from '@/components/@misc'
import { useQueryFindByIdCounterpartyAccount } from '@/hooks'
import { isEmptyRecord } from '@/utils'
import { accountNumberFormatter } from '@/utils/@formatters'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    BoxProps,
    Fade,
    Flex,
    Text
} from '@chakra-ui/react'
import { CounterpartyAccount, Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_ORDER_FORM_DETAILS_STYLES } from '../../PaymentOrderCreate.form.const'

export const PAYMENT_ORDER_FORM_COUNTERPARTY_ACCOUNT_DETAILS_ROWS: EntityDetailRow<CounterpartyAccount>[] = [
    'name',
    'account_verification',
    'holder_name',
    'account_number',
    'bank_code',
    'holder_address'
]

export const PAYMENT_ORDER_FORM_COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<CounterpartyAccount> =
    {
        account_verification: {
            cell(item) {
                return <CounterpartyAccountVerificationStatus accountVerification={item.account_verification} />
            }
        },
        account_number: {
            cell(item) {
                const formattedValue = accountNumberFormatter(item.account_number)
                return <Text>{formattedValue}</Text>
            }
        },
        bank_code: {
            cell(item) {
                return <BankCode value={item.bank_code} />
            }
        },
        holder_address: {
            cell(item) {
                // @todo this should work without adding a padding top fix.
                const temporaryPaddingTopFix = isEmptyRecord(item?.holder_address) ? '0' : '16px'

                return (
                    <Box paddingTop={temporaryPaddingTopFix}>
                        <EntityDetailCellHolderAddress value={item.holder_address} />
                    </Box>
                )
            }
        }
    }

export const PaymentOrderReceivingAccountSectionDetails: React.FC<{
    id?: Uuid
}> = ({ id, ...propsBox }) => {
    const hasValidUUID = useMemo<boolean>(() => UuidSchema.safeParse(id).success, [id])
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const onChange = useCallback((expandedIndex: number) => {
        setActiveIndex(expandedIndex)
    }, [])
    const query = useQueryFindByIdCounterpartyAccount(id, {
        enabled: hasValidUUID
    })

    useEffect(() => {
        if (hasValidUUID) {
            onChange(0)
        }
    }, [hasValidUUID, onChange])

    if (isEmpty(query.data)) {
        return null
    }

    return (
        <Box
            {...propsBox}
            {...PAYMENT_ORDER_FORM_DETAILS_STYLES.DETAILS_CONTAINER}
            className="PaymentOrderCounterpartyAccountDetails">
            <Accordion
                allowMultiple={false}
                allowToggle={!query?.isLoading}
                index={activeIndex}
                backgroundColor="gray.50"
                onChange={onChange}>
                <AccordionItem border="unset">
                    <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                        <Text fontWeight="600" fontSize="16px">
                            <FormattedMessage id="app.payments.payment_orders.create.form.sections.receiving_account.details.title" />
                        </Text>
                        <Flex>
                            <AccordionIcon color="gray.500" />
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                        <Fade in={query?.isSuccess}>
                            <EntityDetail<CounterpartyAccount>
                                data={query?.data}
                                rows={PAYMENT_ORDER_FORM_COUNTERPARTY_ACCOUNT_DETAILS_ROWS}
                                rowCustomizers={PAYMENT_ORDER_FORM_COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS}
                                style={PAYMENT_ORDER_FORM_DETAILS_STYLES.ENTITY_DETAILS_OVERRIDES}
                            />
                        </Fade>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}
