import { Flex, Spinner, Text } from '@chakra-ui/react'
import { FileUploadIconWrapper } from './FileUploadIconWrapper'
import { FormattedMessage } from 'react-intl'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'

export const FileUploadInputStatusProcessing: React.FC<FileUploadInputStatusProps> = ({ color, fontWeight }) => {
    return (
        <Flex direction="column" alignItems="center" justifyContent="center" justifySelf="center">
            <FileUploadIconWrapper color={color}>
                <Spinner />
            </FileUploadIconWrapper>
            <Text color={color} fontWeight={fontWeight}>
                <FormattedMessage id="app.common.loading" />
            </Text>
        </Flex>
    )
}
