import {
    EMPTY_CONTENT_IMAGES,
    EmptyContent,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, usePermissions, useQueryFindAllAPIKeys, useQueryParams } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { APIKey, APIKeysServiceFindAllQueryOptions, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
    API_KEY_DOCUMENTATION_LINK,
    APIKEY_FILTERS,
    APIKEY_TABLE_COLUMN_CUSTOMIZERS,
    APIKEY_TABLE_STATIC_STATE
} from './APIKeys.page.const'

export const APIKeysPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { hasPermission } = usePermissions()
    const { relativePaths } = useNavigationRoutes()
    const { filterBy } = useQueryParams<APIKeysServiceFindAllQueryOptions>({
        filterBy: { configuration: { filters: APIKEY_FILTERS } }
    })
    const query = useQueryFindAllAPIKeys(filterBy.parsedState)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo(() => isEmpty(data), [data])
    const onNavigateToNew = useCallback(() => {
        navigate(relativePaths.NEW)
    }, [navigate, relativePaths])
    const onTableRowClick = useTableRowNavigation<APIKey>('id')
    const addApiKeyButtonProps = {
        onClick: onNavigateToNew,
        children: intl.formatMessage({ id: 'app.developers.api_keys.add' })
    }
    const hasWriteAccess = hasPermission(PERMISSION.DEVELOPERS_CONFIGURE_API_KEYS)
    const actions = useMemo(() => {
        if (hasWriteAccess) {
            return [addApiKeyButtonProps]
        }
    }, [hasWriteAccess, addApiKeyButtonProps])

    return (
        <PageLayout className="APIKeys">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_API_KEYS}>
                <TableHeader<APIKeysServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.api_key}
                    isLoading={query.isLoading}
                    actions={actions}
                    filterBy={filterBy}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError: {
                            return <EmptyContent />
                        }

                        case isDataEmpty: {
                            return (
                                <EmptyContent
                                    imageSrc={EMPTY_CONTENT_IMAGES.SETTINGS}
                                    title={intl.formatMessage({ id: 'app.developers.api_keys.empty_content.title' })}
                                    propsButton={hasWriteAccess ? addApiKeyButtonProps : undefined}
                                    propsLink={{
                                        href: API_KEY_DOCUMENTATION_LINK,
                                        children: intl.formatMessage({
                                            id: 'app.common.link.learn_more.label.alternative'
                                        })
                                    }}
                                />
                            )
                        }

                        default: {
                            return (
                                <Table<APIKey>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    columnCustomizers={APIKEY_TABLE_COLUMN_CUSTOMIZERS}
                                    state={APIKEY_TABLE_STATIC_STATE}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
