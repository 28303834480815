import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { usePermissions, useQueryFindByIdInquiry } from '@/hooks'
import { PageLayout } from '@/layouts'
import { InquiryDenial } from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Inquiry } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { INQUIRY_DETAILS_ROWS, INQUIRY_DETAILS_ROW_CUSTOMIZERS } from './InquiryDetails.page.const'
import { InquiryDetailsCustomRows } from './InquiryDetails.types'

export const InquiryDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_INQUIRIES)
    const query = useQueryFindByIdInquiry(uuid)

    return (
        <PageLayout>
            <RestrictedSection feature={ENTITY_FEATURE.INQUIRY} permission={PERMISSION.PAYMENTS_VIEW_INQUIRIES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError || isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <>
                                    <EntityDetail<Inquiry, InquiryDetailsCustomRows>
                                        data={query.data}
                                        rows={INQUIRY_DETAILS_ROWS}
                                        rowCustomizers={INQUIRY_DETAILS_ROW_CUSTOMIZERS}
                                    />
                                    {hasApprovalPermission && <InquiryDenial data={query.data} />}
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
