import { AxiosHeaders } from 'axios'
import { set } from 'lodash'
import { BulkActionCreateData } from '@webapps/numeral-ui-core'

export function bulkActionsIdsMapperTransform<T>(data: BulkActionCreateData<T>, headers?: AxiosHeaders) {
    const value = data?.items.map((id) => {
        const idWrapper = Object.create(null)
        idWrapper.id = id
        return idWrapper
    })

    set(data, 'items', value)
    return data
}
