import { EmptyContentProps, TableStateWithEntity } from '@/components'
import { AuditTrail, AuditTrailsServiceFindAllQueryOptions } from '@/services'
import { ApiObjectTypeExtended } from '@/types'
import { IntlShape } from 'react-intl'
import { AuditTrailFilterByOption } from './AuditTrail.page.types'

export function getAuditTrailTableStaticState(filterBy?: AuditTrailFilterByOption): TableStateWithEntity<AuditTrail> {
    const columnOrder: Array<keyof AuditTrail> = ['created_at', 'public_name', 'author_type']

    if (!filterBy || filterBy === AuditTrailFilterByOption.OBJECT) {
        columnOrder.push('author_name')
    }

    if (!filterBy || filterBy === AuditTrailFilterByOption.AUTHOR) {
        columnOrder.push('object_id')
    }

    return { columnOrder }
}

export function getAuditTrailEmptyStateProps(
    intl: IntlShape,
    objectType: ApiObjectTypeExtended | undefined,
    filterBy: AuditTrailFilterByOption | undefined
): EmptyContentProps {
    let labelKey: string
    let contentKey: string
    let objectTypeLabel: string | undefined
    switch (true) {
        case filterBy === AuditTrailFilterByOption.OBJECT && !!objectType:
            labelKey = 'app.common.page.audit_trail.filtered_by_object.empty_content.title'
            contentKey = 'app.common.page.audit_trail.filtered_by_object.empty_content.subtitle'
            objectTypeLabel = intl.formatMessage({ id: `api.object.${objectType}` }).toLowerCase()
            break
        case filterBy === AuditTrailFilterByOption.AUTHOR:
            labelKey = 'app.common.page.audit_trail.filtered_by_author.empty_content.title'
            contentKey = 'app.common.page.audit_trail.filtered_by_author.empty_content.subtitle'
            break
        default:
            labelKey = 'app.common.page.audit_trail.empty_content.title'
            contentKey = 'app.common.page.audit_trail.empty_content.subtitle'
            break
    }

    return {
        title: intl.formatMessage({ id: labelKey }, { objectType: objectTypeLabel }),
        content: intl.formatMessage({ id: contentKey }, { objectType: objectTypeLabel })
    }
}

export function getAuditTrailQueryParams(
    filterBy: AuditTrailFilterByOption | undefined,
    id: string | undefined
): Partial<AuditTrailsServiceFindAllQueryOptions> {
    if (!filterBy || !id) {
        return {}
    }
    return { [filterBy]: id }
}
