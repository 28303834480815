import { useAuth, useServices } from '@/hooks'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { UserInfo } from '@webapps/numeral-ui-core'

export function useQueryFindCurrentUserInfo(options?: Partial<UndefinedInitialDataOptions<UserInfo, Error>>) {
    const { userService } = useServices()
    const { isUserLoggedIn } = useAuth()

    const isQueryEnabled = isUserLoggedIn

    return useQuery<UserInfo, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [userService.url, 'user_info'],
        queryFn({ signal }) {
            return userService.findCurrentUserInfo({ signal })
        }
    })
}
