import { ConnectedAccountsInput } from '@/components/@inputs'
import { useQueryFindAllConnectedAccounts } from '@/hooks'
import { enabledConnectedAccountsFilter } from '@/services/ConnectedAccounts'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { ConnectedAccount, ExpectedPayment, Uuid } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { computeExpectedPaymentFormDerivedFields } from '../ExpectedPayment.form.utils'

export const ExpectedPaymentConnectedAccountInput: React.FC<FormikInput> = ({ name, ...inputProps }) => {
    const { setFieldValue } = useFormikContext<ExpectedPayment>()
    const query = useQueryFindAllConnectedAccounts()
    const data = useMemo<ConnectedAccount[]>(() => {
        return queryDataAggregation(query.data)
    }, [query])
    const onChange = useCallback(
        (value: Uuid) => {
            const connectedAccountFinder = (account: ConnectedAccount) => {
                return account.id === value
            }
            const account: ConnectedAccount | undefined = data
                .filter(enabledConnectedAccountsFilter)
                .find(connectedAccountFinder)
            const { connected_account_id, direction } = computeExpectedPaymentFormDerivedFields(account)

            setFieldValue('connected_account_id', connected_account_id)
            setFieldValue('direction', direction)
        },
        [data]
    )

    return (
        <ConnectedAccountsInput
            {...inputProps}
            name={name}
            onChange={onChange}
            isPlaceholderDisplayed={false}
            queryDataFilter={enabledConnectedAccountsFilter}
        />
    )
}
