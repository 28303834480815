import { Button, Flex } from '@chakra-ui/react'
import { EntityActionsMenuProps } from './EntityActionsMenu.types'

export const EntityActionsMenu: React.FC<EntityActionsMenuProps> = ({ actions }) => {
    return (
        <Flex className="EntityActionsMenu" direction="column">
            {actions?.map((action, index: number) => (
                <Button
                    {...action}
                    key={index}
                    justifyContent="flex-start"
                    spinnerPlacement="end"
                    width="100%"
                    variant="ghost"
                    fontWeight="normal"
                    _hover={{
                        color: 'numeralAccent.500',
                        _disabled: {
                            color: 'initial'
                        }
                    }}
                />
            ))}
        </Flex>
    )
}
