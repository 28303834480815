import { useQueryFindAllRoles } from '@/hooks'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { anythingValidator } from '@/utils/@validators'
import { Box, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { ChakraProps } from '@chakra-ui/system'
import { useField } from 'formik'
import { useCallback, useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const UserRoleInput: React.FC<FormikInput> = ({ name, isDisabled, isRequired = false }) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        validate: anythingValidator(intl, isRequired)
    })
    const query = useQueryFindAllRoles()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const propsBox = useRef<ChakraProps>({
        borderWidth: '1px',
        borderRadius: 'var(--numeral-ui-primary-border-radius)',
        marginBottom: '8px'
    }).current
    const onChange = useCallback(
        (value: string) => {
            helpers.setValue(value)
        },
        [helpers]
    )
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Box {...propsBox}>
                <RadioGroup
                    name={name}
                    display="flex"
                    flexDirection="column"
                    isDisabled={isDisabled}
                    onChange={onChange}>
                    {data.map((option, index, array) => {
                        const isLastIndex = array?.length - 1 === index
                        return (
                            <>
                                <Flex padding="22px" direction="column">
                                    <Radio value={option.id} key={index}>
                                        <Box paddingStart="22px">
                                            <Text color="gray.800" fontWeight="semibold">
                                                {option.name}
                                            </Text>
                                            <Text color="gray.600">{option.description}</Text>
                                        </Box>
                                    </Radio>
                                </Flex>
                                {!isLastIndex && <Divider />}
                            </>
                        )
                    })}
                </RadioGroup>
            </Box>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
