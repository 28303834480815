import { Form, FormPageFooter } from '@/components'
import { CounterpartyAccountDenyData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { useRef } from 'react'
import { TextareaInput } from '@/components/@inputs'

interface CounterpartyAccountDenyFormProps {
    loading?: boolean
    disabled?: boolean
    readonly?: boolean

    onSubmit?(data: CounterpartyAccountDenyData): void
}

export const CounterpartyAccountDenyForm: React.FC<CounterpartyAccountDenyFormProps> = ({
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useRef<CounterpartyAccountDenyData>({
        reason: ''
    }).current

    return (
        <Form<CounterpartyAccountDenyData> initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit }) => {
                return (
                    <>
                        <TextareaInput
                            name="reason"
                            customLabelKey="app.counterparties.counterparty_accounts.deny.form.inputs.reason.label"
                            customPlaceholderKey="app.counterparties.counterparty_accounts.deny.form.inputs.reason.placeholder"
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isLoading={loading}
                            isDisabled={disabled}
                            submitLabelMessageId="app.counterparties.counterparty_accounts.deny.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
