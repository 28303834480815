import {
    EmptyContent,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { EmptyContentProps } from '@/components/EmptyContent'
import { useNavigationRoutes, usePermissions, useQueryFindAllReconciliations } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, isCreateReconciliationAvailable, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { ApiObjectTypeSchema, Reconciliation } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReconciliationComputedStatus } from './@components'
import { ReconciliationsListPageProps } from './Reconciliations.page.types'
import {
    getActiveOnlyReconciliationQueryOption,
    getReconciliationQueryParamsForAnEntityType,
    getReconciliationsContentButtonLabel,
    getReconciliationsContentNoneMessage,
    getReconciliationsContentNoneTitle,
    getReconciliationsTableStateByEntityType,
    getReconciliationTableColumnCustomizers
} from './Reconciliations.page.utils'
import { useReconciliation } from '@/pages/Common/ReconcileEntity/@hooks'
import { updateUUIDRelativeActionPathname } from '@/providers'

export function ReconciliationsPage<T>({ paymentType, transactionId, queryItem }: ReconciliationsListPageProps<T>) {
    const intl = useIntl()
    const { uuid } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { hasPermission } = usePermissions()
    const reconciliation = useReconciliation()
    const { relativePaths } = useNavigationRoutes()

    const queryParams = useMemo(() => {
        const partialQueryParams = getReconciliationQueryParamsForAnEntityType(
            {
                payment_id: uuid,
                payment_type: paymentType,
                transaction_id: transactionId
            },
            queryItem.data
        )

        return getActiveOnlyReconciliationQueryOption(partialQueryParams)
    }, [queryItem, uuid, paymentType, transactionId])

    const query = useQueryFindAllReconciliations(queryParams)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const columnCustomizers = useMemo<TableColumnCustomizers<Reconciliation>>(() => {
        return getReconciliationTableColumnCustomizers(queryItem.data)
    }, [queryItem])

    const propsEmptyContent = useMemo<EmptyContentProps | undefined>(() => {
        const canCreateReconciliation = isCreateReconciliationAvailable(queryItem.data?.reconciliation_status)
        const hasReconciliationsConfigurePermission = hasPermission(
            PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
        )

        const formattedTitle = getReconciliationsContentNoneTitle(intl, queryItem.data)
        const formattedLabel = getReconciliationsContentButtonLabel(intl, queryItem.data)
        const formattedContent = getReconciliationsContentNoneMessage(intl, queryItem.data)

        const propsButton =
            canCreateReconciliation && hasReconciliationsConfigurePermission
                ? {
                      children: formattedLabel,
                      onClick() {
                          switch (queryItem.data?.object) {
                              case ApiObjectTypeSchema.enum.transaction: {
                                  const { ACCOUNTS } = relativePaths
                                  const path = updateUUIDRelativeActionPathname(location.pathname, ACCOUNTS.RECONCILE)

                                  navigate(path)
                                  break
                              }

                              default: {
                                  reconciliation.onStartReconciliation(
                                      ApiObjectTypeSchema.enum.transaction,
                                      queryItem.data
                                  )
                              }
                          }
                      }
                  }
                : undefined

        return {
            title: formattedTitle,
            content: formattedContent,
            propsButton
        }
    }, [intl, queryItem, reconciliation])
    const tableState = useMemo<TableStateWithEntity<Reconciliation>>(() => {
        return getReconciliationsTableStateByEntityType(queryItem.data)
    }, [queryItem])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.RECONCILIATIONS_VIEW_RECONCILIATIONS}>
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError: {
                            return <EmptyContent />
                        }

                        case isDataEmpty: {
                            return <EmptyContent {...propsEmptyContent} />
                        }

                        default: {
                            const hasRowOptionsEnabled = hasPermission(
                                PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS
                            )

                            return (
                                <>
                                    <Table<Reconciliation>
                                        data={data}
                                        onScrollToBottom={query.fetchNextPage}
                                        isLoading={query.hasNextPage}
                                        columnCustomizers={columnCustomizers}
                                        state={tableState}
                                        hasRowOptionsEnabled={hasRowOptionsEnabled}
                                    />
                                    {queryItem.data?.id && <ReconciliationComputedStatus item={queryItem.data} />}
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
