import { useActivatedFeatures, useAuth, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { PaymentRetryRule, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdPaymentRetryRule(
    id?: Uuid | null,
    options?: Partial<UndefinedInitialDataOptions<PaymentRetryRule, Error>>
) {
    const { paymentRetryRulesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()

    const isQueryEnabled = isUserLoggedIn && options?.enabled && isFeatureActivated(ENTITY_FEATURE.PAYMENT_RETRY_RULE)

    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentRetryRule, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentRetryRulesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return paymentRetryRulesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
