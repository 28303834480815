import { useConfiguration, useServices } from '@/hooks'
import { UserAccess } from '@/services'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { flattenDeep } from 'lodash'

export function useQueryFindAllUserAccesses(
    options?: Partial<UndefinedInitialDataOptions<UserAccess[][], Error, UserAccess[]>>
) {
    const { userService } = useServices()
    const { apiEnvironment } = useConfiguration()

    return useQuery<UserAccess[][], Error, UserAccess[]>({
        ...options,
        queryKey: [userService.url, 'user_access'],
        queryFn({ signal }) {
            const allApiEnvironments = [apiEnvironment.primary]

            if (apiEnvironment.secondary) {
                allApiEnvironments.push(apiEnvironment.secondary)
            }

            const userAccessesPromises = allApiEnvironments.map(async (apiEnvironment) => {
                const userAccesses = await userService.findAllAccesses({ signal, baseURL: apiEnvironment.url })

                return userAccesses.records.map((userAccess) => {
                    return { ...userAccess, environment: apiEnvironment.name }
                })
            })

            return Promise.all(flattenDeep(userAccessesPromises))
        },
        select(userAccesses) {
            if (!userAccesses) {
                return []
            }

            return flattenDeep(userAccesses)
        }
    })
}
