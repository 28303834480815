import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdTransaction } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Transaction } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { TRANSACTION_DETAILS_ROW_CUSTOMIZERS, TRANSACTION_DETAILS_ROWS } from './TransactionDetails.page.const'
import { TransactionDetailsCustomRows } from './TransactionDetails.types'
import { useMemo } from 'react'
import { getCustomFieldsRowCustomizers } from '@/pages'
import { TransactionReconcileFooter } from './@components'

export const TransactionDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdTransaction(uuid)
    const rowCustomizers = useMemo(() => {
        return {
            ...TRANSACTION_DETAILS_ROW_CUSTOMIZERS,
            custom_fields: getCustomFieldsRowCustomizers(query.data, PERMISSION.ACCOUNTS_EDIT_TRANSACTIONS)
        }
    }, [query.data])

    return (
        <PageLayout className="Transaction">
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <>
                                    <EntityDetail<Transaction, TransactionDetailsCustomRows>
                                        data={query.data}
                                        rows={TRANSACTION_DETAILS_ROWS}
                                        rowCustomizers={rowCustomizers}
                                    />
                                    <TransactionReconcileFooter data={query.data} />
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
