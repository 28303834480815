import {
    BaseHttpClientService,
    extractResponseData,
    List,
    ServicesConfiguration,
    UserInfo
} from '@webapps/numeral-ui-core'
import { UserAccess } from './User.service.types'

export class UserService extends BaseHttpClientService {
    get url() {
        return '/user'
    }

    async findCurrentUserInfo(config?: ServicesConfiguration): Promise<UserInfo> {
        return this.httpClient.get<UserInfo>(`${this.url}/info`, config).then(extractResponseData)
    }

    async updateCurrentUserInfo(data: Partial<UserInfo>, config?: ServicesConfiguration): Promise<UserInfo> {
        return this.httpClient.post<UserInfo>(`${this.url}/info`, data, config).then(extractResponseData)
    }

    async findAllAccesses(config?: ServicesConfiguration): Promise<List<UserAccess>> {
        return this.httpClient.get<List<UserAccess>>(`${this.url}/accesses`, config).then(extractResponseData)
    }
}
