import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { isEmptyRecord, sortKeysByCustomOrder } from '@/utils'
import { Text } from '@chakra-ui/react'
import { AccountHolderAddress } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getAddressTemplateReducer } from './EntityDetailCellHolderAddress.utils'

interface EntityDetailCellHolderAddressProps {
    value?: AccountHolderAddress
}

export const EntityDetailCellHolderAddress: React.FC<EntityDetailCellHolderAddressProps> = ({ value }) => {
    const intl = useIntl()
    const hasEmptyValue = useMemo(() => isEmptyRecord(value), [value])
    const formattedValue = useMemo<string>(() => {
        const addressTemplateReducer = getAddressTemplateReducer(intl, value)
        const addressRecord = value || Object.create(null)
        const addressRecordKeys = Object.keys(addressRecord)
        const sortedAddressKeys = sortKeysByCustomOrder(addressRecordKeys, [
            'line_1',
            'line_2',
            'building_number',
            'street_name',
            'postal_code',
            'city',
            'region_state',
            'country'
        ])

        return sortedAddressKeys.reduce(addressTemplateReducer, '')
    }, [intl, value])

    if (hasEmptyValue) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text whiteSpace="pre-wrap">{formattedValue}</Text>
}
