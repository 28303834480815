import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { getDineroCurrency } from '@/utils'
import { amountFormatter } from '@/utils/@formatters'
import { CurrencyCode } from '@webapps/numeral-ui-core'
import { IntlShape } from 'react-intl'

export function getAmountInputPlaceholder(intl: IntlShape, currencyCode?: CurrencyCode, placeholder?: string) {
    if (placeholder) {
        return placeholder
    }

    if (!currencyCode) {
        return intl.formatMessage({
            id: 'app.common.form.input.amount.placeholder.alternative',
            defaultMessage: EMPTY_VALUE_PLACEHOLDER
        })
    }

    return amountFormatter(intl, 0, currencyCode)
}

/**
 * Returns the maximum number of decimal for the amount, which depends on the currency used
 * For unknown currencies, return a defaults of 2 decimals
 */
export function getAmountScale(currencyCode?: CurrencyCode): number {
    const defaultScale = 2
    const currency = getDineroCurrency(currencyCode)
    return currency?.exponent ?? defaultScale
}
