import { TableCellShortDate, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import React from 'react'
import { CustomField } from '@webapps/numeral-ui-core'
import { FormattedCustomFieldType, TableCellCustomFieldObjectTypes } from './@components'

export const CUSTOM_FIELDS_TABLE_STATIC_STATE: TableStateWithEntity<CustomField> = {
    columnOrder: Object.freeze(['created_at', 'name', 'key', 'type', 'object_types'])
}

export const CUSTOM_FIELDS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<CustomField> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        cell(info) {
            return <FormattedCustomFieldType customFieldType={info.getValue()} />
        }
    },
    object_types: {
        size: COLUMN_WIDTH_RATIO.OBJECT_TYPE,
        cell(info) {
            return <TableCellCustomFieldObjectTypes value={info.getValue()} />
        }
    }
}
