import { useParams } from 'react-router-dom'
import { useQueryFindByIdTransaction } from '@/hooks'
import { Transaction } from '@webapps/numeral-ui-core'
import { ReconciliationsPage } from '@/pages'

export const TransactionsReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdTransaction(uuid)

    return <ReconciliationsPage<Transaction> transactionId={uuid} queryItem={query} />
}
