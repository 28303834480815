import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { APIError } from '@/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UseMutationOptions } from '@tanstack/react-query/src/types'
import { CustomField, Uuid } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationUpdateByIdCustomField(
    id?: Uuid,
    options?: UseMutationOptions<CustomField, AxiosError<APIError<CustomField>>, Partial<CustomField>>
) {
    const { selectedUserAccess } = useAuth()
    const { customFieldsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<CustomField, AxiosError<APIError<CustomField>>, Partial<CustomField>>({
        ...options,
        mutationKey: [customFieldsService.url, { id }],
        mutationFn(data) {
            return customFieldsService.updateById(id as string, data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([customFieldsService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [customFieldsService.url]
                })
                .catch(noop)
        }
    })
}
