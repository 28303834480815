import { Form, FormPageFooter } from '@/components'
import { IncomingPaymentReturnReasonInput } from '@/components/@inputs'
import { Box } from '@chakra-ui/react'
import { ReturnCreateData } from '@webapps/numeral-ui-core'
import { FormikValues } from 'formik'
import { noop } from 'lodash'
import { useCallback, useRef } from 'react'
import { IncomingPaymentReturnFormProps } from './IncomingPaymentReturn.form.types'

export const IncomingPaymentReturnForm: React.FC<IncomingPaymentReturnFormProps> = ({
    data,
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useRef<Partial<ReturnCreateData>>({
        return_reason: undefined
    }).current
    const onFormSubmit = useCallback(
        (values: FormikValues) => {
            if (data?.id) {
                onSubmit({
                    ...values,
                    related_payment_id: data.id
                })
            }
        },
        [data, onSubmit]
    )

    return (
        <Form<Partial<ReturnCreateData>> initialValues={initialValues} onSubmit={onFormSubmit}>
            {({ handleSubmit, dirty }) => {
                return (
                    <Box marginY="16px">
                        <IncomingPaymentReturnReasonInput
                            name="return_reason"
                            isPlaceholderDisplayed={true}
                            data={data}
                        />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.payments.incoming_payments.return.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </Box>
                )
            }}
        </Form>
    )
}
