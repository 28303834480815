import { BULLET_SEPARATOR, EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { UltimateOriginatorOrganizationIdentification } from '@webapps/numeral-ui-core'

export function getFormattedOrganizationIdentification(
    organizationIdentification: UltimateOriginatorOrganizationIdentification
): string {
    const bankCode = organizationIdentification.bank_code
    const other = organizationIdentification.other

    switch (true) {
        case !!bankCode && !!other:
            return `${bankCode} ${BULLET_SEPARATOR} ${other}`
        case !!bankCode && !other:
            return bankCode
        case !bankCode && !!other:
            return other
        default:
            return EMPTY_VALUE_PLACEHOLDER
    }
}
