import { useNavigationRoutes } from '@/hooks'
import { NavigationRoute } from '@/providers'
import React, { ReactNode, useRef } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { chain } from 'lodash'

export const AppRoutes: React.FC = () => {
    const location = useLocation()
    const { routes, paths } = useNavigationRoutes()
    const { current } = useRef((route: NavigationRoute, index: number): ReactNode => {
        const childRoutesWrapper = chain(route.routes)
        const hasChildRoutes = childRoutesWrapper.size().gt(0).value()

        if (hasChildRoutes) {
            return (
                <Route {...route} key={index}>
                    {route?.routes?.map(current) as any}
                </Route>
            )
        }

        if (route.configuration?.isDisabled) {
            const element = <Navigate to={paths.ROOT} state={{ from: location }} />
            return <Route element={element} key={index} />
        }

        return <Route {...route} key={index} />
    })

    return <Routes>{routes?.map(current)}</Routes>
}
