import { useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { paginationQueryOptions } from '@/hooks/api/paginationQueryOptions.const'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { Role } from '@/services/Roles/Roles.service.types'
import { UndefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query'
import { List, Pagination } from '@webapps/numeral-ui-core'

export function useQueryFindAllRoles(
    params?: Partial<Pagination>,
    options?: UndefinedInitialDataInfiniteOptions<List<Role>, Error>
) {
    const { rolesService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_ROLES) && options?.enabled
    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<Role>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [rolesService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return rolesService.findAll({ ...paramsWithUserAccess, ...(pageParam as typeof params) }, { signal })
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
