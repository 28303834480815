import './Page.layout.scss'
import { PropsWithChildren, useRef } from 'react'
import classNames from 'classnames'
import { BoxProps, Flex } from '@chakra-ui/react'

interface PageLayoutProps extends BoxProps {
    className?: string
}

export const PageLayout: React.FC<PropsWithChildren<PageLayoutProps>> = ({ children, className, ...propsBox }) => {
    const extendedClassname: string = useRef(classNames('PageLayout', className)).current
    return (
        <Flex {...propsBox} direction="column" className={extendedClassname} data-testid="page-layout">
            {children}
        </Flex>
    )
}
