import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

interface ExpectedPaymentCreateErrorNotificationProps {
    error: any /* @todo Backend missing Error type */
}

export const ExpectedPaymentCreateErrorNotification: React.FC<ExpectedPaymentCreateErrorNotificationProps> = ({
    error
}) => {
    const errorDetails = useMemo(() => get(error, 'response.data.details', []), [error])

    return (
        <>
            <Text>
                <FormattedMessage id="app.payments.expected_payments.create.form.validation.create.error.description" />
            </Text>
            {errorDetails.map((detail: any, index: number) => {
                return (
                    <Text whiteSpace="normal" key={index}>
                        {`- ${detail?.field}: ${detail?.reason}`}
                    </Text>
                )
            })}
        </>
    )
}
