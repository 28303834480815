import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, PERMISSION, filterUserAccessQueryParams } from '@/services'
import { useQuery, type UndefinedInitialDataOptions } from '@tanstack/react-query'
import { PaymentOrder, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdPaymentOrder(
    id?: Uuid | null,
    options?: Partial<UndefinedInitialDataOptions<PaymentOrder, Error>>
) {
    const { paymentOrdersService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.PAYMENT_ORDER) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<PaymentOrder, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentOrdersService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return paymentOrdersService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
