import { NavigationRoute } from '@/providers'
import { ENTITY_FEATURE, USER_ACCESS_CONFIGURATION_ENTITY_TYPE } from '@/services'
import { chain, partition } from 'lodash'

/**
 * @todo
 * Write unit test
 */
export function getAccordionActiveIndexByPath(routes: NavigationRoute[], pathname: string): number {
    const navigationStartIndex = 1
    const navigationDelta = 2
    const indexFinder = ({ routes, path }: NavigationRoute) => routes?.length && path && pathname.includes(path)

    return chain(routes)
        .findIndex(indexFinder as any)
        .add(navigationStartIndex)
        .subtract(navigationDelta)
        .value()
}

/**
 * @description
 * Partition enabled main routes and enabled footer routes
 */
export function getOrderedRoutes(routes: NavigationRoute[]) {
    const enabledRoutes = routes?.filter((route) => !route.configuration?.isDisabled && !route.configuration?.isHidden)
    const [mainRoutes, footerRoutes] = partition(
        enabledRoutes,
        (route: NavigationRoute) => !route.configuration?.isFooter
    )
    return { mainRoutes, footerRoutes }
}

/**
 * Generate a filter that filters out routes that don't match current legal entity type
 * @param currentLegalEntityType Current type of the legal entity
 * @returns true if the route isn't restricted to financial institution or if the current legal entity is a financial institution
 */
export function getLegalEntityTypeRouteFilter(
    currentLegalEntityType?: USER_ACCESS_CONFIGURATION_ENTITY_TYPE
): (route: NavigationRoute) => boolean {
    return (route) =>
        !route.configuration?.isForFinancialInstitution ||
        currentLegalEntityType === USER_ACCESS_CONFIGURATION_ENTITY_TYPE.FINANCIAL_INSTITUTION
}

/**
 * Generate a filter that filters out routes that require an feature not currently active
 * @param activeFeatures List of active features for the current legal entity
 * @returns true if the route doesn't require a feature or if the feature required is part of the active features
 */
export function getEntityFeatureRouteFilter(activeFeatures?: ENTITY_FEATURE[]): (route: NavigationRoute) => boolean {
    return (route) => !route.configuration?.feature || !!activeFeatures?.includes(route.configuration?.feature)
}

/**
 * @description
 * Check if the routePath is active
 * @returns true if the `currentPath` equals the `routePath` or is a child of the routePath
 */
export function isPathActive(currentPath: string, routePath?: string): boolean {
    if (!routePath) {
        return false
    }
    return currentPath.includes(routePath)
}
