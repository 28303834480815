import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useMutationDenyByIdReturnRequest, useNavigationRoutes, useQueryFindByIdReturnRequest } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { canAcceptOrDenyReturnRequestByStatus } from '@/pages/Payments/ReturnRequests/ReturnRequests.page.utils'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReturnRequestDenyForm } from './@components'
import { updateUUIDRelativeActionPathname } from '@/providers'

export const ReturnRequestDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.return_requests.deny.title' }), [intl])
    const query = useQueryFindByIdReturnRequest(uuid)
    const mutation = useMutationDenyByIdReturnRequest(uuid, {
        onSuccess() {
            const { PAYMENTS } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, PAYMENTS.DETAILS)

            navigate(path)
        }
    })
    const isDisabled = useMemo<boolean>(() => {
        const canAcceptOrDeny = canAcceptOrDenyReturnRequestByStatus(query.data?.status)
        const isLoading = query.isLoading || mutation.isPending
        return !canAcceptOrDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="ReturnRequestDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.RETURN_REQUEST}
                permission={PERMISSION.PAYMENTS_APPROVE_RETURN_REQUESTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <ReturnRequestDenyForm
                                    onSubmit={mutation.mutate}
                                    loading={mutation.isPending}
                                    disabled={isDisabled}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
