import { NEW_LINE_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { AccountHolderAddress } from '@webapps/numeral-ui-core'
import { IntlShape } from 'react-intl'

function isPostalCodeKey(key: string): boolean {
    return key === 'postal_code'
}

function isBuildingNumberKey(key: string): boolean {
    return key === 'building_number'
}

function getAddressSeparatorSymbol(key: string): string {
    return isPostalCodeKey(key) || isBuildingNumberKey(key) ? SPACE_SYMBOL : NEW_LINE_SYMBOL
}

export function getValueWithAddressSeparator(key: string, value: string) {
    const separatorSymbol = getAddressSeparatorSymbol(key)
    return `${value}${separatorSymbol}`
}

export function getAddressTemplateReducer(intl: IntlShape, value?: AccountHolderAddress) {
    return function addressTemplateReducer(acc: string, itemKey: string) {
        const currentValue = value?.[itemKey as keyof AccountHolderAddress]

        if (!currentValue) {
            return acc
        }

        const valueFormats: Record<string, string | undefined> = {
            country: intl.formatDisplayName(currentValue, {
                type: 'region'
            })
        }
        const transformedCurrentValue = valueFormats[itemKey] || currentValue

        if (transformedCurrentValue) {
            acc += getValueWithAddressSeparator(itemKey, transformedCurrentValue)
        }

        return acc
    }
}
