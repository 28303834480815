import { ReactNode, useMemo } from 'react'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useIntl } from 'react-intl'
import { FormattedAmount } from '@/components/@misc'
import { getAmountByEntityType, getReconcilableAmount } from '@/services'
import { ReconcileEntityHeaderProps } from './ReconcileEntity.header.types'

export const ReconcileEntityHeader: React.FC<ReconcileEntityHeaderProps> = ({ queryItem }) => {
    const intl = useIntl()
    const title = useMemo<ReactNode>(() => {
        if (!queryItem.data) {
            return null
        }
        const reconciliationStatus = queryItem.data?.reconciliation_status
        const currency = queryItem.data?.currency
        const messageId = `app.reconcile.${reconciliationStatus}.title`
        const formattedAmount = <FormattedAmount amount={getAmountByEntityType(queryItem.data)} currency={currency} />
        const formattedReconcilableAmount = (
            <FormattedAmount amount={getReconcilableAmount(queryItem.data)} currency={currency} />
        )

        return intl.formatMessage(
            {
                id: messageId
            },
            {
                amount: formattedAmount,
                reconcilableAmount: formattedReconcilableAmount
            }
        )
    }, [queryItem, intl])
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        return {
            title,
            isLoading: queryItem.isLoading
        }
    }, [queryItem, title])

    return <PageHeaderLayout {...propsPageHeaderLayout} />
}
