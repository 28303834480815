import {
    EmptyContent,
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    LoadingSpinner,
    RestrictedSection
} from '@/components'
import { usePermissions, useQueryFindByIdPaymentOrder } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PaymentOrderApproval } from './@components'
import {
    getPaymentOrderEntityDetailRowCustomizers,
    getPaymentOrderEntityDetailRows
} from './PaymentOrderDetails.page.utils'
import { PaymentOrderDetailsCustomRows } from './PaymentOrderDetails.types'
import { If } from '@/components/@misc'

export const PaymentOrderDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdPaymentOrder(uuid)
    const rows = useMemo<EntityDetailRow<PaymentOrder, PaymentOrderDetailsCustomRows>[]>(() => {
        return getPaymentOrderEntityDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<PaymentOrder>>(() => {
        return getPaymentOrderEntityDetailRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            const hasApprovalPermission = hasPermission(PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS)
                            return (
                                <>
                                    <EntityDetail<PaymentOrder, PaymentOrderDetailsCustomRows>
                                        data={query.data}
                                        rows={rows}
                                        rowCustomizers={rowCustomizers}
                                    />
                                    <If condition={hasApprovalPermission}>
                                        <PaymentOrderApproval data={query.data} />
                                    </If>
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
