import { RolePermissions } from '@/services'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { PERMISSION_GROUPING, PERMISSIONS_GROUP_NAME, styleRoleUserPermissions } from './RoleUserPermissions.const'
import { getRolePermissionsInGroup } from './RoleUserPermissions.utils'

interface RolePermissionsProps {
    permissions?: RolePermissions
}

export const RoleUserPermissions: React.FC<RolePermissionsProps> = ({ permissions }) => {
    return (
        <FormControl>
            <FormLabel>
                <FormattedMessage id="app.settings.roles.details.permissions.label" />
            </FormLabel>
            <Box {...styleRoleUserPermissions}>
                <Accordion allowMultiple={true}>
                    {Object.values(PERMISSIONS_GROUP_NAME).map((groupName, index) => {
                        const permissionsInGroup: string[] = getRolePermissionsInGroup(groupName, permissions)
                        const numberPermissionsInGroup = permissionsInGroup?.length
                        const isFirstItem = index === 0
                        const propsAccordionItem = {
                            [isFirstItem ? 'borderTop' : 'borderBottom']: 'unset'
                        }
                        return (
                            <AccordionItem {...propsAccordionItem} key={groupName}>
                                <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                                    <Text color="gray.800" fontWeight="semibold">
                                        <FormattedMessage id={`app.settings.roles.details.group_name.${groupName}`} />
                                    </Text>
                                    <Flex>
                                        <Text color="gray.500" marginX="8px" fontSize="14px">
                                            <FormattedMessage
                                                id="app.settings.roles.details.group_number_of_permissions.label"
                                                values={{ numberPermissionsInGroup }}
                                            />
                                        </Text>
                                        <AccordionIcon color="gray.500" />
                                    </Flex>
                                </AccordionButton>
                                <AccordionPanel
                                    display="flex"
                                    gap="16px"
                                    flexDirection="column"
                                    marginLeft="16px"
                                    color="gray.800">
                                    {PERMISSION_GROUPING[groupName].map((permission) => {
                                        const hasPermission = permissionsInGroup?.includes(permission)
                                        return (
                                            <Checkbox
                                                key={permission}
                                                isChecked={hasPermission}
                                                isDisabled={true}
                                                pointerEvents="none"
                                                sx={{
                                                    _disabled: {
                                                        span: { opacity: 1, userSelect: 'text' }
                                                    }
                                                }}
                                                color={hasPermission ? 'gray.800' : 'gray.400'}>
                                                <FormattedMessage
                                                    id={`app.settings.roles.details.permission.${permission}`}
                                                />
                                            </Checkbox>
                                        )
                                    })}
                                </AccordionPanel>
                            </AccordionItem>
                        )
                    })}
                </Accordion>
            </Box>
        </FormControl>
    )
}
