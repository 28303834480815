import { getFormSelectPlaceholderByReactQueryState } from '@/components'
import { Select, useSearchQuerySelect } from '@/components/@misc'
import { useQueryFindAllPaymentRetryRules } from '@/hooks'
import { FormikInput } from '@/types'
import { uuidValidator } from '@/utils/@validators'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { PaymentRetryRule, PaymentRetryRulesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { identity } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getRetryRuleOptionLabel } from './RetryRuleInput.utils'

export interface RetryRuleInputProps<T = PaymentRetryRule> extends FormikInput {
    queryParams?: Partial<PaymentRetryRulesServiceFindAllQueryOptions>
    queryDataFilter?: (value: T, index: number, array: T[]) => unknown
}

export const RetryRuleInput: React.FC<RetryRuleInputProps> = ({
    name,
    value,
    isDisabled,
    customLabelKey,
    customPlaceholderKey,
    isPlaceholderDisplayed = false,
    validator = uuidValidator,
    isRequired,
    queryParams = undefined,
    queryDataFilter = identity,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({
        name,
        value,
        validate: validator(intl, isRequired)
    })
    const { onInputChange, onFocus } = useSearchQuerySelect()
    const query = useQueryFindAllPaymentRetryRules(queryParams)

    const data = useMemo<PaymentRetryRule[]>(() => {
        if (!query.data?.records) {
            return []
        }
        return query.data.records.filter(queryDataFilter)
    }, [query, queryDataFilter])

    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.retry_rule.label`
        return intl.formatMessage({ id: labelKey })
    }, [customLabelKey, intl])

    const placeholder = useMemo<string | undefined>(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey })
        }
        return getFormSelectPlaceholderByReactQueryState(intl, query, isPlaceholderDisplayed)
    }, [customPlaceholderKey, intl, query, isPlaceholderDisplayed])

    const onChange = useCallback(
        (value: string) => {
            inputProps.onChange?.(value)
            helpers.setValue(value)
        },
        [helpers, inputProps]
    )

    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select<PaymentRetryRule>
                {...inputProps}
                {...field}
                id={name}
                options={data}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isRequired={isRequired}
                isDisabled={isDisabled}
                isLoading={query.isLoading}
                isClearable={true}
                onChange={onChange}
                onFocus={onFocus}
                onInputChange={onInputChange}
                getOptionLabel={getRetryRuleOptionLabel}
                getOptionValue={(option) => option.id}
                noOptionsMessage={() =>
                    intl.formatMessage({
                        id: 'app.common.form.input.retry_rule.no_result_found.title'
                    })
                }
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
