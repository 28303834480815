import { FilterInputs } from '@/components'
import { WILDCARD_SYMBOL } from '@/constants'

export enum QUERY_PARAMS_RESERVED_NAMES {
    SEARCH = 'search',
    GROUP_BY = 'group_by'
}

export enum QueryParamTypes {
    Search = 'search',
    GroupBy = 'groupBy',
    FilterBy = 'filterBy'
}

/**
 * @scope
 * Utility
 * @description
 * Type to enforce extraction of an item with multiple types by the (Query type=Q) from a given configuration table map (Map=M).
 */
export type ExtractQueryType<Q, M> = Q extends keyof M ? M[Q] : never
export type DynamicQueryType<T> = T extends string ? T : keyof T
export type ExtractEnumType<T, Key extends keyof T> = Key extends keyof T ? T[Key] : never

/**
 * @scope
 * Utility
 * @description
 * Allows for shorthands like "search" or "filterBy" instead of a configuration object:
 */
export type QueryParamType<T extends QueryParamTypes> = `${T}`
export type UseQueryParamsArg<T = void> =
    | Partial<{
          [Q in QueryParamTypes]: {
              configuration: ExtractQueryType<Q, QueryParamConfigurationMap<T>>
          }
      }>
    | QueryParamType<QueryParamTypes>

/**
 * @description
 * Different configuration types used by either one of:
 * `Search`, `GroupBy` and `FilterBy` (and others).
 */
export interface QueryParamConfiguration<Q = void, T = void> {
    isDisabled?: boolean

    isPersisted?: boolean
    isExpanded?: boolean

    toggleIsVisible?(state: boolean): void
    toggleIsActive?(state: boolean): void
    toggleIsExpanded?(state: boolean): void

    stateParser?(state: ExtractQueryType<Q, QueryParamStateMap<T>>): typeof state | undefined
    queryParamKey?: QUERY_PARAMS_RESERVED_NAMES
}

export type QueryParamQuickFilters<T> = {
    [K in keyof Partial<T> & { [WILDCARD_SYMBOL]?: [typeof WILDCARD_SYMBOL] }]: ExtractEnumType<T, K>[]
}

export interface QueryParamConfigurationFilterBy<T> extends QueryParamConfiguration<QueryParamTypes.FilterBy, T> {
    filters?: FilterInputs<T>
    preselected?: QueryParamStateFilterBy<T>

    quickFilters?: QueryParamQuickFilters<T>
}
export interface QueryParamConfigurationSearch extends QueryParamConfiguration<QueryParamTypes.Search> {
    isEnabled?: boolean
}
export interface QueryParamConfigurationGroupBy extends QueryParamConfiguration<QueryParamTypes.GroupBy> {
    groups: readonly string[]
    onClose?(): void
}
export type QueryParamConfigurationMap<T = void> = {
    [QueryParamTypes.Search]: QueryParamConfigurationSearch
    [QueryParamTypes.GroupBy]: QueryParamConfigurationGroupBy
    [QueryParamTypes.FilterBy]: QueryParamConfigurationFilterBy<T>
}

/**
 * @description
 * State types and state mappings for `Search`, `GroupBy` and `FilterBy`:
 */
export type QueryParamStateFilterBy<T> = { [key in keyof Partial<Omit<T, QUERY_PARAMS_RESERVED_NAMES>>]: T[key] }
export type QueryParamStateSearch = { [QUERY_PARAMS_RESERVED_NAMES.SEARCH]?: string }
export type QueryParamStateGroupBy = { [QUERY_PARAMS_RESERVED_NAMES.GROUP_BY]?: string[] }
export type QueryParamStateMap<T = void> = {
    [QueryParamTypes.Search]: QueryParamStateSearch
    [QueryParamTypes.GroupBy]: QueryParamStateGroupBy
    [QueryParamTypes.FilterBy]: QueryParamStateFilterBy<T>
}

/**
 * @description
 * General available API for any given hook `Search`, `GroupBy` and `FilterBy`, etc.
 */
export interface UseQueryParamResult<Q, T> {
    readonly isActive?: boolean
    readonly isVisible?: boolean
    //Hook states:
    readonly initialState: ExtractQueryType<Q, QueryParamStateMap<T>> | undefined
    readonly parsedState: ExtractQueryType<Q, QueryParamStateMap<T>> | undefined
    readonly innerState: ExtractQueryType<Q, QueryParamStateMap<T>> | undefined
    //Hook callbacks:
    setState(state?: ExtractQueryType<Q, QueryParamStateMap<T>>): void
    toggleIsVisible(forcedValue?: boolean): void
    //Hook initial configuration:
    readonly configuration?: ExtractQueryType<Q, QueryParamConfigurationMap<T>>
}

export type UseQueryParamResultGroupBy = {
    isExpanded?: boolean

    isGroupByRecordColumnsVisible: boolean
    isGroupByWithEmptyRecordsVisible: boolean
    isExpandedEntries: Record<string, number>

    toggleIsExpanded?(forcedValue?: boolean): void
    getToggleGroupByExpandedEntries?(uniqueKey: string): (expandedIndex: number) => void
}

export type UseQueryParamsResult<Q, T = void> = UseQueryParamResult<Q, T> & UseQueryParamResultGroupBy
