import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { ConnectedAccountName, EnabledStatus, FormattedDateTime } from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { VirtualAccount } from '@webapps/numeral-ui-core'
import { VirtualAccountCustomRows } from './VirtualAccountDetails.page.types'

export const VIRTUAL_ACCOUNT_DETAILS_ROWS: EntityDetailRow<VirtualAccount, VirtualAccountCustomRows>[] = [
    'id',
    'created_at',
    'name',
    'virtual_account_number',
    'status',
    'disabled_at',
    'connected_account_id',
    /*
     * @todo Enable once we have counterparties
     * 'related_objects',
     */
    'metadata'
]

export const VIRTUAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    VirtualAccount,
    VirtualAccountCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    virtual_account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.virtual_account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    status: {
        cell(item) {
            const isDisabled = !!item?.disabled_at
            return <EnabledStatus isDisabled={isDisabled} />
        }
    },
    disabled_at: {
        cell(item) {
            return <FormattedDateTime value={item.disabled_at} />
        }
    },
    related_objects: {
        cell(item) {
            return <EntityDetailCellRelatedObjectsLinks relatedCounterpartyId={item.counterparty_id} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    metadata: {
        nested: true
    }
}
