import * as currencies from '@dinero.js/currencies'
import { Currency } from 'dinero.js'

export const CURRENCIES: Record<string, Currency<number>> = {
    // ISO 4217 currencies
    ...currencies,

    // Overriding non decimal currencies because Numeral API does not follow ISO 4217 for MRU and MGA currencies
    // API ref: https://docs.adyen.com/development-resources/currency-codes
    MRU: {
        code: 'MRU',
        base: 10,
        exponent: 2
    },
    MGA: { code: 'MGA', base: 10, exponent: 2 },

    // Non standard currencies (not part of ISO 4217)
    BDS: {
        code: 'BDS',
        base: 10,
        exponent: 2
    },
    CNH: {
        code: 'CNH',
        base: 10,
        exponent: 2
    },
    CNT: {
        code: 'CNT',
        base: 10,
        exponent: 2
    },
    GGP: {
        code: 'GGP',
        base: 10,
        exponent: 2
    },
    IMP: {
        code: 'IMP',
        base: 10,
        exponent: 2
    },
    JEP: {
        code: 'JEP',
        base: 10,
        exponent: 2
    },
    KID: {
        code: 'KID',
        base: 10,
        exponent: 2
    },
    NIS: {
        code: 'NIS',
        base: 10,
        exponent: 2
    },
    NTD: {
        code: 'NTD',
        base: 10,
        exponent: 2
    },
    PRB: {
        code: 'PRB',
        base: 10,
        exponent: 2
    },
    SLS: {
        code: 'SLS',
        base: 10,
        exponent: 2
    },
    STG: {
        code: 'STG',
        base: 10,
        exponent: 2
    },
    RMB: {
        code: 'RMB',
        base: 10,
        exponent: 2
    },
    TVD: {
        code: 'TVD',
        base: 10,
        exponent: 2
    },
    ZWB: {
        code: 'ZWB',
        base: 10,
        exponent: 2
    }
}

export const DEFAULT_CURRENCY_CODE = CURRENCIES.EUR.code
export const HIGHLIGHTED_CURRENCY_CODES = [CURRENCIES.EUR.code, CURRENCIES.GBP.code, CURRENCIES.USD.code]
