import { isEmpty, isNil } from 'lodash'
import { markedFieldsTransformers } from './markedFields.transform.utils'
import { AxiosHeaders } from 'axios'

export function markedFieldsTransform<T>(data: T, headers?: AxiosHeaders): T | void {
    if (isNil(data) || isEmpty(data)) {
        return
    }

    const generator: Generator<T> = markedFieldsTransformers<T>(data)
    let result: IteratorResult<T> = generator.next()

    while (!result.done) {
        result = generator.next()
    }

    return result.value
}
