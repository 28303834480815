import { Icon, IconProps } from '@chakra-ui/react'

export const HelpCenterIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.83366 2.5H12.167C13.9351 2.5 15.6308 3.20238 16.881 4.45262C18.1313 5.70286 18.8337 7.39856 18.8337 9.16667C18.8337 10.9348 18.1313 12.6305 16.881 13.8807C15.6308 15.131 13.9351 15.8333 12.167 15.8333V18.75C8.00033 17.0833 2.16699 14.5833 2.16699 9.16667C2.16699 7.39856 2.86937 5.70286 4.11961 4.45262C5.36986 3.20238 7.06555 2.5 8.83366 2.5ZM10.5003 14.1667H12.167C12.8236 14.1667 13.4738 14.0373 14.0804 13.7861C14.687 13.5348 15.2382 13.1665 15.7025 12.7022C16.1668 12.2379 16.5351 11.6867 16.7864 11.0801C17.0377 10.4735 17.167 9.82328 17.167 9.16667C17.167 8.51006 17.0377 7.85988 16.7864 7.25325C16.5351 6.64662 16.1668 6.09543 15.7025 5.63113C15.2382 5.16684 14.687 4.79854 14.0804 4.54727C13.4738 4.296 12.8236 4.16667 12.167 4.16667H8.83366C7.50758 4.16667 6.23581 4.69345 5.29813 5.63113C4.36044 6.56881 3.83366 7.84058 3.83366 9.16667C3.83366 12.175 5.88533 14.1383 10.5003 16.2333V14.1667Z"
        />
    </Icon>
)
