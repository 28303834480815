import { AccordionIcon, Flex, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormattedAmount } from '@/components/@misc'
import { useMemo } from 'react'
import { getCountryOptionWithIntlMapper } from '@/components/@inputs'
import { countAllGroupByRecordsForGroupEntry } from './BalancesGroupByHeader.utils'
import { BalancesGroupByHeaderProps } from './BalancesGroupByHeader.types'

export function BalancesGroupByHeader({ group, parentGroupBy }: BalancesGroupByHeaderProps) {
    const intl = useIntl()
    const count = useMemo(() => countAllGroupByRecordsForGroupEntry(group), [group])
    const formattedGroupByName = useMemo(() => {
        const currentGroup = group.group

        switch (parentGroupBy) {
            case 'country': {
                const country = getCountryOptionWithIntlMapper(intl)(currentGroup)
                return <>{country.name}</>
            }

            default: {
                return (
                    currentGroup || (
                        <FormattedMessage
                            id="app.accounts.balances.group_by.no_parent.title"
                            values={{
                                parentGroupBy
                            }}
                        />
                    )
                )
            }
        }
    }, [group, parentGroupBy])

    return (
        <Flex className="BalancesGroupByHeader" flex="1" justifyContent="space-between">
            <Flex>
                <AccordionIcon />
                <Flex marginX="8px">
                    <Text color="gray.800" fontWeight="500" marginRight="8px">
                        {formattedGroupByName}
                    </Text>
                    <Text color="gray.400">
                        <FormattedMessage id="app.accounts.balances.group_by.item.size" values={{ count }} />
                    </Text>
                </Flex>
            </Flex>
            <Text color="gray.800" fontWeight="500">
                <FormattedAmount amount={group?.aggregated_balance} currency={group?.currency} />
            </Text>
        </Flex>
    )
}
