import { Navigate } from 'react-router-dom'
import { NavigationRoute, NotFoundRoute } from '@/providers'
import { LoginErrorPage, LoginPage } from '@/pages'

export function getAccountNavigationRoutes(): NavigationRoute {
    return {
        path: '/account',
        routes: [
            {
                index: true,
                element: <Navigate replace={true} to="login" />
            },
            {
                title: 'app.account.login.title',
                path: 'login',
                element: <LoginPage />
            },
            {
                title: 'app.account.login_error.title',
                path: 'error',
                element: <LoginErrorPage />
            },
            NotFoundRoute
        ]
    }
}
