import { BaseHttpClientService } from '@webapps/numeral-ui-core'
import { AxiosRequestTransformer } from 'axios'

/**
 * @description
 * Utility function that guarantees that the Axios defaults are always set last:
 * - The order in which `transformRequests are declared is important;
 * - Global service defaults can be always set in a controlled manner;
 */
export function addTransformRequestsToHttpClientInstance(
    service: BaseHttpClientService,
    ...transformRequests: AxiosRequestTransformer[]
): AxiosRequestTransformer[] {
    const { transformRequest } = service.httpClient.defaults

    return [...transformRequests, ...(transformRequest as AxiosRequestTransformer[])]
}
