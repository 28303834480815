import React, { useMemo } from 'react'
import { usePermissions } from '@/hooks'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { BalanceExpanded, PERMISSION } from '@/services'
import { RestrictedData } from '@/components/@misc'
import { Text } from '@chakra-ui/react'
import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { connectedAccountNameFormatter } from '@/utils/@formatters'

interface BalanceConnectedAccountNameProps {
    balance?: BalanceExpanded
}

export const BalanceConnectedAccountName: React.FC<BalanceConnectedAccountNameProps> = ({ balance }) => {
    const { hasPermission } = usePermissions()
    const formattedAccountName = useMemo(() => {
        const connectedAccount: Partial<ConnectedAccount> = {
            name: balance?.connected_account_name,
            address: { country: balance?.connected_account_address_country },
            account_number: balance?.connected_account_account_number,
            bank_name: balance?.connected_account_bank_name
        }
        return connectedAccountNameFormatter(connectedAccount, true)
    }, [balance])

    switch (true) {
        case !balance?.connected_account_id:
        case !balance?.connected_account_name: {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        case !hasPermission(PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS): {
            return <RestrictedData />
        }

        default: {
            return (
                <Text as="span" title={formattedAccountName}>
                    {formattedAccountName}
                </Text>
            )
        }
    }
}
