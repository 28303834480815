import { DateISOFormatSchema, UserInfoDepartmentSchema, UuidSchema } from '@webapps/numeral-ui-core'
import { z } from 'zod'
import { RoleNameSchema } from '../Roles/Roles.schema'

export const UserStatusSchema = z.enum(['pending', 'verified', 'disabled'])
export const UserRoleSchema = z.object({
    id: UuidSchema,
    name: RoleNameSchema
})

export const UserSchema = z.object({
    id: UuidSchema.optional(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email(),
    is_enterprise: z.boolean(),
    status: UserStatusSchema,
    roles: z.array(UserRoleSchema),
    picture: z.string().url().optional(),
    created_at: DateISOFormatSchema.optional(),
    disabled_at: DateISOFormatSchema.optional(),
    last_login_date: DateISOFormatSchema.optional(),
    department: UserInfoDepartmentSchema.optional(),
    position: z.string().optional()
})

export const UserInviteRequestSchema = z
    .object({
        email: z.string(),
        /**
         * @todo
         * Should be renamed to <role: UUID> instead of <role_ids: UUID[]>
         */
        role_ids: z.array(UuidSchema).optional()
    })
    .extend({
        /**
         * @description
         * Only used internally
         */
        role: UuidSchema.optional()
    })
