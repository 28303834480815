import { AlertMessage, FormContainer, InputWithCopyToClipboard, InputWithCustomComponent } from '@/components'
import { FormattedDateTime, StatusLabel } from '@/components/@misc'
import { LOCATION_STATE_KEYS } from '@/constants'
import { APIKey } from '@/services'
import { getAPIKeyLast4CharsPlaceholderTemplate, isAPIKeyDisabled } from '@/services/APIKeys/APIKeys.service.utils'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

interface APIKeyDetailsFormProps {
    data?: APIKey
}

export const APIKeyDetailsForm: React.FC<APIKeyDetailsFormProps> = ({ data }) => {
    const intl = useIntl()
    const propsInputCommon = useRef({
        size: 'md',
        readOnly: true,
        variant: 'flushed',
        focusBorderColor: 'none'
    }).current
    const location = useLocation()
    const apiKeyInPlain = useMemo(() => {
        const state: any = location.state /* @todo Workaround for `location.state` type check*/
        return state?.[LOCATION_STATE_KEYS.API_KEY]
    }, [location])
    const hasAPIKeyPlain = useMemo<boolean>(() => apiKeyInPlain?.length > 0, [apiKeyInPlain])
    const isDisabled = useMemo<boolean>(() => isAPIKeyDisabled(data), [data])
    const last4Chars = useRef<string>(getAPIKeyLast4CharsPlaceholderTemplate(data)).current

    return (
        <FormContainer>
            {hasAPIKeyPlain && !isDisabled && (
                <AlertMessage
                    titleKey="app.developers.api_keys.alert.created.title"
                    descriptionKey="app.developers.api_keys.alert.created.description"
                />
            )}
            <FormControl hidden={hasAPIKeyPlain}>
                <FormLabel htmlFor="id" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.id.label" />
                </FormLabel>
                <InputWithCopyToClipboard id="id" name="id" defaultValue={data?.id} {...propsInputCommon} />
            </FormControl>

            <FormControl hidden={!hasAPIKeyPlain}>
                <FormLabel htmlFor="apiKeyPlain" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.apiKey.label" />
                </FormLabel>
                <InputWithCopyToClipboard
                    className="fs-exclude"
                    id="apiKeyPlain"
                    name="apiKeyPlain"
                    defaultValue={apiKeyInPlain}
                    {...propsInputCommon}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor="created_at" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.created_at.label" />
                </FormLabel>
                <InputWithCustomComponent id="created_at" name="created_at" {...propsInputCommon}>
                    <FormattedDateTime value={data?.created_at} />
                </InputWithCustomComponent>
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="name" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.name.label" />
                </FormLabel>
                <Input id="name" name="name" defaultValue={data?.name} {...propsInputCommon} />
            </FormControl>

            <FormControl hidden={hasAPIKeyPlain}>
                <FormLabel htmlFor="last_4_chars" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.apiKey.label" />
                </FormLabel>
                <Input id="last_4_chars" name="last_4_chars" defaultValue={last4Chars} {...propsInputCommon} />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="permission" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.permission.label" />
                </FormLabel>
                <Input
                    id="permission"
                    name="permission"
                    defaultValue={intl.formatMessage({
                        id: `app.developers.api_keys.form.permissions.${data?.permission}.label`
                    })}
                    {...propsInputCommon}
                />
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="status" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.status.label" />
                </FormLabel>
                <InputWithCustomComponent id="status" name="status" {...propsInputCommon}>
                    <StatusLabel value={isDisabled ? 'disabled' : 'enabled'} />
                </InputWithCustomComponent>
            </FormControl>

            <FormControl>
                <FormLabel htmlFor="disabled_at" variant="readonly">
                    <FormattedMessage id="app.developers.api_keys.form.disabled_at.label" />
                </FormLabel>
                <InputWithCustomComponent id="disabled_at" name="disabled_at" {...propsInputCommon}>
                    <FormattedDateTime value={data?.disabled_at} />
                </InputWithCustomComponent>
            </FormControl>
        </FormContainer>
    )
}
