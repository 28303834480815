import { useEffect, useMemo } from 'react'
import { counterpartyAccountFactory } from '@/services'
import { useFormikContext } from 'formik'
import { PaymentOrder } from '@webapps/numeral-ui-core'

/**
 * @description
 * As the two fields are mutually exclusive,
 * We need to reset `receiving_account_id` or `receiving_account` when:
 * - `useExistingCounterparty` flag is unset;
 * - `direct_debit_mandate_id` value is set;
 */
export const PaymentOrderReceivingAccountSectionAutoReset: React.FC<{ useExistingCounterparty: boolean }> = ({
    useExistingCounterparty
}) => {
    const { setValues, getFieldProps } = useFormikContext<Partial<PaymentOrder>>()
    const { value: directDebitMandateId } = getFieldProps('direct_debit_mandate_id')
    const hasDirectDebitMandateId = useMemo(() => globalThis.Boolean(directDebitMandateId), [directDebitMandateId])

    useEffect(() => {
        setValues((values) => {
            const result = {
                ...values,
                receiving_account_id: undefined,
                receiving_account: undefined
            }

            switch (true) {
                case hasDirectDebitMandateId: {
                    return result
                }

                case !useExistingCounterparty: {
                    return {
                        ...result,
                        receiving_account: counterpartyAccountFactory()
                    }
                }

                default: {
                    return result
                }
            }
        })
    }, [useExistingCounterparty, hasDirectDebitMandateId, setValues])

    return null
}
