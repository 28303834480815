import { If } from '@/components/@misc'
import { useAuth } from '@/hooks'
import { isProductionUserAccessEnv } from '@/services'
import { Box } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MainNavigationDivider, MainNavigationSectionListItem } from '../'
import { MainNavigationFooterCollapseItem, MainNavigationProcessPayments } from './@components'
import { MainNavigationFooterProps } from './MainNavigationFooter.types'

export const MainNavigationFooter: React.FC<MainNavigationFooterProps> = (props) => {
    const { selectedUserAccess } = useAuth()
    const { className, isCollapsed, routes, onClick } = props
    const isProductionEntity = useMemo(() => {
        return isProductionUserAccessEnv(selectedUserAccess)
    }, [selectedUserAccess])

    return (
        <Box className={className}>
            <MainNavigationDivider isCollapsed={isCollapsed} marginY="8px" />
            <If condition={!isProductionEntity && !isCollapsed}>
                <MainNavigationProcessPayments />
            </If>
            {routes.map((route, index) => {
                const onClickNavigationItem = () => onClick(route.path)
                return (
                    <MainNavigationSectionListItem
                        isCollapsed={isCollapsed}
                        route={route}
                        onClick={onClickNavigationItem}
                        key={index}
                    />
                )
            })}
            <MainNavigationFooterCollapseItem {...props} />
        </Box>
    )
}
