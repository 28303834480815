import { IndeterminateCheckBoxIcon } from '@/components/@icons'
import { Button, ButtonProps, Fade, Spinner, Tag, TagLabel } from '@chakra-ui/react'
import { noop } from 'lodash'
import { ReactElement, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { countActiveGroupByValues } from './TableHeaderGroupBy.utils'

interface TableHeaderGroupByButtonProps {
    value?: string[]
    isActive?: boolean
    isVisible?: boolean
    isLoading?: boolean
    isDisabled?: boolean

    onToggle?(): void
}

export const TableHeaderGroupByButton: React.FC<TableHeaderGroupByButtonProps> = ({
    value,
    isActive,
    isVisible,
    isDisabled,
    onToggle = noop,
    isLoading
}) => {
    const intl = useIntl()
    const activeItems = useMemo(() => {
        return countActiveGroupByValues(value)
    }, [value])

    const buttonLabel = useMemo<string>(() => {
        if (isActive) {
            return intl.formatMessage({
                id: 'app.table.header.group_by.actions.toggle_on_with_values.label'
            })
        }

        return intl.formatMessage({ id: 'app.table.header.group_by.actions.toggle_on.label' })
    }, [isActive, intl])

    const iconNumberOfActiveItems = useMemo<ReactElement | undefined>(() => {
        if (!isActive) {
            return
        }
        return (
            <Fade in={true}>
                <Tag backgroundColor="numeralAccent.500" color="white" borderRadius="full">
                    <TagLabel>{activeItems}</TagLabel>
                </Tag>
            </Fade>
        )
    }, [isActive, activeItems])

    const toggleButtonProps = useMemo<ButtonProps>(() => {
        const color = isActive ? undefined : 'gray.700'
        const leftIcon =
            isVisible && isLoading ? <Spinner color={color} size="sm" /> : <IndeterminateCheckBoxIcon color={color} />

        return {
            colorScheme: isActive ? undefined : 'gray',
            variant: isActive ? 'solidAlternative' : 'outline',
            color,
            leftIcon
        }
    }, [isActive, isVisible, isLoading])

    if (isDisabled) {
        return null
    }

    return (
        <Button
            {...toggleButtonProps}
            paddingX="16px"
            minWidth="min-content"
            onClick={onToggle}
            rightIcon={iconNumberOfActiveItems}
            aria-label={buttonLabel}>
            {buttonLabel}
        </Button>
    )
}
