import { TableCellAmount, TableCellTextIntl, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { DetailPageLink } from '@/components/@misc'
import { isReconcilablePaymentObjectType } from '@/services'
import { Payment } from '@/types'
import { Reconciliation, ReconciliationsServiceFindAllQueryOptions, Transaction } from '@webapps/numeral-ui-core'
import { ReactNode } from 'react'
import { IntlShape } from 'react-intl'
import { ReconciliationPaymentType, RelatedTransactionLink } from './@components'
import { RECONCILIATIONS_TABLE_COLUMN_CUSTOMIZERS } from './Reconciliations.page.const'

export function getActiveOnlyReconciliationQueryOption<T = Partial<ReconciliationsServiceFindAllQueryOptions>>(
    query: T
): T {
    return {
        ...query,
        canceled: 'false'
    }
}

export function getReconciliationQueryParamsForAnEntityType(
    query: Partial<ReconciliationsServiceFindAllQueryOptions>,
    entity?: Payment | Transaction
): Partial<ReconciliationsServiceFindAllQueryOptions> {
    if (isReconcilablePaymentObjectType(entity)) {
        return {
            payment_type: query?.payment_type,
            payment_id: query?.payment_id,
            transaction_id: undefined
        }
    }

    return {
        transaction_id: query?.transaction_id
    }
}

export function getReconciliationTableColumnCustomizers(
    parentEntity?: Payment | Transaction
): TableColumnCustomizers<Reconciliation> {
    const customizers: TableColumnCustomizers<Reconciliation> = Object.create(null)

    if (isReconcilablePaymentObjectType(parentEntity)) {
        customizers.transaction_id = {
            cell(info) {
                return <RelatedTransactionLink value={info.getValue()} />
            }
        }
    } else {
        customizers.payment_id = {
            cell(info) {
                const { payment_type } = info.row.original
                return (
                    <DetailPageLink
                        objectId={info.getValue() as string}
                        objectType={payment_type}
                        customLabelMessageKey="app.table.cell.payment_id"
                    />
                )
            }
        }

        customizers.payment_type = {
            cell(info) {
                return <ReconciliationPaymentType value={info.getValue()} />
            }
        }
    }

    return {
        ...RECONCILIATIONS_TABLE_COLUMN_CUSTOMIZERS,
        ...customizers,
        amount: {
            header() {
                return <TableCellTextIntl messageId="app.table.header.reconciled_amount" />
            },
            cell(info) {
                return <TableCellAmount amount={info.getValue()} currency={parentEntity?.currency} />
            }
        }
    }
}

export function getReconciliationsContentButtonLabel(intl: IntlShape, entity?: Payment | Transaction): ReactNode {
    const messageId = isReconcilablePaymentObjectType(entity)
        ? 'app.reconciliations.none.content.actions.reconcile_payment.label'
        : 'app.reconciliations.none.content.actions.reconcile_transaction.label'
    const formattedObjectType = intl.formatMessage({ id: `api.object.${entity?.object}` }).toLowerCase()

    return intl.formatMessage(
        {
            id: messageId
        },
        {
            objectType: formattedObjectType
        }
    )
}

export function getReconciliationsContentNoneMessage(intl: IntlShape, entity?: Payment | Transaction): ReactNode {
    const messageId = isReconcilablePaymentObjectType(entity)
        ? 'app.payments.tabs.reconciliations.none.content'
        : 'app.accounts.tabs.reconciliations.none.content'
    const formattedObjectType = intl.formatMessage({ id: `api.object.${entity?.object}` }).toLowerCase()

    return intl.formatMessage(
        {
            id: messageId
        },
        {
            objectType: formattedObjectType
        }
    )
}

export function getReconciliationsContentNoneTitle(intl: IntlShape, entity?: Payment | Transaction): ReactNode {
    return intl.formatMessage({
        id: isReconcilablePaymentObjectType(entity)
            ? 'app.payments.tabs.reconciliations.empty_content.title'
            : 'app.accounts.tabs.reconciliations.empty_content.title'
    })
}

export function getReconciliationsTableStateByEntityType(
    entity?: Payment | Transaction
): TableStateWithEntity<Reconciliation> {
    if (isReconcilablePaymentObjectType(entity)) {
        return {
            columnOrder: ['created_at', 'amount', 'match_type', 'reconciliation_rule_id', 'transaction_id']
        }
    }

    return {
        columnOrder: ['created_at', 'amount', 'match_type', 'payment_type', 'reconciliation_rule_id', 'payment_id']
    }
}
