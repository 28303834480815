import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { Role, UserRole } from '@/services'
import { chain, isEmpty } from 'lodash'
import { UserRoleSchema } from '@/services/Users/Users.schema'
import { SafeParseReturnType } from 'zod'
import { asOptionalField } from '@/utils/@validators/validators.utils'
import { Uuid } from '@webapps/numeral-ui-core'

export function userRolesValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    return (value: UserRole[]): string | void => {
        const roleValue = chain(value).head().value()
        let validationResult: SafeParseReturnType<UserRole, any>

        if (isRequired) {
            validationResult = UserRoleSchema.safeParse(roleValue)
        } else {
            validationResult = asOptionalField(UserRoleSchema).safeParse(roleValue)
        }

        if (isRequired && isEmpty(roleValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult) {
            return intl.formatMessage({ id: 'app.common.form.input.role.validation.invalid' })
        }
    }
}

export function pickUserRoleAsArray(data: Role[], roleId: Uuid): UserRole[] {
    return chain(data)
        .find((role: Role) => role?.id === roleId)
        .pick('id', 'name')
        .concat()
        .value()
}
