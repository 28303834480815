import { useAuth } from '@/hooks'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    UseDisclosureProps
} from '@chakra-ui/react'
import { noop } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

interface LogoutModalProps extends UseDisclosureProps {
    isOpen: boolean
    onClose(): void
}

export const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, onClose = noop }) => {
    const intl = useIntl()
    const cancelRef = useRef(null)
    const [loading, setLoading] = useState<boolean>(false)
    const onCloseWithLoading = useCallback(() => {
        if (loading) {
            return
        }

        onClose()
    }, [loading, onClose])

    const { onLogout } = useAuth()
    const onClickLogout = useCallback(() => {
        setLoading(true)
        onLogout()
    }, [onLogout])

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {intl.formatMessage({
                            id: 'app.common.actions.logout'
                        })}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {intl.formatMessage({
                            id: 'app.nav.logout.dialog.content'
                        })}
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button variant="secondary" ref={cancelRef} onClick={onCloseWithLoading} disabled={loading}>
                            {intl.formatMessage({
                                id: 'app.common.form.actions.cancel.label'
                            })}
                        </Button>
                        <Button onClick={onClickLogout} isLoading={loading}>
                            {intl.formatMessage({
                                id: 'app.common.actions.logout'
                            })}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
