import { Select } from '@/components/@misc'
import { SPACE_SYMBOL } from '@/constants'
import { useCustomFormikField, useQueryFindAllRoles } from '@/hooks'
import { Role, UserRole } from '@/services'
import { FormikInput } from '@/types'
import { queryDataAggregation } from '@/utils'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Uuid } from '@webapps/numeral-ui-core'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { pickUserRoleAsArray, userRolesValidator } from './UserRolesInput.utils'

interface UserRolesInputProps extends FormikInput {
    customPlaceholderKey?: string
}

export const UserRolesInput: React.FC<UserRolesInputProps> = ({
    name,
    value,
    customPlaceholderKey,
    isRequired = false,
    isDisabled = false,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<UserRole[]>({
        name,
        value,
        validate: userRolesValidator(intl, isRequired)
    })
    const query = useQueryFindAllRoles()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const placeholder = useMemo(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.${name}.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL })
    }, [intl, name, customPlaceholderKey])
    const onChange = useCallback(
        (value: Uuid) => {
            const userRole = pickUserRoleAsArray(data, value)

            if (!userRole) {
                return
            }

            helpers.setValue(userRole, true)
        },
        [helpers, data]
    )
    return (
        <FormControl key={name} isInvalid={meta.touched && !!meta.error}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<Role>
                {...inputProps}
                {...field}
                value={value}
                id={name}
                placeholder={placeholder}
                isLoading={query.isLoading}
                isDisabled={isDisabled}
                isRequired={isRequired}
                options={data}
                onChange={onChange}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
