import { Form, FormPageFooter, FormSection } from '@/components'
import { AccountDetailsForm, OptionalAccountHolderAddressForm } from '@/components/@forms'
import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { CustomFieldsSection } from '@/pages'
import { ApiObjectTypeSchema, CounterpartyAccount, CounterpartyAccountTypeSchema } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { CounterpartyAccountCompanyRegistrationNumberTypeInput, CounterpartyAccountTypeInput } from './@components'
import { CounterpartyAccountFormProps } from './CounterpartyAccount.form.types'
import { getCounterpartyAccountFormInitialValues } from './CounterpartyAccount.form.utils'

export const CounterpartyAccountForm: React.FC<CounterpartyAccountFormProps> = ({
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useMemo<CounterpartyAccount>(getCounterpartyAccountFormInitialValues, [])

    return (
        <Form<CounterpartyAccount> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const isLegalEntityAccount = values.type === CounterpartyAccountTypeSchema.enum.legal_entity
                const hasCompanyRegistrationNumberType = globalThis.Boolean(values.company_registration_number_type)

                return (
                    <>
                        <FormSection titleId="Account holder">
                            <CounterpartyAccountTypeInput
                                name="type"
                                customLabelKey="app.counterparties.counterparty_accounts.create.form.inputs.type.label"
                                customPlaceholderKey="app.counterparties.counterparty_accounts.create.form.inputs.type.placeholder"
                                isDisabled={disabled}
                            />
                            <TextInput
                                name="name"
                                customPlaceholderKey="app.counterparties.counterparty_accounts.create.form.inputs.name.placeholder"
                                isDisabled={disabled}
                                data-testid="name"
                            />
                            <If condition={isLegalEntityAccount}>
                                <CounterpartyAccountCompanyRegistrationNumberTypeInput
                                    name="company_registration_number_type"
                                    customLabelKey="app.counterparties.counterparty_accounts.create.form.inputs.company_registration_number_type.label"
                                    customPlaceholderKey="app.counterparties.counterparty_accounts.create.form.inputs.company_registration_number_type.placeholder"
                                    isDisabled={disabled}
                                    isRequired={true}
                                />
                                <TextInput
                                    name="company_registration_number"
                                    customLabelKey="app.counterparties.counterparty_accounts.create.form.inputs.company_registration_number.label"
                                    customPlaceholderKey="app.counterparties.counterparty_accounts.create.form.inputs.company_registration_number.placeholder"
                                    isDisabled={!hasCompanyRegistrationNumberType || disabled}
                                    isRequired={true}
                                />
                            </If>
                        </FormSection>
                        <FormSection titleId="Account details">
                            <AccountDetailsForm isLookupAvailable={true} isDisabled={disabled} isRequired={true} />
                        </FormSection>

                        <OptionalAccountHolderAddressForm isDisabled={disabled} />
                        <CustomFieldsSection<CounterpartyAccount>
                            objectType={ApiObjectTypeSchema.enum.counterparty_account}
                            isDisabled={disabled}
                            isExpanded={false}
                        />

                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.counterparties.counterparty_accounts.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
