import {
    BalancesService as PublicBalancesService,
    extractResponseData,
    ServicesConfiguration
} from '@webapps/numeral-ui-core'
import { Group } from '@/types'
import { AggregateAllBalancesQueryOptions, BalanceExpanded, BalanceGroup } from './Balances.service.types'

export class BalancesService extends PublicBalancesService {
    async aggregateAll(
        params?: AggregateAllBalancesQueryOptions,
        config?: ServicesConfiguration
    ): Promise<Group<BalanceExpanded, BalanceGroup>> {
        return this.httpClient
            .get<Group<BalanceExpanded, BalanceGroup>>(`${super.url}/aggregate`, {
                params,
                ...config
            })
            .then(extractResponseData)
    }
}
