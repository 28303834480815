import { Icon, IconProps } from '@chakra-ui/react'

export const SuccessIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" data-testid="success-icon" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99984 18.3337C5.39734 18.3337 1.6665 14.6028 1.6665 10.0003C1.6665 5.39783 5.39734 1.66699 9.99984 1.66699C14.6023 1.66699 18.3332 5.39783 18.3332 10.0003C18.3332 14.6028 14.6023 18.3337 9.99984 18.3337ZM9.169 13.3337L15.0607 7.44116L13.8823 6.26283L9.169 10.977L6.8115 8.61949L5.63317 9.79783L9.169 13.3337Z"
        />
    </Icon>
)
