import { useGoogleTagManager } from '@/hooks'
import { LocaleSwitcherProvider } from '@/providers'
import * as Sentry from '@sentry/react'
import { AppWithProviders } from './@components'

export const App = Sentry.withProfiler(() => {
    useGoogleTagManager()

    return (
        <LocaleSwitcherProvider>
            <AppWithProviders />
        </LocaleSwitcherProvider>
    )
})
