import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { PageContext } from './PageProvider.context'
import { isEmptyRecord } from '@/utils'
import { chain } from 'lodash'

export const PageProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [context, setContext] = useState<Partial<Record<string, any>> | undefined>(undefined)
    const setNonPersistedState = useCallback((value: typeof context) => {
        setContext((prevState) => ({
            ...prevState,
            ...value
        }))
    }, [])
    const getNonPersistedState = useCallback((key: keyof typeof context) => chain(context).get(key).value(), [context])
    const resetNonPersistedState = useCallback(() => setContext(undefined), [])
    const hasActiveContext = useMemo(() => !isEmptyRecord(context), [context])
    const value = {
        context,
        hasActiveContext,
        setNonPersistedState,
        getNonPersistedState,
        resetNonPersistedState
    }

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
