import { Flex, VStack } from '@chakra-ui/react'
import { Direction, PaymentRetryRuleRetries } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getPaymentOrderTypeTag, getWaitDurationTag } from './PaymentRetryRuleDetailsRetries.utils'

type PaymentRetryRuleDetailsRetriesProps = {
    direction: Direction
    retries: PaymentRetryRuleRetries[]
}

export const PaymentRetryRuleDetailsRetries: React.FC<PaymentRetryRuleDetailsRetriesProps> = memo(
    ({ direction, retries }) => {
        return (
            <VStack spacing="8px" alignItems="flex-start">
                {retries.map((retry, index) => {
                    return (
                        <Flex key={index} alignItems="center" gap="4px">
                            <FormattedMessage
                                id="app.settings.payment_retry_rules.retry"
                                values={{
                                    retryNumber: index + 1,
                                    retryAfter: getWaitDurationTag(retry.retry_after),
                                    retryPaymentType: getPaymentOrderTypeTag(direction, retry.retry_to_payment_type)
                                }}
                            />
                        </Flex>
                    )
                })}
            </VStack>
        )
    }
)
