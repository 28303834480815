import { TABLE_SCROLL_TO_TOP_THRESHOLD_IN_PX } from './TableScrollToTop.const'

export function isTableScrollToTopVisible(): boolean {
    return globalThis.window.scrollY >= TABLE_SCROLL_TO_TOP_THRESHOLD_IN_PX
}

export function scrollToTop() {
    globalThis.window.scroll({
        top: 0,
        behavior: 'smooth'
    })
}
