import { DetailPageLink, LinkWithFilterBy, LinkWithFiltersProps } from '@/components/@misc'
import { useNavigationRoutes } from '@/hooks'
import { Flex } from '@chakra-ui/react'
import {
    ApiObjectTypeSchema,
    DirectDebitMandate,
    DirectDebitMandateDirectionSchema,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { useMemo } from 'react'

interface DirectDebitMandatesDetailsRelatedObjectsProps {
    directDebitMandate: DirectDebitMandate
}

export const DirectDebitMandatesDetailsRelatedObjects: React.FC<DirectDebitMandatesDetailsRelatedObjectsProps> = ({
    directDebitMandate
}) => {
    const { paths } = useNavigationRoutes()
    const isDirectionOutgoing = useMemo<boolean>(() => {
        return directDebitMandate?.direction === DirectDebitMandateDirectionSchema.enum.outgoing
    }, [directDebitMandate])
    const isDirectionIncoming = useMemo<boolean>(() => {
        return directDebitMandate?.direction === DirectDebitMandateDirectionSchema.enum.incoming
    }, [directDebitMandate])

    const propsRelatedObjects = useMemo<
        LinkWithFiltersProps<IncomingPaymentsServiceFindAllQueryOptions & PaymentOrdersServiceFindAllQueryOptions>
    >(() => {
        const type = isDirectionOutgoing
            ? ApiObjectTypeSchema.enum.payment_order
            : isDirectionIncoming
              ? ApiObjectTypeSchema.enum.incoming_payment
              : ''
        const path = isDirectionOutgoing
            ? paths.PAYMENTS.PAYMENT_ORDERS
            : isDirectionIncoming
              ? paths.PAYMENTS.INCOMING_PAYMENTS
              : paths.NOT_FOUND

        return {
            labelKey: `app.counterparties.direct_debit_mandates.details.related_objects.${type}.label`,
            path,
            filterBy: {
                direct_debit_mandate_id: directDebitMandate?.id
            }
        }
    }, [directDebitMandate, paths])

    return (
        <Flex direction="column" gap="8px">
            <DetailPageLink
                objectId={directDebitMandate.receiving_account_id}
                objectType={ApiObjectTypeSchema.enum.counterparty_account}
            />
            {isDirectionOutgoing && (
                <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions> {...propsRelatedObjects} />
            )}

            {isDirectionIncoming && (
                <LinkWithFilterBy<IncomingPaymentsServiceFindAllQueryOptions> {...propsRelatedObjects} />
            )}
        </Flex>
    )
}
