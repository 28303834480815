import { useConfiguration } from '@/hooks'
import { sanitizeDataTransform } from '@/services/@transformRequests'
import { PropsWithChildren, useMemo } from 'react'
import { useAddServiceInterceptors } from './@hooks'
import { ServicesContext } from './ServicesProvider.context'
import { ServicesContextValue } from './ServicesProvider.types'
import { getBFFServiceInstances, getPublicAPIServiceInstances } from './ServicesProvider.utils'

export const ServicesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { apiEnvironment } = useConfiguration()
    const services = useMemo<ServicesContextValue>(() => {
        const serviceConfiguration = {
            baseURL: apiEnvironment.active.url,
            transformRequest: [sanitizeDataTransform<any>]
        }

        return {
            ...getBFFServiceInstances(serviceConfiguration),
            ...getPublicAPIServiceInstances(serviceConfiguration)
        }
    }, [apiEnvironment])
    const servicesWithoutInterceptors: (keyof ServicesContextValue)[] = ['authenticationService']

    useAddServiceInterceptors(services, ...servicesWithoutInterceptors)

    return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>
}
