import { useEffect, useMemo } from 'react'
import { QueryParamTypes, usePage, usePrevious, UseQueryParamsResult } from '@/hooks'
import { chain, isNull, noop } from 'lodash'
import { Uuid } from '@webapps/numeral-ui-core'
import { isEmptyRecord } from '@/utils'
import { RECONCILE_ENTITY_PAGE_CONTEXT } from '@/pages'

interface useBulkActionsResetAllSelectionsProps<T> {
    search: UseQueryParamsResult<QueryParamTypes.Search>
    filterBy: UseQueryParamsResult<QueryParamTypes.FilterBy, T>

    onChangeSelection(value?: Set<Uuid>): void
    onChangeSelectAllQueryEnabled(value?: boolean): void
}

/**
 * @description
 * Documented special cases for implicit auto reset (including persisted selections):
 * 1. Page query search parameters changes (`filterBy`, `search`, etc.);
 * 2. Page `context` value changes from `undefined` to any value;
 * 3. Page `context` value changes from any value to `undefined`;
 * 4. Page `context` RECONCILE_ENTITY_PAGE_CONTEXT is set to `null`;
 */
export function useBulkActionsAutoReset<T>({
    search,
    filterBy,
    onChangeSelection = noop,
    onChangeSelectAllQueryEnabled = noop
}: useBulkActionsResetAllSelectionsProps<T>) {
    const { context, getNonPersistedState, resetNonPersistedState } = usePage()
    const previousContext = usePrevious(context)
    const previousQueryParams = usePrevious({
        ...search?.parsedState,
        ...filterBy?.parsedState
    })
    const hasDifferentQueryParams = useMemo(() => {
        return !chain(previousQueryParams)
            .isEqual({ ...search?.parsedState, ...filterBy?.parsedState })
            .value()
    }, [previousQueryParams, search, filterBy])

    const hasPreviousQueryParams = useMemo(() => {
        return !chain(previousQueryParams).isEmpty().value()
    }, [previousQueryParams, search, filterBy])

    useEffect(() => {
        const currentReconcileContext = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
        const hasNullCurrentReconcileContext = isNull(currentReconcileContext)

        const hasEmptyPreviousPageContext = isEmptyRecord(previousContext)
        const hasEmptyCurrentPageContext = isEmptyRecord(context)

        switch (true) {
            // 1. Page query search parameters changes (`filterBy`, `search`, etc.);
            // 2. Page `context` value changes from `undefined` to any value;
            // 3. Page `context` value changes from any value to `undefined`;
            case hasPreviousQueryParams && hasDifferentQueryParams:
            case hasEmptyPreviousPageContext && !hasEmptyCurrentPageContext:
            case !hasEmptyPreviousPageContext && hasEmptyCurrentPageContext: {
                onChangeSelection?.(undefined)
                onChangeSelectAllQueryEnabled?.(false)
                break
            }

            // 4. Page `context` RECONCILE_ENTITY_PAGE_CONTEXT is set to `null`;
            case hasNullCurrentReconcileContext: {
                onChangeSelection?.(undefined)
                resetNonPersistedState()
                break
            }
        }
    }, [hasPreviousQueryParams, hasDifferentQueryParams, previousContext, context])
}
