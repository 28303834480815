import React, { useMemo } from 'react'
import { CustomField, CustomFieldValue } from '@webapps/numeral-ui-core'
import { chain, isString } from 'lodash'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

export interface EntityDetailCellCustomFieldValuesProps {
    data?: CustomField[]
    value?: Record<string, string>
}

export const EntityDetailCellCustomFieldValues: React.FC<EntityDetailCellCustomFieldValuesProps> = ({
    data,
    value
}) => {
    const transformedValue = useMemo(() => {
        return chain(value)
            .keys()
            .map((key) => {
                const customFieldValue = value?.[key]
                const keyFilter = (item: CustomField) => item?.key === key
                const valuesMapper = (item: CustomField) => item?.values
                const keyFinder = (item: CustomFieldValue) => item?.key === customFieldValue

                return chain(data)
                    .filter(keyFilter)
                    .flatMap(valuesMapper)
                    .find(keyFinder)
                    .defaultTo(customFieldValue)
                    .value()
            })
            .value()
    }, [data, value])

    return (
        <>
            {transformedValue.map((item, index) => {
                const value = isString(item) ? item : item?.name

                return (
                    <tr key={index}>
                        <td style={{ padding: '8px 0' }} title={value}>
                            {value || EMPTY_VALUE_PLACEHOLDER}
                        </td>
                    </tr>
                )
            })}
        </>
    )
}
