import { useIntl } from 'react-intl'
import { useNavigationRoutes } from '@/hooks'
import { useCallback, useMemo, useState } from 'react'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { StickyFooter } from '@/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Inquiry } from '@webapps/numeral-ui-core'
import { canDenyInquiry } from '@/pages/Payments/Inquiries/Inquiries.page.utils'
import { updateUUIDRelativeActionPathname } from '@/providers'

interface InquiryDenialProps {
    data?: Inquiry
}

export const InquiryDenial: React.FC<InquiryDenialProps> = ({ data }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const isVisible = useMemo<boolean>(() => canDenyInquiry(data), [data])
    const onDeny = useCallback(() => {
        const { PAYMENTS } = relativePaths
        const path = updateUUIDRelativeActionPathname(location.pathname, PAYMENTS.DENY)

        navigate(path)
    }, [location, relativePaths, navigate])
    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="InquiryApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                {intl.formatMessage({
                                    id: 'app.payments.inquiries.denial.title'
                                })}
                            </Text>
                            <Text>
                                {intl.formatMessage({
                                    id: 'app.payments.inquiries.denial.description'
                                })}
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button onClick={onDeny} variant="solid">
                                {intl.formatMessage({
                                    id: 'app.payments.inquiries.denial.actions.deny.label'
                                })}
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
