import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdBalance } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Balance } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { BalancesCustomColumns } from '../Balances.page.types'
import { BALANCE_DETAILS_ROWS, BALANCE_ENTITY_DETAIL_ROW_CUSTOMIZERS } from './BalanceDetails.page.const'

export const BalanceDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const query = useQueryFindByIdBalance(uuid)

    return (
        <PageLayout className="Balance">
            <RestrictedSection feature={ENTITY_FEATURE.BALANCE} permission={PERMISSION.ACCOUNTS_VIEW_BALANCES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError: {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.error.title'
                                    })}
                                />
                            )
                        }

                        case isEmpty(query?.data): {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.accounts.balances.empty_content.title'
                                    })}
                                    content={intl.formatMessage({
                                        id: 'app.accounts.balances.none.content'
                                    })}
                                />
                            )
                        }

                        default: {
                            return (
                                <EntityDetail<Balance, BalancesCustomColumns>
                                    data={query.data}
                                    rows={BALANCE_DETAILS_ROWS}
                                    rowCustomizers={BALANCE_ENTITY_DETAIL_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
