import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, useQueryFindAllVirtualAccounts } from '@/hooks'
import { PageLayout } from '@/layouts'
import { VIRTUAL_ACCOUNT_DOCUMENTATION_LINK } from '@/pages/Counterparties/VirtualAccounts/VirtualAccounts.page.const'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { VirtualAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS,
    CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_STATIC_STATE
} from './ConnectedAccountVirtualAccounts.page.const'

export const ConnectedAccountVirtualAccountsPage = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { paths, relativePaths } = useNavigationRoutes()

    const query = useQueryFindAllVirtualAccounts({ connected_account_id: uuid })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps>(() => {
        const learnMoreLink = (
            <Link href={VIRTUAL_ACCOUNT_DOCUMENTATION_LINK} target="_blank">
                <FormattedMessage id="app.common.link.learn_more.label" />
            </Link>
        )

        return {
            title: intl.formatMessage({ id: 'app.accounts.connected_accounts.virtual_accounts.empty_content.title' }),
            content: intl.formatMessage(
                { id: 'app.accounts.connected_accounts.virtual_accounts.empty_content.content' },
                { learnMoreLink }
            )
        }
    }, [intl])

    const onTableRowClick = useTableRowNavigation<VirtualAccount>(
        'id',
        `${paths.COUNTERPARTIES.VIRTUAL_ACCOUNTS}`,
        true
    )

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError: {
                            return <EmptyContent />
                        }

                        case isDataEmpty: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<VirtualAccount>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    columnCustomizers={CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS}
                                    state={CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_STATIC_STATE}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
