import { Direction as DirectionType } from '@webapps/numeral-ui-core'
import { FormattedTextIntl } from '@/components/@misc'

interface DirectionProps {
    value: DirectionType
}

export const Direction: React.FC<DirectionProps> = ({ value }) => {
    return <FormattedTextIntl messageId={`api.commons.direction.${value}`} />
}
