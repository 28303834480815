import React from 'react'
import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { FormattedDateTime } from '@/components/@misc'
import { Code, Flex, Text } from '@chakra-ui/react'
import { SPACE_SYMBOL } from '@/constants'
import { CustomField, CustomFieldValue } from '@webapps/numeral-ui-core'
import { EntityDetailCellCustomFieldObjectTypes } from '@/pages'
import { FormattedCustomFieldType } from '../@components'

export const CUSTOM_FIELD_ENTITY_DETAIL_ROWS: EntityDetailRow<CustomField>[] = [
    'id',
    'created_at',
    'name',
    'key',
    'type',
    'object_types',
    'values'
]

export const CUSTOM_FIELD_ENTITY_DETAIL_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<CustomField> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    key: {
        cell(item) {
            return <Code colorScheme="gray">{item.key}</Code>
        }
    },
    object_types: {
        cell(item) {
            return <EntityDetailCellCustomFieldObjectTypes value={item.object_types} />
        }
    },
    type: {
        cell(item) {
            return <FormattedCustomFieldType customFieldType={item.type} />
        }
    },
    values: {
        nested: true,
        rows: ['0'],
        rowCustomizers: {
            '0': {
                cell(value: CustomFieldValue[]) {
                    return value.map(({ name, key }) => {
                        return (
                            <Flex key={key} paddingY="8px">
                                {name}
                                <Text whiteSpace="pre-wrap" color="gray.400">
                                    {SPACE_SYMBOL} (<Code colorScheme="gray">{key}</Code>)
                                </Text>
                            </Flex>
                        )
                    })
                }
            }
        }
    }
}
