import { Icon } from '@chakra-ui/react'

export const SearchIcon: React.FC<any> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
        <path
            fill="currentColor"
            d="M15.0262 13.8475L18.5953 17.4158L17.4162 18.595L13.8478 15.0258C12.5201 16.0901 10.8687 16.669 9.16699 16.6666C5.02699 16.6666 1.66699 13.3066 1.66699 9.16663C1.66699 5.02663 5.02699 1.66663 9.16699 1.66663C13.307 1.66663 16.667 5.02663 16.667 9.16663C16.6694 10.8683 16.0905 12.5197 15.0262 13.8475ZM13.3545 13.2291C14.4121 12.1415 15.0027 10.6837 15.0003 9.16663C15.0003 5.94329 12.3895 3.33329 9.16699 3.33329C5.94366 3.33329 3.33366 5.94329 3.33366 9.16663C3.33366 12.3891 5.94366 15 9.16699 15C10.684 15.0023 12.1419 14.4117 13.2295 13.3541L13.3545 13.2291V13.2291Z"
        />
    </Icon>
)
