export enum SIZE_UNIT {
    BYTE = 'B',
    KB = 'KB',
    MB = 'MB',
    GB = 'GB',
    TB = 'TB'
}

export const ORDERED_UNITS = [SIZE_UNIT.BYTE, SIZE_UNIT.KB, SIZE_UNIT.MB, SIZE_UNIT.GB, SIZE_UNIT.TB]

export const ORDERED_UNIT_STEP_MULTIPLIER = 1024
