import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import { useMutationAcceptByIdReturnRequest, useNavigationRoutes } from '@/hooks'
import { canAcceptOrDenyReturnRequestByStatus } from '@/pages/Payments/ReturnRequests/ReturnRequests.page.utils'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { ReturnRequest } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateUUIDRelativeActionPathname } from '@/providers'

interface ReturnRequestApprovalProps {
    data?: ReturnRequest
}

export function ReturnRequestApproval({ data }: ReturnRequestApprovalProps) {
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const mutationApprove = useMutationAcceptByIdReturnRequest(data?.id)
    const isVisible = useMemo<boolean>(() => canAcceptOrDenyReturnRequestByStatus(data?.status), [data])
    const onApprove = useCallback(() => {
        mutationApprove.mutate()
    }, [mutationApprove])
    const onCancel = useCallback(() => {
        const { PAYMENTS } = relativePaths
        const path = updateUUIDRelativeActionPathname(location.pathname, PAYMENTS.CANCEL)

        navigate(path)
    }, [relativePaths, navigate, location])
    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="ReturnRequestApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                {intl.formatMessage({ id: 'app.payments.return_requests.approval.title' })}
                            </Text>
                            <Text>
                                {intl.formatMessage({ id: 'app.payments.return_requests.approval.description' })}
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                colorScheme="gray"
                                onClick={onCancel}
                                disabled={mutationApprove.isPending}
                                variant="outline"
                                backgroundColor="white">
                                {intl.formatMessage({
                                    id: 'app.payments.return_requests.approval.actions.deny.label'
                                })}
                            </Button>
                            <Button
                                onClick={onApprove}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationApprove.isPending)}
                                disabled={mutationApprove.isPending}
                                variant="solid">
                                {intl.formatMessage({
                                    id: 'app.payments.return_requests.approval.actions.approve.label'
                                })}
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
