import { FilterInputs, TableHeaderForm } from '@/components'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { QUERY_PARAMS_RESERVED_NAMES, QueryParamStateFilterBy } from '@/hooks'
import { flattenDeep } from '@/utils'
import { chain, set } from 'lodash'
import { pickFilterByValues } from '../../TableHeader.utils'

export function countActiveFiltersValues<T>(filterState?: TableHeaderForm<T>) {
    const filters = flattenDeep(pickFilterByValues<T>(filterState))
    return chain(filters).values().filter(globalThis.Boolean).filter().size().value()
}

export function removeReservedWordsFilter(filterName: string) {
    return !Object.values(QUERY_PARAMS_RESERVED_NAMES).includes(filterName as QUERY_PARAMS_RESERVED_NAMES)
}

export function getFilterByInitialValues<T>(filters?: FilterInputs<T>): QueryParamStateFilterBy<T> {
    if (!filters) {
        return Object.create(null)
    }

    return Object.keys(filters)
        .filter(removeReservedWordsFilter)
        .reduce((acc, key) => {
            return set(acc, key, EMPTY_CHAR_SYMBOL)
        }, Object.create(null))
}
