import { UserInviteRequest } from '@/services'

export function getUserInviteFormInitialValues(): UserInviteRequest {
    return {
        email: '',
        role: ''
    }
}

export function transformUserInviteFormData(values: UserInviteRequest): UserInviteRequest {
    return {
        email: values?.email,
        role_ids: [values?.role as string]
    }
}
