import { FormContainer } from '@/components'
import { FormattedDateTime, ReadonlyField, StatusLabel } from '@/components/@misc'
import { getUserFirstRoleName, User, UserStatus } from '@/services'

interface UserDetailsContentProps {
    data?: User
}

export const UserDetailsContent: React.FC<UserDetailsContentProps> = ({ data }) => {
    return (
        <FormContainer>
            <ReadonlyField name="first_name" value={data?.first_name} />
            <ReadonlyField name="last_name" value={data?.last_name} />
            <ReadonlyField name="email" value={data?.email} />
            <ReadonlyField name="status" value={<StatusLabel value={data?.status as UserStatus} />} />
            <ReadonlyField name="roles" value={getUserFirstRoleName(data?.roles)} />
            <ReadonlyField name="position" value={data?.position} />
            <ReadonlyField name="department" value={data?.department} />
            <ReadonlyField name="created_at" value={<FormattedDateTime value={data?.created_at} />} />
            <ReadonlyField name="disabled_at" value={<FormattedDateTime value={data?.disabled_at} />} />
            <ReadonlyField name="last_login_date" value={<FormattedDateTime value={data?.last_login_date} />} />
        </FormContainer>
    )
}
