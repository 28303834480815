import { PageHeaderLayout, PageLayout } from '@/layouts'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { DebugEnvVariables, DebugIntl } from './@components'
import { FormContainer } from '@/components'

export const DebugPage: React.FC = () => {
    const intl = useIntl()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.debug.title' }), [intl])

    return (
        <PageLayout className="Debug">
            <PageHeaderLayout title={title} />
            <FormContainer>
                <DebugEnvVariables />
                <DebugIntl />
            </FormContainer>
        </PageLayout>
    )
}
