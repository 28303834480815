import { useBulkActionsPluralization } from '@/providers'
import { Box, Text } from '@chakra-ui/react'
import { chain } from 'lodash'
import { useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { BulkActionsFooterProps } from '../BulkActionsFooter.types'

export const BulkActionsFooterDefault = <T,>({ targetObject, selection }: BulkActionsFooterProps<T>) => {
    const selectionSize = useMemo(() => chain(selection).get('size', 0).value(), [selection])
    const { formattedObjectType } = useBulkActionsPluralization(targetObject, selectionSize)

    return (
        <Box fontWeight="bold" fontSize="lg">
            <FormattedNumber value={selectionSize} />
            <Text>{formattedObjectType}</Text>
        </Box>
    )
}
