import { useEffect, useRef } from 'react'
import { cloneDeep } from 'lodash'

export function usePrevious<T>(value?: T | null) {
    const ref = useRef<T | null>()

    useEffect(() => {
        ref.current = value ? cloneDeep(value) : undefined
    }, [value])

    return ref?.current
}
