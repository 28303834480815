import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { Duration, FormattedDate, PaymentRetryRuleName, PaymentType, StatusLabel } from '@/components/@misc'
import { FormattedMessage } from 'react-intl'
import { RetriedPaymentSummary } from './PaymentOrderRetries.page.type'

const PAYMENT_RETRY_SUMMARY_DETAIL_ROWS: EntityDetailRow<RetriedPaymentSummary>[] = [
    'retried_payment_id',
    'retried_payment_status',
    'retried_payment_return_reason_code',
    'retried_payment_date',
    'retried_payment_type'
]

export const ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS: EntityDetailRow<RetriedPaymentSummary>[] = [
    ...PAYMENT_RETRY_SUMMARY_DETAIL_ROWS,
    'payment_retry_rule_id'
]

export const NON_ORIGINAL_PAYMENT_RETRY_SUMMARY_DETAIL_ROWS: EntityDetailRow<RetriedPaymentSummary>[] = [
    ...PAYMENT_RETRY_SUMMARY_DETAIL_ROWS,
    'retried_after'
]

export const PAYMENT_RETRY_SUMMARY_DETAIL_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<RetriedPaymentSummary> = {
    retried_payment_id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.retried_payment_id} />
        }
    },
    retried_payment_status: {
        cell(item) {
            return <StatusLabel value={item.retried_payment_status} />
        }
    },
    retried_payment_date: {
        cell(item) {
            return <FormattedDate value={item.retried_payment_date} />
        }
    },
    retried_payment_type: {
        cell(item) {
            return (
                <PaymentType
                    type={item.retried_payment_type}
                    direction={item.retried_payment_direction}
                    isAbbreviated={true}
                />
            )
        }
    },
    payment_retry_rule_id: {
        cell(item) {
            return <PaymentRetryRuleName paymentRetryRuleId={item.payment_retry_rule_id} />
        }
    },
    retried_after: {
        label(item) {
            if (item.retried_payment_id) {
                return <FormattedMessage id="app.page.details.fields.retried_after.label" />
            } else {
                return <FormattedMessage id="app.page.details.fields.retry_after.label" />
            }
        },
        cell(item) {
            return <Duration duration={item.retried_after} />
        }
    }
}
