import { Select } from '@/components/@misc'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { anythingValidator } from '@/utils/@validators'
import {
    CounterpartyAccountCompanyRegistrationNumberType,
    CounterpartyAccountCompanyRegistrationNumberTypeSchema
} from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { useMemo } from 'react'

export const CounterpartyAccountCompanyRegistrationNumberTypeInput: React.FC<FormikInput> = ({
    name,
    value,
    customLabelKey,
    customPlaceholderKey,
    customPlaceholderValues,
    isRequired,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<CounterpartyAccountCompanyRegistrationNumberType>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const labelKey = useMemo(() => customLabelKey || `app.common.form.input.${name}.label`, [customLabelKey, name])
    const placeholder = useMemo(() => {
        if (customPlaceholderKey) {
            return intl.formatMessage({ id: customPlaceholderKey }, customPlaceholderValues)
        }
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [customPlaceholderKey, customPlaceholderValues, intl])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={labelKey} />
            </FormLabel>
            <Select<CounterpartyAccountCompanyRegistrationNumberType>
                {...inputProps}
                {...field}
                name={name}
                placeholder={placeholder}
                isInvalid={isInvalid}
                isRequired={isRequired}
                onChange={helpers.setValue}
                options={CounterpartyAccountCompanyRegistrationNumberTypeSchema.options}
                getOptionLabel={(value) => {
                    return intl.formatMessage({
                        id: `api.company_registration_number_type.${value}`,
                        defaultMessage: value
                    })
                }}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
