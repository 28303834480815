import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { z } from 'zod'

const MinValueSchema = z.union([z.string().min(1), z.array(z.any()).min(1)])

/**
 * @description
 * Pass through validator, used to validate required inputs that don't need any specific validation
 */
export function anythingValidator(
    intl: IntlShape,
    isRequired?: boolean,
    name?: string,
    values?: unknown
): FieldValidator {
    return (value: string): string | void => {
        if (isRequired && !MinValueSchema.safeParse(value).success) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }
    }
}
