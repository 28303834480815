import { defineStyleConfig } from '@chakra-ui/react'

export const FormLabel = defineStyleConfig({
    baseStyle: {
        color: 'gray.700',
        fontWeight: 'medium',
        marginBottom: '8px',
        marginRight: 0
    },
    variants: {
        readonly: {
            color: 'gray.500'
        }
    }
})
