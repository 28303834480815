import { memo } from 'react'
import { SELECT_OPTION_SEPARATOR } from '@/constants'

interface SelectOptionSeparatorProps {
    key?: string
}

export const SelectOptionSeparator: React.FC<SelectOptionSeparatorProps> = memo(({ key = 'option_separator' }) => {
    return (
        <option key={key} disabled={true}>
            {SELECT_OPTION_SEPARATOR}
        </option>
    )
})
