import {
    EmptyContent,
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    LoadingSpinner,
    RestrictedSection
} from '@/components'
import { useQueryFindByIdIncomingPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { IncomingPayment } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { IncomingPaymentDetailsCustomRows } from './IncomingPaymentDetails.types'
import { useMemo } from 'react'
import {
    geIncomingPaymentEntityDetailRows,
    getIncomingPaymentEntityDetailRowCustomizers
} from './IncomingPaymentDetails.page.utils'

export const IncomingPaymentDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdIncomingPayment(uuid)
    const rows = useMemo<EntityDetailRow<IncomingPayment, IncomingPaymentDetailsCustomRows>[]>(() => {
        return geIncomingPaymentEntityDetailRows(query.data)
    }, [query])
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<IncomingPayment>>(() => {
        return getIncomingPaymentEntityDetailRowCustomizers(query.data)
    }, [query])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<IncomingPayment, IncomingPaymentDetailsCustomRows>
                                    data={query.data}
                                    rows={rows}
                                    rowCustomizers={rowCustomizers}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
