import {
    FilterInputs,
    TableCellAccount,
    TableCellAmount,
    TableCellReconciliationStatus,
    TableCellShortDate,
    TableCellStatus,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AmountInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    IncomingPaymentStatusInput,
    PaymentDirectionInput,
    PaymentOrderTypeInput,
    ReconciliationStatusInput,
    TextInput
} from '@/components/@inputs'
import { PaymentType } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { QueryParamQuickFilters } from '@/hooks'
import { uuidValidator } from '@/utils/@validators'
import {
    IncomingPayment,
    IncomingPaymentStatusSchema,
    IncomingPaymentsServiceFindAllQueryOptions,
    ReconciliationStatusSchema
} from '@webapps/numeral-ui-core'

export const INCOMING_PAYMENT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/the-incoming-payment-object`

export const INCOMING_PAYMENT_TABLE_STATIC_STATE: TableStateWithEntity<IncomingPayment> = {
    columnOrder: Object.freeze([
        'created_at',
        'type',
        'amount',
        'originating_account',
        'receiving_account',
        'value_date',
        'status',
        'reconciliation_status'
    ])
}

export const INCOMING_PAYMENT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<IncomingPayment> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        cell(info) {
            const { direction } = info.row.original

            return <PaymentType type={info.getValue()} direction={direction} isAbbreviated={true} />
        }
    },
    amount: {
        minSize: MIN_COLUMN_WIDTH.AMOUNT,
        cell(info) {
            const { currency } = info.row.original
            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    originating_account: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NAME,
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    receiving_account: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NAME,
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    value_date: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <TableCellStatus value={info.getValue()} />
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <TableCellReconciliationStatus value={info.getValue()} />
        }
    }
}

export const INCOMING_PAYMENT_FILTERS: FilterInputs<IncomingPaymentsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    type: <PaymentOrderTypeInput name="type" />,
    direction: <PaymentDirectionInput name="direction" isPlaceholderDisplayed={true} />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    status: <IncomingPaymentStatusInput name="status" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    direct_debit_mandate_id: <TextInput name="direct_debit_mandate_id" validator={uuidValidator} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />
}

export const INCOMING_PAYMENT_QUICK_FILTERS: QueryParamQuickFilters<IncomingPaymentsServiceFindAllQueryOptions> = {
    status: [IncomingPaymentStatusSchema.enum.pending_confirmation, IncomingPaymentStatusSchema.enum.pending_return],
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}
