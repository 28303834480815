import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useMutationUpdateByIdWebhook, useNavigationRoutes, useQueryFindByIdWebhook, useToasts } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { WebhookCreateOrEditForm } from '../@components'

export const WebhookEditPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const { onAdd } = useToasts()
    const { paths } = useNavigationRoutes()

    const title = useRef(intl.formatMessage({ id: 'app.developers.webhooks.edit.title' })).current
    const query = useQueryFindByIdWebhook(uuid)
    const mutation = useMutationUpdateByIdWebhook(uuid, {
        onSuccess() {
            const path = [paths.DEVELOPERS.WEB_HOOKS, uuid].join('/')
            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.developers.webhooks.edit.success.toast.title" />,
                description: <FormattedMessage id="app.developers.webhooks.edit.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.developers.webhooks.edit.error.toast.title" />,
                description: <FormattedMessage id="app.developers.webhooks.edit.error.toast.description" />
            })
        }
    })

    return (
        <PageLayout className="WebhookEdit">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS}>
                <PageHeaderLayout title={title} />
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError: {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.error.title'
                                    })}
                                />
                            )
                        }
                        case isEmpty(query?.data): {
                            return (
                                <EmptyContent
                                    title={intl.formatMessage({
                                        id: 'app.common.not_found.title'
                                    })}
                                />
                            )
                        }

                        default: {
                            return (
                                <WebhookCreateOrEditForm
                                    data={query.data}
                                    onSubmit={mutation.mutate}
                                    isLoading={mutation.isPending}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
