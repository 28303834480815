import { useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { APIKey, APIKeysServiceFindAllQueryOptions, filterUserAccessQueryParams, PERMISSION } from '@/services'
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllAPIKeys(
    params?: Partial<APIKeysServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<APIKey>, Error>>
) {
    const { apiKeysService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.DEVELOPERS_VIEW_API_KEYS) && options?.enabled
    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<APIKey>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [apiKeysService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return apiKeysService.findAll({ ...paramsWithUserAccess, ...(pageParam as typeof params) }, { signal })
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
