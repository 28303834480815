import { Flex } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { stopEventPropagation } from '../Select.utils'

export const SelectMessageContainer: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Flex
            minHeight="90px"
            padding="8px"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            color="gray.500"
            onClick={stopEventPropagation}
            onMouseUp={stopEventPropagation}
            data-testid="select-message-container">
            {children}
        </Flex>
    )
}
