import {
    DirectionSchema,
    PaymentOrderTypeSchema,
    ServiceNameSchema,
    TreasuryOptionSchema
} from '@webapps/numeral-ui-core'

export const CONNECTED_ACCOUNT_SERVICE_NAME_TO_PAYMENT_PROPERTIES = Object.freeze({
    // FPS payment schema mappings:
    [ServiceNameSchema.enum.fps]: {
        type: PaymentOrderTypeSchema.enum.fps,
        direction: DirectionSchema.enum.credit
    },
    // BACS payment schema mappings:
    [ServiceNameSchema.enum.bacs]: {
        type: PaymentOrderTypeSchema.enum.bacs,
        direction: DirectionSchema.enum.credit
    },
    // CHAPS payment schema mappings:
    [ServiceNameSchema.enum.chaps]: {
        type: PaymentOrderTypeSchema.enum.chaps,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.bacs_direct_debit]: {
        type: PaymentOrderTypeSchema.enum.bacs,
        direction: DirectionSchema.enum.debit
    },
    [ServiceNameSchema.enum.bacs_direct_credit]: {
        type: PaymentOrderTypeSchema.enum.bacs,
        direction: DirectionSchema.enum.credit
    },
    // SEPA payment schema mappings:
    [ServiceNameSchema.enum.sdd]: {
        type: PaymentOrderTypeSchema.enum.sepa,
        direction: DirectionSchema.enum.debit
    },
    [ServiceNameSchema.enum.sct]: {
        type: PaymentOrderTypeSchema.enum.sepa,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.sct_inst]: {
        type: PaymentOrderTypeSchema.enum.sepa_instant,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.sct_inst_watch]: {
        type: PaymentOrderTypeSchema.enum.sepa_instant,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.sct_urgent]: {
        type: PaymentOrderTypeSchema.enum.sepa_urgent,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.target2]: {
        type: PaymentOrderTypeSchema.enum.target2,
        direction: DirectionSchema.enum.credit
    },
    // Treasury
    [ServiceNameSchema.enum.treasury_local]: {
        type: PaymentOrderTypeSchema.enum.treasury,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.treasury_international]: {
        type: PaymentOrderTypeSchema.enum.treasury,
        direction: DirectionSchema.enum.credit
    },
    [ServiceNameSchema.enum.treasury_relocated]: {
        type: PaymentOrderTypeSchema.enum.treasury,
        direction: DirectionSchema.enum.credit
    },
    // Confidentiality,
    [ServiceNameSchema.enum.sct_payroll]: {
        type: PaymentOrderTypeSchema.enum.sepa,
        direction: DirectionSchema.enum.credit
    },
    // Swift,
    [ServiceNameSchema.enum.swift]: {
        type: PaymentOrderTypeSchema.enum.swift,
        direction: DirectionSchema.enum.credit
    },
    // IcelandincEXP,
    [ServiceNameSchema.enum.local_iceland]: {
        type: PaymentOrderTypeSchema.enum.icelandic_exp,
        direction: DirectionSchema.enum.credit
    }
})

export const CONNECTED_ACCOUNT_SERVICE_NAME_TO_PAYMENT_TREASURY_OPTION = Object.freeze({
    [ServiceNameSchema.enum.treasury_local]: TreasuryOptionSchema.enum.local,
    [ServiceNameSchema.enum.treasury_international]: TreasuryOptionSchema.enum.international,
    [ServiceNameSchema.enum.treasury_relocated]: TreasuryOptionSchema.enum.relocated,
    [ServiceNameSchema.enum.treasury_safeguarding]: TreasuryOptionSchema.enum.safeguarding
})
