import { TableColumnCustomizers } from '@/components'
import { BalanceExpanded } from '@/services'
import { COLUMN_WIDTH_RATIO } from '@/constants'
import { BALANCE_TABLE_COLUMN_CUSTOMIZERS } from '../../Balances.page.const'
import { BalanceConnectedAccountName } from '../BalanceConnectedAccountName'

export const BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY: TableColumnCustomizers<BalanceExpanded> = {
    ...BALANCE_TABLE_COLUMN_CUSTOMIZERS,
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <BalanceConnectedAccountName balance={info.row.original} />
        }
    }
}
