import { defineStyleConfig } from '@chakra-ui/react'

export const Radio = defineStyleConfig({
    variants: {
        solidAlternative: {
            parts: 'control',
            control: {
                _checked: {
                    backgroundColor: 'numeralAccent.500',
                    borderColor: 'numeralAccent.500'
                }
            }
        }
    },
    defaultProps: { variant: 'solidAlternative' }
})
