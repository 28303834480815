import {
    QueryParamConfigurationFilterBy,
    QueryParamStateFilterBy,
    QueryParamTypes,
    UseQueryParamsResult
} from '@/hooks'
import { PropsWithChildren } from 'react'

export type QuickFilterContextValue<T = void> = {
    state?: QueryParamStateFilterBy<T>
    quickFilters: QueryParamConfigurationFilterBy<T>['quickFilters']
    onChange(value?: QueryParamStateFilterBy<T>): void
    onReset(): void
}

export enum QuickFilterParamTypes {
    QuickFilter = 'quickFilter'
}

export interface QuickFilterProviderProps<T> extends PropsWithChildren {
    filterBy: UseQueryParamsResult<QueryParamTypes.FilterBy, T>
}
