import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik'
import { createContext } from 'react'

interface CheckboxGroupContextValue {
    field: FieldInputProps<string[]>
    helpers: FieldHelperProps<string[]>
    meta: FieldMetaProps<string[]>
}

export const CheckboxGroupContext = createContext<CheckboxGroupContextValue | null>(null)
