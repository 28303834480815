import { BaseHttpClientService, extractResponseData, ServicesConfiguration } from '@webapps/numeral-ui-core'
import { resolverLimitCheckInterceptor } from '../@responseInterceptors'
import { PaymentOrderResolverAllQueryOptions, PaymentOrdersResolver } from './PaymentOrdersResolver.service.types'

export class PaymentOrdersResolverService extends BaseHttpClientService {
    get url() {
        return '/v1/payment_orders_resolver'
    }

    constructor(configuration?: ServicesConfiguration) {
        super(configuration)

        this.getResponseInterceptorsManager().use(resolverLimitCheckInterceptor)
    }

    async resolve<T>(
        params: PaymentOrderResolverAllQueryOptions,
        config?: ServicesConfiguration
    ): Promise<PaymentOrdersResolver> {
        return this.httpClient
            .get<PaymentOrdersResolver>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }
}
