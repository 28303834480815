import { SUPPORT_EMAIL } from '@/constants'
import { INTERCOM_SDK_COMMANDS } from '@/types'
import { debounce } from 'lodash'
import { isIntercomSDKEnabled } from '../isIntercomSDKEnabled'
import { CONTACT_SUPPORT_DEBOUNCE_OPTIONS, CONTACT_SUPPORT_DEBOUNCE_WAIT } from './contactSupport.const'

const showIntercomMessages = () => {
    globalThis.window.Intercom?.(INTERCOM_SDK_COMMANDS.SHOW_MESSAGES)
}

const openMailToSupport = () => {
    globalThis.window.location.href = `mailto:${SUPPORT_EMAIL}`
}

const debouncedShowIntercomMessages = debounce(
    showIntercomMessages,
    CONTACT_SUPPORT_DEBOUNCE_WAIT,
    CONTACT_SUPPORT_DEBOUNCE_OPTIONS
)

const debouncedOpenMailToSupport = debounce(
    openMailToSupport,
    CONTACT_SUPPORT_DEBOUNCE_WAIT,
    CONTACT_SUPPORT_DEBOUNCE_OPTIONS
)

export const contactSupport = () => {
    if (isIntercomSDKEnabled()) {
        debouncedShowIntercomMessages()
    } else {
        debouncedOpenMailToSupport()
    }
}
