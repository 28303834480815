import { useAuth, usePermissions, useServices } from '@/hooks'
import { APIKey, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdAPIKey(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<APIKey, Error>>) {
    const { apiKeysService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()
    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.DEVELOPERS_VIEW_API_KEYS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<APIKey, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [apiKeysService.url, { id }],
        queryFn({ signal }) {
            return apiKeysService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
