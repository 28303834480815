import React, { useMemo } from 'react'
import { MainNavigationFooterProps, MainNavigationSectionListItem } from '@/components/MainNavigation/@components'
import { useIntl } from 'react-intl'
import { Box } from '@chakra-ui/react'
import { CollapseIcon, ExpandIcon } from '@/components/@icons'

export const MainNavigationFooterCollapseItem: React.FC<Partial<MainNavigationFooterProps>> = ({
    isCollapsed,
    onToggleNavigation
}) => {
    const intl = useIntl()
    const title = useMemo<string>(() => {
        const messageId = isCollapsed ? 'app.nav.expand.label' : 'app.nav.collapse.label'
        return intl.formatMessage({
            id: messageId
        })
    }, [isCollapsed, intl])

    return (
        <MainNavigationSectionListItem onClick={onToggleNavigation} isCollapsed={isCollapsed} title={title}>
            <Box flex="1" textAlign="left">
                {isCollapsed ? (
                    <ExpandIcon />
                ) : (
                    <>
                        <CollapseIcon />
                        {title}
                    </>
                )}
            </Box>
        </MainNavigationSectionListItem>
    )
}
