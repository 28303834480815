import { chain } from 'lodash'

export function sortKeysByCustomOrder(keys?: string[], order?: string[]) {
    const sortedOrder = chain(order)?.slice(0).reverse().value()
    const sortBy = (a: string, b: string) => {
        const indexOfa = -sortedOrder?.indexOf(a)
        const indexOfb = -sortedOrder?.indexOf(b)

        return indexOfa - indexOfb
    }

    return chain(keys).sort(sortBy).value()
}
