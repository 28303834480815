import { UploadIcon } from '@/components/@icons'
import { Flex, Link, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { FILE_UPLOAD_SAMPLE_FILE_URL } from '../FileUploadInput.const'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'
import { FileUploadIconWrapper } from './FileUploadIconWrapper'

export const FileUploadInputStatusActive: React.FC<FileUploadInputStatusProps> = ({
    color,
    browseLink,
    formattedAcceptedSize,
    fontWeight,
    ...props
}) => {
    return (
        <Flex direction="column" alignItems="center">
            <FileUploadIconWrapper color={color}>
                <UploadIcon />
            </FileUploadIconWrapper>
            <Text fontWeight={fontWeight} marginBottom="16px" {...props}>
                <FormattedMessage id="app.common.form.input.file.placeholder" values={{ browseLink }} />
            </Text>
            <Text color={color} width="80%" textAlign="center">
                <FormattedMessage
                    id="app.common.form.input.file.placeholder.alternative"
                    values={{
                        downloadTemplateLink: (
                            <Link href={FILE_UPLOAD_SAMPLE_FILE_URL}>
                                <FormattedMessage id="(download template)" />
                            </Link>
                        ),
                        formattedAcceptedSize
                    }}
                />
            </Text>
        </Flex>
    )
}
