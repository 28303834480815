import { Select } from '@/components/@misc'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { DirectDebitMandateType, DirectDebitMandateTypeSchema } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { anythingValidator } from '@/utils/@validators'
import { SPACE_SYMBOL } from '@/constants'

export const DirectDebitMandateTypeInput: React.FC<FormikInput> = ({
    name,
    value,
    isRequired,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    validator = anythingValidator,
    isPlaceholderDisplayed,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<DirectDebitMandateType>({
        name,
        value,
        validate: validator(intl, isRequired)
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.select.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select<DirectDebitMandateType>
                {...inputProps}
                {...field}
                id={name}
                isInvalid={isInvalid}
                isRequired={isRequired}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={DirectDebitMandateTypeSchema.options}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
