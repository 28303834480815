import { CounterpartyAccountVerificationStatus, If } from '@/components/@misc'
import { SelectOption } from '@/components/@misc/Select/@components'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Box, Flex, Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'

export const CounterpartyAccountsInputSelectOption: React.FC<
    OptionProps<any> & { isAccountVerificationStatusDisplayed?: boolean }
> = ({ children, innerRef, innerProps, isAccountVerificationStatusDisplayed, ...props }) => {
    const accountVerification = get(props, 'data.original.account_verification')
    const accountNumber = get(props, 'data.original.account_number', EMPTY_VALUE_PLACEHOLDER)

    return (
        <SelectOption innerRef={innerRef} innerProps={innerProps} {...props}>
            <Flex alignItems="center" justifyContent="space-between" paddingX="8px">
                <Box>
                    <Text color="gray.700" noOfLines={1}>
                        {children}
                    </Text>
                    <Text color="gray.400" fontSize="small" noOfLines={1} title={accountNumber}>
                        {accountNumber}
                    </Text>
                </Box>
                <If condition={isAccountVerificationStatusDisplayed}>
                    <CounterpartyAccountVerificationStatus accountVerification={accountVerification} />
                </If>
            </Flex>
        </SelectOption>
    )
}
