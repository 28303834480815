import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useAuth, useMutationUpdateByIdUser, useNavigationRoutes, useQueryFindByIdUser, useToasts } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps, PageLayout } from '@/layouts'
import { TOAST_VARIANTS } from '@/providers'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { UserEditForm } from './@components'
import { isUserEditingSelf } from './UserEdit.page.utils'

export const UserEditPage: React.FC = () => {
    const { uuid } = useParams()
    const { selectedUserAccess } = useAuth()
    const intl = useIntl()
    const { onAdd } = useToasts()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const query = useQueryFindByIdUser(uuid)
    const mutation = useMutationUpdateByIdUser(uuid, {
        onSuccess() {
            const path = [paths.SETTINGS.USERS, uuid, relativePaths.SETTINGS.DETAILS].join('/')

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.settings.users.edit.success.toast.title" />,
                description: <FormattedMessage id="app.settings.users.edit.success.toast.description" />
            })
        },
        onError() {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.settings.users.edit.error.toast.title" />,
                description: <FormattedMessage id="app.settings.users.edit.error.toast.description" />
            })
        }
    })

    const isEditingSelf = isUserEditingSelf(selectedUserAccess?.id, uuid)
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const title = intl.formatMessage({
            id: 'app.settings.users.edit.title'
        })

        return {
            title,
            isLoading: query.isLoading
        }
    }, [intl, query])

    return (
        <PageLayout className="UserEdit">
            <PageHeaderLayout {...propsPageHeaderLayout} />
            <RestrictedSection permission={PERMISSION.SETTINGS_EDIT_USERS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <UserEditForm
                                    data={query.data}
                                    isEditingSelf={isEditingSelf}
                                    loading={mutation.isPending}
                                    disabled={mutation.isPending}
                                    onSubmit={mutation.mutate}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
