export async function getHashedEmail(email: string): Promise<string | undefined> {
    if (!email) {
        return
    }

    const encoder = new TextEncoder()
    const data = encoder.encode(email)
    const hashBuffer = await globalThis.window.crypto.subtle.digest('SHA-1', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // Convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // Convert bytes to hex string
    return hashHex
}
