import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { PaymentType } from '@/components/@misc'
import { PaymentRetryRule } from '@webapps/numeral-ui-core'
import { PaymentRetryRuleDetailsRetries, PaymentRetryRuleReasonCodes, PaymentRetryRuleStatuses } from './@components'
import { PaymentOrderDetailsCustomRows } from './PaymentRetryRuleDetails.page.type'

export const PAYMENT_RETRY_RULE_DETAILS_ROWS: EntityDetailRow<PaymentRetryRule, PaymentOrderDetailsCustomRows>[] = [
    'id',
    'description',
    'payment_type',
    'statuses',
    'reason_codes',
    'retries'
]

export const PAYMENT_RETRY_RULE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    PaymentRetryRule,
    PaymentOrderDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    payment_type: {
        cell(item) {
            return (
                <PaymentType
                    type={item.retry_conditions.payment_type}
                    direction={item.retry_conditions.payment_direction}
                />
            )
        }
    },
    statuses: {
        cell(item) {
            return <PaymentRetryRuleStatuses statuses={item.retry_conditions.statuses} />
        }
    },
    reason_codes: {
        cell(item) {
            return <PaymentRetryRuleReasonCodes reasonCodes={item.retry_conditions.reason_codes} />
        }
    },
    retries: {
        cell(item) {
            return (
                <PaymentRetryRuleDetailsRetries
                    direction={item.retry_conditions.payment_direction}
                    retries={item.retries}
                />
            )
        }
    }
}
