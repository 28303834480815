import { FormattedMessage } from 'react-intl'

const BASE_INTL_ID = 'app.table.header.'

interface TableHeaderDefaultTextProps {
    columnId: string
}

export const TableHeaderDefaultText: React.FC<TableHeaderDefaultTextProps> = ({ columnId }) => {
    return <FormattedMessage id={`${BASE_INTL_ID}${columnId}`} />
}
