import { Select } from '@/components/@misc'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { DirectDebitMandateSequence, DirectDebitMandateSequenceSchema } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { anythingValidator } from '@/utils/@validators'
import { SPACE_SYMBOL } from '@/constants'

export const DirectDebitMandateSequenceInput: React.FC<FormikInput> = ({
    name,
    value,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    isPlaceholderDisplayed,
    isRequired,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<DirectDebitMandateSequence>({
        name,
        value,
        validate: validator(intl, isRequired)
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.select.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select<DirectDebitMandateSequence>
                {...inputProps}
                {...field}
                id={name}
                isInvalid={isInvalid}
                isRequired={isRequired}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={DirectDebitMandateSequenceSchema.options}
                getOptionLabel={(value) => intl.formatMessage({ id: `api.direct_debit_mandate.sequence.${value}` })}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
