import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getConvertedSizeWithUnit } from './EntityDetailCellFileSize.utils'

interface EntityDetailFileSizeProps {
    sizeInBytes: number
}

export const EntityDetailCellFileSize: React.FC<EntityDetailFileSizeProps> = ({ sizeInBytes }) => {
    const intl = useIntl()

    const { size, unit } = useMemo(() => getConvertedSizeWithUnit(sizeInBytes), [sizeInBytes])
    const formattedSize = useMemo<string>(() => {
        const formattedSize = intl.formatNumber(size, { maximumFractionDigits: 2 })
        return `${formattedSize} ${unit}`
    }, [size, intl, unit])

    return <>{formattedSize}</>
}
