import { isCustomFieldKeyValueDuplicated } from '@/services'
import { CustomField, CustomFieldTypesSchema, CustomFieldValue } from '@webapps/numeral-ui-core'
import { FieldValidator } from 'formik'
import { isEmpty } from 'lodash'
import { IntlShape } from 'react-intl'

export function getCustomFieldFormInitialValues(data?: Partial<CustomField>): Partial<CustomField> {
    return {
        key: '',
        name: '',
        object_types: [],
        values: [{ name: '', key: '' }],
        type: CustomFieldTypesSchema.enum.select,
        ...data
    }
}

export function customFieldValuesValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    return (value: CustomFieldValue[]): string | void => {
        function hasUniqueCustomFieldKeyValues(value: CustomFieldValue[], key: keyof CustomFieldValue = 'key') {
            return value && !value?.some((item) => isCustomFieldKeyValueDuplicated(value, key, item.key))
        }

        if (isRequired && isEmpty(value)) {
            return intl.formatMessage({ id: 'app.common.form.validation.custom_field_values.required' })
        }

        if (isRequired && !hasUniqueCustomFieldKeyValues(value)) {
            return intl.formatMessage({ id: 'app.common.form.validation.custom_field_values.unique_key.invalid' })
        }
    }
}
