import { SPACE_SYMBOL } from '@/constants'
import { ColorProps } from '@chakra-ui/styled-system'
import { UseQueryResult } from '@tanstack/react-query'
import { IntlShape } from 'react-intl'
import z, { ZodSchema } from 'zod'
import { Spinner } from '@chakra-ui/react'

export function getFormValidatorWithZodSchema<T>(schema: ZodSchema<any>, hasOutput?: boolean) {
    return function validatorFn(values: z.infer<typeof schema>) {
        try {
            const evaluation = schema.parse(values)
            if (hasOutput) {
                return evaluation
            }
        } catch (error: any) {
            return error?.formErrors?.fieldErrors
        }
    }
}

export function getFormSelectPlaceholderByReactQueryState<T = void>(
    intl: IntlShape,
    resource?: UseQueryResult<T>,
    isPlaceholderDisplayed?: boolean
) {
    if (!isPlaceholderDisplayed) {
        return
    }

    const defaultPlaceholderId = 'app.common.form.input.select.placeholder'
    let messageId: string

    switch (true) {
        case !resource?.isSuccess: {
            messageId = defaultPlaceholderId
            break
        }

        case resource?.isLoading: {
            messageId = 'app.common.loading'
            break
        }
        case resource?.isError: {
            messageId = 'app.common.error'
            break
        }

        default: {
            messageId = defaultPlaceholderId
            break
        }
    }
    return intl.formatMessage({ id: messageId, defaultMessage: SPACE_SYMBOL })
}

export function getFormButtonLoadingLeftIcon(isLoading?: boolean) {
    return isLoading ? <Spinner color="gray.300" size="sm" /> : undefined
}
