import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    CONNECTED_ACCOUNT_DETAILS_ROWS
} from './ConnectedAccountDetails.page.const'
import { ConnectedAccountDetailsCustomRows } from './ConnectedAccountDetails.types'

export const ConnectedAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdConnectedAccount(uuid)

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<ConnectedAccount, ConnectedAccountDetailsCustomRows>
                                    data={query.data}
                                    rows={CONNECTED_ACCOUNT_DETAILS_ROWS}
                                    rowCustomizers={CONNECTED_ACCOUNT_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
