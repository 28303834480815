import { DateInterval, TupleFromObjectKeys } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import { intervalValidatorBuilder } from '../validators.utils'
import { isValid } from 'date-fns'

export const dateIntervalValidator = (() => {
    const tuple = ['start_date', 'end_date'] as TupleFromObjectKeys<DateInterval>
    const validator = anythingValidator /** @todo Should be a dedicated Date validator */
    const transformer = (value: any) => {
        const date = new globalThis.Date(value)

        if (isValid(date)) {
            return date?.getTime()
        }

        return value
    }

    return intervalValidatorBuilder<DateInterval>(tuple, validator, transformer)
})()
