import { chain } from 'lodash'
import { prettyPrintCasedWords } from '@/utils'
import { COMMA_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { ApiObjectType } from '@webapps/numeral-ui-core'

interface TableCellCustomFieldObjectTypesProps {
    value: (ApiObjectType | undefined)[]
}
export const TableCellCustomFieldObjectTypes: React.FC<TableCellCustomFieldObjectTypesProps> = ({ value }) => {
    const transformedValue = useMemo(() => {
        return chain(value)
            .map((value) => `${prettyPrintCasedWords(value)}s`)
            .join(`${COMMA_SYMBOL}${SPACE_SYMBOL}`)
            .value()
    }, [value])

    return (
        <Text noOfLines={1} display="block" title={transformedValue}>
            {transformedValue}
        </Text>
    )
}
