import { SearchIcon } from '@/components/@icons'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'
import { ChangeEventHandler } from 'react'

interface SearchInputProps {
    isDisabled?: boolean
    placeholder?: string
    inputWidth?: string
    inputMargin?: string
    value?: string
    onInputChange?: ChangeEventHandler<HTMLInputElement>
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
    (
        {
            isDisabled = false,
            placeholder,
            inputWidth = 'initial',
            inputMargin = '0',
            value = '',
            onInputChange,
            ...eventsHandlers
        },
        ref
    ) => {
        return (
            <InputGroup ref={ref} color="gray.400" width={inputWidth} margin={inputMargin} {...eventsHandlers}>
                <InputLeftElement pointerEvents="none">
                    <SearchIcon />
                </InputLeftElement>
                <Input
                    colorScheme="gray"
                    variant="outline"
                    type="search"
                    margin="0"
                    placeholder={placeholder}
                    value={value}
                    onChange={onInputChange}
                    disabled={isDisabled}
                />
            </InputGroup>
        )
    }
)
