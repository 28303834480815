import { EMPTY_CHAR_SYMBOL } from '@/constants'

const WHITESPACE_PATTERN = /[\s\uFEFF\xA0\u1680\u180E\u2000-\u200A\u2028\u2029\u202F\u205F\u3000]/g

/**
 * @description
 * - The \s matches all whitespace characters, including spaces, tabs, and line breaks.
 * - The \uFEFF matches the Unicode "zero-width non-breaking space" character.
 * - The \xA0 matches the non-breaking space character.
 * - The \u1680 matches the "ogham space mark" character.
 * - The \u180E matches the "mongolian vowel separator" character.
 * - The \u2000-\u200A matches various space characters such as "en space", "em space", and "hair space".
 * - The \u2028 matches the "line separator" character.
 * - The \u2029 matches the "paragraph separator" character.
 * - The \u202F matches the "narrow no-break space" character.
 * - The \u205F matches the "medium mathematical space" character.
 * - The \u3000 matches the "ideographic space" character.
 */
export function whitespaceFormatter(value?: string): string {
    return value?.replace?.(WHITESPACE_PATTERN, EMPTY_CHAR_SYMBOL) || EMPTY_CHAR_SYMBOL
}
