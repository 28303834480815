import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { prettyPrintCasedWords } from '@/utils'
import { ApiObjectType } from '@webapps/numeral-ui-core'

export function useBulkActionsPluralization(objectType?: ApiObjectType, selectionSize = 0, isUpperCase = true) {
    const intl = useIntl()
    const generateFormattedLabel = (count: number) => {
        return intl.formatMessage(
            { id: 'app.common.pluralization.label' },
            { count, singular: prettyPrintCasedWords(objectType, isUpperCase) }
        )
    }

    const formattedObjectType = useMemo(() => {
        return generateFormattedLabel(selectionSize)
    }, [selectionSize, objectType])

    const formattedPluralObjectType = useMemo(() => {
        return generateFormattedLabel(2)
    }, [objectType])

    return { formattedObjectType, formattedPluralObjectType }
}
