import enGB from '@/assets/i18n/en-GB.json'
import frFR from '@/assets/i18n/fr-FR.json'
import { Language, SUPPORTED_LOCALE } from './LocaleSwitcherProvider.types'

export const DEFAULT_LOCALE = SUPPORTED_LOCALE.EN_GB

export const LANGUAGES: Record<SUPPORTED_LOCALE, Language> = {
    [SUPPORTED_LOCALE.EN_GB]: enGB,
    [SUPPORTED_LOCALE.FR_FR]: frFR
}
