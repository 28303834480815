export type LanguageMessages = Record<string, string>

export enum SUPPORTED_LOCALE {
    EN_GB = 'en-GB',
    FR_FR = 'fr-FR'
}

export interface Language {
    locale: string
    localeName: string
    messages?: Record<string, string>
}

export interface TranslationsContextValue {
    locale: string
    localeName?: string
    messages?: LanguageMessages
    setLocale(locale: SUPPORTED_LOCALE): void
}
