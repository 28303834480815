import { Group } from '@/types'

export function countAllGroupByRecordsForGroupEntry<T, G>(data: Group<T, G>): number {
    let result: number

    if (!data) {
        return 0
    }

    result = data.records ? data.records.length : 0

    if (data.groups) {
        data.groups.forEach((group) => {
            result += countAllGroupByRecordsForGroupEntry(group)
        })
    }

    return result
}
