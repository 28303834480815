import { useFormikAutoSubmit } from '@/hooks'
import { TABLE_HEADER_AUTO_SUBMIT_DEBOUNCE } from '../TableHeader.const'

/**
 * @description
 * - This component auto submits the form when an input changes, only if the form is valid;
 * - Simply render this component inside a formik context;
 */
export const TableHeaderFormAutoSubmit: React.FC = () => {
    useFormikAutoSubmit(TABLE_HEADER_AUTO_SUBMIT_DEBOUNCE)
    return null
}
