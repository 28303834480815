import { useEffect } from 'react'

/**
 * @description
 * 1. Programmatically blocks the native browser buttons (back and forward) buttons.
 * 2. Works with History API enabled browsers (no-op if the History API is not supported)
 */
export function useNativeNavigationBlocker(enabled: boolean) {
    useEffect(() => {
        if (!enabled || !globalThis.Boolean(globalThis.window?.history)) {
            return
        }

        try {
            const history: History = globalThis.window.history
            const href = globalThis.window.location.href

            const data = null
            const unused = ''

            history.pushState(data, unused, href)
            history.replaceState(data, unused, href)

            globalThis.window.onpopstate = function onpopstate() {
                history.pushState(data, unused, href)
            }
        } catch (e) {
            const { error } = globalThis.console
            error('Could not block native navigation:', e)
        }
    }, [enabled])
}
