import { FormattedAmount, If, Separator } from '@/components/@misc'
import { useBulkActions } from '@/hooks'
import { useBulkActionsPluralization } from '@/providers'
import { isLastIndex } from '@/utils'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Amount, ApiObjectType } from '@webapps/numeral-ui-core'
import { get } from 'lodash'
import { useMemo } from 'react'
import { FormattedNumber } from 'react-intl'

export const PaymentOrderPendingApprovalBulkActionsFooterContent: React.FC<{
    targetObject: ApiObjectType
}> = ({ targetObject }) => {
    const { selection, resolvedData, data } = useBulkActions<typeof targetObject>()

    const selectionSize = useMemo(() => {
        return selection?.size || 0
    }, [selection])
    const { formattedObjectType } = useBulkActionsPluralization(targetObject, selectionSize, false)

    const totalSelectedAmounts = useMemo(() => {
        const totalSelectedAmounts: Record<string, Amount> = Object.create(null)
        selection?.forEach((selectedId) => {
            let currency: string | undefined
            let amount: Amount | undefined
            const itemData = data?.find((payment) => selectedId === get(payment, 'id'))

            if (itemData) {
                currency = get(itemData, 'currency')
                amount = get(itemData, 'amount')
            } else {
                const resolvedItemData = resolvedData?.[selectedId]
                currency = get(resolvedItemData, 'currency')
                amount = get(resolvedItemData, 'amount')
            }

            if (currency && amount) {
                totalSelectedAmounts[currency] = (totalSelectedAmounts[currency] || 0) + amount
            }
        })

        return totalSelectedAmounts
    }, [selection, resolvedData, data])

    const formattedAmounts = useMemo(() => {
        return Object.entries(totalSelectedAmounts).map(([currency, amount], index, entriesArray) => {
            return (
                <Text key={currency} as="span">
                    <FormattedAmount currency={currency} amount={amount} />
                    <If condition={!isLastIndex(index, entriesArray)}>
                        <Separator />
                    </If>
                </Text>
            )
        })
    }, [totalSelectedAmounts])

    return (
        <Box fontWeight="bold" fontSize="lg">
            <Flex>
                <Text as="span" marginRight="4px">
                    <FormattedNumber value={selectionSize} />
                </Text>
                <Text as="span">{formattedObjectType}</Text>
            </Flex>
            <Box>{formattedAmounts}</Box>
        </Box>
    )
}
