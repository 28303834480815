import { SearchIcon } from '@/components/@icons'
import { useIntersectionObserver } from '@/components/@misc'
import { useClickOutside } from '@/hooks'
import { Box, Input, InputGroup, InputLeftElement, Theme, useTheme } from '@chakra-ui/react'
import classNames from 'classnames'
import { chain, defaultTo, get, identity } from 'lodash'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
    ActionMeta,
    GroupBase,
    MultiValue,
    default as ReactSelect,
    SelectInstance,
    SingleValue,
    components
} from 'react-select'
import { SelectComponentsConfig } from 'react-select/dist/declarations/src/components'
import { StylesConfig } from 'react-select/dist/declarations/src/styles'
import { InputActionMeta, OptionsOrGroups } from 'react-select/dist/declarations/src/types'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'
import { SelectControlLabel, SelectDropdownIndicator, SelectMessageContainer, SelectOption } from './@components'
import { SELECT_DEFAULT_PAGE_SIZE } from './Select.const'
import './Select.scss'
import { SelectProps } from './Select.types'
import {
    areArrowUpOrArrowDownKeysPressed,
    getSelectMenuPortalStyleFn,
    getSelectOptionStyleFnChakraUI,
    isEnterKeyPressed,
    normaliseSelectOptions,
    shouldPopCurrentSelectValue,
    stopEventPropagation
} from './Select.utils'

export function Select<T = void>({
    value,
    options,
    loadingMessage,
    noOptionsMessage,
    isDisabled,
    onChange,
    onInputChange,
    onKeyDown,
    onBlur,
    onFocus,
    getOptionLabel = identity,
    getOptionValue = identity,
    menuPortalTarget = globalThis.document.body,
    isSearchable = true,
    isInvalid = false,
    isRequired = false,
    isClearable = true,
    ...props
}: SelectProps<T>) {
    const intl = useIntl()
    const theme = useTheme<Theme>()
    const ref = useRef<SelectInstance<T, any, GroupBase<T>> | null>(null)
    const currentSelectedValue = useRef<SingleValue<T> | MultiValue<T>>(null)

    const [menuIsOpen, setMenuIsOpen] = useState(props.menuIsOpen)
    const [inputValue, setInputValue] = useState<string | undefined>(props.inputValue)
    const normalisedSelectOptions = useMemo(
        () => normaliseSelectOptions(options, getOptionLabel, getOptionValue) as OptionsOrGroups<T, any>,
        [options, getOptionLabel, getOptionValue]
    )
    const customComponents = useMemo((): SelectComponentsConfig<T, any, any> => {
        return {
            Control: isSearchable ? SelectControlLabel : components.Control,
            LoadingIndicator: isSearchable ? undefined : components.LoadingIndicator,
            DropdownIndicator: SelectDropdownIndicator,
            NoOptionsMessage: SelectMessageContainer,
            LoadingMessage: SelectMessageContainer,
            Option: SelectOption,
            Menu: isSearchable
                ? ({ children, innerRef, innerProps, ...props }) => {
                      const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
                          onInputChangeSelect(event.target.value, Object.create(null) as InputActionMeta)
                      }

                      return (
                          <components.Menu innerRef={innerRef} innerProps={innerProps} {...props}>
                              <Box data-testid="select-menu">
                                  <Box paddingX="8px" paddingTop="8px" data-testid="select-menu-search">
                                      <InputGroup>
                                          <InputLeftElement color="gray.400">
                                              <SearchIcon />
                                          </InputLeftElement>
                                          <Input
                                              type="text"
                                              autoComplete="off"
                                              autoCapitalize="off"
                                              value={inputValue}
                                              autoFocus={true}
                                              onChange={onChangeInput}
                                              onClick={stopEventPropagation}
                                              onMouseUp={stopEventPropagation}
                                              onBlur={onBlur}
                                              onFocus={onFocus}
                                              data-testid="select-menu-search-input"
                                          />
                                      </InputGroup>
                                  </Box>
                                  {children}
                              </Box>
                          </components.Menu>
                      )
                  }
                : components.Menu,
            ...props.components
        }
    }, [isSearchable, inputValue, onFocus, onBlur, props.components])

    const styles: StylesConfig<T> = useMemo((): StylesConfig<T> => {
        return {
            menuPortal: getSelectMenuPortalStyleFn(),
            option: getSelectOptionStyleFnChakraUI(theme)
        }
    }, [theme])
    const currentFormattedValue = useMemo(() => {
        const transformedValue = value?.toString()

        if (!transformedValue) {
            return
        }

        return chain(normalisedSelectOptions)
            .find(({ value }) => value === transformedValue)
            .thru((result) => defaultTo(result, currentSelectedValue.current))
            .thru((result) => {
                const fallbackOption = {
                    value,
                    label: transformedValue
                }

                return defaultTo(result, fallbackOption)
            })
            .value()
    }, [value, currentSelectedValue, normalisedSelectOptions])

    const hasClearableValue = useMemo(() => {
        if (isDisabled) {
            return false
        }

        if (isClearable && !isRequired) {
            return true
        }
    }, [isDisabled, isRequired, isClearable])
    const onInputChangeSelect = useCallback(
        (value: string, inputActionMeta: InputActionMeta) => {
            setInputValue(value)
            onInputChange?.(value, inputActionMeta)
        },
        [onInputChange]
    )
    const onCloseMenu = useCallback(() => {
        const resetValue = ''

        setMenuIsOpen(false)
        // Reset the outside value to the current value
        onInputChangeSelect(resetValue, Object.create(null) as InputActionMeta)
    }, [setMenuIsOpen, onInputChangeSelect])
    const onChangeSelect = useCallback(
        (newValue: SingleValue<T> | MultiValue<T>, actionMeta: ActionMeta<T>) => {
            onCloseMenu()

            currentSelectedValue.current = newValue

            switch (actionMeta.action) {
                case 'select-option': {
                    const extractedValue = chain(newValue as NonNullable<typeof newValue>)
                        .get('value')
                        .value()
                    onChange?.(extractedValue)
                    break
                }

                case 'clear': {
                    onChange?.(undefined)
                    break
                }

                case 'pop-value': {
                    //@description No change event has been triggered by external reset.
                    break
                }
            }
        },
        [onChange, onCloseMenu]
    )
    const onRef = useCallback(
        (value: SelectInstance<T, any, GroupBase<T>>) => {
            ref.current = value
        },
        [ref]
    )
    const loadingMessageWithDefault = useMemo(() => {
        if (!loadingMessage) {
            return () => intl.formatMessage({ id: 'app.common.loading' })
        }

        return loadingMessage
    }, [loadingMessage, intl])
    const noOptionsMessageWithDefault = useMemo(() => {
        if (!noOptionsMessage) {
            return () => intl.formatMessage({ id: 'app.common.no_result_found.title' })
        }

        return noOptionsMessage
    }, [noOptionsMessage, intl])
    const placeholderWithDefault = useMemo(() => {
        if (!props.placeholder) {
            return intl.formatMessage({
                id: 'app.common.form.input.select.placeholder.alternative'
            })
        }

        return props.placeholder
    }, [props.placeholder, intl])

    const dataTestId = useMemo(() => get(props, 'data-testid'), [])
    const className = useMemo(() => {
        return classNames('Select', {
            disabled: isDisabled,
            invalid: isInvalid
        })
    }, [isDisabled, isInvalid])
    const onMenuOpen = useCallback(() => {
        if (!isDisabled) {
            setMenuIsOpen((prevState) => !prevState)
        }
    }, [isDisabled])

    const onKeyDownSelect = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            switch (true) {
                case areArrowUpOrArrowDownKeysPressed(event):
                case isDisabled: {
                    event.preventDefault()
                    break
                }

                case isEnterKeyPressed(event): {
                    onCloseMenu()
                    break
                }
            }

            onKeyDown?.(event)
        },
        [isDisabled, onCloseMenu, onKeyDown]
    )
    const [selectContainer, isIntersecting] = useIntersectionObserver()
    const propsReactSelect = {
        ref: onRef,
        ...(props as StateManagerProps<T>),
        ...(isSearchable ? { menuIsOpen } : undefined),
        inputValue,
        classNamePrefix: 'Select',
        placeholder: placeholderWithDefault,
        form: props.name,
        styles,
        options: normalisedSelectOptions,
        onFocus,
        onBlur,
        onChange: onChangeSelect,
        onInputChange: onInputChangeSelect,
        onKeyDown: onKeyDownSelect,
        value: currentFormattedValue,
        required: isRequired,
        isDisabled,
        isSearchable,
        isClearable: hasClearableValue,
        components: customComponents,
        noOptionsMessage: noOptionsMessageWithDefault,
        loadingMessage: loadingMessageWithDefault,
        menuPortalTarget: menuPortalTarget,
        pageSize: SELECT_DEFAULT_PAGE_SIZE
    }

    useClickOutside(selectContainer, onCloseMenu)

    useEffect(() => {
        const selectInstanceRef = ref.current

        if (!isIntersecting) {
            onCloseMenu()
        }

        if (shouldPopCurrentSelectValue(value, currentFormattedValue)) {
            selectInstanceRef?.popValue()
        }
    }, [value, currentFormattedValue, isIntersecting, onCloseMenu])

    return (
        <div className={className} ref={selectContainer} data-testid={dataTestId} onClick={onMenuOpen}>
            <ReactSelect {...propsReactSelect} />
        </div>
    )
}
