import { TableCellShortDate, TableCellText, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { EnabledStatus } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { accountNumberFormatter } from '@/utils/@formatters'
import { VirtualAccount } from '@webapps/numeral-ui-core'

export const CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_STATIC_STATE: TableStateWithEntity<VirtualAccount> = {
    columnOrder: Object.freeze(['created_at', 'name', 'virtual_account_number', 'disabled_at'])
}

export const CONNECTED_ACCOUNT_VIRTUAL_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<VirtualAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    virtual_account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    disabled_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        size: COLUMN_WIDTH_RATIO.STATUS,
        cell(info) {
            const isDisabled = !!info.getValue()
            return <EnabledStatus isDisabled={isDisabled} />
        }
    }
}
