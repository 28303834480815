import { Icon, IconProps } from '@chakra-ui/react'

export const AccountsIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00065 1.33325L14.6673 5.01049V6.72374H1.33398V5.01049L8.00065 1.33325ZM3.3734 5.39996H12.6279L8.00065 2.84763L3.3734 5.39996ZM3.00065 12.019V8.04755H4.33398V12.019H3.00065ZM7.33398 12.019V8.04755H8.66732V12.019H7.33398ZM11.6673 12.019V8.04755H13.0007V12.019H11.6673ZM14.6673 13.3428V14.6666H1.33398V13.3428H14.6673Z"
            fill="currentColor"
        />
    </Icon>
)
