import { Icon, IconProps } from '@chakra-ui/react'

export const HomeIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="-2.5 -2.5 20 20" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9007 5.00663L8.27398 0.839961C7.52065 0.166628 6.37398 0.166628 5.62065 0.839961L0.993984 5.00663C0.573984 5.38663 0.333984 5.91996 0.333984 6.47996V11.5533C0.333984 12.6466 1.22065 13.5333 2.32065 13.5333H5.61398V8.90663H5.62065C5.62065 8.53996 5.92065 8.24663 6.28732 8.24663H7.60732C7.97398 8.24663 8.26732 8.53996 8.26732 8.90663H8.28065V13.5333H11.574C12.6673 13.5333 13.5607 12.6466 13.5607 11.5533V6.47996C13.5607 5.91996 13.3207 5.38663 12.9007 5.00663ZM12.234 11.5533C12.234 11.9133 11.9407 12.2133 11.574 12.2133H9.59398V8.90663C9.59398 7.80663 8.70065 6.91996 7.60732 6.91996H6.28732C5.18732 6.91996 4.30065 7.80663 4.30065 8.90663V12.2133H2.32065C1.95398 12.2133 1.65398 11.9133 1.65398 11.5533V6.47996C1.65398 6.29329 1.73398 6.11996 1.87398 5.99329L6.50065 1.82663C6.75398 1.59996 7.13398 1.59996 7.38732 1.82663L12.014 5.99329C12.154 6.11996 12.234 6.29329 12.234 6.47996V11.5533Z"
        />
    </Icon>
)
