import { MAIN_LAYOUT_PORTAL_CONTAINER_ID } from '@/layouts'
import { Portal, SlideFade } from '@chakra-ui/react'
import { chain, noop } from 'lodash'
import { CSSProperties, useEffect, useMemo, useRef } from 'react'
import { STICKY_FOOTER_THROTTLE_EVENT_WINDOW } from './StickyFooter.const'
import './StickyFooter.scss'
import { StickyFooterProps } from './StickyFooter.types'
import { getPrimarySpacingValue } from './StickyFooter.utils'

export const StickyFooter: React.FC<StickyFooterProps> = ({ children, onMount }) => {
    const mainLayoutPortalContainerElementRef = useRef<HTMLElement | null>(null)
    const primarySpacing = useRef<number>(getPrimarySpacingValue())
    const onAnimationComplete = useMemo(() => {
        if (!onMount) {
            return noop
        }

        const throttledCallback = () => {
            const computedFooterHeightWithOffset = chain<typeof mainLayoutPortalContainerElementRef>(
                mainLayoutPortalContainerElementRef
            )
                .get('current.clientHeight', 0)
                .add(primarySpacing.current)
                .value()

            onMount?.(computedFooterHeightWithOffset)
        }

        return chain<typeof throttledCallback>(throttledCallback).throttle(STICKY_FOOTER_THROTTLE_EVENT_WINDOW).value()
    }, [onMount])
    const styleProps = useRef<CSSProperties | undefined>()

    useEffect(() => {
        mainLayoutPortalContainerElementRef.current = globalThis.document.getElementById(
            MAIN_LAYOUT_PORTAL_CONTAINER_ID
        )

        globalThis.addEventListener('resize', onAnimationComplete)

        return () => {
            globalThis.removeEventListener('resize', onAnimationComplete)
        }
    }, [onAnimationComplete])

    return (
        <Portal containerRef={mainLayoutPortalContainerElementRef}>
            <SlideFade
                className="StickyFooter"
                onAnimationComplete={onAnimationComplete}
                style={styleProps.current}
                in={true}>
                {children}
            </SlideFade>
        </Portal>
    )
}
