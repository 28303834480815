import { Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import { omit } from 'lodash'
import { PropsWithChildren, useMemo } from 'react'

type InputWithCustomComponentProps = InputProps & PropsWithChildren

export const InputWithCustomComponent: React.FC<InputWithCustomComponentProps> = (props) => {
    const propsInput = useMemo(() => {
        return omit(props, 'children', 'value')
    }, [props.isReadOnly])

    return (
        <InputGroup>
            {props.children ? <InputLeftElement width="auto" children={props.children} /> : null}
            <Input {...propsInput} />
        </InputGroup>
    )
}
