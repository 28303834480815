import { isEmpty, isObjectLike } from 'lodash'

export function isEmptyRecord(values?: any): boolean {
    for (const key in values) {
        const value = values[key]

        if (!isEmpty(value) || (isObjectLike(value) && !isEmptyRecord(value))) {
            return false
        }
    }

    return true
}
