import { useMemo } from 'react'
import { get } from 'lodash'
import { ReconciliationStatus } from '@webapps/numeral-ui-core'
import { Flex, Text, useTheme } from '@chakra-ui/react'
import { computeReconciliationStatusColor } from './ReconciliationStatusLabel.utils'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'

interface ReconciliationStatusLabelProps {
    value?: ReconciliationStatus
}

export const ReconciliationStatusLabel: React.FC<ReconciliationStatusLabelProps> = ({ value }) => {
    const theme = useTheme()
    const { color, label } = useMemo(() => {
        const label = value ? prettyPrintCasedWords(value) : EMPTY_VALUE_PLACEHOLDER
        const color = computeReconciliationStatusColor(value)

        return {
            label,
            color: get(theme.colors, color)
        }
    }, [value, theme])

    return (
        <Flex>
            <svg viewBox="0 0 10 10" width="10px" fill={color}>
                <circle cx="5" cy="5" r="4" strokeWidth="1.5" stroke={color} fill="rgba(0,0,0,0)" />
                <circle cx="5" cy="5" r="2" fill={color} />
            </svg>
            <Text marginLeft="8px" title={label} aria-label={label}>
                {label}
            </Text>
        </Flex>
    )
}
