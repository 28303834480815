import { ReactElement } from 'react'
import { chain } from 'lodash'
import { PopoverProps } from '@chakra-ui/react'
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableCellOptionsMenu, TableCellText, TableColumnCustomizers, TableHeaderDefaultText } from '@/components'
import { TABLE_COLUMN_RESERVED_KEYS, TABLE_DEFAULT_OPTIONS, TABLE_SCROLL_TO_BOTTOM_OFFSET_PX } from './Table.const'
import { COLUMN_WIDTH_RATIO } from '@/constants'

export function getTableColumnsWithCustomizer<T, C = void>(
    columnOrderState: string[],
    columnCustomizers?: TableColumnCustomizers<T, C>,
    hasRowOptions?: boolean
): ColumnDef<T>[] {
    const columnHelper = createColumnHelper<T>()
    const defaultColumnCustomizer: Partial<ColumnDef<T, any>> = {
        size: COLUMN_WIDTH_RATIO.DEFAULT,
        minSize: undefined,
        header(info) {
            return <TableHeaderDefaultText columnId={info.header.id} />
        },
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    }
    const columnHelperMapper = (dataKey: string) => {
        const columnCustomization = { ...defaultColumnCustomizer, ...chain(columnCustomizers).get(dataKey).value() }
        return columnHelper.accessor(dataKey as any, columnCustomization)
    }

    return (
        chain<typeof columnOrderState>(columnOrderState)
            // @ts-ignore TBD
            .union(hasRowOptions && [TABLE_COLUMN_RESERVED_KEYS.OPTIONS])
            .map(columnHelperMapper)
            .value()
    )
}

export function getTableRowOptionsColumnDef<T>(
    MenuComponent: React.FC<{ item: T }>,
    props?: PopoverProps
): Partial<ColumnDef<T, void>> & {
    header(): null
    cell(info: CellContext<T, void>): ReactElement
} {
    return {
        ...TABLE_DEFAULT_OPTIONS,
        size: 0.22,
        header() {
            return null
        },
        cell(info) {
            return (
                <TableCellOptionsMenu<T> {...props}>
                    <MenuComponent item={info?.row?.original} />
                </TableCellOptionsMenu>
            )
        }
    }
}

export function getColumnWidthPercentage(numberOfColumns: number, columnWidthRatio = 1): number | undefined {
    if (!!numberOfColumns && numberOfColumns > 0 && columnWidthRatio > 0) {
        return (100 / numberOfColumns) * columnWidthRatio
    }
}

export function shouldRowGetInViewRef(numberOfRows: number, rowIndex: number): boolean {
    return rowIndex + TABLE_SCROLL_TO_BOTTOM_OFFSET_PX === numberOfRows
}
