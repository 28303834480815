import { EmptyContent, EntityDetail, LoadingSpinner } from '@/components'
import { usePermissions, useQueryFindByIdFile } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { NumeralFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { FileApproval } from './@components'
import { FILE_DETAILS_ROW_CUSTOMIZERS, FILE_DETAILS_ROWS } from './FileDetails.page.const'
import { FileDetailCustomRows } from './FileDetails.page.types'
import { If } from '@/components/@misc'

export const FileDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const hasApprovalPermission = hasPermission(PERMISSION.FILES_APPROVE_FILES)
    const query = useQueryFindByIdFile(uuid)

    return (
        <PageLayout>
            {(() => {
                switch (true) {
                    case query?.isLoading: {
                        return <LoadingSpinner />
                    }

                    case query?.isError: {
                        return (
                            <EmptyContent
                                title={intl.formatMessage({
                                    id: 'app.common.error.title'
                                })}
                            />
                        )
                    }
                    case isEmpty(query?.data): {
                        return (
                            <EmptyContent
                                title={intl.formatMessage({
                                    id: 'app.navigation.page.dashboard.accounts.connectedAccounts.empty_content.title'
                                })}
                                content={intl.formatMessage({
                                    id: 'app.navigation.page.dashboard.accounts.connectedAccounts.none.content'
                                })}
                            />
                        )
                    }

                    default: {
                        return (
                            <>
                                <EntityDetail<NumeralFile, FileDetailCustomRows>
                                    data={query.data}
                                    rows={FILE_DETAILS_ROWS}
                                    rowCustomizers={FILE_DETAILS_ROW_CUSTOMIZERS}
                                />
                                <If condition={hasApprovalPermission}>
                                    <FileApproval data={query.data} />
                                </If>
                            </>
                        )
                    }
                }
            })()}
        </PageLayout>
    )
}
