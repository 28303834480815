import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { useField } from 'formik'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select } from '@/components/@misc'
import { prettyPrintCasedWords } from '@/utils'

export const BooleanInput: React.FC<FormikInput> = ({ name, value, ...inputProps }) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField({ name, value })
    const placeholder = useMemo<string>(() => {
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [intl])
    const options = useMemo(() => [true, false], [])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<boolean>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={options}
                isInvalid={isInvalid}
                getOptionValue={(option) => option.toString()}
                getOptionLabel={(option) => prettyPrintCasedWords(option.toString())}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
