import { List, Uuid } from '@webapps/numeral-ui-core'

type Identifiable = {
    id?: Uuid
}

const getNextPageParam = <T extends Identifiable>(lastPage: List<T>, paginationLimit: number) => {
    const recordsLength = lastPage?.records?.length

    if (!recordsLength || recordsLength < paginationLimit) {
        return
    }

    const lastRecordIndex = recordsLength - 1
    const lastRecordId = lastPage.records[lastRecordIndex]?.id
    if (!lastRecordId) {
        return
    }

    return { starting_after: lastRecordId }
}

export const paginationQueryOptions = {
    getNextPageParam
}
