import { memo, useCallback, useMemo } from 'react'
import { CellContext } from '@tanstack/table-core'
import { useBulkActions, useQuickFilter } from '@/hooks'
import { Flex, Skeleton } from '@chakra-ui/react'
import { getBulkActionsTableRowRange, isBulkActionsShiftSelectActive } from './BulkActionsTable.utils'
import {
    BULK_ACTIONS_TABLE_CELL_CONTAINER_STYLE,
    BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION
} from './BulkActionsTable.const'
import { Uuid } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

let LAST_SELECTED_TABLE_ROW_INDEX: number
type RowContextWithUuid<T> = T & { id: Uuid }

function BulkActionsTableSelectCell<T>({ row, table }: CellContext<RowContextWithUuid<T>, any>) {
    const { actions, selection, isLoading, onSelect, onChangeSelection } = useBulkActions()
    const { state } = useQuickFilter<T>()
    const isChecked = useMemo(() => {
        return selection?.has(row.original?.id)
    }, [selection])
    const currentActiveContextConfiguration = useMemo(() => {
        const activeContext = chain(state).values().head().value()
        return chain(actions).get(activeContext).get('configuration', Object.create(null)).value()
    }, [actions, state])
    const onClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            const rowWrapper = chain(row)
            const rowId: Uuid = rowWrapper.get('original').get('id').value()
            const rowIndex: number = rowWrapper.get('index').value()
            const { isSingleSelect } = currentActiveContextConfiguration

            event.stopPropagation()

            switch (true) {
                // @todo Add unit test case
                case !isSingleSelect && isBulkActionsShiftSelectActive(event, LAST_SELECTED_TABLE_ROW_INDEX): {
                    const { rows } = table.getRowModel()
                    const rowRange = getBulkActionsTableRowRange<RowContextWithUuid<T>>(
                        rows,
                        rowIndex,
                        LAST_SELECTED_TABLE_ROW_INDEX
                    )

                    rowRange.forEach(({ original }) => {
                        const currentRowId: Uuid = original?.id
                        onSelect(currentRowId)
                    })
                    break
                }

                // @todo Add unit test case
                case isSingleSelect && !isChecked: {
                    onChangeSelection(new Set<Uuid>([rowId]))
                    break
                }

                // @todo Add unit test case
                default: {
                    onSelect(rowId)
                }
            }

            LAST_SELECTED_TABLE_ROW_INDEX = rowIndex
        },
        [row, table, onSelect, currentActiveContextConfiguration, isChecked, onChangeSelection, onSelect]
    )

    if (isLoading) {
        return <Skeleton width={BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION} height={BULK_ACTIONS_TABLE_CHECKBOX_DIMENSION} />
    }

    return (
        <Flex {...BULK_ACTIONS_TABLE_CELL_CONTAINER_STYLE} onClick={onClick}>
            <input type="checkbox" checked={isChecked} readOnly={true} style={{ cursor: 'inherit' }} />
        </Flex>
    )
}

export const BulkActionsTableSelectCellMemoized = memo(BulkActionsTableSelectCell)
