import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Portal
} from '@chakra-ui/react'
import { noop } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface FormPageFooterAlertDialogProps {
    isOpen: boolean

    onConfirm(): void

    onClose(): void
}

export const FormPageFooterAlertDialog: React.FC<FormPageFooterAlertDialogProps> = ({
    isOpen,
    onConfirm = noop,
    onClose = noop
}) => {
    const dialogContainerRef = useRef<HTMLBodyElement>(globalThis.document.getElementsByTagName('body').item(0))
    const cancelRef = useRef(null)
    const [loading, setLoading] = useState<boolean>(false)
    const onCloseWithLoading = useCallback(() => {
        if (loading) {
            return
        }

        onClose()
    }, [loading, onClose])

    const onClickConfirm = useCallback(() => {
        setLoading(true)
        onConfirm()
    }, [onClose, onConfirm])

    return (
        <Portal containerRef={dialogContainerRef}>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCloseWithLoading}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <FormattedMessage id="app.common.form.dialog.unsaved_changes.title" />
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <FormattedMessage id="app.common.form.dialog.unsaved_changes.content" />
                        </AlertDialogBody>
                        <AlertDialogFooter gap="16px">
                            <Button onClick={onClickConfirm} isLoading={loading}>
                                <FormattedMessage id="app.common.form.dialog.unsaved_changes.actions.confirm.label" />
                            </Button>
                            <Button variant="secondary" ref={cancelRef} onClick={onCloseWithLoading} disabled={loading}>
                                <FormattedMessage id="app.common.form.actions.cancel.label" />
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Portal>
    )
}
