import { Divider, DividerProps } from '@chakra-ui/react'
import { useMemo } from 'react'

interface MainNavigationDividerProps extends DividerProps {
    isCollapsed: boolean
}
export const MainNavigationDivider: React.FC<MainNavigationDividerProps> = ({ isCollapsed, ...dividerProps }) => {
    const marginX = useMemo<string>(() => (isCollapsed ? '4px' : '16px'), [isCollapsed])
    return <Divider borderColor="gray.200" width="auto" marginX={marginX} {...dividerProps} />
}
