import { Form, FormPageFooter } from '@/components'
import { CustomFieldsSection } from '@/pages'
import { ApiObjectTypeSchema, PaymentOrder, PaymentOrderStatusSchema } from '@webapps/numeral-ui-core'
import { chain, isEmpty, noop } from 'lodash'
import React, { memo, useCallback } from 'react'
import { PaymentOrderRetryRuleSection } from '../../@forms'

interface PaymentOrderEditFormProps {
    data?: PaymentOrder
    disabled?: boolean
    loading?: boolean

    onSubmit?(data: PaymentOrder): void
}

export const PaymentOrderEditForm: React.FC<PaymentOrderEditFormProps> = memo(
    ({ data, loading, disabled, onSubmit = noop }) => {
        const onSubmitForm = useCallback(
            (item: typeof data) => {
                const editableFieldsValue = chain(item)
                    .pick('retry_details.payment_retry_rule_id', 'custom_fields')
                    .value()

                onSubmit(editableFieldsValue)
            },
            [onSubmit]
        )

        const isPaymentOrderRetryRuleSectionExpanded = !isEmpty(data?.retry_details?.payment_retry_rule_id)

        return (
            <Form initialValues={data} onSubmit={onSubmitForm}>
                {({ handleSubmit, dirty }) => {
                    const isRetryRuleUpdateDisable =
                        loading || disabled || data?.status !== PaymentOrderStatusSchema.enum.pending_approval
                    return (
                        <>
                            <PaymentOrderRetryRuleSection
                                isDisabled={isRetryRuleUpdateDisable}
                                isSectionDisplayed={true}
                                isSectionExpanded={isPaymentOrderRetryRuleSectionExpanded}
                                selectedPaymentOrderType={data?.type}
                                selectedDirection={data?.direction}
                            />

                            <CustomFieldsSection<typeof data>
                                objectType={ApiObjectTypeSchema.enum.payment_order}
                                isDisabled={loading || disabled}
                                isExpanded={!isEmpty(data?.custom_fields)}
                            />

                            <FormPageFooter
                                onSubmit={handleSubmit}
                                isDisabled={disabled || !dirty}
                                isLoading={loading}
                                showUnsavedChangesWarning={true}
                            />
                        </>
                    )
                }}
            </Form>
        )
    }
)
