import { useLocaleSwitcher } from '@/hooks'
import { SUPPORTED_LOCALE } from '@/providers'
import { LANGUAGES } from '@/providers/LocaleSwitcherProvider/LocaleSwitcherProvider.const'
import { Box, FormLabel, Select } from '@chakra-ui/react'
import { ChangeEvent, useCallback, useId } from 'react'
import { FormattedMessage } from 'react-intl'

export const DebugIntl: React.FC = () => {
    const selectId = useId()
    const { locale, setLocale } = useLocaleSwitcher()
    const onChangeLanguage = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            setLocale(event.target.value as SUPPORTED_LOCALE)
            event.stopPropagation()
        },
        [setLocale]
    )

    return (
        <Box className="DebugIntl">
            <FormLabel htmlFor={selectId}>
                <FormattedMessage id="app.debug.language_title.label" />
            </FormLabel>
            <Select id={selectId} onChange={onChangeLanguage} value={locale}>
                {Object.keys(LANGUAGES).map((LanguageKey) => (
                    <option value={LanguageKey} key={LanguageKey}>
                        {LANGUAGES[LanguageKey as SUPPORTED_LOCALE].localeName}
                    </option>
                ))}
            </Select>
        </Box>
    )
}
