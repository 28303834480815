import { IntlShape } from 'react-intl'
import { ExpectedPayment, Transaction } from '@webapps/numeral-ui-core'
import { Payment } from '@/types'
import {
    areReconcilingEntitiesMatchingCurrencies,
    SourceReconciliationObject,
    TargetReconciliationObject
} from '@/services'
import { prettyPrintCasedWords } from '@/utils'
import {
    areExpectedPaymentsReconciledAmountsValid,
    areTargetReconciledStatusesValid,
    areMoreThanOneTargetObjectsReconciling,
    isTargetReconcilableAmountLowerOrEqualThanSource
} from '../../ReconcileEntity.page.utils'

export function getReconcileEntityFooterContentWithIntl(intl: IntlShape) {
    /**
     * @description
     * Handles 3 edge cases:
     * 1. when more than one (1) expected_payment types is selected:
     *  a - Cannot mix range amounts and fixed amounts;
     *  b - Cannot have selected target amounts greater than the source.
     * 2. Cannot mix currencies between source and target(s).
     */
    return function getReconcileEntityFooterContent<T extends Transaction | Payment>(
        sourceObject?: SourceReconciliationObject<T>,
        targetObjects?: TargetReconciliationObject<T>[]
    ) {
        const result = {
            title: undefined,
            description: undefined
        }

        if (!sourceObject || !targetObjects?.length) {
            return result
        }

        const hasValidTargetsLength = areMoreThanOneTargetObjectsReconciling(targetObjects)
        const values = {
            sourceObjectType: prettyPrintCasedWords(sourceObject?.object, false),
            targetObjectType: intl.formatMessage(
                { id: 'app.common.pluralization.label' },
                {
                    count: targetObjects.length,
                    singular: prettyPrintCasedWords(targetObjects?.at(0)?.object, false)
                }
            )
        }

        switch (true) {
            case hasValidTargetsLength &&
                !areExpectedPaymentsReconciledAmountsValid(targetObjects as ExpectedPayment[]): {
                const title = intl.formatMessage({
                    id: 'app.reconcile.footer.validation.expected_payment_range_amount.title'
                })
                const description = intl.formatMessage(
                    {
                        id: 'app.reconcile.footer.validation.expected_payment_range_amount.description'
                    },
                    values
                )

                return {
                    title,
                    description
                }
            }

            case hasValidTargetsLength &&
                !isTargetReconcilableAmountLowerOrEqualThanSource<T>(sourceObject, targetObjects): {
                const title = intl.formatMessage({
                    id: 'app.reconcile.footer.validation.greater_total_selected_amount.title'
                })
                const description = intl.formatMessage(
                    {
                        id: 'app.reconcile.footer.validation.greater_total_selected_amount.description'
                    },
                    values
                )

                return {
                    title,
                    description
                }
            }

            case !areReconcilingEntitiesMatchingCurrencies(sourceObject, ...targetObjects): {
                const title = intl.formatMessage({
                    id: 'app.reconcile.footer.validation.not_matching_currencies.title'
                })
                const description = intl.formatMessage(
                    {
                        id: 'app.reconcile.footer.validation.not_matching_currencies.description'
                    },
                    values
                )
                return {
                    title,
                    description
                }
            }

            default: {
                return result
            }
        }
    }
}

/**
 * @description
 * Handles 3 edge cases:
 * 1. when more than one (1) expected_payment types is selected:
 *  a - Cannot mix range amounts and fixed amounts;
 *  b - Cannot have selected target amounts greater than the source.
 * 2. Cannot mix currencies between source and target(s).
 */
export function isReconciliationDisabled<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObjects?: TargetReconciliationObject<T>[]
) {
    if (!sourceObject || !targetObjects?.length) {
        return false
    }

    const hasValidTargetsLength = areMoreThanOneTargetObjectsReconciling(targetObjects)

    switch (true) {
        case hasValidTargetsLength && !areExpectedPaymentsReconciledAmountsValid(targetObjects as ExpectedPayment[]):
        case hasValidTargetsLength && !isTargetReconcilableAmountLowerOrEqualThanSource<T>(sourceObject, targetObjects):
        case !areReconcilingEntitiesMatchingCurrencies(sourceObject, ...targetObjects):
        case !areTargetReconciledStatusesValid(targetObjects): {
            return true
        }
    }

    return false
}
