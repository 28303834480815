import { Icon, IconProps } from '@chakra-ui/react'

export const CounterpartiesIcon: React.FC<IconProps> = (props) => (
    <Icon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 13.8215V3.71042H3.38889V0.821533H10.6111V6.59931H13.5V13.8215H7.72222V10.9326H6.27778V13.8215H0.5ZM1.94444 12.3771H3.38889V10.9326H1.94444V12.3771ZM1.94444 9.4882H3.38889V8.04376H1.94444V9.4882ZM1.94444 6.59931H3.38889V5.15487H1.94444V6.59931ZM4.83333 9.4882H6.27778V8.04376H4.83333V9.4882ZM4.83333 6.59931H6.27778V5.15487H4.83333V6.59931ZM4.83333 3.71042H6.27778V2.26598H4.83333V3.71042ZM7.72222 9.4882H9.16667V8.04376H7.72222V9.4882ZM7.72222 6.59931H9.16667V5.15487H7.72222V6.59931ZM7.72222 3.71042H9.16667V2.26598H7.72222V3.71042ZM10.6111 12.3771H12.0556V10.9326H10.6111V12.3771ZM10.6111 9.4882H12.0556V8.04376H10.6111V9.4882Z"
        />
    </Icon>
)
