import { CounterpartyAccount, PaymentOrdersServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { useNavigationRoutes } from '@/hooks'
import { LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

interface CounterpartyAccountDetailsRelatedObjectsProps {
    counterpartyAccount?: CounterpartyAccount
}

export const CounterpartyAccountDetailsRelatedObjects: React.FC<CounterpartyAccountDetailsRelatedObjectsProps> = ({
    counterpartyAccount
}) => {
    const { paths } = useNavigationRoutes()

    if (!counterpartyAccount?.id) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <LinkWithFilterBy<PaymentOrdersServiceFindAllQueryOptions>
            path={paths.PAYMENTS.PAYMENT_ORDERS}
            filterBy={{ counterparty_account_id: counterpartyAccount.id }}
            labelKey="app.page.details.cells.related_objects.files.payment_orders.link"
        />
    )
}
