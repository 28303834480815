import { PERMISSION } from '@/services'
import { EntityAction } from '@/components'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useNavigationRoutes, usePermissions } from '@/hooks'
import { updateUUIDRelativeActionPathname } from '@/providers'
import { UseDisclosureProps } from '@chakra-ui/react'
import { CustomField } from '@webapps/numeral-ui-core'

export function useCustomFieldActions(item?: CustomField, options?: UseDisclosureProps) {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const actions: EntityAction[] = []

    if (hasPermission(PERMISSION.SETTINGS_EDIT_CUSTOM_FIELDS)) {
        actions.push({
            onClick() {
                const { SETTINGS, EDIT, UUID } = relativePaths
                const path = uuid
                    ? updateUUIDRelativeActionPathname(location.pathname, SETTINGS.EDIT)
                    : EDIT.replace(UUID, item?.id as string)

                navigate(path)

                navigate(path)
            },
            children: intl.formatMessage({
                id: 'app.settings.custom_fields.edit.actions.edit.label'
            })
        })
    }

    return actions
}
