import { useEffect, useRef } from 'react'

export function usePreventWindowDragOverAndDrop() {
    const onPreventDefault = useRef((event: any) => {
        event.preventDefault()
    }).current

    useEffect(() => {
        globalThis.window.addEventListener('dragover', onPreventDefault, false)
        globalThis.window.addEventListener('drop', onPreventDefault, false)

        return () => {
            globalThis.window.removeEventListener('dragover', onPreventDefault, false)
            globalThis.window.removeEventListener('drop', onPreventDefault, false)
        }
    }, [onPreventDefault])
}
