import { prettyPrintCasedWords } from '@/utils'
import React, { memo } from 'react'
import { CustomFieldTypes } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'

interface CustomFieldTypeInputProps {
    customFieldType: CustomFieldTypes
}

export const FormattedCustomFieldType: React.FC<CustomFieldTypeInputProps> = memo(({ customFieldType }) => {
    const messageId = `api.custom_fields.type.${customFieldType}`
    const defaultMessage = prettyPrintCasedWords(customFieldType)

    return <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
})
