import {
    FilterInputs,
    TableCellShortDate,
    TableCellText,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { BooleanInput, ConnectedAccountsInput, DateInput, TextInput } from '@/components/@inputs'
import { ConnectedAccountName, EnabledStatus } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { accountNumberFormatter } from '@/utils/@formatters'
import { VirtualAccount, VirtualAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export const VIRTUAL_ACCOUNT_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/the-virtual-account-object`

export const VIRTUAL_ACCOUNT_FILTERS: FilterInputs<VirtualAccountsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    name: (
        <TextInput
            name="name"
            customLabelKey="app.common.form.input.virtual_account_name.label"
            customPlaceholderKey="app.common.form.input.virtual_account_name.placeholder"
        />
    ),
    virtual_account_number: <TextInput name="virtual_account_number" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    counterparty_id: <TextInput name="counterparty_id" />,
    disabled: <BooleanInput name="disabled" />
}

export const VIRTUAL_ACCOUNT_TABLE_STATIC_STATE: TableStateWithEntity<VirtualAccount> = {
    columnOrder: Object.freeze([
        'created_at',
        'connected_account_id',
        'name',
        'virtual_account_number',
        'disabled_at'
        /*
         * @todo Enable once we have counterparties
         * 'counterparty_id',
         */
    ])
}

export const VIRTUAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<VirtualAccount> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    name: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    virtual_account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())

            return <TableCellText value={formattedValue} />
        }
    },
    disabled_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        size: COLUMN_WIDTH_RATIO.STATUS,
        cell(info) {
            const isDisabled = !!info.getValue()
            return <EnabledStatus isDisabled={isDisabled} />
        }
    },
    counterparty_id: {
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    }
}
