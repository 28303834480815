import { Logo } from '@/components'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { Divider } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const HomePage: React.FC = () => {
    const intl = useIntl()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.home.title' }), [intl])

    return (
        <PageLayout className="Home">
            <PageHeaderLayout title={title} showBackButton={false} />
            <Divider />
            <Logo />
        </PageLayout>
    )
}
