import { DirectDebitMandate, DirectDebitMandateDirectionSchema } from '@webapps/numeral-ui-core'

export function getDirectDebitMandateFormInitialValues(): Partial<DirectDebitMandate> {
    const item: Partial<DirectDebitMandate> = Object.create(null)

    item.connected_account_id = undefined
    item.type = undefined

    /**
     * @description
     * - The `receiving_account_id` only supports `counterparty_account` ids and no corporate `connected_account` ids.
     * - Keep prefilled as `outgoing` until API supports all ids;
     */
    item.direction = DirectDebitMandateDirectionSchema.enum.outgoing

    item.originating_account = undefined

    item.receiving_account_id = undefined
    item.receiving_account = undefined

    item.sequence = undefined
    item.reference = undefined
    item.signature_date = undefined

    return item
}
