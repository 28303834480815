import { EmptyContent, EmptyContentProps } from '@/components'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from '@chakra-ui/react'
import { BALANCE_DOCUMENTATION_LINK } from '@/pages/Accounts/Balances/Balances.page.const'

interface BalancesEmptyContentProps {
    isFiltering?: boolean
    isSearching?: boolean
    isEmpty?: boolean
}

export const BalancesEmptyContent: React.FC<BalancesEmptyContentProps> = ({ isSearching, isFiltering, isEmpty }) => {
    const intl = useIntl()
    const propsEmptyContent = useMemo<EmptyContentProps | undefined>(() => {
        if (!isEmpty) {
            return
        }

        if (isSearching || isFiltering) {
            return {
                title: intl.formatMessage({ id: 'app.accounts.balances.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={BALANCE_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.accounts.balances.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.accounts.balances.empty_content.subtitle' }, { learnMoreLink })
            }
        }
    }, [isEmpty, isSearching, isFiltering, intl])

    return <EmptyContent {...propsEmptyContent} />
}
