import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useMutationExportIncomingPayments,
    useNavigationRoutes,
    usePage,
    useQueryFindAllIncomingPayments,
    useQueryParams,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ReconcileEntityDynamicHeader, getReconcileEntityBulkActions } from '@/pages'
import { BulkActionsProvider, BulkActionsTable, QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import {
    ApiObjectTypeSchema,
    IncomingPayment,
    IncomingPaymentsServiceFindAllQueryOptions,
    Transaction
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    INCOMING_PAYMENT_DOCUMENTATION_LINK,
    INCOMING_PAYMENT_FILTERS,
    INCOMING_PAYMENT_QUICK_FILTERS,
    INCOMING_PAYMENT_TABLE_COLUMN_CUSTOMIZERS,
    INCOMING_PAYMENT_TABLE_STATIC_STATE
} from './IncomingPayments.page.const'

export const IncomingPaymentsPage: React.FC = () => {
    const page = usePage<Transaction>()
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.incoming_payments.title' }), [intl])
    const { search, filterBy } = useQueryParams<IncomingPaymentsServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: INCOMING_PAYMENT_FILTERS, quickFilters: INCOMING_PAYMENT_QUICK_FILTERS } }
    })

    const query = useQueryFindAllIncomingPayments({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportIncomingPayments({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.payments.incoming_payments.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={INCOMING_PAYMENT_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.payments.incoming_payments.empty_content.title' }),
                content: intl.formatMessage(
                    { id: 'app.payments.incoming_payments.empty_content.subtitle' },
                    { learnMoreLink }
                )
            }
        }
    }, [isDataEmpty, search.isActive, filterBy.isActive, intl])

    const actions = useMemo<TableHeaderActionProps[]>(() => {
        if (page.hasActiveContext) {
            return []
        }

        return [
            {
                onClick() {
                    mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
                },
                children: intl.formatMessage({ id: 'app.common.actions.export' }),
                isDisabled: isDataEmpty || mutationExport.isPending
            }
        ]
    }, [page, intl, isDataEmpty, mutationExport, search.parsedState, filterBy.parsedState])
    const bulkActions = useMemo(() => {
        return getReconcileEntityBulkActions(!page.hasActiveContext)
    }, [page])
    const onTableRowClick = useTableRowNavigation<IncomingPayment>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout className="IncomingPayments">
            <ReconcileEntityDynamicHeader>
                <PageHeaderLayout title={title} showBackButton={false} />
            </ReconcileEntityDynamicHeader>
            <RestrictedSection
                feature={ENTITY_FEATURE.INCOMING_PAYMENT}
                permission={PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS}>
                <QuickFilterProvider<IncomingPaymentsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <BulkActionsProvider<IncomingPayment, IncomingPaymentsServiceFindAllQueryOptions>
                        targetObject={ApiObjectTypeSchema.enum.incoming_payment}
                        actions={bulkActions}
                        filterBy={filterBy}
                        search={search}
                        data={data}>
                        <TableHeader<IncomingPaymentsServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeExtendedSchema.enum.incoming_payment}
                            isLoading={query.isLoading}
                            actions={actions}
                            filterBy={filterBy}
                            search={search}
                        />
                        {(() => {
                            switch (true) {
                                case query.isLoading: {
                                    return <LoadingSpinner />
                                }

                                case isDataEmpty:
                                case query.isError: {
                                    return <EmptyContent {...emptyContentProps} />
                                }

                                default: {
                                    return (
                                        <BulkActionsTable<IncomingPayment>
                                            data={data}
                                            onRowClick={onTableRowClick}
                                            onScrollToBottom={query.fetchNextPage}
                                            isLoading={query.hasNextPage}
                                            state={INCOMING_PAYMENT_TABLE_STATIC_STATE}
                                            columnCustomizers={INCOMING_PAYMENT_TABLE_COLUMN_CUSTOMIZERS}
                                        />
                                    )
                                }
                            }
                        })()}
                    </BulkActionsProvider>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
