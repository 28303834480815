import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { PageLayout } from '@/layouts'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    VIRTUAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS,
    VIRTUAL_ACCOUNT_DETAILS_ROWS
} from './VirtualAccountDetails.page.const'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { VirtualAccount } from '@webapps/numeral-ui-core'
import { useQueryFindByIdVirtualAccount } from '@/hooks/api'
import { VirtualAccountCustomRows } from '@/pages/Counterparties/VirtualAccounts/VirtualAccountDetails/VirtualAccountDetails.page.types'

export const VirtualAccountDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdVirtualAccount(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<VirtualAccount, VirtualAccountCustomRows>
                                    data={query.data}
                                    rows={VIRTUAL_ACCOUNT_DETAILS_ROWS}
                                    rowCustomizers={VIRTUAL_ACCOUNT_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
