import './TableHeaderPanel.scss'
import { Box, BoxProps, Divider, Flex } from '@chakra-ui/react'

export const TableHeaderPanelHeader: React.FC<BoxProps> = ({ title, children }) => {
    return (
        <Flex direction="column">
            <Flex gap="16px" alignItems="center" flex="1" justifyContent="space-between">
                <Box title={title} fontWeight="500">
                    {title}
                </Box>
                <Box>{children}</Box>
            </Flex>
            <Divider marginTop="8px" marginBottom="16px" />
        </Flex>
    )
}
