import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { isString } from 'lodash'

const SORT_CODE_PATTERN = /^(\d{2})[-]?(\d{2})[-]?(\d{2})$/
const SORT_CODE_WITH_DASH_PATTERN = '$1-$2-$3'

export function sortCodeFormatter(value?: string): string {
    if (!isString(value)) {
        return EMPTY_CHAR_SYMBOL
    }

    return value?.replace(SORT_CODE_PATTERN, SORT_CODE_WITH_DASH_PATTERN)
}
