import { WILDCARD_SYMBOL } from '@/constants'
import { assign, chain } from 'lodash'
import { QueryParamConfigurationFilterBy } from '@/hooks'

/**
 * @description
 * Moves "All" at the tail or keeps it at the head,
 * depending on the quick filters configuration
 */
export function rotateAllWildcardsAcrossQuickFilters<T>(
    quickFiltersConfiguration: QueryParamConfigurationFilterBy<T>['quickFilters']
) {
    const wildcard = { [WILDCARD_SYMBOL]: [WILDCARD_SYMBOL] }
    return chain(quickFiltersConfiguration).has(WILDCARD_SYMBOL).value()
        ? assign(Object.create(null), quickFiltersConfiguration, wildcard)
        : assign(wildcard, quickFiltersConfiguration)
}
