import { If, SupportEmailMailtoLink } from '@/components/@misc'
import { DOT_SYMBOL, SPACE_SYMBOL } from '@/constants'
import { useMutationProcessPayments, useToasts } from '@/hooks'
import { TOAST_VARIANTS } from '@/providers'
import { Alert, AlertDescription, AlertIcon, Link, Spinner, Text } from '@chakra-ui/react'
import { values } from 'lodash'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const MainNavigationProcessPayments: React.FC = () => {
    const intl = useIntl()
    const { onAdd } = useToasts()
    const triggerErrorToast = useCallback(
        () =>
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                isClosable: true,
                title: intl.formatMessage({
                    id: 'app.payments.process_payments.actions.process.error.toast.title'
                }),
                description: intl.formatMessage(
                    { id: 'app.payments.process_payments.actions.process.error.toast.description' },
                    {
                        supportEmailMailtoLink: <SupportEmailMailtoLink />
                    }
                )
            }),
        [intl, onAdd]
    )
    const mutation = useMutationProcessPayments({
        onSuccess(response) {
            const hasUnsuccessfullyProcessedPayments = values(response.accounts).some(
                (processingResult) => processingResult.unsuccessfully_processed != null
            )
            if (hasUnsuccessfullyProcessedPayments) {
                triggerErrorToast()
            } else {
                onAdd({
                    variant: TOAST_VARIANTS.SUCCESS,
                    status: 'success',
                    isClosable: true,
                    title: intl.formatMessage({
                        id: 'app.payments.process_payments.actions.process.success.toast.title'
                    }),
                    description: intl.formatMessage({
                        id: 'app.payments.process_payments.actions.process.success.toast.description'
                    })
                })
            }
        },
        onError(error) {
            triggerErrorToast()
        }
    })
    const onSubmit = useCallback(() => {
        if (mutation.isPending) {
            return
        }

        mutation.mutate({
            connected_account_ids: ['*'],
            payment_types: ['*']
        })
    }, [mutation])

    return (
        <Alert alignItems="flex-start" variant="left-accent" status="info" margin="8px 16px 16px" width="inherit">
            <AlertIcon />
            <AlertDescription>
                <Text>
                    <FormattedMessage id="app.payments.process_payments.description" />
                    {SPACE_SYMBOL}
                    <Link onClick={onSubmit}>
                        <FormattedMessage id="app.payments.process_payments.actions.process.label" />
                    </Link>
                    {DOT_SYMBOL}
                    <If condition={mutation.isPending}>
                        <Spinner height="12px" width="12px" marginX="4px" color="orange" />
                    </If>
                </Text>
            </AlertDescription>
        </Alert>
    )
}
