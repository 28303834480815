import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import { useMutationApproveCounterpartyAccount, useNavigationRoutes, useToasts } from '@/hooks'
import { TOAST_VARIANTS, updateUUIDRelativeActionPathname } from '@/providers'
import { canApproveOrDenyCounterpartyAccountByStatus } from '@/services'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { CounterpartyAccount } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

interface CounterpartyAccountApprovalProps {
    data?: CounterpartyAccount
}

export const CounterpartyAccountApproval: React.FC<CounterpartyAccountApprovalProps> = ({ data }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const mutationApprove = useMutationApproveCounterpartyAccount(data?.id, {
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.counterparties.counterparty_accounts.approve.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.counterparty_accounts.approve.success.toast.description" />
                )
            })
        }
    })
    const isVisible = useMemo<boolean>(() => canApproveOrDenyCounterpartyAccountByStatus(data?.status), [data])
    const onApprove = useCallback(() => {
        mutationApprove.mutate()
    }, [mutationApprove])
    const onDeny = useCallback(() => {
        const { COUNTERPARTIES } = relativePaths
        const path = updateUUIDRelativeActionPathname(location.pathname, COUNTERPARTIES.DENY)

        navigate(path)
    }, [location, navigate, relativePaths])
    const isLoading = useMemo<boolean>(() => {
        return mutationApprove.isPending
    }, [mutationApprove])
    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="CounterpartyAccountApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                <FormattedMessage id="app.counterparties.counterparty_accounts.tabs.details.approval.title" />
                            </Text>
                            <Text>
                                <FormattedMessage id="app.counterparties.counterparty_accounts.tabs.details.approval.description" />
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                colorScheme="gray"
                                onClick={onDeny}
                                disabled={isLoading}
                                variant="outline"
                                backgroundColor="white">
                                <FormattedMessage id="app.counterparties.counterparty_accounts.tabs.details.approval.actions.deny.label" />
                            </Button>
                            <Button
                                onClick={onApprove}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationApprove.isPending)}
                                disabled={isLoading}
                                variant="solid">
                                <FormattedMessage id="app.counterparties.counterparty_accounts.tabs.details.approval.actions.approve.label" />
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
