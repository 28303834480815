import { Icon, IconProps } from '@chakra-ui/react'

export const LeftArrowIcon: React.FC<IconProps> = (props) => (
    <Icon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
        <path
            d="M5.53033 1.53033C5.82322 1.23744 5.82322 0.762563 5.53033 0.46967C5.23744 0.176777 4.76256 0.176777 4.46967 0.46967L5.53033 1.53033ZM1 5L0.46967 4.46967C0.176777 4.76256 0.176777 5.23744 0.46967 5.53033L1 5ZM4.46967 9.53033C4.76256 9.82322 5.23744 9.82322 5.53033 9.53033C5.82322 9.23744 5.82322 8.76256 5.53033 8.46967L4.46967 9.53033ZM4.46967 0.46967L0.46967 4.46967L1.53033 5.53033L5.53033 1.53033L4.46967 0.46967ZM0.46967 5.53033L4.46967 9.53033L5.53033 8.46967L1.53033 4.46967L0.46967 5.53033Z"
            fill="currentColor"
        />
    </Icon>
)
