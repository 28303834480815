import { useAuth, useConfiguration, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ConnectedAccount, ConnectedAccountsServiceFindAllQueryOptions, List } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllConnectedAccounts(
    params?: Partial<ConnectedAccountsServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<ConnectedAccount>, Error>>
) {
    const { connectedAccountsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { pagination } = useConfiguration()
    const { hasPermission } = usePermissions()
    const isQueryEnabled =
        isUserLoggedIn && hasPermission(PERMISSION.ACCOUNTS_VIEW_CONNECTED_ACCOUNTS) && options?.enabled

    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useInfiniteQuery<List<ConnectedAccount>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [connectedAccountsService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return connectedAccountsService.findAll(
                { ...paramsWithUserAccess, ...(pageParam as typeof params) },
                { signal }
            )
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
