import { useMutationUserInfo, useQueryFindCurrentUserInfo } from '@/hooks'
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { UserInfo } from '@webapps/numeral-ui-core'
import { omit } from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { If } from '../@misc'
import { UserInfoForm } from './@components'
import { USER_INFO_HIDDEN_KEYS } from './IncompleteUserInfoModal.const'
import { getUserInfoUpdatedFields, isUserInfoIncomplete } from './IncompleteUserInfoModal.utils'

export const IncompleteUserInfoModal: React.FC<PropsWithChildren> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const queryUserInfo = useQueryFindCurrentUserInfo()
    const mutation = useMutationUserInfo({ onSuccess: onClose })
    const initialUserInfo = useMemo(() => omit(queryUserInfo.data, USER_INFO_HIDDEN_KEYS), [queryUserInfo])

    const hasIncompleteUserInfo = useMemo(() => {
        return queryUserInfo?.data && isUserInfoIncomplete(queryUserInfo.data)
    }, [queryUserInfo])

    const onUserInfoUpdate = useCallback(
        (data: Partial<UserInfo>) => {
            const updatedUserInfo = getUserInfoUpdatedFields<UserInfo>(queryUserInfo.data, data)

            if (Object.keys(updatedUserInfo).length > 0) {
                mutation.mutate(updatedUserInfo)
            }
        },
        [queryUserInfo, mutation]
    )

    useEffect(() => {
        onOpen()
    }, [onOpen])

    return (
        <>
            <If condition={hasIncompleteUserInfo}>
                <Modal closeOnOverlayClick={false} size="xl" isOpen={isOpen} onClose={onClose} isCentered={true}>
                    <ModalOverlay />
                    <ModalContent padding="40px">
                        <UserInfoForm
                            data={initialUserInfo}
                            onSubmit={onUserInfoUpdate}
                            isLoading={mutation.isPending}
                        />
                    </ModalContent>
                </Modal>
            </If>
            {children}
        </>
    )
}
