import { SPACE_SYMBOL } from '@/constants'
import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { anythingValidator } from '@/utils/@validators'
import { Flex, FormControl, FormErrorMessage, FormLabel, Text, Textarea } from '@chakra-ui/react'
import { chain, toArray } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'

export const TextareaInput: React.FC<FormikInput> = ({
    name,
    value,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    validator = anythingValidator,
    isRequired = false,
    isDisabled = false,
    width = '100%',
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<typeof value>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])

    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.${name}.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])

    const errorMessages = useMemo<ReactNode>(() => {
        const hasMultipleErrors = chain(meta.error).isArray().value()

        if (!hasMultipleErrors) {
            return meta.error
        }

        return (
            <Flex direction="column" gap="4px">
                {toArray(meta.error).map((message, key) => (
                    <Text key={key}>{message}</Text>
                ))}
            </Flex>
        )
    }, [meta])

    return (
        <FormControl width={width} isInvalid={meta.touched && !!meta.error}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Textarea {...field} placeholder={placeholder} />
            <FormErrorMessage>{errorMessages}</FormErrorMessage>
        </FormControl>
    )
}
