import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { TableCellTextIntl } from '@/components'

export const ReconciliationPaymentType: React.FC<{ value?: PaymentType }> = ({ value }) => {
    if (PaymentTypeSchema.safeParse(value).success) {
        return <TableCellTextIntl messageId={`api.object.${value}`} />
    }

    return <TableCellTextIntl messageId="app.table.cell.payment_type_not_applicable" />
}
