import { ORDERED_UNITS, ORDERED_UNIT_STEP_MULTIPLIER, SIZE_UNIT } from './EntityDetailCellFileSize.const'

export const getConvertedSizeWithUnit = (
    sizeInBytes: number
): {
    size: number
    unit: SIZE_UNIT
} => {
    if (!sizeInBytes || sizeInBytes <= 0) {
        return {
            size: 0,
            unit: SIZE_UNIT.BYTE
        }
    }
    const maxUnitIndex = ORDERED_UNITS.length - 1
    let unitIndex = Math.floor(Math.log(sizeInBytes) / Math.log(ORDERED_UNIT_STEP_MULTIPLIER))
    unitIndex = unitIndex > maxUnitIndex ? maxUnitIndex : unitIndex

    const size = sizeInBytes / Math.pow(ORDERED_UNIT_STEP_MULTIPLIER, unitIndex)
    const unit = ORDERED_UNITS[unitIndex]

    return { size, unit }
}
