import { EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { IncomingPayment } from '@webapps/numeral-ui-core'
import {
    INCOMING_PAYMENT_DETAILS_ROW_CUSTOMIZERS,
    INCOMING_PAYMENT_DETAILS_ROWS
} from './IncomingPaymentDetails.page.const'
import { IncomingPaymentDetailsCustomRows } from './IncomingPaymentDetails.types'
import { getDirectDebitMandateRowCustomizers, isPaymentMandateEntityRowVisible } from '@/pages/Common/Payments'

export function geIncomingPaymentEntityDetailRows(item?: IncomingPayment) {
    const rows = [...INCOMING_PAYMENT_DETAILS_ROWS]

    if (!isPaymentMandateEntityRowVisible(item)) {
        return rows
    }

    const directDebitMandateRow: EntityDetailRow<IncomingPayment, IncomingPaymentDetailsCustomRows>[] = [
        'direct_debit_mandate'
    ]
    const relatedObjectsRowIndex = rows.findIndex((row) => row === 'related_objects')

    rows.splice(relatedObjectsRowIndex, 0, directDebitMandateRow as any)

    return rows
}

export function getIncomingPaymentEntityDetailRowCustomizers(
    item?: IncomingPayment
): EntityDetailRowCustomizers<IncomingPayment> {
    const rowCustomizers: EntityDetailRowCustomizers<IncomingPayment, IncomingPaymentDetailsCustomRows> =
        Object.create(null)

    if (!isPaymentMandateEntityRowVisible(item)) {
        return INCOMING_PAYMENT_DETAILS_ROW_CUSTOMIZERS
    }

    if (item) {
        rowCustomizers.direct_debit_mandate = getDirectDebitMandateRowCustomizers(item)
    }

    return Object.assign(rowCustomizers, INCOMING_PAYMENT_DETAILS_ROW_CUSTOMIZERS)
}
