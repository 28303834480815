import { FormattedAmount } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { PaymentFileTotalAmounts } from '@webapps/numeral-ui-core'
import React from 'react'

interface PaymentFileDetailsTotalAmountsProps {
    totalAmounts: PaymentFileTotalAmounts
}

export const PaymentFileDetailsTotalAmounts: React.FC<PaymentFileDetailsTotalAmountsProps> = React.memo(
    ({ totalAmounts }) => {
        if (!totalAmounts || !totalAmounts.length) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        return (
            <>
                {totalAmounts.map((paymentFileAmount, index) => {
                    const isFirstItem = index === 0
                    return (
                        <Text key={paymentFileAmount.currency} marginTop={isFirstItem ? undefined : '8px'}>
                            <FormattedAmount amount={paymentFileAmount.amount} currency={paymentFileAmount.currency} />
                        </Text>
                    )
                })}
            </>
        )
    }
)
