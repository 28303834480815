import {
    DirectDebitMandateStatusSchema,
    EventStatusSchema,
    FileStatusSchema,
    IncomingPaymentStatusSchema,
    PaymentOrderStatusSchema,
    ReconciliationStatusSchema,
    ReturnRequestStatusSchema
} from '@webapps/numeral-ui-core'
import { StatusLabelValue } from './StatusLabel.types'

export const computeStatusLabelProps = (status?: StatusLabelValue) => {
    const partialLabelId = 'api.status.'

    switch (status) {
        case 'disabled':
        case ReconciliationStatusSchema.enum.excluded:
        case IncomingPaymentStatusSchema.enum.pending_confirmation:
        case PaymentOrderStatusSchema.enum.sent:
        case PaymentOrderStatusSchema.enum.pending:
        case PaymentOrderStatusSchema.enum.pending_approval:
        case PaymentOrderStatusSchema.enum.processing:
        case EventStatusSchema.enum.created:
        case EventStatusSchema.enum.archived:
        case PaymentOrderStatusSchema.enum.retried: {
            return {
                color: 'gray.600',
                backgroundColor: 'gray.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case IncomingPaymentStatusSchema.enum.pending_return:
        case PaymentOrderStatusSchema.enum.returned:
        case EventStatusSchema.enum.pending_retry: {
            return {
                color: 'orange.600',
                backgroundColor: 'orange.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case 'active':
        case 'verified':
        case 'enabled':
        case 'activate':
        case IncomingPaymentStatusSchema.enum.confirmed:
        case ReturnRequestStatusSchema.enum.accepted:
        case PaymentOrderStatusSchema.enum.approved:
        case PaymentOrderStatusSchema.enum.executed:
        case IncomingPaymentStatusSchema.enum.received:
        case FileStatusSchema.enum.processed:
        case EventStatusSchema.enum.delivered: {
            return {
                color: 'green.600',
                backgroundColor: 'green.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        case 'deactivate':
        case DirectDebitMandateStatusSchema.enum.blocked:
        case PaymentOrderStatusSchema.enum.canceled:
        case EventStatusSchema.enum.failed:
        case FileStatusSchema.enum.rejected:
        case ReturnRequestStatusSchema.enum.denied: {
            return {
                color: 'red.600',
                backgroundColor: 'red.100',
                labelId: `${partialLabelId}${status}`
            }
        }

        default: {
            return {
                color: 'gray.600',
                backgroundColor: 'gray.100'
            }
        }
    }
}
