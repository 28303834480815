import { chain } from 'lodash'

export function getPrimarySpacingValue(): number {
    const { documentElement } = globalThis.document
    const computedStyle = globalThis.getComputedStyle(documentElement)

    return chain<CSSStyleDeclaration>(computedStyle)
        .invoke('getPropertyValue', '--numeral-ui-primary-spacing')
        .replace(/px|rem/, '')
        .toNumber()
        .defaultTo(0)
        .value()
}
