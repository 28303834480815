import { BoxProps, FlexProps } from '@chakra-ui/react'
import { FORM_MAX_WIDTH } from '@/components'

export const propsFormContainer: FlexProps = {
    maxWidth: '100%',
    direction: {
        base: 'column',
        lg: 'row'
    }
}

export const propsRolesAndPermissionsContainerCommon: BoxProps = {
    maxWidth: FORM_MAX_WIDTH,
    flexShrink: 0
}

export const propsRolesAndPermissionsContainerRight: BoxProps = {
    width: '100%',
    paddingRight: { base: 'unset', lg: '32px' }
}
