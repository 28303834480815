import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { OPERATOR_NAME } from './FormattedComperator.const'
import { FormattedComperatorProps } from './FormattedComperator.types'

export const FormattedComperator: React.FC<FormattedComperatorProps> = memo(({ comparator }) => {
    if (!comparator) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Text as="span">
            <FormattedMessage
                id={`app.common.comparator.${OPERATOR_NAME[comparator]}.label`}
                defaultMessage={comparator}
            />
        </Text>
    )
})
