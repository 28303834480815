import { Icon } from '@chakra-ui/react'

export const ScreenIcon: React.FC<any> = (props) => (
    <Icon width="45" height="45" viewBox="0 0 45 45" {...props}>
        <rect x="5%" y="5%" width="85%" height="55%" fill="transparent" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 11.2498C4.5 9.59291 5.84316 8.24976 7.5 8.24976H37.5C39.1568 8.24976 40.5 9.59291 40.5 11.2498V29.9998C40.5 31.6565 39.1568 32.9998 37.5 32.9998H23.625V36.3748H31.875V38.6248H13.125V36.3748H21.375V32.9998H7.5C5.84316 32.9998 4.5 31.6567 4.5 29.9998V11.2498ZM7.5 10.4998C7.08579 10.4998 6.75 10.8355 6.75 11.2498V29.9998C6.75 30.414 7.08579 30.7498 7.5 30.7498H37.5C37.9142 30.7498 38.25 30.414 38.25 29.9998V11.2498C38.25 10.8355 37.9142 10.4998 37.5 10.4998H7.5Z"
            fill="currentColor"
        />
        <path
            d="M26.0022 24.4591H23.6655V20.5647H21.3289V24.4591H18.9922V19.3964C18.9922 18.7511 19.5153 18.228 20.1605 18.228H24.8339C25.4791 18.228 26.0022 18.7511 26.0022 19.3964V24.4591Z"
            fill="currentColor"
        />
    </Icon>
)
