import {
    EntityCellReturnDirection,
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import {
    ConnectedAccountName,
    FormattedAmount,
    FormattedDate,
    FormattedDateTime,
    ReconciliationStatusLabel,
    SepaReturnReasonDescription,
    StatusLabel
} from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { Return } from '@webapps/numeral-ui-core'
import { ReturnDetailsCustomRows } from './ReturnDetails.types'

export const RETURN_DETAILS_ROWS: EntityDetailRow<Return, ReturnDetailsCustomRows>[] = [
    'id',
    'created_at',
    'direction',
    'returned_amount',
    'connected_account_id',
    'receiving_account_number',
    'return_reason',
    'value_date',
    'status',
    'status_details',
    'reconciliation_status',
    'reconciled_amount',
    'related_objects',
    'metadata',
    'bank_data'
]

export const RETURN_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<Return, ReturnDetailsCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    direction: {
        cell(item) {
            return <EntityCellReturnDirection value={item.related_payment_type} type={item.object} />
        }
    },
    returned_amount: {
        cell(item) {
            return <FormattedAmount currency={item.currency} amount={item.returned_amount} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    receiving_account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.receiving_account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    return_reason: {
        cell(item) {
            return <SepaReturnReasonDescription sepaReturnReason={item.return_reason} />
        }
    },
    value_date: {
        cell(item) {
            return <FormattedDate value={item.value_date} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    reconciliation_status: {
        cell(item) {
            return <ReconciliationStatusLabel value={item.reconciliation_status} />
        }
    },
    reconciled_amount: {
        cell(item) {
            return <FormattedAmount currency={item.currency} amount={item.reconciled_amount} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedPaymentId={item.related_payment_id}
                    relatedPaymentType={item.related_payment_type}
                    showRelatedPaymentLink={true}
                    showRelatedReturnRequestLink={true}
                    relatedFileId={item.bank_data?.file_id}
                />
            )
        }
    },
    metadata: {
        nested: true
    },
    bank_data: {
        nested: true
    }
}
