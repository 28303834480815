import {
    EntityDetailCellRelatedObjectsLinks,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { ConnectedAccountName, FormattedDateTime, SepaReturnReasonDescription, StatusLabel } from '@/components/@misc'
import { Inquiry, SepaReturnReason } from '@webapps/numeral-ui-core'
import { getInquiryStatusMapper } from '../Inquiries.page.utils'
import { InquiryDetailsCustomRows } from './InquiryDetails.types'

export const INQUIRY_DETAILS_ROWS: EntityDetailRow<Inquiry, InquiryDetailsCustomRows>[] = [
    'id',
    'created_at',
    'connected_account_id',
    'status',
    'deny_reason',
    'denied_at',
    'related_objects',
    'bank_data'
]

export const INQUIRY_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<Inquiry, InquiryDetailsCustomRows> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    connected_account_id: {
        cell(item) {
            return <ConnectedAccountName connectedAccountId={item.connected_account_id} />
        }
    },
    status: {
        cell(item) {
            const value = getInquiryStatusMapper(item)
            return <StatusLabel value={value} />
        }
    },
    deny_reason: {
        cell(item) {
            return <SepaReturnReasonDescription sepaReturnReason={item.deny_reason as SepaReturnReason} />
        }
    },
    denied_at: {
        cell(item) {
            return <FormattedDateTime value={item.denied_at} />
        }
    },
    related_objects: {
        cell(item) {
            return (
                <EntityDetailCellRelatedObjectsLinks
                    relatedPaymentId={item.related_request_id}
                    relatedPaymentType={item.related_request_type}
                    showRelatedPaymentLink={true}
                    relatedFileId={item.bank_data?.file_id}
                />
            )
        }
    },
    bank_data: {
        nested: true
    }
}
