import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useDisclosure
} from '@chakra-ui/react'
import { useNativeNavigationBlocker, usePage, useRouterNavigationBlocker } from '@/hooks'
import { Payment } from '@/types'
import { Transaction } from '@webapps/numeral-ui-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { RECONCILE_ENTITY_PAGE_CONTEXT, useReconciliation } from '@/pages'

export const ReconcileEntityCancelConfirmation: React.FC = () => {
    const containerRef = useRef(globalThis.document.getElementById('root'))
    const cancelRef = useRef(null)

    const { getNonPersistedState } = usePage<Payment | Transaction>()
    const reconcileEntityNonPersistedState = getNonPersistedState(RECONCILE_ENTITY_PAGE_CONTEXT)
    const [isOpen, setIsOpen] = useState(false)
    const { onCancelReconciliation } = useReconciliation()
    const navigate = useNavigate()
    const { onClose } = useDisclosure({
        onClose() {
            setIsOpen(false)
        }
    })

    const hasContext = useMemo(
        () => globalThis.Boolean(reconcileEntityNonPersistedState),
        [reconcileEntityNonPersistedState]
    )
    const redirectToPathname = useRef<string | undefined>(undefined)
    const onLeave = useCallback(() => {
        onClose()
        onCancelReconciliation()
    }, [])

    useNativeNavigationBlocker(hasContext)
    useRouterNavigationBlocker({
        onBlock({ retry, cancel, redirectTo }) {
            if (hasContext) {
                // Set path for redirect:
                redirectToPathname.current = redirectTo.pathname
                setIsOpen(true)
                retry()
            } else {
                // Clear path for redirect:
                redirectToPathname.current = undefined
                setIsOpen(false)
                cancel()
            }
        },
        enabled: hasContext
    })

    useEffect(() => {
        if (!hasContext && redirectToPathname.current) {
            navigate(redirectToPathname.current)
        }
    }, [hasContext, redirectToPathname])

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} portalProps={{ containerRef }}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <FormattedMessage id="app.reconcile.cancel.confirm.title" />
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <FormattedMessage id="app.reconcile.cancel.confirm.description" />
                    </AlertDialogBody>
                    <AlertDialogFooter gap="16px">
                        <Button variant="secondary" onClick={onLeave}>
                            <FormattedMessage id="app.reconcile.cancel.confirm.actions.leave.label" />
                        </Button>
                        <Button onClick={onClose}>
                            <FormattedMessage id="app.reconcile.cancel.confirm.actions.close.label" />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
