import { Flex, Text } from '@chakra-ui/react'
import { FormattedAmount, If, isBalanceNegative, RestrictedData, Separator, SeparatorVariant } from '@/components/@misc'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'
import { get, isEmpty } from 'lodash'
import { SelectOption } from '@/components/@misc/Select/@components'
import { ConnectedAccountExpanded, PERMISSION } from '@/services'
import { Balance, ConnectedAccount } from '@webapps/numeral-ui-core'
import { SPACE_SYMBOL } from '@/constants'
import { usePermissions } from '@/hooks'
import { FormattedMessage } from 'react-intl'
import { isLastIndex } from '@/utils'

export const ConnectedAccountsInputSelectOption: React.FC<OptionProps<ConnectedAccount | ConnectedAccountExpanded>> = ({
    children,
    innerRef,
    innerProps,
    ...props
}) => {
    const { hasPermission } = usePermissions()

    const name = get(props, 'data.original.name')
    const latestBalances = get(props, 'data.original.latest_balances', [] as Balance[])

    return (
        <SelectOption innerRef={innerRef} innerProps={innerProps} {...props}>
            <Text color="gray.700" noOfLines={1} title={name}>
                {children}
            </Text>
            <Text color="gray.400" fontSize="small">
                {(() => {
                    switch (true) {
                        case !hasPermission(PERMISSION.ACCOUNTS_VIEW_BALANCES): {
                            return <RestrictedData />
                        }

                        case isEmpty(latestBalances): {
                            return (
                                <FormattedMessage id="app.common.form.input.connected_account.latest_balance.empty_content" />
                            )
                        }

                        default: {
                            return (
                                <Flex noOfLines={1}>
                                    {latestBalances?.map((item, index, array) => {
                                        const isLastElement = isLastIndex(index, array)
                                        const hasNegativeBalance = isBalanceNegative(item.direction)
                                        return (
                                            <>
                                                <FormattedAmount
                                                    asNegative={hasNegativeBalance}
                                                    amount={item.amount}
                                                    currency={item.currency}
                                                    key={index}
                                                />
                                                <If condition={!isLastElement}>
                                                    <Separator variant={SeparatorVariant.Bullet} />
                                                </If>
                                            </>
                                        )
                                    })}
                                </Flex>
                            )
                        }
                    }
                })()}
            </Text>
        </SelectOption>
    )
}
