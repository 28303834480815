import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { markedFieldsTransform } from '@/services/@transformRequests'
import { addTransformRequestsToHttpClientInstance } from '@/services/@transformRequests/transformRequests.utils'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { ExpectedPayment, ExpectedPaymentCreateData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCreateExpectedPayment(
    options?: MutationOptions<ExpectedPayment, Error, ExpectedPaymentCreateData>
) {
    const { selectedUserAccess } = useAuth()
    const { expectedPaymentsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<ExpectedPayment, Error, ExpectedPaymentCreateData>({
        ...options,
        mutationKey: [expectedPaymentsService.url],
        mutationFn(data) {
            return expectedPaymentsService.createExpectedPayment(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(
                    expectedPaymentsService,
                    markedFieldsTransform
                )
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [expectedPaymentsService.url]
                })
                .catch(noop)
        }
    })
}
