import { QuickFilterContext, QuickFilterContextValue } from '@/providers'
import { useContext } from 'react'

export function useQuickFilter<T>() {
    const context = useContext(QuickFilterContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${QuickFilterContext.displayName}`)
    }

    return context as QuickFilterContextValue<T>
}
