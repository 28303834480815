import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, TransactionsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportTransactions(
    options?: MutationOptions<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>
) {
    const { selectedUserAccess } = useAuth()
    const { transactionsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [transactionsService.url],
        mutationFn(data) {
            return transactionsService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
