import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION, User } from '@/services'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdUser(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<User, Error>>) {
    const { usersService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_USERS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<User, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [usersService.url, { id }],
        queryFn({ signal }) {
            return usersService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
