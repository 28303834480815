import { Select } from '@/components/@misc'
import { useCustomFormikField, useQueryFindByIdConnectedAccount } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import {
    DirectDebitMandate,
    DirectDebitMandateType,
    DirectDebitMandateTypeSchema,
    ServiceNameSchema
} from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { anythingValidator } from '@/utils/@validators'
import { SPACE_SYMBOL } from '@/constants'
import { useFormikContext } from 'formik'

export const DirectDebitMandateTypeInput: React.FC<FormikInput> = ({
    name,
    value,
    isRequired,
    customLabelKey,
    customLabelValues,
    customPlaceholderKey,
    customPlaceholderValues,
    validator = anythingValidator,
    isPlaceholderDisplayed,
    isDisabled,
    ...inputProps
}) => {
    const { getFieldProps } = useFormikContext<DirectDebitMandate>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<DirectDebitMandateType>({
        name,
        value,
        validate: validator(intl, isRequired)
    })
    const label = useMemo<string>(() => {
        const labelKey = customLabelKey || `app.common.form.input.${name}.label`
        return intl.formatMessage({ id: labelKey }, customLabelValues)
    }, [name, customLabelKey, intl, customLabelValues])
    const placeholder = useMemo<string>(() => {
        const messageKey = customPlaceholderKey ?? `app.common.form.input.select.placeholder`
        return intl.formatMessage({ id: messageKey, defaultMessage: SPACE_SYMBOL }, customPlaceholderValues)
    }, [intl, name, customPlaceholderKey, customPlaceholderValues])
    const filteredOptions = useMemo(() => {
        const servicesActivated = query?.data?.services_activated
        const result = [] as DirectDebitMandateType[]

        if (servicesActivated?.includes(ServiceNameSchema.enum.sdd)) {
            result.push(DirectDebitMandateTypeSchema.enum.sepa_core)
            result.push(DirectDebitMandateTypeSchema.enum.sepa_b2b)
        }

        if (servicesActivated?.includes(ServiceNameSchema.enum.bacs_direct_debit)) {
            result.push(DirectDebitMandateTypeSchema.enum.bacs_debit)
        }

        return result
    }, [query.data])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Select<DirectDebitMandateType>
                {...inputProps}
                {...field}
                id={name}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                isRequired={isRequired}
                placeholder={placeholder}
                onChange={helpers.setValue}
                options={filteredOptions}
                getOptionLabel={(value) => intl.formatMessage({ id: `api.direct_debit_mandate.type.${value}` })}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
