import { Icon, IconProps } from '@chakra-ui/react'

export const LogoutIcon: React.FC<IconProps> = (props) => (
    <Icon width="20px" height="20px" viewBox="0 0 20 20" {...props}>
        <path
            d="M9.16667 10.8333V2.5H10.8333V10.8333H9.16667ZM10 17.5C8.97222 17.5 8.00333 17.3022 7.09333 16.9067C6.18389 16.5106 5.38889 15.9722 4.70833 15.2917C4.02778 14.6111 3.48944 13.8161 3.09333 12.9067C2.69778 11.9967 2.5 11.0278 2.5 10C2.5 8.88889 2.72917 7.84028 3.1875 6.85417C3.64583 5.86806 4.29167 5.01389 5.125 4.29167L6.29167 5.45833C5.625 6.01389 5.10417 6.6875 4.72917 7.47917C4.35417 8.27083 4.16667 9.11111 4.16667 10C4.16667 11.6111 4.73611 12.9861 5.875 14.125C7.01389 15.2639 8.38889 15.8333 10 15.8333C11.625 15.8333 13.0033 15.2639 14.135 14.125C15.2672 12.9861 15.8333 11.6111 15.8333 10C15.8333 9.11111 15.6492 8.27083 15.2808 7.47917C14.9131 6.6875 14.3889 6.01389 13.7083 5.45833L14.875 4.29167C15.7083 5.01389 16.3542 5.86806 16.8125 6.85417C17.2708 7.84028 17.5 8.88889 17.5 10C17.5 11.0278 17.3022 11.9967 16.9067 12.9067C16.5106 13.8161 15.9758 14.6111 15.3025 15.2917C14.6286 15.9722 13.8369 16.5106 12.9275 16.9067C12.0175 17.3022 11.0417 17.5 10 17.5Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
        />
    </Icon>
)
