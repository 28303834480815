export const USER_IDENTITY_PROVIDERS_PATTERN = /google|windowslive|linkedin|github/

export enum USER_ACCESS_CONFIGURATION_ENTITY_TYPE {
    CORPORATE = 'corporate',
    FINANCIAL_INSTITUTION = 'financial_institution'
}

export enum ENTITY_FEATURE {
    PAYMENT_ORDER = 'payment_order',
    EXPECTED_PAYMENT = 'expected_payment',
    INCOMING_PAYMENT = 'incoming_payment',
    RETURN = 'return',
    RETURN_REQUEST = 'return_request',
    BALANCE = 'balance',
    TRANSACTION = 'transaction',
    COUNTERPARTY = 'counterparty',
    COUNTERPARTY_ACCOUNT_VERIFICATION = 'account_verification',
    DIRECT_DEBIT_MANDATE = 'direct_debit_mandate',
    RECONCILIATION = 'reconciliation',
    SIMULATOR = 'simulator',
    INQUIRY = 'inquiry',
    PAYMENT_CAPTURE = 'payment_capture',
    PAYMENT_RETRY_RULE = 'payment_retry_rule',
    BANK_CODE_LOOKUP = 'bank_code_lookup'
}

export enum PERMISSION {
    ACCOUNTS_ADMIN = 'accounts.admin',
    ACCOUNTS_VIEW_BALANCES = 'accounts.view_balances',
    ACCOUNTS_VIEW_CONNECTED_ACCOUNTS = 'accounts.view_connected_accounts',

    ACCOUNTS_EDIT_CONNECTED_ACCOUNTS = 'accounts.edit_connected_accounts',

    ACCOUNTS_VIEW_TRANSACTIONS = 'accounts.view_transactions',

    ACCOUNTS_EDIT_TRANSACTIONS = 'accounts.edit_transactions',

    COUNTERPARTIES_ADMIN = 'counterparties.admin',
    COUNTERPARTIES_APPROVE_COUNTERPARTIES = 'counterparties.approve_counterparties',
    COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS = 'counterparties.approve_counterparty_accounts',
    COUNTERPARTIES_CREATE_COUNTERPARTIES = 'counterparties.create_counterparties',
    COUNTERPARTIES_EDIT_COUNTERPARTIES = 'counterparties.edit_counterparties',
    COUNTERPARTIES_CREATE_COUNTERPARTY_ACCOUNTS = 'counterparties.create_counterparty_accounts',

    COUNTERPARTIES_EDIT_COUNTERPARTY_ACCOUNTS = 'counterparties.edit_counterparty_accounts',

    COUNTERPARTIES_VERIFY_COUNTERPARTY_ACCOUNTS = 'counterparties.verify_counterparty_accounts',
    COUNTERPARTIES_CREATE_VIRTUAL_ACCOUNTS = 'counterparties.create_virtual_accounts',
    COUNTERPARTIES_EDIT_VIRTUAL_ACCOUNTS = 'counterparties.edit_virtual_accounts',
    COUNTERPARTIES_VIEW_COUNTERPARTIES = 'counterparties.view_counterparties',
    COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS = 'counterparties.view_counterparty_accounts',
    COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS = 'counterparties.view_virtual_accounts',
    COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES = 'counterparties.view_direct_debit_mandates',
    COUNTERPARTIES_EDIT_DIRECT_DEBIT_MANDATES = 'counterparties.edit_direct_debit_mandates',
    COUNTERPARTIES_CREATE_DIRECT_DEBIT_MANDATES = 'counterparties.create_direct_debit_mandates',
    DEVELOPERS_ADMIN = 'developers.admin',
    DEVELOPERS_CONFIGURE_API_KEYS = 'developers.configure_api_keys',
    DEVELOPERS_CONFIGURE_WEBHOOKS = 'developers.configure_webhooks',
    DEVELOPERS_RESEND_FAILED_EVENTS = 'developers.resend_failed_events',
    DEVELOPERS_TRIGGER_BANK_SIMULATOR = 'developers.trigger_bank_simulator',
    DEVELOPERS_VIEW_API_KEYS = 'developers.view_api_keys',
    DEVELOPERS_VIEW_EVENTS = 'developers.view_events',
    DEVELOPERS_VIEW_LOGS = 'developers.view_logs',
    DEVELOPERS_VIEW_WEBHOOKS = 'developers.view_webhooks',
    FILES_ADMIN = 'files.admin',
    FILES_APPROVE_FILES = 'files.approve_files',
    FILES_DOWNLOAD_FILES = 'files.download_files',
    FILES_VIEW_FILES = 'files.view_files',
    PAYMENTS_ADMIN = 'payments.admin',
    PAYMENTS_APPROVE_INQUIRIES = 'payments.approve_inquiries',
    PAYMENTS_APPROVE_PAYMENT_ORDERS = 'payments.approve_payment_orders',
    PAYMENTS_APPROVE_RETURN_REQUESTS = 'payments.approve_return_requests',
    PAYMENTS_APPROVE_RETURNS = 'payments.approve_returns',
    PAYMENTS_CONFIRM_INCOMING_PAYMENTS = 'payments.confirm_incoming_payments',
    PAYMENTS_CREATE_EXPECTED_PAYMENTS = 'payments.create_expected_payments',
    PAYMENTS_CREATE_PAYMENT_ORDERS = 'payments.create_payment_orders',
    PAYMENTS_CREATE_RETURN_REQUESTS = 'payments.create_return_requests',
    PAYMENTS_CREATE_RETURNS = 'payments.create_returns',
    PAYMENTS_CREATE_PAYMENT_CAPTURES = 'payments.create_payment_captures',
    PAYMENTS_EDIT_EXPECTED_PAYMENTS = 'payments.edit_expected_payments',
    PAYMENTS_EDIT_PAYMENT_ORDERS = 'payments.edit_payment_orders',
    PAYMENTS_EDIT_PAYMENT_CAPTURES = 'payments.edit_payment_captures',
    PAYMENTS_VIEW_EXPECTED_PAYMENTS = 'payments.view_expected_payments',
    PAYMENTS_VIEW_INCOMING_PAYMENTS = 'payments.view_incoming_payments',
    PAYMENTS_VIEW_INQUIRIES = 'payments.view_inquiries',
    PAYMENTS_VIEW_PAYMENT_ORDERS = 'payments.view_payment_orders',
    PAYMENTS_VIEW_RETURN_REQUESTS = 'payments.view_return_requests',
    PAYMENTS_VIEW_RETURNS = 'payments.view_returns',
    PAYMENTS_VIEW_PAYMENT_CAPTURES = 'payments.view_payment_captures',
    RECONCILIATIONS_ADMIN = 'reconciliations.admin',
    RECONCILIATIONS_CONFIGURE_RECONCILIATIONS = 'reconciliations.configure_reconciliations',
    RECONCILIATIONS_VIEW_RECONCILIATIONS = 'reconciliations.view_reconciliations',
    SETTINGS_ADMIN = 'settings.admin',
    SETTINGS_CONFIGURE_APPROVAL_RULES = 'settings.configure_approval_rules',
    SETTINGS_CONFIGURE_BILLING = 'settings.configure_billing',
    SETTINGS_CONFIGURE_LEGAL_ENTITY = 'settings.configure_legal_entity',
    SETTINGS_CONFIGURE_RECONCILIATION_RULES = 'settings.configure_reconciliation_rules',
    SETTINGS_CONFIGURE_ROLES = 'settings.configure_roles',

    SETTINGS_EDIT_USERS = 'settings.edit_users',
    SETTINGS_EDIT_CUSTOM_FIELDS = 'settings.edit_custom_fields',

    SETTINGS_DISABLE_USERS = 'settings.disable_users',
    SETTINGS_DISABLE_CUSTOM_FIELDS = 'settings.disable_custom_fields',

    SETTINGS_CREATE_ROLES = 'settings.create_roles',
    SETTINGS_CREATE_CUSTOM_FIELDS = 'settings.create_custom_fields',

    SETTINGS_EXPORT_INVOICES = 'settings.export_invoices',
    SETTINGS_INVITE_USERS = 'settings.invite_users',
    SETTINGS_VIEW_APPROVAL_RULES = 'settings.view_approval_rules',
    SETTINGS_VIEW_BILLING = 'settings.view_billing',
    SETTINGS_VIEW_LEGAL_ENTITY = 'settings.view_legal_entity',
    SETTINGS_VIEW_RECONCILIATION_RULES = 'settings.view_reconciliation_rules',
    SETTINGS_VIEW_ROLES = 'settings.view_roles',
    SETTINGS_VIEW_USERS = 'settings.view_users',
    SETTINGS_VIEW_CUSTOM_FIELDS = 'settings.view_custom_fields',

    SETTINGS_VIEW_AUDIT_TRAILS = 'settings.view_audit_trails'
}
