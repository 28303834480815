import { chain } from 'lodash'
import { IntlShape } from 'react-intl'
import { CountryOption } from './CountryInput.types'

export function getCountryOptionWithIntlMapper(intl: IntlShape) {
    return (value: string, index?: number): CountryOption => {
        const name = chain<IntlShape>(intl)
            .invoke('formatDisplayName', value, { type: 'region' })
            .defaultTo(value)
            .deburr()
            .value()

        return {
            code: value,
            name
        }
    }
}
