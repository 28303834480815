import { ZodSchema } from 'zod'
import { IntlShape } from 'react-intl'
import { EventSchema, NumeralEvent } from '@webapps/numeral-ui-core'

export function getEventFormInitialValues(data?: Partial<NumeralEvent>): Partial<NumeralEvent> {
    return {
        //@todo
        ...data
    }
}

export function getEventFormValidationSchemaWithIntl(intl: IntlShape): ZodSchema {
    return EventSchema
}
