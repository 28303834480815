import { FieldValidator } from 'formik'
import { IntlShape } from 'react-intl'
import { z } from 'zod'
import { asOptionalField } from '../validators.utils'

const FIRST_NAME_MIN_LENGTH = 2

export const firstNameValidator =
    (intl: IntlShape, isRequired: boolean): FieldValidator =>
    (value: unknown) => {
        const schematic = z.string().min(FIRST_NAME_MIN_LENGTH)
        let isSuccess: boolean
        if (isRequired) {
            isSuccess = schematic.safeParse(value).success
        } else {
            isSuccess = asOptionalField(schematic).safeParse(value).success
        }

        if (!isSuccess) {
            return intl.formatMessage(
                { id: 'app.common.form.validation.first_name.invalid' },
                {
                    charLength: FIRST_NAME_MIN_LENGTH
                }
            )
        }
    }
