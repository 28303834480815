import {
    API_BASE_URLS,
    API_ENVIRONMENTS_PER_APP_ENVIRONMENT,
    SupportedApiEnvironmentName,
    SupportedEnvironment
} from '@/configuration'
import { ApiEnvironment } from './ConfigurationProvider.types'

export function getApiEnvironmentNames(): Array<SupportedApiEnvironmentName> {
    const apiEnvironmentNames =
        API_ENVIRONMENTS_PER_APP_ENVIRONMENT[globalThis.location.hostname as SupportedEnvironment]
    if (!apiEnvironmentNames) {
        throw new Error(`Environment "${globalThis.location.hostname}" is not supported`)
    }
    return apiEnvironmentNames
}

export function getApiEnvironment(apiEnvironmentName: SupportedApiEnvironmentName): ApiEnvironment {
    const apiEnvironmentUrl = API_BASE_URLS[apiEnvironmentName]
    if (!apiEnvironmentUrl) {
        throw new Error(
            `Api environment "${apiEnvironmentName}" is not supported in app environment "${globalThis.location.hostname}"`
        )
    }
    return { name: apiEnvironmentName, url: apiEnvironmentUrl }
}
