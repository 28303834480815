import { noop } from 'lodash'
import { createContext } from 'react'
import { AuthContextValue } from './AuthProvider.types'

export const AuthContext = createContext<AuthContextValue>({
    isUserLoggedIn: false,
    isUserLoggingOut: false,
    selectedUserAccess: undefined,
    activatedFeatures: undefined,
    userPermissions: undefined,
    selectUserAccess: noop,
    onLogin: noop,
    onLogout: noop
})
