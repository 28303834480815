import React, { useMemo } from 'react'
import { ApiObjectType, CustomField } from '@webapps/numeral-ui-core'
import { isEmptyRecord, queryDataAggregation } from '@/utils'
import { useQueryFindAllCustomFields } from '@/hooks'
import { Skeleton } from '@chakra-ui/react'

export function withCustomFieldsData<P = void>(Component: React.FC<P>, type?: ApiObjectType) {
    type ComponentProps = {
        data?: CustomField[]
        value?: Record<string, string>
    }

    return (props: ComponentProps & P) => {
        const hasEnabledQuery = useMemo(() => !isEmptyRecord(props.value), [props.value])
        const query = useQueryFindAllCustomFields({
            object_type: type,
            enabled: hasEnabledQuery
        })
        const data = useMemo<CustomField[]>(() => queryDataAggregation(query.data), [query])

        if (query.isLoading) {
            return <Skeleton width="220px" height="32px" />
        }

        return <Component {...props} data={data} />
    }
}
