import { GroupByTable, LoadingSpinner, useTableRowNavigation } from '@/components'
import { useNavigationRoutes } from '@/hooks'
import { BalanceExpanded, BalanceGroup } from '@/services'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { BALANCE_TABLE_STATIC_STATE } from '../../Balances.page.const'
import { BalancesEmptyContent } from '../BalancesEmptyContent'
import { BalancesGroupByHeader } from '../BalancesGroupByHeader'
import { BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY } from './BalancesGroupByTable.const'
import { BalancesGroupByTableProps } from './BalancesGroupByTable.types'

export const BalancesGroupByTable: React.FC<BalancesGroupByTableProps> = ({
    queryItem,
    isFiltering,
    isSearching,
    ...props
}) => {
    const { relativePaths } = useNavigationRoutes()
    const onTableRowClick = useTableRowNavigation<BalanceExpanded>('id', relativePaths.ACCOUNTS.DETAILS)
    const isDataEmpty = useMemo<boolean>(() => isEmpty(queryItem.data?.groups), [queryItem])

    switch (true) {
        case queryItem.isLoading: {
            return <LoadingSpinner />
        }

        case isDataEmpty:
        case queryItem.isError: {
            return <BalancesEmptyContent isFiltering={isFiltering} isSearching={isSearching} isEmpty={isDataEmpty} />
        }
        default: {
            return (
                <GroupByTable<BalanceExpanded, BalanceGroup>
                    {...props}
                    data={queryItem.data}
                    onRowClick={onTableRowClick}
                    groupHeader={BalancesGroupByHeader}
                    state={BALANCE_TABLE_STATIC_STATE}
                    columnCustomizers={BALANCE_TABLE_COLUMN_CUSTOMIZERS_GROUP_BY}
                />
            )
        }
    }
}
