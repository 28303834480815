import { createRef, RefObject, useEffect, useState } from 'react'

export function useIntersectionObserver(options?: IntersectionObserverInit): [RefObject<HTMLDivElement>, boolean] {
    const [isIntersecting, setIsIntersecting] = useState(false)
    const targetRef = createRef<HTMLDivElement>()
    const optionsWithDefault: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
        ...options
    }

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting)
        }, optionsWithDefault)

        if (targetRef.current) {
            observer.observe(targetRef.current)
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current)
            }
        }
    }, [options, targetRef])

    return [targetRef, isIntersecting]
}
