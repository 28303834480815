import { EmptyContent, EmptyContentProps, LoadingSpinner, RestrictedSection, Table } from '@/components'
import { useQueryFindAllAuditTrails } from '@/hooks'
import { PageLayout } from '@/layouts'
import { AuditTrail, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { AUDIT_TRAIL_TABLE_COLUMN_CUSTOMIZERS } from './AuditTrail.page.const'
import { AuditTrailPageProps } from './AuditTrail.page.types'
import {
    getAuditTrailEmptyStateProps,
    getAuditTrailQueryParams,
    getAuditTrailTableStaticState
} from './AuditTrail.page.utils'

export const AuditTrailPage: React.FC<AuditTrailPageProps> = ({ objectType, filterBy }) => {
    const { uuid } = useParams()
    const location = useLocation()
    const intl = useIntl()
    const params = useMemo(() => {
        return getAuditTrailQueryParams(filterBy, uuid)
    }, [location, uuid])
    const query = useQueryFindAllAuditTrails(params)
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const tableState = useMemo(() => {
        return getAuditTrailTableStaticState(filterBy)
    }, [filterBy])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (isDataEmpty) {
            return getAuditTrailEmptyStateProps(intl, objectType, filterBy)
        }
    }, [intl, isDataEmpty, objectType, filterBy])

    return (
        <PageLayout>
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_AUDIT_TRAILS}>
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isDataEmpty:
                        case query.isError: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<AuditTrail>
                                    data={data}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={tableState}
                                    columnCustomizers={AUDIT_TRAIL_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
