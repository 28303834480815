import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useIntl } from 'react-intl'
import { useQueryFindByIdAPIKey } from '@/hooks'
import { useAPIKeyActions } from '../../@hooks'

export const APIKeyHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const query = useQueryFindByIdAPIKey(uuid)
    const actions = useAPIKeyActions(query.data)
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = (query.isError && notFoundTitle) || query.data?.name || uuid

        return {
            title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query, uuid])

    return <PageHeaderLayout {...propsPageHeaderLayout} actions={actions} />
}
