import { Form, FormPageFooter } from '@/components'
import { TextInput, UserDepartmentInput } from '@/components/@inputs'
import { isUserFieldDisabled, User } from '@/services'
import { firstNameValidator } from '@/utils/@validators'
import { chain, noop } from 'lodash'
import { useMemo } from 'react'
import { UserRolesInput } from './@components'
import { UserEditFormProps } from './UserEdit.form.types'
import { getUserEditFormInitialValues } from './UserEdit.form.utils'

export const UserEditForm: React.FC<UserEditFormProps> = ({
    data,
    isEditingSelf,
    disabled,
    loading,
    onSubmit = noop
}) => {
    const initialValues = useMemo<User>(() => getUserEditFormInitialValues(data, isEditingSelf), [data, isEditingSelf])
    const dataWrapper = chain(data)

    return (
        <Form<User> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const selectedRoleId = chain(isEditingSelf ? data?.roles : values.roles)
                    .head()
                    .get('id')
                    .value()
                const hasFirstName = dataWrapper.get('first_name.length').gt(0).value()
                const hasLastName = dataWrapper.get('last_name.length').gt(0).value()

                const areNameFieldsDisabled = isUserFieldDisabled(data, disabled)
                const hasRolesDisabled = disabled || isEditingSelf

                return (
                    <>
                        <TextInput
                            name="first_name"
                            isRequired={hasFirstName}
                            isDisabled={areNameFieldsDisabled}
                            validator={firstNameValidator}
                        />
                        <TextInput name="last_name" isRequired={hasLastName} isDisabled={areNameFieldsDisabled} />
                        <TextInput name="email" isRequired={true} isDisabled={true} />
                        <UserRolesInput
                            name="roles"
                            value={selectedRoleId}
                            isRequired={!isEditingSelf}
                            isDisabled={hasRolesDisabled}
                        />
                        <TextInput name="position" isDisabled={disabled} />
                        <UserDepartmentInput name="department" isDisabled={disabled} />
                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
