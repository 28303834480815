const NUMERAL_ACCENT = '#2A85FF'
const NUMERAL_BLUE = '#3B61F4'
const NUMERAL_PURPLE = '#5226CC'

export default {
    numeralAccent: {
        50: '#E5F0FF',
        100: '#B8D6FF',
        200: '#8ABCFF',
        300: '#5CA2FF',
        400: NUMERAL_ACCENT,
        500: NUMERAL_ACCENT,
        600: NUMERAL_ACCENT,
        700: NUMERAL_ACCENT,
        800: NUMERAL_ACCENT,
        900: NUMERAL_ACCENT
    },
    numeralBlue: {
        500: NUMERAL_BLUE
    },
    numeralPurple: {
        500: NUMERAL_PURPLE
    },
    numeralLightBlue: {
        500: 'rgba(59, 97, 244, 0.07)'
    }
}
