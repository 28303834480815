import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { useMutation } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'

export function useMutationDownloadByIdFile(id?: Uuid) {
    const { filesService } = useServices()
    const { selectedUserAccess } = useAuth()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<string, Error, void>({
        mutationKey: [filesService.url, paramsWithUserAccess],
        mutationFn(data) {
            return filesService.downloadFile(id as string, {
                params: paramsWithUserAccess
            })
        }
    })
}
