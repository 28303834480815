import { defineStyleConfig } from '@chakra-ui/react'

function alertVariantFactory(primaryColor: string, secondaryColor: string) {
    return {
        icon: {
            color: primaryColor
        },
        parts: 'container',
        container: {
            borderColor: primaryColor,
            backgroundColor: secondaryColor,
            borderRadius: 'var(--numeral-ui-primary-border-radius)',
            borderInlineStartWidth: '4px'
        }
    }
}

export const Alert = defineStyleConfig({
    variants: {
        numeralAccent: alertVariantFactory('numeralAccent.500', 'blue.50'),
        numeralSuccess: alertVariantFactory('green.500', 'green.50'),
        numeralError: alertVariantFactory('red.500', 'red.50'),
        numeralWarning: alertVariantFactory('orange.500', 'orange.50'),
        leftAccent: {
            parts: 'container',
            container: {
                borderRadius: 'var(--numeral-ui-primary-border-radius)'
            }
        }
    },
    defaultProps: {
        colorScheme: 'numeralAccent'
    }
})
