import { AxiosRequestHeaders } from 'axios'
import { isEmpty, isNil } from 'lodash'
import { sanitizeData } from './sanitizeData.tranform.utils'

/**
 * @description
 * Async axios request/response transform that sanitises `any` object shape.
 */
export function sanitizeDataTransform<T>(data: T, headers?: AxiosRequestHeaders): string | void {
    if (isNil(data) || isEmpty(data)) {
        return
    }

    const generator: Generator<T> = sanitizeData<T>(data)
    let result: IteratorResult<T> = generator.next()

    while (!result.done) {
        result = generator.next()
    }

    headers?.set('Content-Type', 'application/json')

    return globalThis.JSON.stringify(result.value)
}
