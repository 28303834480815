import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { SepaReturnReason } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

export interface SepaReturnReasonDescriptionProps {
    sepaReturnReason: SepaReturnReason
}

export const SepaReturnReasonDescription: React.FC<SepaReturnReasonDescriptionProps> = memo(({ sepaReturnReason }) => {
    if (!sepaReturnReason) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Text as="span">
            <FormattedMessage id={`api.sepa_return_reason.${sepaReturnReason}`} defaultMessage={sepaReturnReason} />
        </Text>
    )
})
