import { prettyPrintCasedWords } from '@/utils'
import { Direction, PaymentOrderType } from '@webapps/numeral-ui-core'

export function getPaymentTypeMessageKey(
    type: PaymentOrderType,
    direction?: Direction,
    isAbbreviated?: boolean
): string {
    const rootMessagePartialId = 'api.payment.type'
    const typeMessageKeyFragment = `.${type}`
    const directionMessageKeyFragment = direction ? `.${direction}` : ''
    const abbreviatedMessageKeyFragment = direction && isAbbreviated ? '.abbreviated' : ''

    return `${rootMessagePartialId}${typeMessageKeyFragment}${directionMessageKeyFragment}${abbreviatedMessageKeyFragment}`
}

export function getDefaultPaymentTypeMessage(type: PaymentOrderType, direction?: Direction): string {
    if (!direction) {
        return prettyPrintCasedWords(type)
    } else {
        return prettyPrintCasedWords(`${type} ${direction}`)
    }
}
