import { sortCodeFormatter } from '@/utils/@formatters'
import { Text } from '@chakra-ui/react'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useMemo } from 'react'

interface BankCodeProps {
    value?: string
}

export const BankCode: React.FC<BankCodeProps> = ({ value }) => {
    const formattedValue = useMemo(() => sortCodeFormatter(value), [value])

    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text>{formattedValue}</Text>
}
