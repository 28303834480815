import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams, Resolver, ResolverAllQueryOptions } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { Uuid } from '@webapps/numeral-ui-core'

export function useQueryResolveIds<T>(
    params: ResolverAllQueryOptions<T>,
    options?: Partial<UndefinedInitialDataOptions<Resolver<Uuid>, Error>>
) {
    const { idsResolverService } = useServices()
    const { selectedUserAccess } = useAuth()
    const paramsWithUserAccess: typeof params = {
        ...params,
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<Resolver<Uuid>, Error>({
        ...options,
        queryKey: [idsResolverService.url, paramsWithUserAccess],
        queryFn({ signal }) {
            return idsResolverService.resolveIds<Uuid, T>(paramsWithUserAccess, { signal })
        }
    })
}
