import './index.scss'
import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { App } from './app'
import { setupNumeralCoreConfiguration, setupSentry } from './configuration'
;(async () => {
    const container: HTMLElement | null = document.getElementById('root')
    const root: Root = createRoot(container as HTMLElement)

    setupSentry()
    setupNumeralCoreConfiguration()

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    )
})()
