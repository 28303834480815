import { Flex, Text } from '@chakra-ui/react'
import { FileUploadIconWrapper } from './FileUploadIconWrapper'
import { SuccessIcon } from '@/components/@icons'
import { FormattedMessage } from 'react-intl'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'

export const FileUploadInputStatusSuccess: React.FC<FileUploadInputStatusProps> = ({
    color,
    fileNameWithExtension,
    browseLink,
    fontWeight
}) => {
    return (
        <Flex direction="column" alignItems="center">
            <FileUploadIconWrapper color={color}>
                <SuccessIcon />
            </FileUploadIconWrapper>
            <Text color={color} fontWeight={fontWeight} marginBottom="8px">
                <FormattedMessage
                    id="app.common.form.input.file.alt"
                    values={{
                        fileNameWithExtension
                    }}
                />
            </Text>
            <Text color={color} fontWeight="400">
                <FormattedMessage id="app.common.form.input.file.placeholder" values={{ browseLink }} />
            </Text>
        </Flex>
    )
}
