import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { usePermissions, useQueryFindByIdVirtualAccount } from '@/hooks'
import { ReactNode, useMemo } from 'react'
import { EntityAction } from '@/components'
import { PERMISSION } from '@/services'

export const VirtualAccountDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdVirtualAccount(uuid)
    const actions = useMemo<EntityAction[]>(() => {
        return [
            {
                onClick() {
                    /*@todo in Virtual Accounts write mode*/
                },
                color: 'red.500',
                disabled: query.isLoading
            }
        ]
    }, [query])
    const title = useMemo<ReactNode>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        return (query.isError && notFoundTitle) || query.data?.name || uuid
    }, [intl, query, uuid])
    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        return {
            title,
            /*
             * @todo in Virtual Accounts write mode
             * actions,
             * */
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [title, query, actions])

    if (!hasPermission(PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS)) {
        return null
    }

    return <PageHeaderLayout {...propsPageHeaderLayout} />
}
