import { Row } from '@tanstack/react-table'
import { chain } from 'lodash'
import { TABLE_DEFAULT_OPTIONS } from '@/components/Table/Table.const'
import { BulkActionsTableSelectCellMemoized } from './BulkActionsTableSelectCell'
import { BulkActionsTableSelectHeaderMemoized } from './BulkActionsTableSelectHeader'
import { BulkActions, BulkActionsActiveContext } from '@/providers'

export function isBulkActionsTableHeaderDisabled<T>(
    actions?: BulkActions<T>,
    context?: BulkActionsActiveContext<T>
): boolean {
    const activeContext = chain(context).values().head().value()

    return chain(actions)
        .get(activeContext)
        .get('configuration')
        .thru((configuration) => {
            return configuration?.isSingleSelect || configuration?.isSelectAllDisabled
        })
        .value()
}

export function getBulkActionsTableRowRange<T>(
    rows: Row<T>[],
    currentIndex: number,
    lastSelectedIndex: number
): Row<T>[] {
    const range = chain([currentIndex, lastSelectedIndex])

    if (currentIndex > lastSelectedIndex) {
        range.reverse().value()
    }

    const start = range.head().add(1).value()
    const end = range.last().add(1).value()

    return rows.slice(start, end)
}

export function isBulkActionsShiftSelectActive(
    event: React.MouseEvent<HTMLDivElement>,
    lastSelectedTableRowIndex?: number
): boolean {
    return event?.shiftKey && globalThis.Boolean(lastSelectedTableRowIndex)
}

export function getTableRowSelectColumnDef<T>() {
    return {
        ...TABLE_DEFAULT_OPTIONS,
        size: 0.25,
        header: BulkActionsTableSelectHeaderMemoized,
        cell: BulkActionsTableSelectCellMemoized
    }
}
