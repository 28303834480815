import { createStandaloneToast, ToastId, UseToastOptions } from '@chakra-ui/toast'
import { CUSTOM_CHAKRA_THEME } from '@/theme'
import { StyleProps } from '@chakra-ui/system'

export const { ToastContainer, toast } = createStandaloneToast(CUSTOM_CHAKRA_THEME)

export const TOAST_MESSAGES = Object.seal({
    _queue: Object.create(null) as Record<ToastId, UseToastOptions>,
    get queue() {
        return this._queue
    },
    removeAll() {
        this._queue = Object.create(null)
    },
    addOrUpdateItem(item: UseToastOptions) {
        if (item.id) {
            this._queue[item.id] = item
        }
    },
    removeItem(id: ToastId) {
        if (id) {
            delete this._queue[id]
        }
    }
})

export const TOAST_DURATIONS = Object.freeze({
    MIN: 5000,
    MAX: 10000
})

export const TOAST_CONTAINER_STYLE: StyleProps = {
    width: 380
}
