import { Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@/components/@icons'
import { useFormikContext } from 'formik'
import { CustomField } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

export const CustomFieldFormKeyTooltip: React.FC = () => {
    const { values } = useFormikContext<CustomField>()
    const isEditMode = useMemo(() => globalThis.Boolean(values?.id), [values])
    const labelId = useMemo(() => {
        const messageFragment = isEditMode ? 'edit' : 'create'
        return `app.settings.custom_fields.form.key.${messageFragment}.title`
    }, [isEditMode])
    return (
        <Tooltip
            placement="right-end"
            arrowSize={6}
            hasArrow={true}
            shouldWrapChildren={true}
            label={<FormattedMessage id={labelId} />}>
            <InfoIcon marginLeft="2px" fill="black" />
        </Tooltip>
    )
}
