export const WILDCARD_SYMBOL = '*'
export const NEW_LINE_SYMBOL = '\n'
export const EMPTY_VALUE_PLACEHOLDER = '-'
export const COMMA_SYMBOL = ','
export const DOT_SYMBOL = '.'
export const COLON_SYMBOL = ':'
export const SPACE_SYMBOL = ' '
export const EMPTY_CHAR_SYMBOL = ''

export const BULLET_SEPARATOR = '•'
export const DASH_SEPARATOR = '-'

export const ARROW_SEPARATOR = '→'
export const SELECT_OPTION_SEPARATOR = '──────────'
