import { useCustomFormikField } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { FileDirectionSchema, NumeralFileDirection } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Select } from '@/components/@misc'

export const FileDirectionInput: React.FC<FormikInput> = ({ name, value, ...inputProps }) => {
    const intl = useIntl()
    const [field, meta, helpers] = useCustomFormikField<NumeralFileDirection>({ name, value })
    const placeholder = useMemo<string>(() => {
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [intl])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<NumeralFileDirection>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                isInvalid={isInvalid}
                options={FileDirectionSchema.options}
            />
        </FormControl>
    )
}
