import { validateIBAN } from 'fast-iban'
import { z } from 'zod'

export const IBANSchema = z.string().refine((value) => {
    try {
        return validateIBAN(value, true)
    } catch (error) {
        return false
    }
})
