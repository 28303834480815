import { EntityRowCustomizer } from '@/components'
import { Divider, Td, Tr } from '@chakra-ui/react'
import { ChakraProps } from '@chakra-ui/system'
import { useId, useMemo, useRef } from 'react'
import {
    getEntityDetailRowsWithCustomizer,
    getRowCellStyle,
    getRowLabelStyle,
    getRowTestIds,
    normaliseObjectLikeRowData
} from '../EntityDetail.utils'

export function EntityDetailNestedRow<T>({ row }: { row: EntityRowCustomizer<T> }) {
    const leadingRowKey = useId()
    const secondaryRowKey = useId()
    const generatedRows = useMemo(() => {
        // TODO DASH-482 Always return the entire object
        const rowNestedData = row?.original?.[row.accessorKey] || row?.original
        const normalisedRowData = normaliseObjectLikeRowData(rowNestedData)

        return getEntityDetailRowsWithCustomizer<T>(normalisedRowData, row.rows, row.rowCustomizers, row.autogenerated)
    }, [row])
    const propsCommonStyle = useRef<ChakraProps>({ paddingY: '8px', border: 'unset' }).current
    const propsLeadingLabelTd = useRef<ChakraProps>(
        getRowLabelStyle({
            paddingBottom: '8px',
            border: 'unset !important'
        })
    ).current
    const propsLabelTd = useRef<ChakraProps>(getRowLabelStyle(propsCommonStyle)).current
    const propsCellTd = useRef<ChakraProps>(getRowCellStyle(propsCommonStyle)).current

    return (
        <>
            <Tr key={leadingRowKey} data-testid={row.accessorKey}>
                <Td {...propsLeadingLabelTd}>{row.label}</Td>
            </Tr>
            <Tr key={secondaryRowKey} data-testid={secondaryRowKey} verticalAlign="top">
                <Td paddingLeft="12px" paddingTop="unset">
                    {generatedRows?.map((row, index) => {
                        const [testIdLabel] = getRowTestIds(row)

                        return (
                            <Tr border="unset" key={index} data-testid={testIdLabel}>
                                <Td {...propsLabelTd}>{row?.label}</Td>
                            </Tr>
                        )
                    })}
                </Td>
                <Td paddingLeft="unset" paddingTop="unset">
                    {generatedRows?.map((row, index) => {
                        const [, testIdValue] = getRowTestIds(row)

                        return (
                            <Tr key={index} data-testid={testIdValue}>
                                <Td {...propsCellTd}>{row?.cell}</Td>
                            </Tr>
                        )
                    })}
                </Td>
                <Divider />
            </Tr>
        </>
    )
}
