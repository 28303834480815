import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { Button, Divider, Heading, Stack, Text } from '@chakra-ui/react'
import { getPackageInfo } from '@webapps/numeral-ui-core'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

export const DebugEnvVariables: React.FC = () => {
    const intl = useIntl()
    const numeralUICorePackageInfo = useMemo(getPackageInfo, [getPackageInfo])
    const onBreakTheWorld = useCallback(() => {
        throw new Error('Broke the world')
    }, [])

    return (
        <Stack className="DebugEnvVariables">
            <Heading as="h3">Environment variables</Heading>
            <Text>
                {intl.formatMessage({ id: 'app.debug.packageName.label' })} -{' '}
                <b>{ENVIRONMENT_VARIABLES.VITE_PACKAGE_NAME}</b>
            </Text>
            <Text>
                {intl.formatMessage({ id: 'app.debug.packageVersion.label' })} - <b>{APP_VERSION}</b>
            </Text>
            <Text>
                {intl.formatMessage({ id: 'app.debug.git_short_hash.label' })} -{' '}
                <b>{ENVIRONMENT_VARIABLES.VITE_GIT_SHORT_HASH}</b>
            </Text>
            <Text>
                {intl.formatMessage({ id: 'app.debug.package.version' })} -{' '}
                <b>
                    {numeralUICorePackageInfo.name}: {numeralUICorePackageInfo.version}
                </b>
            </Text>
            <Text>
                {intl.formatMessage({ id: 'app.debug.api_timeout.label' })} -{' '}
                <b>{ENVIRONMENT_VARIABLES.VITE_DEFAULT_API_TIMEOUT}</b>
            </Text>
            <Text>
                {intl.formatMessage({ id: 'app.debug.pagination.label' })} -{' '}
                <b>{ENVIRONMENT_VARIABLES.VITE_DEFAULT_PAGINATION_LIMIT}</b>
            </Text>
            <Divider marginY="16px" />
            <Heading as="h3">Sentry</Heading>
            <Button onClick={onBreakTheWorld} colorScheme="red">
                {intl.formatMessage({ id: 'app.debug.button.sentry.label' })}
            </Button>
            <Divider marginY="16px" />
        </Stack>
    )
}
