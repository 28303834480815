import { useNavigate } from 'react-router-dom'
import { QueryParamStateFilterBy, QueryParamStateSearch, QueryParamTypes, usePage } from '@/hooks'
import { useCallback } from 'react'
import { Payment } from '@/types'
import {
    ApiObjectType,
    ExpectedPaymentsServiceFindAllQueryOptions,
    IncomingPaymentsServiceFindAllQueryOptions,
    PaymentCaptureServiceFindAllQueryOptions,
    PaymentOrdersServiceFindAllQueryOptions,
    ReturnsServiceFindAllQueryOptions,
    Transaction,
    TransactionsServiceFindAllQueryOptions
} from '@webapps/numeral-ui-core'
import { QuickFilterParamTypes } from '@/providers'
import { getReconcilableTargetObjectFilterByState } from './useReconciliation.utils'
import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { getReconcilableTargetObjectAbsolutePath, RECONCILE_ENTITY_PAGE_CONTEXT } from '@/pages'
import { set } from 'lodash'
import { RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING } from './useReconciliation.const'

/**
 * @description
 * Starts or cancels a reconciliation flow based on the entry point (payment_* or transactions).
 * Steps:
 * 1. Starts a navigation with preset navigations states (for search, filters, quick-filters, etc.);
 * 2. Commits a sourceObject as a global context object accessible from any page (see `usePage` hook);
 */
export function useReconciliation() {
    const { setNonPersistedState } = usePage<Payment | Transaction>()
    const navigate = useNavigate()
    const onStartReconciliation = useCallback(
        (targetObjectType: ApiObjectType, sourceObject?: Payment | Transaction) => {
            const targetPath = getReconcilableTargetObjectAbsolutePath(targetObjectType)
            const hasSameObjectType = targetObjectType === sourceObject?.object

            if (!targetPath || hasSameObjectType) {
                return
            }

            const searchBy: QueryParamStateSearch = {
                search: EMPTY_CHAR_SYMBOL
            }
            const targetFilterBy: QueryParamStateFilterBy<
                | PaymentOrdersServiceFindAllQueryOptions
                | IncomingPaymentsServiceFindAllQueryOptions
                | ExpectedPaymentsServiceFindAllQueryOptions
                | ReturnsServiceFindAllQueryOptions
                | PaymentCaptureServiceFindAllQueryOptions
                | TransactionsServiceFindAllQueryOptions
            > = {
                ...RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING,
                ...getReconcilableTargetObjectFilterByState(targetObjectType, sourceObject)
            }

            const reconcileEntityNonPersistedState = set(
                Object.create(null),
                RECONCILE_ENTITY_PAGE_CONTEXT,
                sourceObject
            )
            setNonPersistedState(reconcileEntityNonPersistedState)

            navigate(targetPath, {
                state: {
                    [QuickFilterParamTypes.QuickFilter]: RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING,
                    [QueryParamTypes.FilterBy]: targetFilterBy,
                    [QueryParamTypes.Search]: searchBy
                }
            })
        },
        [navigate, setNonPersistedState]
    )

    const onCancelReconciliation = useCallback(() => {
        const reconcileEntityNonPersistedState = set(Object.create(null), RECONCILE_ENTITY_PAGE_CONTEXT, null)
        setNonPersistedState(reconcileEntityNonPersistedState)
    }, [])

    return {
        targetQuickFilter: RECONCILIATION_CONTEXT_TO_QUICK_FILTER_CONTEXT_BINDING,
        onStartReconciliation,
        onCancelReconciliation
    }
}
