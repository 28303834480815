import { ConnectedAccount } from '@webapps/numeral-ui-core'
import { DOT_SYMBOL } from '@/constants'

export function getConnectedAccountAccountNumberFirstAndLastChars(item?: Partial<ConnectedAccount>, numberOfChars = 4) {
    const accountNumber = item?.account_number

    if (numberOfChars <= 0) {
        throw new Error('The amount of chars must be a positive number')
    }

    if (accountNumber && accountNumber?.length < 2) {
        throw new Error('The input string must have at least two characters')
    }

    const firstChars = accountNumber?.slice(0, numberOfChars)
    const lastChars = accountNumber?.slice(-numberOfChars)

    return `${firstChars}${DOT_SYMBOL}${DOT_SYMBOL}${lastChars}`
}
