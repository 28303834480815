import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { prettyPrintCasedWords } from '@/utils'
import { CounterpartyAccountType as NumeralCounterpartyAccountType } from '@webapps/numeral-ui-core'
import { Text } from '@chakra-ui/react'

export const CounterpartyAccountType: React.FC<{ accountType?: NumeralCounterpartyAccountType | null }> = ({
    accountType
}) => {
    if (!accountType) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text>{prettyPrintCasedWords(accountType)}</Text>
}
