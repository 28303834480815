import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import { useMutationApproveByIdFile, useMutationCancelByIdFile } from '@/hooks'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { NumeralFile } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { canApproveOrCancelFileByStatus } from './FileApproval.utils'

interface FileApprovalProps {
    data?: NumeralFile
}

export const FileApproval: React.FC<FileApprovalProps> = ({ data }) => {
    const mutationApprove = useMutationApproveByIdFile(data?.id)
    const mutationCancel = useMutationCancelByIdFile(data?.id)
    const isVisible = useMemo<boolean>(() => canApproveOrCancelFileByStatus(data?.status), [data])
    const onApprove = useCallback(() => {
        mutationApprove.mutate()
    }, [mutationApprove])
    const onCancel = useCallback(() => {
        mutationCancel.mutate()
    }, [mutationCancel])
    const isLoading = useMemo<boolean>(() => {
        return mutationApprove.isPending || mutationCancel.isPending
    }, [mutationApprove, mutationCancel])
    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="FileApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                <FormattedMessage id="app.files.approval.title" />
                            </Text>
                            <Text>
                                <FormattedMessage id="app.files.approval.description" />
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                colorScheme="gray"
                                onClick={onCancel}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationCancel.isPending)}
                                disabled={isLoading}
                                variant="outline"
                                backgroundColor="white">
                                <FormattedMessage id="app.files.approval.actions.cancel.label" />
                            </Button>
                            <Button
                                onClick={onApprove}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationApprove.isPending)}
                                disabled={isLoading}
                                variant="solid">
                                <FormattedMessage id="app.files.approval.actions.approve.label" />
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
