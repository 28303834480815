import { Form } from '@/components'
import { TextInput, UserDepartmentInput } from '@/components/@inputs'
import { firstNameValidator } from '@/utils/@validators'
import { Box, Button, Text } from '@chakra-ui/react'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { UserInfoFormProps } from './UserInfo.form.types'
import { getUserInfoFormValidationSchema } from './UserInfo.form.utils'
import { isUserFieldDisabled } from '@/services'

export const UserInfoForm: React.FC<UserInfoFormProps> = ({ data, isLoading, onSubmit = noop }) => {
    const intl = useIntl()
    const validationSchema = useMemo(() => {
        return getUserInfoFormValidationSchema(intl)
    }, [intl])

    return (
        <>
            <Text textAlign="center" fontSize="32px" padding="0">
                {intl.formatMessage({ id: 'app.user_info_modal.title' })}
            </Text>
            <Text textAlign="center">{intl.formatMessage({ id: 'app.user_info_modal.description' })}</Text>
            <Box marginY="20px" />
            <Form<typeof data> initialValues={data} onSubmit={onSubmit} validationSchema={validationSchema}>
                {({ dirty, handleSubmit }) => {
                    const areNameFieldsDisabled = isUserFieldDisabled(data, isLoading)

                    return (
                        <>
                            <TextInput
                                name="first_name"
                                isDisabled={areNameFieldsDisabled}
                                isRequired={true}
                                validator={firstNameValidator}
                            />
                            <TextInput name="last_name" isDisabled={areNameFieldsDisabled} isRequired={true} />
                            <TextInput name="email" isDisabled={true} />
                            <UserDepartmentInput
                                name="department"
                                isDisabled={isLoading}
                                isRequired={true}
                                menuPortalTarget={null}
                            />
                            <TextInput name="position" isRequired={true} isDisabled={isLoading} />
                            <Button
                                type="submit"
                                onClick={handleSubmit as any}
                                size="lg"
                                width="100%"
                                margin="48px auto 0 auto"
                                isLoading={isLoading}
                                disabled={!dirty}>
                                <FormattedMessage id="app.user_info_modal.button.next.label" />
                            </Button>
                        </>
                    )
                }}
            </Form>
        </>
    )
}
