export const TABLE_COLUMN_RESERVED_KEYS = {
    OPTIONS: 'options'
}

export const TABLE_DEFAULT_OPTIONS = {
    manualSorting: false,
    enableSorting: false,
    enableColumnFilter: false
}

export const TABLE_SCROLL_TO_BOTTOM_OFFSET_PX = 10
