import { TableCellText } from '@/components'
import { FormattedTextIntl } from '@/components/@misc'
import { AuditTrailAuthorType, AuditTrailAuthorTypeSchema } from '@/services'
import { prettyPrintCasedWords } from '@/utils'

interface TableCellAuthorTypeProps {
    value: AuditTrailAuthorType
}

export const TableCellAuthorType: React.FC<TableCellAuthorTypeProps> = ({ value }) => {
    switch (value) {
        case AuditTrailAuthorTypeSchema.enum.internal_api_key:
        case AuditTrailAuthorTypeSchema.enum.api_key: {
            return (
                <FormattedTextIntl
                    messageId={`app.common.page.audit_trail.author_type.${value}`}
                    defaultMessage={value}
                />
            )
        }
        default: {
            const prettifiedValue = prettyPrintCasedWords(value)
            return <TableCellText value={prettifiedValue} />
        }
    }
}
