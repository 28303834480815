import { chain, toNumber } from 'lodash'
import { StorageServiceTransformationStrategy } from '@/services'

export const getDefaultStorageTransformationStrategy = (): StorageServiceTransformationStrategy => ({
    input: (value: any) => value,
    output: (value: any) => value
})

/**
 * @todo
 */
export const getUTF8StorageTransformationStrategy = (): StorageServiceTransformationStrategy => {
    const input = (value: string) => {
        try {
            const encoder = new TextEncoder()
            return chain<TextEncoder>(encoder).invoke('encode', value).toString()
        } catch (error) {
            return value
        }
    }

    const output = (value: string | null) => {
        try {
            const decoder = new TextDecoder()
            const array = chain<string>(value).split(',').map(toNumber).defaultTo([]).value()

            return chain<TextDecoder>(decoder).invoke('decode', new Uint8Array(array)).value()
        } catch (error) {
            return value
        }
    }

    return {
        input,
        output
    }
}
