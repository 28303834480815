import { useConfiguration, useNavigationRoutes, useServices } from '@/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ExtraLogoutPage: React.FC = () => {
    const { authenticationService } = useServices()
    const { apiEnvironment } = useConfiguration()
    const navigate = useNavigate()
    const { paths } = useNavigationRoutes()

    useEffect(() => {
        if (apiEnvironment.secondary) {
            authenticationService.logout(apiEnvironment.secondary.url)
        } else {
            navigate(paths.ACCOUNT.LOGIN)
        }
    }, [apiEnvironment.secondary, authenticationService, navigate, paths.ACCOUNT.LOGIN])

    return null
}
