import { useQueryFindByIdPaymentRetryRule } from '@/hooks'
import { PageHeaderLayout, PageHeaderLayoutProps } from '@/layouts'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

export const PaymentRetryRuleDetailsHeader: React.FC = () => {
    const intl = useIntl()
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentRetryRule(uuid)

    const propsPageHeaderLayout = useMemo<PageHeaderLayoutProps>(() => {
        const notFoundTitle = intl.formatMessage({
            id: 'app.common.not_found.title'
        })
        const title = query.data?.name

        return {
            title: query.isError ? notFoundTitle : title,
            isLoading: query.isLoading,
            disabled: query.isError
        }
    }, [intl, query])

    return <PageHeaderLayout {...propsPageHeaderLayout} />
}
