import { noop } from 'lodash'
import { RefObject, useCallback, useEffect } from 'react'

export function useClickOutside(ref?: RefObject<Element>, callback = noop, isDisabled = false) {
    const eventHandler = useCallback(
        (event: MouseEvent) => {
            const containsTarget = (event: MouseEvent): boolean => {
                return globalThis.Boolean(ref?.current?.contains(event.target as HTMLElement))
            }

            if (!containsTarget(event)) {
                callback(event)
            }
        },
        [ref, callback]
    )

    useEffect(() => {
        if (isDisabled) {
            return
        }

        globalThis.document.addEventListener('click', eventHandler)
        globalThis.document.addEventListener('mouseup', eventHandler)

        return () => {
            globalThis.document.removeEventListener('click', eventHandler)
            globalThis.document.removeEventListener('mouseup', eventHandler)
        }
    }, [isDisabled, ref, eventHandler])
}
