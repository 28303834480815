import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdReconciliationRule } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ReconciliationRule } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    RECONCILIATION_RULE_DETAILS_ROW_CUSTOMIZERS,
    RECONCILIATION_RULE_DETAILS_ROWS
} from './ReconciliationRuleDetails.page.const'
import { ReconciliationRuleDetailsCustomRows } from './ReconciliationRuleDetails.page.type'

export const ReconciliationRuleDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdReconciliationRule(uuid)

    return (
        <PageLayout className="PaymentRetryRuleDetailsDetails">
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<ReconciliationRule, ReconciliationRuleDetailsCustomRows>
                                    data={query.data}
                                    rows={RECONCILIATION_RULE_DETAILS_ROWS}
                                    rowCustomizers={RECONCILIATION_RULE_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
