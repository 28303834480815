import { BaseHttpClientService, extractResponseData, List, ServicesConfiguration, Uuid } from '@webapps/numeral-ui-core'
import { AuditTrail, AuditTrailsServiceFindAllQueryOptions } from './AuditTrail.types'

export class AuditTrailService extends BaseHttpClientService {
    get url() {
        return '/v1/audit_trails'
    }

    async findAll(
        params: Partial<AuditTrailsServiceFindAllQueryOptions>,
        config?: ServicesConfiguration
    ): Promise<List<AuditTrail>> {
        return this.httpClient
            .get<List<AuditTrail>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async findById(id: Uuid, config?: ServicesConfiguration): Promise<AuditTrail> {
        return this.httpClient.get<AuditTrail>(`${this.url}/${id}`, config).then(extractResponseData)
    }
}
