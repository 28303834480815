import { Icon, IconProps } from '@chakra-ui/react'

export const UpArrowIcon: React.FC<IconProps> = (props) => (
    <Icon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
        <path
            d="M5.58339 2.56601V9.66634H4.41672V2.56601L1.28772 5.69501L0.462891 4.87017L5.00006 0.333008L9.53722 4.87017L8.71239 5.69501L5.58339 2.56601Z"
            fill="currentColor"
        />
    </Icon>
)
