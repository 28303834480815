import * as Sentry from '@sentry/react'
import { ENVIRONMENT_VARIABLES } from '../envConfig'

export function setupSentry() {
    if (ENVIRONMENT_VARIABLES.DEV) {
        return
    }

    Sentry.init({
        dsn: ENVIRONMENT_VARIABLES.VITE_SENTRY_URL,
        environment: ENVIRONMENT_VARIABLES.MODE,
        // This enables automatic instrumentation (highly recommended), but is not
        // necessary for purely manual usage
        integrations: [Sentry.browserTracingIntegration(), Sentry.reportingObserverIntegration()],
        // To set a uniform sample rate
        tracesSampleRate: 0.2
    })
}
