import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, useQueryFindAllInquiries, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { InquiriesServiceFindAllQueryOptions, Inquiry } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    INQUIRY_DOCUMENTATION_LINK,
    INQUIRY_FILTERS,
    INQUIRY_TABLE_COLUMN_CUSTOMIZERS,
    INQUIRY_TABLE_STATIC_STATE
} from './Inquiries.page.const'

export const InquiriesPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.inquiries.title' }), [intl])
    const { filterBy } = useQueryParams<InquiriesServiceFindAllQueryOptions>({
        filterBy: { configuration: { filters: INQUIRY_FILTERS } }
    })

    const query = useQueryFindAllInquiries(filterBy.parsedState)
    const data = useMemo(() => queryDataAggregation(query.data), [query])

    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.payments.inquiries.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={INQUIRY_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.payments.inquiries.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.payments.inquiries.empty_content.subtitle' }, { learnMoreLink })
            }
        }
    }, [intl, query, isDataEmpty, filterBy])
    const onTableRowClick = useTableRowNavigation<Inquiry>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.INQUIRY} permission={PERMISSION.PAYMENTS_VIEW_INQUIRIES}>
                <TableHeader<InquiriesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.incoming_payment}
                    isLoading={query.isLoading}
                    filterBy={filterBy}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError || isDataEmpty: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<Inquiry>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={INQUIRY_TABLE_STATIC_STATE}
                                    columnCustomizers={INQUIRY_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
