import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { PaymentType } from '@/components/@misc'
import { PaymentRetryRule } from '@webapps/numeral-ui-core'
import { FormattedMessage } from 'react-intl'

export const PAYMENT_RETRY_RULES_TABLE_STATIC_STATE: TableStateWithEntity<PaymentRetryRule> = {
    columnOrder: Object.freeze(['name', 'retry_conditions'])
}

export const PAYMENT_RETRY_RULES_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<PaymentRetryRule> = {
    retry_conditions: {
        header() {
            return <FormattedMessage id="app.table.header.payments_to_retry" />
        },
        cell(info) {
            const value = info.getValue()
            return <PaymentType type={value.payment_type} direction={value.payment_direction} />
        }
    }
}
