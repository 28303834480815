import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useCustomFieldFilters,
    useMutationExportTransactions,
    useNavigationRoutes,
    usePage,
    useQueryFindAllTransactions,
    useQueryParams,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ReconcileEntityDynamicHeader, getReconcileEntityBulkActions } from '@/pages'
import { BulkActionsProvider, BulkActionsTable, QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Payment } from '@/types'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { Transaction, TransactionsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    TRANSACTIONS_FILTERS,
    TRANSACTIONS_QUICK_FILTERS,
    TRANSACTION_DOCUMENTATION_LINK,
    TRANSACTION_TABLE_COLUMN_CUSTOMIZERS,
    TRANSACTION_TABLE_STATIC_STATE
} from './Transactions.page.const'

export const TransactionsPage: React.FC = () => {
    const page = usePage<Payment>()
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const { onAdd } = useToasts()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.accounts.transactions.title' }), [intl])

    const filters = useCustomFieldFilters(ApiObjectTypeExtendedSchema.enum.transaction, TRANSACTIONS_FILTERS)
    const { search, filterBy } = useQueryParams<TransactionsServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters,
                quickFilters: TRANSACTIONS_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllTransactions({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportTransactions({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const actions = useMemo<TableHeaderActionProps[]>(() => {
        if (page.hasActiveContext) {
            return []
        }

        return [
            {
                onClick() {
                    mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
                },
                children: intl.formatMessage({ id: 'app.common.actions.export' }),
                isDisabled: isDataEmpty || mutationExport.isPending
            }
        ]
    }, [page, intl, isDataEmpty, mutationExport, search.parsedState, filterBy.parsedState])

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.accounts.transactions.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={TRANSACTION_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.accounts.transactions.empty_content.title' }),
                content: intl.formatMessage(
                    { id: 'app.accounts.transactions.empty_content.subtitle' },
                    { learnMoreLink }
                )
            }
        }
    }, [isDataEmpty, search.isActive, filterBy.isActive, intl])
    const bulkActions = useMemo(() => {
        return getReconcileEntityBulkActions(!page.hasActiveContext)
    }, [page])
    const onTableRowClick = useTableRowNavigation<Transaction>('id', relativePaths.ACCOUNTS.DETAILS)

    return (
        <PageLayout className="Transactions">
            <ReconcileEntityDynamicHeader>
                <PageHeaderLayout title={title} showBackButton={false} />
            </ReconcileEntityDynamicHeader>
            <RestrictedSection feature={ENTITY_FEATURE.TRANSACTION} permission={PERMISSION.ACCOUNTS_VIEW_TRANSACTIONS}>
                <QuickFilterProvider<TransactionsServiceFindAllQueryOptions> filterBy={filterBy}>
                    <BulkActionsProvider<Transaction, TransactionsServiceFindAllQueryOptions>
                        targetObject={ApiObjectTypeExtendedSchema.enum.transaction}
                        actions={bulkActions}
                        filterBy={filterBy}
                        search={search}
                        data={data}>
                        <TableHeader<TransactionsServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeExtendedSchema.enum.transaction}
                            isLoading={query.isLoading}
                            actions={actions}
                            filterBy={filterBy}
                            search={search}
                        />
                        {(() => {
                            switch (true) {
                                case query.isLoading: {
                                    return <LoadingSpinner />
                                }

                                case isDataEmpty:
                                case query.isError: {
                                    return <EmptyContent {...emptyContentProps} />
                                }

                                default: {
                                    return (
                                        <BulkActionsTable<Transaction>
                                            data={data}
                                            onScrollToBottom={query.fetchNextPage}
                                            onRowClick={onTableRowClick}
                                            isLoading={query.hasNextPage}
                                            state={TRANSACTION_TABLE_STATIC_STATE}
                                            columnCustomizers={TRANSACTION_TABLE_COLUMN_CUSTOMIZERS}
                                        />
                                    )
                                }
                            }
                        })()}
                    </BulkActionsProvider>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
