import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { PaymentType, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

interface EntityCellReturnDirectionProps {
    value: PaymentType
    type?: 'return' | 'return_request'
}

export const EntityCellReturnDirection: React.FC<EntityCellReturnDirectionProps> = ({ value, type }) => {
    const intl = useIntl()
    const formattedDirection = useMemo<string>(() => {
        const isReturnPaymentType = type === PaymentTypeSchema.enum.return
        const idFragment = isReturnPaymentType ? `app.payments.returns.direction` : `app.payments.direction`
        return intl.formatMessage({
            id: `${idFragment}.${value}.label`,
            defaultMessage: value
        })
    }, [value, type, intl])

    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <Text className="EntityCellReturnDirection">{formattedDirection}</Text>
}
