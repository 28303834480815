import { IntlShape } from 'react-intl'
import { FieldValidator } from 'formik'
import { SafeParseReturnType, z } from 'zod'
import { isEmpty } from 'lodash'
import { asOptionalField, sanitiseValidatorValue } from '../validators.utils'

const SEPA_CHARACTER_SET_PATTERN = /^[A-Za-z0-9+/?' ,;:_.!()*"%-]*$/
const SepaSchema = z.string().regex(SEPA_CHARACTER_SET_PATTERN)

export function sepaValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    return (value: string): string | void => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (isRequired) {
            validationResult = SepaSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(SepaSchema).safeParse(sanitisedValue)
        }

        if (isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.sepa.invalid' })
        }
    }
}
