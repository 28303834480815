import { memo } from 'react'
import { Amount, CurrencyCode } from '@webapps/numeral-ui-core'
import { amountFormatter } from '@/utils/@formatters'
import { useIntl } from 'react-intl'
import { asNegativeAmount } from './FormattedAmount.utils'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

interface FormattedAmountProps {
    amount?: Amount
    currency?: CurrencyCode
    showCurrencyCode?: boolean
    asNegative?: boolean
}

export const FormattedAmount: React.FC<FormattedAmountProps> = memo(
    ({ amount = 0, currency, asNegative = false, showCurrencyCode = true }) => {
        const intl = useIntl()
        const formattedAmount = asNegative ? asNegativeAmount(amount) : amount

        if (!currency) {
            return <>{EMPTY_VALUE_PLACEHOLDER}</>
        }

        return <>{amountFormatter(intl, formattedAmount, currency, showCurrencyCode)}</>
    }
)
