import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { FormikInput } from '@/types'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { CustomField, CustomFieldTypesSchema, CustomFieldValue } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { chain } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { TextInput } from '..'
import { Select } from '../../@misc'

/**
 * The name of the formik input is automatically derived from the custom field key
 */
interface CustomFieldInputsProps extends Omit<FormikInput, 'name'> {
    customField: CustomField
    isDisabled?: boolean
}

export const CustomFieldInput: React.FC<CustomFieldInputsProps> = ({
    customField,
    isDisabled,
    customPlaceholderKey,
    ...inputProps
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext()
    const computedFieldName = `custom_fields.${customField?.key}`
    const formattedPlaceholderValues = {
        customFieldName: customField?.name
    }
    const { value } = getFieldMeta<CustomField['key']>(computedFieldName)

    switch (customField.type) {
        case CustomFieldTypesSchema.enum.select: {
            const placeholder = (
                <FormattedMessage
                    id={customPlaceholderKey || 'app.common.form.input.custom_field.types.select.placeholder'}
                    values={formattedPlaceholderValues}
                />
            )
            const onChange = (value?: string) => {
                const transformedValue = chain(value).isEmpty().value()
                    ? EMPTY_CHAR_SYMBOL // Removal of key by empty value
                    : value

                setFieldValue(computedFieldName, transformedValue)
            }

            return (
                <FormControl>
                    <FormLabel htmlFor={computedFieldName}>{customField.name}</FormLabel>
                    <Select<CustomFieldValue>
                        {...inputProps}
                        name={computedFieldName}
                        placeholder={placeholder}
                        options={customField?.values}
                        value={value}
                        getOptionLabel={(item) => item?.name}
                        getOptionValue={(item) => item?.key}
                        onChange={onChange}
                        isDisabled={isDisabled}
                    />
                </FormControl>
            )
        }

        case CustomFieldTypesSchema.enum.text:
        default: {
            return (
                <TextInput
                    {...inputProps}
                    name={computedFieldName}
                    customLabelKey={customField?.name}
                    customPlaceholderKey="app.common.form.input.custom_field.types.text.placeholder"
                    customPlaceholderValues={formattedPlaceholderValues}
                    value={value}
                />
            )
        }
    }
}
