import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { extendTheme, withDefaultColorScheme, withDefaultProps } from '@chakra-ui/react'
import {
    Alert,
    Button,
    Checkbox,
    FormError,
    FormLabel,
    Input,
    Link,
    Modal,
    Popover,
    Radio,
    Select,
    Spinner,
    Tag
} from './components'
import colors from './foundations/colors'
import fontSizes from './foundations/fontSizes'
import fonts from './foundations/fonts'
import transition from './foundations/transition'
import styles from './global/styles'

const chakraThemeOverrides = {
    styles,
    fonts,
    fontSizes,
    colors,
    transition,
    components: {
        Alert,
        Button,
        Checkbox,
        FormError,
        FormLabel,
        Link,
        Radio,
        Spinner,
        Tag,
        Modal,
        Popover,
        Select,
        Input,
        NumberInput: Input,
        Textarea: Input
    },
    config: {
        cssVarPrefix: ENVIRONMENT_VARIABLES.VITE_PACKAGE_NAME
    }
}

export const CUSTOM_CHAKRA_THEME: Record<string, Record<any, any>> = extendTheme(
    chakraThemeOverrides,

    withDefaultColorScheme({
        colorScheme: 'numeralBlue'
    }),
    withDefaultProps({
        defaultProps: {
            borderRadius: 'var(--numeral-ui-primary-border-radius)',
            size: 'md'
        }
    })
)
