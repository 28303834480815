import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'
import { APIError } from '@/types'
import { CustomField } from '@webapps/numeral-ui-core'
import { DASH_SEPARATOR } from '@/constants'
import { AxiosError } from 'axios'

export const CustomFieldErrorNotification: React.FC<{ error: AxiosError<APIError<CustomField>> }> = ({ error }) => {
    const errorMessage = useMemo(() => get(error, 'response.data.message', error.message), [error])
    const errorDetails = useMemo(() => get(error, 'response.data.details', []), [error])

    return (
        <>
            <Text>
                <FormattedMessage id="app.settings.custom_fields.create.form.validation.error.toast.description" />
            </Text>
            {errorDetails.length
                ? errorDetails.map((detail, index: number) => {
                      return (
                          <Text whiteSpace="normal" key={index}>
                              {`${DASH_SEPARATOR} ${detail?.field}: ${detail?.reason}`}
                          </Text>
                      )
                  })
                : errorMessage}
        </>
    )
}
