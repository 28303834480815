import { TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { ReconciliationRuleType } from '@/components/@misc'
import { ReconciliationRule } from '@webapps/numeral-ui-core'
import { ReconciliationRulesCustomColumns } from './ReconciliationRules.page.type'

export const RECONCILIATION_RULES_TABLE_STATIC_STATE: TableStateWithEntity<
    ReconciliationRule,
    ReconciliationRulesCustomColumns
> = {
    columnOrder: Object.freeze(['name', 'rank', 'type'])
}

export const RECONCILIATION_RULES_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    ReconciliationRule,
    ReconciliationRulesCustomColumns
> = {
    type: {
        cell(info) {
            const { reconcile_with_payments, relationship } = info.row.original
            return (
                <ReconciliationRuleType reconcileWithPayments={reconcile_with_payments} relationship={relationship} />
            )
        }
    }
}
