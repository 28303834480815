import { useAuth, useNavigationRoutes, usePrivateTopLevelRoutes } from '@/hooks'
import {
    navigationProviderDisabledRoutesFilter,
    navigationProviderIndexRoutesFilter,
    NavigationRoute
} from '@/providers'
import { AccordionProps } from '@chakra-ui/accordion'
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    ExpandedIndex,
    Flex,
    Text
} from '@chakra-ui/react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    MainNavigationAccount,
    MainNavigationDivider,
    MainNavigationFooter,
    MainNavigationSectionListItem
} from './@components'
import './MainNavigation.scss'
import {
    getAccordionActiveIndexByPath,
    getEntityFeatureRouteFilter,
    getLegalEntityTypeRouteFilter,
    getOrderedRoutes
} from './MainNavigation.utils'

interface MainNavigationProps {
    isCollapsed: boolean

    onToggleNavigation(): void
}

export const MainNavigation: React.FC<MainNavigationProps> = ({ isCollapsed, onToggleNavigation }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { selectedUserAccess } = useAuth()
    const { paths } = useNavigationRoutes()
    const { routes } = usePrivateTopLevelRoutes()
    const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>(
        getAccordionActiveIndexByPath(routes, location.pathname)
    )
    const orderedRoutes = useMemo(() => getOrderedRoutes(routes), [routes])
    const legalEntityTypeRouteFilter = useMemo(() => {
        return getLegalEntityTypeRouteFilter(selectedUserAccess?.configuration?.entity_type)
    }, [selectedUserAccess])
    const entityFeatureRouteFilter = useMemo(() => {
        return getEntityFeatureRouteFilter(selectedUserAccess?.features)
    }, [selectedUserAccess])
    const onNavigationItemClick = useCallback(
        (path?: string) => {
            navigate(path || paths.ROOT)
        },
        [navigate, paths]
    )

    const propsAccordion = useMemo<AccordionProps>(
        () => ({
            allowMultiple: false,
            index: accordionIndex,
            onChange: setAccordionIndex
        }),
        [accordionIndex]
    )
    const className = useMemo<string>(() => {
        return classNames('MainNavigation', {
            Collapsed: isCollapsed
        })
    }, [isCollapsed])

    useEffect(() => {
        const accordionIndex = getAccordionActiveIndexByPath(routes, location.pathname)
        setAccordionIndex(accordionIndex)
    }, [routes, location])

    return (
        <Box className={className} backgroundColor="gray.50" data-testid="main-navigation">
            <MainNavigationAccount isCollapsed={isCollapsed} />
            <MainNavigationDivider isCollapsed={isCollapsed} />
            <Flex className="MainNavigation-Section" flex="1" justifyContent="space-between" direction="column">
                <Box className="MainNavigation-Section-List" paddingTop="16px">
                    <Accordion className="MainNavigation-Section-List-Accordion" {...propsAccordion}>
                        {orderedRoutes.mainRoutes.map((item: NavigationRoute, index) => {
                            const rootLevelItemRoute = item?.path
                            const formattedMessageTitle = intl.formatMessage({
                                id: item?.title
                            })

                            if (!isCollapsed && item.routes?.length && item?.configuration?.isGroup) {
                                const navigationItems = item.routes
                                    .filter(navigationProviderIndexRoutesFilter)
                                    .filter(navigationProviderDisabledRoutesFilter)
                                    .filter(legalEntityTypeRouteFilter)
                                    .filter(entityFeatureRouteFilter)
                                    .map((item, index) => {
                                        const completePath = `${rootLevelItemRoute}/${item?.path}`

                                        return (
                                            <MainNavigationSectionListItem
                                                isCollapsed={isCollapsed}
                                                route={item}
                                                onClick={onNavigationItemClick}
                                                completePath={completePath}
                                                key={index}
                                            />
                                        )
                                    })

                                const propsAccordionButton = {
                                    className: 'MainNavigation-Section-List-Accordion-Button',
                                    title: formattedMessageTitle,
                                    padding: '6px 14px',
                                    'aria-label': formattedMessageTitle,
                                    color: 'gray.500',
                                    _expanded: {
                                        color: 'numeralBlue.500'
                                    },
                                    _hover: {
                                        backgroundColor: 'transparent'
                                    }
                                }
                                const accordionIcon = index === accordionIndex ? faChevronUp : faChevronDown

                                return (
                                    <AccordionItem
                                        key={index}
                                        isDisabled={item.configuration?.isDisabled}
                                        _focusVisible={{ boxShadow: 'none', outline: 'none' }}>
                                        <AccordionButton {...propsAccordionButton}>
                                            <Flex flex="1" alignItems="center">
                                                {item?.icon}
                                                <Text>{formattedMessageTitle}</Text>
                                            </Flex>
                                            <FontAwesomeIcon icon={accordionIcon} fixedWidth={true} size="xs" />
                                        </AccordionButton>
                                        <AccordionPanel padding={0}>{navigationItems}</AccordionPanel>
                                    </AccordionItem>
                                )
                            }

                            return (
                                <MainNavigationSectionListItem
                                    isCollapsed={isCollapsed}
                                    route={item}
                                    onClick={onNavigationItemClick}
                                    key={index}
                                />
                            )
                        })}
                    </Accordion>
                </Box>
                <MainNavigationFooter
                    className="MainNavigation-Section-List"
                    isCollapsed={isCollapsed}
                    onClick={onNavigationItemClick}
                    onToggleNavigation={onToggleNavigation}
                    routes={orderedRoutes.footerRoutes}
                />
            </Flex>
        </Box>
    )
}
