import { EntityDetailCellTextWithCopyButton, EntityDetailRow, EntityDetailRowCustomizers } from '@/components'
import { ReconciliationRuleType, StatusLabel } from '@/components/@misc'
import { ReconciliationRule } from '@webapps/numeral-ui-core'
import { ReconciliationRuleConditions } from './@components'
import { ReconciliationRuleDetailsCustomRows } from './ReconciliationRuleDetails.page.type'

export const RECONCILIATION_RULE_DETAILS_ROWS: EntityDetailRow<
    ReconciliationRule,
    ReconciliationRuleDetailsCustomRows
>[] = ['id', 'name', 'status', 'rank', 'type', 'conditions']

export const RECONCILIATION_RULE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    ReconciliationRule,
    ReconciliationRuleDetailsCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    type: {
        cell(item) {
            return (
                <ReconciliationRuleType
                    reconcileWithPayments={item.reconcile_with_payments}
                    relationship={item.relationship}
                />
            )
        }
    },
    conditions: {
        cell(item) {
            return <ReconciliationRuleConditions filters={item.filters} conditions={item.conditions} />
        }
    }
}
