import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdExpectedPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { ExpectedPayment } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    EXPECTED_PAYMENT_DETAILS_ROW_CUSTOMIZERS,
    EXPECTED_PAYMENT_DETAILS_ROWS
} from './ExpectedPaymentDetails.page.const'
import { ExpectedPaymentCustomRows } from './ExpectedPaymentDetails.types'

export const ExpectedPaymentDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdExpectedPayment(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<ExpectedPayment, ExpectedPaymentCustomRows>
                                    data={query.data}
                                    rows={EXPECTED_PAYMENT_DETAILS_ROWS}
                                    rowCustomizers={EXPECTED_PAYMENT_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
