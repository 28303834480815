import { LockIcon } from '@/components/@icons'
import { Box, Text, Tooltip } from '@chakra-ui/react'
import { memo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import './RestrictedData.scss'

export const RestrictedData: React.FC = memo(() => {
    const intl = useIntl()
    const tooltipContent = intl.formatMessage({ id: 'app.common.restricted_data.tooltip.content' })

    return (
        <Tooltip placement="top" hasArrow={true} label={tooltipContent}>
            <Box className="RestrictedData" display="inline-block" position="relative" maxWidth="100%">
                <LockIcon className="LockIcon" position="absolute" left="0" right="0" bottom="0" margin="auto" />
                <Text as="span" userSelect="none" color="transparent" textShadow="0 0 10px #000">
                    <FormattedMessage id="app.common.restricted_data.content" />
                </Text>
            </Box>
        </Tooltip>
    )
})
