import { EntityDetailNestedRow } from '@/components'
import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { ChakraProps } from '@chakra-ui/system'
import { useMemo, useRef } from 'react'
import './EntityDetail.scss'
import { EntityDetailProps } from './EntityDetail.types'
import {
    getEntityDetailRowsWithCustomizer,
    getRowCellStyle,
    getRowLabelStyle,
    getRowTestIds,
    isNestedRowAndHasData
} from './EntityDetail.utils'

export function EntityDetail<T, C = void>({ data, rowCustomizers, rows, style }: EntityDetailProps<T, C>) {
    const generatedRows = useMemo(() => {
        return getEntityDetailRowsWithCustomizer<T, C>(data, rows, rowCustomizers)
    }, [data, rows, rowCustomizers])
    const propsBox = useRef<ChakraProps>({
        borderWidth: '1px',
        borderRadius: 'var(--numeral-ui-primary-border-radius)',
        padding: '16px',
        ...style
    }).current
    const propsLabelTd = useRef<ChakraProps>(getRowLabelStyle()).current
    const propsCellTd = useRef<ChakraProps>(getRowCellStyle(style)).current

    return (
        <Box {...propsBox} className="EntityDetail">
            <Table variant="simple" colorScheme="gray">
                <Tbody>
                    {generatedRows.map((row, index) => {
                        if (isNestedRowAndHasData(row)) {
                            return <EntityDetailNestedRow<T> row={row} key={index} />
                        }

                        const [testIdLabel, testIdValue] = getRowTestIds(row)

                        return (
                            <Tr key={row.accessorKey as string} data-testid={row.accessorKey}>
                                <Td {...propsLabelTd} data-testid={testIdLabel}>
                                    {row.label}
                                </Td>
                                <Td {...propsCellTd} data-testid={testIdValue}>
                                    {row.cell}
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </Box>
    )
}
