import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams, User, UserInviteRequest } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { noop } from 'lodash'

export function useMutationSendInvitation(options?: MutationOptions<Partial<User> | void, Error, UserInviteRequest>) {
    const { selectedUserAccess } = useAuth()
    const { usersService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessQueryParams(selectedUserAccess) }

    return useMutation<Partial<User> | void, Error, UserInviteRequest>({
        ...options,
        mutationKey: [usersService.url],
        mutationFn(data) {
            return usersService.sendInvitation(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [usersService.url]
                })
                .catch(noop)
        }
    })
}
