import { isFirstSelectOptionSelected, stopEventPropagation } from '@/components/@misc'
import { Flex, FlexProps } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { components } from 'react-select'
import { OptionProps } from 'react-select/dist/declarations/src/components/Option'

export const SelectOption: React.FC<OptionProps<any>> = ({ children, innerRef, innerProps, ...props }) => {
    const [isFocused, setIsFocused] = useState(false)
    const onMouseLeave = useCallback(() => {
        setIsFocused(false)
    }, [])

    useEffect(() => {
        setIsFocused(isFirstSelectOptionSelected(props))
    }, [props])

    const propsFlex: FlexProps = {
        flexDirection: 'column',
        color: props.isDisabled ? 'gray.400' : 'gray.700',
        backgroundColor: props.isDisabled ? 'gray.50' : 'inherit',
        cursor: props.isDisabled ? 'not-allowed' : 'inherit',

        onMouseLeave,
        onDragStart: stopEventPropagation,
        /**
         * @important
         * This `onMouseUp` event needs to be stopped,
         * from propagating to the parent elements (interferes with Forms inside modals).
         */
        onMouseUp: stopEventPropagation
    }

    return (
        <components.Option innerRef={innerRef} innerProps={innerProps} {...props} isFocused={isFocused}>
            <Flex {...propsFlex} data-testid={`select-menu-list-option-${innerProps?.id}`} padding="8px">
                {children}
            </Flex>
        </components.Option>
    )
}
