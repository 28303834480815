import { IntlShape } from 'react-intl'
import { z, ZodSchema } from 'zod'
import { WEBHOOK_RULES_MODES } from './WebhookCreateOrEdit.form.const'
import { Webhook } from '@/services'
import { WebhookSchema } from '@/services/Webhooks/Webhook.schema'
import { DEFAULT_INPUT_TEXT_MAX_LENGTH, DEFAULT_INPUT_TEXT_MIN_LENGTH } from '@/constants'

/**
 * @todo
 * Add unit test
 */
export function isAllWebhookRulesEnabled(value: string): boolean {
    return value === WEBHOOK_RULES_MODES.ALL
}

/**
 * @todo
 * Add unit test
 */
export function getWebhookRulesModeInitialValue(data?: Webhook) {
    return data?.rules ? WEBHOOK_RULES_MODES.INDIVIDUAL : WEBHOOK_RULES_MODES.ALL
}

/**
 * @todo
 * Add unit test
 */
export function getWebhookFormInitialValues(data?: Partial<Webhook>): Webhook {
    return {
        name: '',
        url: '',
        rules: null,
        ...data
    }
}

export function getWebhookFormValidationSchemaWithIntl(intl: IntlShape): ZodSchema {
    const minMessage = intl.formatMessage(
        {
            id: 'app.developers.webhooks.form.name.validation.min.message'
        },
        {
            size: DEFAULT_INPUT_TEXT_MIN_LENGTH
        }
    )
    const maxMessage = intl.formatMessage(
        {
            id: 'app.developers.webhooks.form.name.validation.max.message'
        },
        {
            size: DEFAULT_INPUT_TEXT_MAX_LENGTH
        }
    )

    return WebhookSchema.extend({
        name: z
            .string()
            .trim()
            .min(DEFAULT_INPUT_TEXT_MIN_LENGTH, minMessage)
            .max(DEFAULT_INPUT_TEXT_MAX_LENGTH, maxMessage)
    })
}
