import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, useQueryFindAllFiles, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { FilesServiceFindAllQueryOptions, NumeralFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    FILES_DOCUMENTATION_LINK,
    FILES_FILTERS,
    FILES_TABLE_COLUMN_CUSTOMIZERS,
    FILES_TABLE_STATIC_STATE
} from './Files.page.const'

export const FilesPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.files.title' }), [intl])
    const { search, filterBy } = useQueryParams<FilesServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: FILES_FILTERS } }
    })
    const query = useQueryFindAllFiles({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.files.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={FILES_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.files.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.files.empty_content.subtitle' }, { learnMoreLink })
            }
        }
    }, [intl, query, isDataEmpty, search, filterBy])
    const onTableRowClick = useTableRowNavigation<NumeralFile>('id', relativePaths.FILES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection permission={PERMISSION.FILES_VIEW_FILES}>
                <TableHeader<FilesServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.file}
                    isLoading={query.isLoading}
                    search={search}
                    filterBy={filterBy}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError || isDataEmpty: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<NumeralFile>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={FILES_TABLE_STATIC_STATE}
                                    columnCustomizers={FILES_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
