import { memo } from 'react'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { FormattedDate as ReactIntlFormattedDate } from 'react-intl'

interface FormattedDateProps {
    value?: string
}

export const FormattedDate: React.FC<FormattedDateProps> = memo(({ value }) => {
    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <ReactIntlFormattedDate value={value} dateStyle="short" />
})
