import {
    EntityDetailCellHolderAddress,
    EntityDetailCellTextWithCopyButton,
    EntityDetailRow,
    EntityDetailRowCustomizers
} from '@/components'
import { BankCode, FormattedDateTime, StatusLabel } from '@/components/@misc'
import { accountNumberFormatter, whitespaceFormatter } from '@/utils/@formatters'
import { CounterpartyAccount } from '@webapps/numeral-ui-core'
import { CounterpartyAccountCustomRows } from './CounterpartyAccountDetails.page.types'
import {
    CounterpartyAccountCompanyRegistrationNumberType,
    CounterpartyAccountDetailsRelatedObjects,
    CounterpartyAccountType
} from './@components'

export const COUNTERPARTY_ACCOUNT_DETAILS_ROWS: EntityDetailRow<CounterpartyAccount, CounterpartyAccountCustomRows>[] =
    [
        'id',
        'created_at',
        'name',
        'type',
        'account_number',
        'bank_code',
        'holder_name',
        'holder_address',
        'company_registration_number',
        'company_registration_number_type',
        'status',
        'status_details',
        'disabled_at',
        'related_objects',
        'custom_fields',
        'metadata'
    ]

export const COUNTERPARTY_ACCOUNT_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<
    CounterpartyAccount,
    CounterpartyAccountCustomRows
> = {
    id: {
        cell(item) {
            return <EntityDetailCellTextWithCopyButton value={item.id} />
        }
    },
    created_at: {
        cell(item) {
            return <FormattedDateTime value={item.created_at} />
        }
    },
    type: {
        cell(item) {
            return <CounterpartyAccountType accountType={item?.type} />
        }
    },
    account_number: {
        cell(item) {
            const formattedValue = accountNumberFormatter(item.account_number)
            return <EntityDetailCellTextWithCopyButton value={formattedValue} formatter={whitespaceFormatter} />
        }
    },
    bank_code: {
        cell(item) {
            return <BankCode value={item.bank_code} />
        }
    },
    holder_address: {
        cell(item) {
            return <EntityDetailCellHolderAddress value={item.holder_address} />
        }
    },
    company_registration_number_type: {
        cell(item) {
            return (
                <CounterpartyAccountCompanyRegistrationNumberType
                    companyRegistrationNumberType={item.company_registration_number_type}
                />
            )
        }
    },
    status: {
        cell(item) {
            return <StatusLabel value={item.status} />
        }
    },
    disabled_at: {
        cell(item) {
            return <FormattedDateTime value={item.disabled_at} />
        }
    },
    related_objects: {
        cell(item) {
            return <CounterpartyAccountDetailsRelatedObjects counterpartyAccount={item} />
        }
    },
    metadata: {
        nested: true
    }
}
