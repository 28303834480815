import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

export function useFormikAutoSubmit(wait?: number) {
    const formikContext = useFormikContext()
    const debouncedSubmit = useMemo(
        () =>
            debounce(() => {
                if (formikContext?.isValid) {
                    formikContext.handleSubmit()
                }
            }, wait),
        [wait]
    )

    useEffect(() => {
        debouncedSubmit()

        return () => {
            debouncedSubmit.cancel()
        }
    }, [wait, formikContext?.values])
}
