import {
    EntityDetailCellCustomFieldValues,
    EntityDetailLabelCustomFields,
    EntityDetailLabelCustomFieldValues,
    EntityDetailRowCustomizers,
    EntityRowCustomizerDef
} from '@/components'
import { withCustomFieldsData } from '@/pages'
import { PERMISSION } from '@/services'
import { isEmptyRecord } from '@/utils'
import { CounterpartyAccount, PaymentOrder, Transaction } from '@webapps/numeral-ui-core'

export function getCustomFieldsRowCustomizers(
    data?: PaymentOrder | Transaction | CounterpartyAccount,
    permission?: PERMISSION
): EntityRowCustomizerDef<typeof data> {
    const indexRow = '0'
    const hasNestedRows = !isEmptyRecord(data?.custom_fields)
    const EntityDetailLabelCustomFieldValuesWithData = withCustomFieldsData(
        EntityDetailLabelCustomFieldValues,
        data?.object
    )
    const EntityDetailCellCustomFieldValuesWithData = withCustomFieldsData(
        EntityDetailCellCustomFieldValues,
        data?.object
    )

    const rowCustomizers: EntityDetailRowCustomizers<Record<string, string>> = {
        [indexRow]: {
            label(item) {
                return <EntityDetailLabelCustomFieldValuesWithData value={item} />
            },
            cell(item) {
                return <EntityDetailCellCustomFieldValuesWithData value={item} />
            }
        }
    }

    return {
        nested: hasNestedRows,
        label() {
            return <EntityDetailLabelCustomFields permission={permission} />
        },
        rows: [indexRow],
        rowCustomizers
    }
}
