import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { isString } from 'lodash'

const DIGITS_PATTERN = /\D/g

export function digitsFormatter(value?: string): string {
    if (!isString(value)) {
        return EMPTY_CHAR_SYMBOL
    }

    return value.replace?.(DIGITS_PATTERN, EMPTY_CHAR_SYMBOL)
}
