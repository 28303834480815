import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@/components/@icons'
import {
    CounterpartyAccountVerificationResult,
    CounterpartyAccountVerificationResultSchema
} from '@webapps/numeral-ui-core'
import { ReactNode } from 'react'

export function getVerificationMessageIconFromResult(result?: CounterpartyAccountVerificationResult): ReactNode {
    switch (result) {
        case CounterpartyAccountVerificationResultSchema.enum.valid:
            return <SuccessIcon color="green.500" />
        case CounterpartyAccountVerificationResultSchema.enum.invalid:
            return <ErrorIcon color="orange.500" />
        case CounterpartyAccountVerificationResultSchema.enum.impossible_to_verify:
            return <WarningIcon color="red.500" />
        case CounterpartyAccountVerificationResultSchema.enum.not_verified:
            return <InfoIcon color="blue.500" />
        default:
            return null
    }
}
