/**
 * @description
 * Minimum width of column types in pixel
 */
export const MIN_COLUMN_WIDTH = {
    SHORT_DATE: 110,
    LONG_DATE: 220,
    SHORT_DATE_RANGE: 215,
    AMOUNT: 150,
    AMOUNT_RANGE: 200,
    DIRECTION: 100,
    DIRECT_DEBIT_TYPE: 180,
    FILE_CATEGORY: 170,
    FILE_FORMAT: 100,
    ACCOUNT_NAME: 100,
    ACCOUNT_NUMBER: 480,
    RELATED_TRANSACTIONS: 160,
    STATUS: 165,
    SHORT_STATUS: 100,
    RECONCILIATION_STATUS: 175,
    BALANCE_TYPE: 150,
    FULL_NAME: 210,
    EMAIL: 200,
    ROLE: 70
}

/**
 * Column width growth ratio, 1 is the default growth ratio
 * @example
 * 0.5 -> grow half the width of a default column
 * 2 -> grow twice the width of a default column
 */

export const COLUMN_WIDTH_RATIO = {
    DEFAULT: 1,
    SHORT_DATE: 0.5,
    ROLE_NAME: 0.2,
    DIRECTION: 0.5,
    STATUS: 0.5,
    ACCOUNT: 1.5,
    AMOUNT: 0.5,
    REFERENCE: 1.5,
    DESCRIPTION: 1.5,
    FILE_NAME: 2,
    API_KEY_NAME: 1.5,
    EMAIL: 1.5,
    CONNECTED_ACCOUNT: 1.5,
    CAPTURE_TYPE: 0.5,
    OBJECT_TYPE: 1.5
}
