import { RequestActionTypes } from '@webapps/numeral-ui-core'

export function getFormattedActionPastTense(action: RequestActionTypes) {
    switch (action) {
        case RequestActionTypes.APPROVE: {
            return 'approved'
        }
        case RequestActionTypes.CANCEL: {
            return 'canceled'
        }

        default: {
            return ''
        }
    }
}
