import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, ReturnsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportReturn(
    options?: MutationOptions<void, Error, ExportPayload<ReturnsServiceFindAllQueryOptions>>
) {
    const { selectedUserAccess } = useAuth()
    const { returnsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<void, Error, ExportPayload<ReturnsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [returnsService.url],
        mutationFn(data) {
            return returnsService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
