import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { ReturnRequest, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdReturnRequest(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<ReturnRequest, Error>>
) {
    const { returnRequestsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.RETURN_REQUEST) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_RETURN_REQUESTS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<ReturnRequest, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [returnRequestsService.url, { id }],
        queryFn({ signal }) {
            return returnRequestsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
