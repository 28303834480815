import { FormContainer } from '@/components'
import { ReadonlyField } from '@/components/@misc'
import { usePermissions } from '@/hooks'
import { PERMISSION, Role } from '@/services'
import { RoleAssignedUsers, RoleUserPermissions } from './@components'

interface RoleDetailsContentProps {
    data?: Role
}

export const RoleDetailsContent: React.FC<RoleDetailsContentProps> = ({ data }) => {
    const { hasPermission } = usePermissions()
    const showUsersAssignToRole = hasPermission(PERMISSION.SETTINGS_VIEW_USERS)

    return (
        <FormContainer>
            <ReadonlyField name="name" value={data?.name} />
            <ReadonlyField name="description" value={data?.description} />
            <RoleUserPermissions permissions={data?.permissions} />
            {showUsersAssignToRole && <RoleAssignedUsers roleId={data?.id} />}
        </FormContainer>
    )
}
