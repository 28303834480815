import { BulkActionsContext, BulkActionsContextValue } from '@/providers'
import { QuickFilterContext } from '@/providers/QuickFilterProvider'
import { useContext } from 'react'

export function useBulkActions<T = void>() {
    const quickFiltersContext = useContext(QuickFilterContext)
    const context = useContext(BulkActionsContext)

    if (!quickFiltersContext || !context) {
        throw new Error(
            `Must be used in the scope of ${QuickFilterContext.displayName} and ${BulkActionsContext.displayName}`
        )
    }

    return context as BulkActionsContextValue<T>
}
