import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Link } from '@chakra-ui/react'
import { chain } from 'lodash'
import { SyntheticEvent, useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { LinkWithFiltersProps } from './LinkWithFilters.types'
import { QueryParamTypes } from '@/hooks'

export function LinkWithFilterBy<T = void>({ labelKey, path, filterBy }: LinkWithFiltersProps<T>) {
    const intl = useIntl()
    const navigate = useNavigate()
    const hasEmptyFilterBy = useRef<boolean>(chain<typeof filterBy>(filterBy).isEmpty().value()).current
    const hasNoPath = useRef<boolean>(chain<typeof path>(path).isEmpty().value()).current
    const onNavigate = useCallback(
        (event: SyntheticEvent) => {
            event.stopPropagation()
            const options = filterBy
                ? {
                      state: {
                          [QueryParamTypes.FilterBy]: filterBy
                      }
                  }
                : undefined

            navigate(path, options)
        },
        [path, navigate, filterBy]
    )
    const formattedMessage = useMemo<string>(() => {
        if (!labelKey) {
            return EMPTY_VALUE_PLACEHOLDER
        }
        return intl.formatMessage({ id: labelKey })
    }, [intl, labelKey])

    if (hasNoPath || hasEmptyFilterBy) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Link onClick={onNavigate} display="block" textDecoration="underline">
            {formattedMessage}
        </Link>
    )
}
