import { Formik } from 'formik'
import { omit } from 'lodash'
import { useMemo } from 'react'
import { FormContainer } from './@components'
import { FormProps } from './Form.types'
import { getFormValidatorWithZodSchema } from './Form.utils'

export function Form<T>(props: FormProps<T>) {
    const propsFormik = useMemo<any>(() => {
        const validate = getFormValidatorWithZodSchema<T>(props.validationSchema)

        return {
            ...omit(props, 'validationSchema'),
            validate
        }
    }, [props])

    return (
        <FormContainer maxWidth={props.maxWidth}>
            <Formik enableReinitialize={true} validateOnBlur={false} validateOnChange={true} {...propsFormik} />
        </FormContainer>
    )
}
