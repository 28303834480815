import { FieldHelperProps, FieldHookConfig, FieldInputProps, FieldMetaProps, useField, useFormikContext } from 'formik'
import { isObjectLike } from 'lodash'
import { useEffect, useRef } from 'react'

/**
 * @todo
 * Needs to be renamed (collision with the feature "Custom Fields")
 * @description
 * Custom implementation of the formik useField hook that allow to have a custom touched behavior.
 */
export function useCustomFormikField<T = void>(
    propsOrFieldName: string | FieldHookConfig<T>
): [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>] {
    const [field, meta, helpers] = useField<T>(propsOrFieldName)
    const { values, initialValues } = useFormikContext<any>()

    const fieldName: string = isObjectLike(propsOrFieldName)
        ? (propsOrFieldName as FieldHookConfig<T>).name
        : (propsOrFieldName as string)

    const handleBlur = useRef((event: React.FocusEvent<HTMLInputElement>) => {
        // Override default formik onBlur to avoid setting field as touched
        // Any custom onBlur behavior for Formik field should be added here
    }).current

    useEffect(() => {
        if (values[fieldName] !== initialValues[fieldName] && !meta.touched) {
            helpers.setTouched(true)
        }
    }, [values[fieldName]])

    return [{ ...field, onBlur: handleBlur }, meta, helpers]
}
