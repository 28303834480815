import { isArray, isEmpty, isNil, isString, toArray } from 'lodash'

/**
 * @description
 * It's a good industry practice to sanitise outgoing data.
 * To be used as a JSON payload sanitiser;
 * To be used in general at `httpClient` config level and/or at request level;
 */
export function* sanitizeData<T>(data?: T): Generator<any, T, any> {
    const sanitizedPayload = Object.create(null)

    if (isNil(data)) {
        return sanitizedPayload
    }

    for (const [key, value] of Object.entries(data as Record<any, any>)) {
        if (value !== undefined) {
            switch (true) {
                case isString(value) && isEmpty(value): {
                    sanitizedPayload[key] = null
                    break
                }

                case isString(value): {
                    sanitizedPayload[key] = value.trim()
                    break
                }

                case isObject(value): {
                    sanitizedPayload[key] = yield* sanitizeData(value)
                    break
                }

                case isArray(value): {
                    sanitizedPayload[key] = toArray(yield* sanitizeData(value))
                    break
                }

                default: {
                    sanitizedPayload[key] = value
                }
            }
        }
    }

    return sanitizedPayload
}

export function isObject(value: Record<any, any>): boolean {
    return value != null && typeof value === 'object' && globalThis.Array.isArray(value) === false
}
