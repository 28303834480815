import { defineStyleConfig } from '@chakra-ui/react'

export const Select = defineStyleConfig({
    baseStyle: {
        borderRadius: 'var(--numeral-ui-primary-border-radius)',
        field: {
            marginBottom: '8px',
            _disabled: {
                backgroundColor: 'gray.50',
                opacity: 1
            }
        },
        icon: {
            marginTop: '-4px'
        }
    },
    variants: {
        outline: {
            field: {
                backgroundColor: 'white'
            }
        }
    },
    defaultProps: {
        // @ts-ignore chakra doesn't officially support focus color override
        // https://github.com/chakra-ui/chakra-ui/issues/663
        focusBorderColor: 'numeralAccent.500'
    }
})
