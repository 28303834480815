import { SUPPORT_EMAIL } from '@/constants'
import { Link } from '@chakra-ui/react'

export const SupportEmailMailtoLink: React.FC = () => {
    return (
        <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
            {SUPPORT_EMAIL}
        </Link>
    )
}
