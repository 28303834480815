import { useConfiguration, useNavigationRoutes } from '@/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useServiceResponseInterceptors } from '@webapps/numeral-ui-core'
import { chain, noop } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ServicesContextValue } from '../../ServicesProvider.types'
import { getInvalidSessionInterceptor } from '../../ServicesProvider.utils'

/**
 * @description
 * Augments service instances with `Request` and `Response` interceptors.
 */
export const useAddServiceInterceptors = (
    services: Partial<ServicesContextValue>,
    ...omittedServiceInstances: (keyof ServicesContextValue)[]
) => {
    const { apiEnvironment } = useConfiguration()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { paths } = useNavigationRoutes()

    const serviceInstances = useMemo(
        () => chain(services).omit(omittedServiceInstances).values().value(),
        [services, omittedServiceInstances]
    )

    const onInvalidSession = useCallback(() => {
        queryClient.cancelQueries().catch(noop)
        navigate(paths.ACCOUNT.LOGIN)
    }, [queryClient, services, apiEnvironment])

    const addResponseInterceptor = useServiceResponseInterceptors(serviceInstances)

    addResponseInterceptor(...getInvalidSessionInterceptor(onInvalidSession))

    return serviceInstances
}
