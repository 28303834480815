import { SepaReturnReasonDescription } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { VStack } from '@chakra-ui/react'
import { SepaReturnReason } from '@webapps/numeral-ui-core'
import { memo } from 'react'

type PaymentRetryRuleReasonCodesProps = {
    reasonCodes: SepaReturnReason[]
}

export const PaymentRetryRuleReasonCodes: React.FC<PaymentRetryRuleReasonCodesProps> = memo(({ reasonCodes }) => {
    if (!reasonCodes || !reasonCodes.length) {
        return <> {EMPTY_VALUE_PLACEHOLDER} </>
    }

    return (
        <VStack spacing="8px" alignItems="flex-start">
            {reasonCodes.map((reasonCode) => {
                return <SepaReturnReasonDescription key={reasonCode} sepaReturnReason={reasonCode} />
            })}
        </VStack>
    )
})
