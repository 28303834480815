/**
 * @description
 * Small utility wrapper which adds the `name` attribute of the function to the function object itself.
 * @usage
 * export function myFunction() {
 *     console.log(This function will print the name "myFunction"`, myFunction.name)
 *
 *     return withCalleeName(myFunction.name, (...args: any[]) => {...})
 */
export function withCalleeName(name: string, fn: any) {
    return Object.defineProperty(fn, 'name', { value: name })
}
