import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import { CopyToClipboardButton } from '@/components/@misc'

export const InputWithCopyToClipboard: React.FC<InputProps> = (props) => {
    const refInputRightElement = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number | undefined>(100)
    const children = useMemo(() => {
        const valueText = props?.value || props.defaultValue

        if (!valueText) {
            return null
        }

        return <CopyToClipboardButton value={valueText} />
    }, [props.value])

    useLayoutEffect(() => {
        setWidth(refInputRightElement?.current?.offsetWidth)
    }, [])

    return (
        <InputGroup>
            <Input {...props} paddingRight={`${width}px`} textOverflow="ellipsis" />
            {children && <InputRightElement ref={refInputRightElement} children={children} width="auto" />}
        </InputGroup>
    )
}
