import {
    FilterInputs,
    TableCellAccount,
    TableCellAmount,
    TableCellReconciliationStatus,
    TableCellShortDate,
    TableCellStatus,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AmountInput,
    BooleanInput,
    ConnectedAccountsInput,
    CounterpartyAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentDirectionInput,
    PaymentOrderStatusInput,
    PaymentOrderTypeInput,
    ReconciliationStatusInput,
    TextInput
} from '@/components/@inputs'
import { ConnectedAccountName, PaymentType } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, HIGHLIGHTED_CURRENCY_CODES, MIN_COLUMN_WIDTH } from '@/constants'
import { QueryParamQuickFilters } from '@/hooks'
import { BulkActions, BulkActionsTableCustomColumn } from '@/providers'
import { PERMISSION } from '@/services'
import { uuidValidator } from '@/utils/@validators'
import {
    PaymentOrder,
    PaymentOrderStatusSchema,
    PaymentOrdersServiceFindAllQueryOptions,
    ReconciliationStatusSchema
} from '@webapps/numeral-ui-core'
import {
    PaymentOrderPendingApprovalBulkActionsFooterActions,
    PaymentOrderPendingApprovalBulkActionsFooterContent
} from './@components'

export const CREATE_PAYMENT_ORDER_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/create-a-payment-order`

export const PAYMENT_ORDER_TABLE_STATIC_STATE: TableStateWithEntity<PaymentOrder, BulkActionsTableCustomColumn> = {
    columnSizing: Object.freeze({ created_at: 100 }),
    columnOrder: [
        'created_at',
        'type',
        'amount',
        'connected_account_id',
        'receiving_account',
        'value_date',
        'status',
        'reconciliation_status'
    ]
}

export const PAYMENT_ORDER_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    PaymentOrder,
    BulkActionsTableCustomColumn
> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        cell(info) {
            const { direction } = info.row.original

            return <PaymentType type={info.getValue()} direction={direction} isAbbreviated={true} />
        }
    },
    amount: {
        minSize: MIN_COLUMN_WIDTH.AMOUNT,
        cell(info) {
            const { currency } = info.row.original

            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    receiving_account: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NAME,
        cell(info) {
            return <TableCellAccount value={info.getValue()} />
        }
    },
    value_date: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <TableCellStatus value={info.getValue()} />
        }
    },
    reconciliation_status: {
        minSize: MIN_COLUMN_WIDTH.RECONCILIATION_STATUS,
        cell(info) {
            return <TableCellReconciliationStatus value={info.getValue()} />
        }
    }
}

export const PAYMENT_ORDER_FILTERS: FilterInputs<PaymentOrdersServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    type: <PaymentOrderTypeInput name="type" />,
    direction: <PaymentDirectionInput name="direction" isPlaceholderDisplayed={true} />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    status: <PaymentOrderStatusInput name="status" />,
    reconciliation_status: <ReconciliationStatusInput name="reconciliation_status" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    payment_file_id: <TextInput name="payment_file_id" validator={uuidValidator} />,
    counterparty_account_id: <CounterpartyAccountsInput name="counterparty_account_id" isPlaceholderDisplayed={true} />,
    direct_debit_mandate_id: <TextInput name="direct_debit_mandate_id" validator={uuidValidator} />,
    mandate_reference: <TextInput name="mandate_reference" />,
    idempotency_key: <TextInput name="idempotency_key" />,
    auto_approval: <BooleanInput name="auto_approval" />
}

export const PAYMENT_ORDER_QUICK_FILTERS: QueryParamQuickFilters<PaymentOrdersServiceFindAllQueryOptions> = {
    status: [PaymentOrderStatusSchema.enum.pending_approval],
    reconciliation_status: [ReconciliationStatusSchema.enum.unreconciled]
}

export const PAYMENT_ORDER_BULK_ACTIONS: BulkActions<PaymentOrdersServiceFindAllQueryOptions> = {
    [PaymentOrderStatusSchema.enum.pending_approval]: {
        actionComponent: PaymentOrderPendingApprovalBulkActionsFooterActions,
        contentComponent: PaymentOrderPendingApprovalBulkActionsFooterContent,
        configuration: {
            permissions: [PERMISSION.PAYMENTS_APPROVE_PAYMENT_ORDERS]
        }
    }
}
