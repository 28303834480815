import { ErrorIcon } from '@/components/@icons'
import { isValidJSONString } from '@/utils'
import { Box, Flex, Text } from '@chakra-ui/react'
import { chain } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ZodCustomIssue } from 'zod'
import { FileUploadInputStatusProps } from '../FileUploadInput.types'
import { FileUploadIconWrapper } from './FileUploadIconWrapper'

export const FileUploadInputStatusError: React.FC<FileUploadInputStatusProps> = ({
    error,
    browseLink,
    color,
    fontWeight
}) => {
    const errorContent = useMemo<ZodCustomIssue[] | string[]>(
        () => (isValidJSONString(error) ? globalThis.JSON.parse(error as string) : error),
        [error]
    )

    return (
        <Flex direction="column" alignItems="center">
            <FileUploadIconWrapper color={color}>
                <ErrorIcon />
            </FileUploadIconWrapper>
            <Flex direction="column">
                {chain(errorContent)
                    .map((error, index) => {
                        const { params, message } = error as ZodCustomIssue

                        return (
                            <Box key={index}>
                                <Text color={color} fontWeight={fontWeight} marginBottom="8px">
                                    {message}
                                </Text>
                                <Text color={color} fontWeight="400">
                                    <FormattedMessage
                                        id={`app.common.form.validation.file.${params?.code}.description`}
                                        values={{
                                            ...params,
                                            browseLink
                                        }}
                                    />
                                </Text>
                            </Box>
                        )
                    })
                    .head()
                    .value()}
            </Flex>
        </Flex>
    )
}
