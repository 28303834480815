import { TableCellStatus, TableColumnCustomizers, TableStateWithEntity } from '@/components'
import { FormattedDateTime } from '@/components/@misc'
import { NumeralEvent } from '@webapps/numeral-ui-core'
import { DOCUMENTATION_BASE_URL } from '@/constants'

export const EVENTS_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/the-event-object`

export const EVENTS_TABLE_STATIC_STATE: TableStateWithEntity<NumeralEvent> = {
    columnOrder: ['created_at', 'type', 'status']
}

export const EVENTS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<NumeralEvent> = {
    created_at: {
        cell(info) {
            return <FormattedDateTime value={info.getValue()} />
        }
    },
    status: {
        cell(info) {
            return <TableCellStatus value={info.getValue()} />
        }
    }
}
