import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import {
    useMutationExportPaymentFiles,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllPaymentFiles,
    useQueryParams,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { PaymentFile, PaymentFilesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PaymentFileCustomColumns } from './PaymentFile.type'
import {
    PAYMENT_FILE_FILTERS,
    PAYMENT_FILE_TABLE_COLUMN_CUSTOMIZERS,
    PAYMENT_FILE_TABLE_STATIC_STATE
} from './PaymentFiles.page.const'
import { SupportEmailMailtoLink } from '@/components/@misc'

export const PaymentFilesPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const { onAdd } = useToasts()
    const { search, filterBy } = useQueryParams<PaymentFilesServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: PAYMENT_FILE_FILTERS } }
    })

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_files.title' }), [intl])
    const query = useQueryFindAllPaymentFiles({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportPaymentFiles({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            return {
                title: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.subtitle' })
            }
        }
    }, [filterBy.isActive, intl, isDataEmpty, search.isActive])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []

        actions.push({
            onClick() {
                mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
            },
            children: intl.formatMessage({ id: 'app.common.actions.export' }),
            isDisabled: isDataEmpty || mutationExport.isPending
        })

        if (hasPermission(PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS)) {
            actions.push({
                onClick() {
                    navigate(`${paths.PAYMENTS.PAYMENT_ORDERS}/${relativePaths.UPLOAD}`)
                },
                children: intl.formatMessage({ id: 'app.payments.payment_files.actions.upload.title' })
            })
        }
        return actions
    }, [
        hasPermission,
        intl,
        isDataEmpty,
        navigate,
        mutationExport,
        paths.PAYMENTS.PAYMENT_ORDERS,
        relativePaths.UPLOAD
    ])

    const onTableRowClick = useTableRowNavigation<PaymentFile>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_ORDER} permission={PERMISSION.FILES_VIEW_FILES}>
                <QuickFilterProvider<PaymentFilesServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<PaymentFilesServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.payment_file}
                        isLoading={query.isLoading}
                        filterBy={filterBy}
                        search={search}
                        actions={actions}
                    />
                    {(() => {
                        switch (true) {
                            case query.isLoading: {
                                return <LoadingSpinner />
                            }

                            case query.isError || isDataEmpty: {
                                return <EmptyContent {...emptyContentProps} />
                            }

                            default: {
                                return (
                                    <Table<PaymentFile, PaymentFileCustomColumns>
                                        data={data}
                                        onRowClick={onTableRowClick}
                                        onScrollToBottom={query.fetchNextPage}
                                        isLoading={query.hasNextPage}
                                        state={PAYMENT_FILE_TABLE_STATIC_STATE}
                                        columnCustomizers={PAYMENT_FILE_TABLE_COLUMN_CUSTOMIZERS}
                                    />
                                )
                            }
                        }
                    })()}
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
