import { useAuth } from '@/hooks'
import { useEffect } from 'react'

export const LoginPage = () => {
    const { onLogin } = useAuth()

    useEffect(() => {
        onLogin()
    }, [])

    return null
}
