import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdDirectDebitMandate } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import {
    DIRECT_DEBIT_MANDATE_DETAILS_ROWS,
    DIRECT_DEBIT_MANDATE_DETAILS_ROW_CUSTOMIZERS
} from './DirectDebitMandateDetails.page.const'
import { DirectDebitMandateDetailsCustomRows } from './DirectDebitMandateDetails.types'

export const DirectDebitMandateDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdDirectDebitMandate(uuid)

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.DIRECT_DEBIT_MANDATE}
                permission={PERMISSION.COUNTERPARTIES_VIEW_DIRECT_DEBIT_MANDATES}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<DirectDebitMandate, DirectDebitMandateDetailsCustomRows>
                                    data={query.data}
                                    rows={DIRECT_DEBIT_MANDATE_DETAILS_ROWS}
                                    rowCustomizers={DIRECT_DEBIT_MANDATE_DETAILS_ROW_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
