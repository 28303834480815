import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, useDisclosure } from '@chakra-ui/react'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { If } from '../@misc/If'
import { AlertMessageProps, AlertMessageType } from './AlertMessage.types'

export const AlertMessage: React.FC<AlertMessageProps> = memo(
    ({ titleKey, descriptionKey, type = AlertMessageType.WARNING, isClosable = true }) => {
        const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

        return (
            <Alert status={type} borderRadius="var(--numeral-ui-primary-border-radius)" marginY="8px" hidden={!isOpen}>
                <AlertIcon />
                <Box>
                    <If condition={!!titleKey}>
                        <AlertTitle>
                            <FormattedMessage id={titleKey} />
                        </AlertTitle>
                    </If>

                    <If condition={!!descriptionKey}>
                        <AlertDescription>
                            <FormattedMessage id={descriptionKey} />
                        </AlertDescription>
                    </If>
                </Box>
                {isClosable && <CloseButton alignSelf="flex-start" position="absolute" right="8px" onClick={onClose} />}
            </Alert>
        )
    }
)
