import {
    EntityDetail,
    EntityDetailRow,
    EntityDetailRowCustomizers,
    TableCellAccount,
    TableCellTextIntl
} from '@/components'
import { useQueryFindByIdDirectDebitMandate } from '@/hooks'
import { prettyPrintCasedWords } from '@/utils'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Fade,
    Flex,
    Text
} from '@chakra-ui/react'
import { DirectDebitMandate, Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { PAYMENT_ORDER_FORM_DETAILS_STYLES } from '../../PaymentOrderCreate.form.const'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

export const PAYMENT_ORDER_FORM_DIRECT_DEBIT_MANDATE_DETAILS_ROWS: EntityDetailRow<DirectDebitMandate>[] = [
    'originating_account',
    'receiving_account',
    'direction',
    'type',
    'sequence',
    'reference'
]

export const PAYMENT_ORDER_FORM_DIRECT_DEBIT_MANDATE_DETAILS_ROW_CUSTOMIZERS: EntityDetailRowCustomizers<DirectDebitMandate> =
    {
        originating_account: {
            cell(item) {
                return <TableCellAccount value={item.originating_account} />
            }
        },
        receiving_account: {
            cell(item) {
                return <TableCellAccount value={item.receiving_account} />
            }
        },
        direction: {
            cell(item) {
                const value = chain<DirectDebitMandate>(item).get('direction').value()
                const formattedValue = prettyPrintCasedWords(value)
                return <Text>{formattedValue || EMPTY_VALUE_PLACEHOLDER}</Text>
            }
        },
        type: {
            cell(item) {
                const type = item.type
                return <TableCellTextIntl messageId={`api.direct_debit_mandate.type.${type}`} defaultMessage={type} />
            }
        },
        sequence: {
            cell(item) {
                const sequence = item.sequence
                return (
                    <TableCellTextIntl
                        messageId={`api.direct_debit_mandate.sequence.${sequence}`}
                        defaultMessage={sequence}
                    />
                )
            }
        }
    }

export const PaymentOrderDirectDebitMandateSectionDetails: React.FC<{
    id?: Uuid
}> = ({ id, ...propsBox }) => {
    const hasValidUUID = useMemo<boolean>(() => UuidSchema.safeParse(id).success, [id])
    const [activeIndex, setActiveIndex] = useState<number>(-1)
    const onChange = useCallback((expandedIndex: number) => {
        setActiveIndex(expandedIndex)
    }, [])
    const query = useQueryFindByIdDirectDebitMandate(id, {
        enabled: hasValidUUID
    })

    useEffect(() => {
        if (hasValidUUID) {
            onChange(0)
        }
    }, [hasValidUUID, onChange])

    if (isEmpty(query.data)) {
        return null
    }

    return (
        <Box
            {...propsBox}
            {...PAYMENT_ORDER_FORM_DETAILS_STYLES.DETAILS_CONTAINER}
            className="PaymentOrderDirectDebitMandateDetails">
            <Accordion
                allowMultiple={false}
                allowToggle={!query?.isLoading}
                index={activeIndex}
                backgroundColor="gray.50"
                onChange={onChange}>
                <AccordionItem border="unset">
                    <AccordionButton display="flex" padding="16px" justifyContent="space-between">
                        <Text fontWeight="600" fontSize="16px">
                            <FormattedMessage id="app.payments.payment_orders.create.form.sections.direct_debit_mandate.details.title" />
                        </Text>
                        <Flex>
                            <AccordionIcon color="gray.500" />
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                        <Fade in={query?.isSuccess}>
                            <EntityDetail<DirectDebitMandate>
                                data={query?.data}
                                rows={PAYMENT_ORDER_FORM_DIRECT_DEBIT_MANDATE_DETAILS_ROWS}
                                rowCustomizers={PAYMENT_ORDER_FORM_DIRECT_DEBIT_MANDATE_DETAILS_ROW_CUSTOMIZERS}
                                style={PAYMENT_ORDER_FORM_DETAILS_STYLES.ENTITY_DETAILS_OVERRIDES}
                            />
                        </Fade>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}
