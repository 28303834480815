import { FormattedDate, Separator, SeparatorVariant } from '@/components/@misc'
import { useQueryFindByIdEvent } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { Box, BoxProps, Text } from '@chakra-ui/react'
import React, { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getNumeralEventTopicAndTypePattern } from './EventDetails.header.utils'

export const EventDetailsHeader: React.FC<BoxProps> = (props) => {
    const { uuid } = useParams()
    const query = useQueryFindByIdEvent(uuid)
    const title = useMemo<ReactNode>(() => {
        if (!query.data) {
            return null
        }

        const objectId = query.data.id
        const displayDate = query.data.created_at
        const hasTopicAndType = globalThis.Boolean(query.data.topic && query.data.type)

        return (
            <>
                {objectId && (
                    <>
                        <Text as="span" whiteSpace="nowrap">
                            {objectId}
                        </Text>
                        <br />
                    </>
                )}

                {hasTopicAndType && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        {getNumeralEventTopicAndTypePattern(query.data)}
                    </>
                )}
                {displayDate && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <FormattedDate value={displayDate} />
                    </>
                )}
            </>
        )
    }, [query])

    return (
        <Box {...props}>
            <PageHeaderLayout title={title} isLoading={query.isLoading} />
        </Box>
    )
}
