import { useContext } from 'react'
import { PageContext, PageContextValue } from '@/providers'

export function usePage<T>() {
    const context = useContext(PageContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${PageContext.displayName}`)
    }

    return context as PageContextValue<T>
}
