import {
    DirectDebitMandate,
    DirectDebitMandateDirectionSchema,
    DirectDebitMandateStatusSchema
} from '@webapps/numeral-ui-core'

export function showAuthorizeMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.enum.incoming &&
        mandate.status === DirectDebitMandateStatusSchema.enum.blocked
    )
}

export function showDisableMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.enum.outgoing &&
        mandate.status === DirectDebitMandateStatusSchema.enum.active
    )
}

export function showBlockMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.enum.incoming &&
        mandate.status === DirectDebitMandateStatusSchema.enum.active
    )
}
