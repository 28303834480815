import { FormikInput } from '@/types'
import { ExpectedPayment } from '@webapps/numeral-ui-core'
import { useField, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { ibanValidator } from '@/utils/@validators'
import { TextInput } from '@/components/@inputs'
import { isEmpty } from 'lodash'

/**
 * @description
 * - If the nested property `virtual_account.virtual_account_number` is empty,
 * the container object `virtual_account`, has to be `undefined` or `null`;
 * - This is a special validation case enforced at API level;
 */
export const ExpectedPaymentVirtualAccountInput: React.FC<FormikInput> = ({ name, ...inputProps }) => {
    const [field, meta] = useField(name)
    const { setFieldValue } = useFormikContext<ExpectedPayment>()

    useEffect(() => {
        if (isEmpty(field.value)) {
            setFieldValue('virtual_account', undefined)
        }
    }, [field.value, setFieldValue])

    return <TextInput name={name} validator={ibanValidator} {...inputProps} />
}
