import { User } from '@/services'
import { UserStatusSchema } from '@/services/Users/Users.schema'
import { omit } from 'lodash'

export function getUserEditFormInitialValues(data?: Partial<User>, isEditingSelf?: boolean): User {
    return {
        first_name: '',
        last_name: '',
        email: '',
        is_enterprise: false,
        status: UserStatusSchema.enum.pending,
        roles: [],
        position: undefined,
        department: undefined,
        ...omit(data, 'id', 'last_login_date', isEditingSelf ? 'roles' : '')
    }
}
