import { memo } from 'react'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { FormattedDate } from 'react-intl'

interface FormattedDateTimeProps {
    value?: string | null
}

export const FormattedDateTime: React.FC<FormattedDateTimeProps> = memo(({ value }) => {
    if (!value) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return <FormattedDate value={value} dateStyle="short" timeStyle="long" />
})
