import { useMemo } from 'react'
import { chain } from 'lodash'
import { Text } from '@chakra-ui/react'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'

interface TableCellTextProps {
    value?: string
}

export const TableCellText: React.FC<TableCellTextProps> = ({ value }) => {
    const formattedValue = useMemo<string | undefined>(() => {
        const isEmptyString = chain(value).trim().isEmpty().value()
        return isEmptyString ? EMPTY_VALUE_PLACEHOLDER : value
    }, [value])

    return (
        <Text as="span" title={formattedValue}>
            {formattedValue}
        </Text>
    )
}
