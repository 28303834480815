import { useActivatedFeatures, useAuth, usePermissions, useServices } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { CounterpartyAccount, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdCounterpartyAccount(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<CounterpartyAccount, Error>>
) {
    const { counterpartyAccountsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isUserLoggedIn &&
        isFeatureActivated(ENTITY_FEATURE.COUNTERPARTY) &&
        hasPermission(PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<CounterpartyAccount, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [counterpartyAccountsService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return counterpartyAccountsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
