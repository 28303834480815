import { InfiniteData } from '@tanstack/react-query'
import { List } from '@webapps/numeral-ui-core'

/*
 * @description
 * Helps with data aggregating when using `useInfiniteQuery` for infinite scrolling.
 */
export function queryDataAggregation<T>(data?: InfiniteData<List<T>>): T[] {
    if (!data?.pages?.length) {
        return []
    }

    return data.pages.reduce<T[]>((acc, data: List<T>) => {
        return acc.concat(data?.records)
    }, [])
}
