import { CurrencyCode } from '@webapps/numeral-ui-core'
import { FormattedAmount } from '@/components/@misc'
import { Text } from '@chakra-ui/react'
import React from 'react'

interface TableCellAmountProps {
    amount?: number
    currency?: CurrencyCode
}

export const TableCellAmount: React.FC<TableCellAmountProps> = React.memo((props) => {
    return (
        <Text textAlign="right">
            <FormattedAmount {...props} />
        </Text>
    )
})
