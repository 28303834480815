import { Tag, TagProps } from '@chakra-ui/react'
import { CounterpartyAccountVerification } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { getCounterpartyAccountVerificationStatusTagProps } from './CounterpartyAccountVerificationStatus.utils'
import { Nullable } from '@/types'

interface CounterpartyAccountVerificationStatusProps extends TagProps {
    accountVerification?: Nullable<CounterpartyAccountVerification>
}

export const CounterpartyAccountVerificationStatus: React.FC<CounterpartyAccountVerificationStatusProps> = memo(
    ({ accountVerification, ...props }) => {
        const intl = useIntl()
        const { messageKey, color, backgroundColor, numberOfValidVerifications, numberOfTotalVerifications } =
            getCounterpartyAccountVerificationStatusTagProps(accountVerification)
        const formattedMessage = intl.formatMessage(
            { id: messageKey },
            { numberOfValidVerifications, numberOfTotalVerifications }
        )

        return (
            <Tag
                title={formattedMessage}
                color={color}
                backgroundColor={backgroundColor}
                aria-label={formattedMessage}
                overflow="hidden"
                borderRadius="var(--numeral-ui-primary-border-radius)"
                {...props}>
                {formattedMessage}
            </Tag>
        )
    }
)
