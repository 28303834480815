import { memo, PropsWithChildren, useRef } from 'react'
import { TOAST_MESSAGES, ToastContainer } from './ToastsProvider.const'
import { ToastContext } from './ToastsProvider.context'
import { addToast, closeAllToasts, closeToast, updateToast } from './ToastsProvider.utils'

export const ToastsProvider: React.FC<PropsWithChildren> = memo(({ children }) => {
    const value = useRef({
        items: TOAST_MESSAGES.queue,
        onAdd: addToast,
        onUpdate: updateToast,
        onClose: closeToast,
        onCloseAll: closeAllToasts
    })

    return (
        <ToastContext.Provider value={value.current}>
            <ToastContainer />
            {children}
        </ToastContext.Provider>
    )
})
