import { Box, BoxProps } from '@chakra-ui/react'
import { useMemo } from 'react'
import { MainNavigationAccountDropdown } from './@components'
import './MainNavigationAccount.scss'

interface MainNavigationAccountProps {
    isCollapsed: boolean
}

export const MainNavigationAccount: React.FC<MainNavigationAccountProps> = ({ isCollapsed }) => {
    const propsBox = useMemo<BoxProps>(() => {
        return {
            paddingX: isCollapsed ? '0' : '8px',
            marginY: '22px'
        }
    }, [isCollapsed])

    return (
        <Box className="MainNavigation-Account" {...propsBox} data-testid="main-navigation-account">
            <MainNavigationAccountDropdown isCollapsed={isCollapsed} />
        </Box>
    )
}
