import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { chain, invoke, isEmpty, isUndefined, set } from 'lodash'
import { locationStateCleanup } from '@/utils'

/**
 * @todo
 * Adapt configuration logic to match `useQueryParams` implementation
 * Use in the `useQueryParams` to clean the hook and remove duplicated persistence logic.
x */

export function usePersistedLocationState<T>(
    store: Record<string, T>,
    stateKey: string,
    configuration?: any
): [T | undefined, (value?: T) => void] {
    const location = useLocation()
    const storeKey = useMemo(() => {
        const { pathname } = location
        return stateKey ? `${pathname}:${stateKey}` : pathname
    }, [location, stateKey])

    const initialLocationState = useMemo(() => {
        return chain<NonNullable<typeof location.state>>(location.state).get(stateKey).value()
    }, [location, stateKey])

    const getPersistedState = useCallback(() => store?.[storeKey], [store, storeKey])
    const hasPersistedState = useMemo(() => {
        const persistedState = getPersistedState()
        const state = invoke(configuration, 'stateParser', persistedState) || persistedState

        if (isUndefined(state)) {
            return true
        }

        return !isEmpty(state)
    }, [configuration, getPersistedState])
    const setStoreValue = useCallback(
        (value?: T) => {
            set(store, storeKey, value)
        },
        [store, storeKey]
    )

    if (initialLocationState) {
        locationStateCleanup(storeKey)
        return [initialLocationState, setStoreValue]
    }

    if (hasPersistedState) {
        return [getPersistedState(), setStoreValue]
    }

    return [undefined, setStoreValue]
}
