import { ENVIRONMENT_VARIABLES, SupportedApiEnvironmentName } from '@/configuration'
import { PropsWithChildren, useMemo, useState } from 'react'
import { ConfigurationContext } from './ConfigurationProvider.context'
import { getApiEnvironment, getApiEnvironmentNames } from './ConfigurationProvider.utils'

export const ConfigurationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [paginationLimit, setPaginationLimit] = useState(ENVIRONMENT_VARIABLES.VITE_DEFAULT_PAGINATION_LIMIT)

    const apiEnvironmentNames = useMemo(() => getApiEnvironmentNames(), [])
    const primaryApiEnvironment = useMemo(() => getApiEnvironment(apiEnvironmentNames[0]), [apiEnvironmentNames])
    const secondaryApiEnvironment = useMemo(() => {
        const envName = apiEnvironmentNames[1]
        if (!envName) {
            return
        }

        return getApiEnvironment(envName)
    }, [apiEnvironmentNames])

    const [activeApiEnvironment, setCurrentApiEnvironment] = useState(primaryApiEnvironment)
    const setCurrentApiEnvironmentByName = (apiEnvironmentName: SupportedApiEnvironmentName) => {
        setCurrentApiEnvironment(getApiEnvironment(apiEnvironmentName))
    }

    const contextValue = {
        apiEnvironment: {
            primary: primaryApiEnvironment,
            secondary: secondaryApiEnvironment,
            active: activeApiEnvironment,
            setActiveEnvironmentByName: setCurrentApiEnvironmentByName
        },
        pagination: {
            limit: paginationLimit,
            setLimit: setPaginationLimit
        }
    }

    return <ConfigurationContext.Provider value={contextValue}>{children}</ConfigurationContext.Provider>
}
