import { EmptyContent, EntityDetail, EntityDetailRowCustomizers, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdExpectedPayment } from '@/hooks'
import { PageLayout } from '@/layouts'
import { getAccountRowCustomizers } from '@/pages'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { Account } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { EXPECTED_PAYMENT_COUNTERPARTY_DETAIL_ROWS } from './ExpectedPaymentCounterparty.page.const'

export const ExpectedPaymentCounterpartyPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdExpectedPayment(uuid)
    const rowCustomizers = useMemo<EntityDetailRowCustomizers<Account>>(getAccountRowCustomizers, [])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.RECONCILIATION}
                permission={PERMISSION.PAYMENTS_VIEW_EXPECTED_PAYMENTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError:
                        case isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <EntityDetail<Account>
                                    data={query.data?.counterparty_account}
                                    rows={EXPECTED_PAYMENT_COUNTERPARTY_DETAIL_ROWS}
                                    rowCustomizers={rowCustomizers}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
