import { FormikInput } from '@/types'
import { HTMLChakraProps } from '@chakra-ui/system'
import { InputHTMLAttributes, ReactNode } from 'react'

export type FileUploadInputProps = FormikInput & {
    acceptedSize?: number
    acceptedExtensions: string[]
} & InputHTMLAttributes<HTMLInputElement>

export enum FileUploadInputDataTransferKind {
    FILE = 'file'
}

export type FileUploadInputStatusProps = HTMLChakraProps<any> & {
    color?: string
    error?: any
    browseLink?: ReactNode
    formattedAcceptedSize?: string
    fileNameWithExtension?: string
}

export enum FileReaderReadyState {
    EMPTY = 0, // No data loaded.
    LOADING = 1, // Data is currently being loaded.
    DONE = 2 // Data has been loaded successfully.
}
