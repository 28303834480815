import { useAuth, usePermissions, useServices } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { CustomField, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdCustomField(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<CustomField, Error>>
) {
    const { customFieldsService } = useServices()
    const { isUserLoggedIn, selectedUserAccess } = useAuth()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = isUserLoggedIn && hasPermission(PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useQuery<CustomField, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [customFieldsService.url, { id }],
        queryFn({ signal }) {
            return customFieldsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
