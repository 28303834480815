import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import { useQueryFindByIdWebhook } from '@/hooks'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { WebhookDetailsForm } from '../@components'

export const WebhookDetailsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdWebhook(uuid)

    return (
        <PageLayout className="WebhookDetails">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_WEBHOOKS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query?.isError || isEmpty(query?.data): {
                            return <EmptyContent />
                        }

                        default: {
                            return <WebhookDetailsForm data={query.data} />
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
