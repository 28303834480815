import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { useEffect } from 'react'

/**
 * This hooks loads Google tag manager which integrate analytics tool in the app
 */
export const useGoogleTagManager = () => {
    useEffect(() => {
        if (ENVIRONMENT_VARIABLES.PROD) {
            /* eslint-disable */
            // Google tag manager code, do not update
            ;(function (w: any, d: any, s: any, l: any, i: any) {
                w[l] = w[l] || []
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : ''
                j.async = true
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
                f.parentNode.insertBefore(j, f)
            })(window, document, 'script', 'dataLayer', 'GTM-M55HLBB9')
            // End of Google tag manager
            /* eslint-enable */
        }
    }, [])
}
