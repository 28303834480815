import { FilterInputs, TableCellText, TableCellTextIntl, TableStateWithEntity } from '@/components'
import { BooleanInput, CountryInput, TextInput } from '@/components/@inputs'
import { EnabledStatus } from '@/components/@misc'
import { TableColumnCustomizers } from '@/components/Table'
import { COLUMN_WIDTH_RATIO, MIN_COLUMN_WIDTH } from '@/constants'
import { accountNumberFormatter } from '@/utils/@formatters'
import { ConnectedAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { ConnectedAccountExpanded } from '@/services'

export const CONNECTED_ACCOUNTS_TABLE_STATIC_STATE: TableStateWithEntity<ConnectedAccountExpanded> = {
    columnOrder: ['name', 'account_number', 'bank_name', 'disabled_at']
}

export const CONNECTED_ACCOUNTS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<ConnectedAccountExpanded> = {
    name: {
        size: COLUMN_WIDTH_RATIO.ACCOUNT,
        header(info) {
            return <TableCellTextIntl messageId="app.table.header.account_name" />
        },
        cell(info) {
            return <TableCellText value={info.getValue()} />
        }
    },
    account_number: {
        minSize: MIN_COLUMN_WIDTH.ACCOUNT_NUMBER,
        cell(info) {
            const formattedValue = accountNumberFormatter(info.getValue())
            return <TableCellText value={formattedValue} />
        }
    },
    disabled_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_STATUS,
        cell(info) {
            const isDisabled = !!info.getValue()
            return <EnabledStatus isDisabled={isDisabled} />
        }
    }
}

export const CONNECTED_ACCOUNTS_FILTERS: FilterInputs<ConnectedAccountsServiceFindAllQueryOptions> = {
    enabled: <BooleanInput name="enabled" />,
    bank_name: <TextInput name="bank_name" />,
    'address.country': (
        <CountryInput
            name="['address.country']"
            customLabelKey="app.common.form.input.country.label"
            isPlaceholderDisplayed={true}
        />
    )
}
