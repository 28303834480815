import { ARROW_SEPARATOR, BULLET_SEPARATOR, DASH_SEPARATOR } from '@/constants'
import { Text } from '@chakra-ui/react'
import { memo, ReactNode } from 'react'
import { SeparatorVariant } from './Separator.type'

interface SeparatorProps {
    variant?: SeparatorVariant
}

export const Separator: React.FC<SeparatorProps> = memo(({ variant = SeparatorVariant.Bullet }) => {
    let symbol: ReactNode

    switch (variant) {
        case SeparatorVariant.Arrow:
            symbol = ARROW_SEPARATOR
            break
        case SeparatorVariant.Bullet:
            symbol = BULLET_SEPARATOR
            break
        case SeparatorVariant.Dash:
            symbol = DASH_SEPARATOR
            break
    }

    return (
        <Text as="span" marginX="4px">
            {symbol}
        </Text>
    )
})
