import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, useQueryParams } from '@/hooks'
import { useQueryFindAllVirtualAccounts } from '@/hooks/api/virtualAccounts'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { VirtualAccount, VirtualAccountsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    VIRTUAL_ACCOUNT_DOCUMENTATION_LINK,
    VIRTUAL_ACCOUNT_FILTERS,
    VIRTUAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS,
    VIRTUAL_ACCOUNT_TABLE_STATIC_STATE
} from './VirtualAccounts.page.const'

export const VirtualAccountsPage: React.FC = () => {
    const intl = useIntl()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.counterparties.virtual_accounts.title' }), [intl])
    const { search, filterBy } = useQueryParams<VirtualAccountsServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: VIRTUAL_ACCOUNT_FILTERS } }
    })
    const query = useQueryFindAllVirtualAccounts({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({
                    id: 'app.counterparties.virtual_accounts.empty_content.filter.title'
                }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            const learnMoreLink = (
                <Link href={VIRTUAL_ACCOUNT_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.counterparties.virtual_accounts.empty_content.title' }),
                content: intl.formatMessage(
                    { id: 'app.counterparties.virtual_accounts.empty_content.subtitle' },
                    { learnMoreLink }
                )
            }
        }
    }, [intl, query, search, filterBy])
    const onTableRowClick = useTableRowNavigation<VirtualAccount>('id', relativePaths.COUNTERPARTIES.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS}>
                <TableHeader<VirtualAccountsServiceFindAllQueryOptions>
                    objectType={ApiObjectTypeExtendedSchema.enum.virtual_account}
                    isLoading={query.isLoading}
                    search={search}
                    filterBy={filterBy}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isDataEmpty:
                        case query.isError: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<VirtualAccount>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={VIRTUAL_ACCOUNT_TABLE_STATIC_STATE}
                                    columnCustomizers={VIRTUAL_ACCOUNT_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
