import { InfoIcon } from '@/components/@icons'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { FormikInput } from '@/types'
import { FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react'
import { useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Uuid } from '@webapps/numeral-ui-core'

interface PaymentOrderSEPACreditorIdentifierInputProps extends Omit<FormikInput, 'value' | 'name'> {
    value?: Uuid
}

export const PaymentOrderSEPACreditorIdentifierInput: React.FC<PaymentOrderSEPACreditorIdentifierInputProps> = ({
    value,
    ...inputProps
}) => {
    const name = useRef<string>('creditor_identifier').current
    const query = useQueryFindByIdConnectedAccount(value, {
        enabled: !inputProps.isDisabled || globalThis.Boolean(value)
    })
    const creditorIdentifier = useMemo<string | undefined>(() => {
        if (!query.data?.creditor_identifier) {
            return EMPTY_VALUE_PLACEHOLDER
        }

        return query.data?.creditor_identifier
    }, [query])

    return (
        <FormControl key={name}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id="app.payments.payment_orders.create.form.inputs.creditor_identifier.label" />
                <Tooltip
                    placement="right-end"
                    arrowSize={6}
                    hasArrow={true}
                    shouldWrapChildren={true}
                    label={
                        <FormattedMessage id="app.payments.payment_orders.create.form.inputs.creditor_identifier.title" />
                    }>
                    <InfoIcon fill="black" />
                </Tooltip>
            </FormLabel>
            <Input {...inputProps} name={name} id={name} value={creditorIdentifier} type="text" disabled={true} />
        </FormControl>
    )
}
