import { useAuth, useServices } from '@/hooks'
import { User, filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationEnableByIdUser(id?: Uuid, options?: MutationOptions<User, Error, void>) {
    const { selectedUserAccess } = useAuth()
    const { usersService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessQueryParams(selectedUserAccess) }

    return useMutation<User, Error, void>({
        ...options,
        mutationKey: [usersService.url, { id, ...paramsWithUserAccess }],
        mutationFn() {
            return usersService.enableById(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([usersService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [usersService.url]
                })
                .catch(noop)
        }
    })
}
