import { Flex, FlexProps } from '@chakra-ui/react'
import { FORM_MAX_WIDTH } from '../Form.const'

export const FormContainer: React.FC<FlexProps> = ({ children, maxWidth = FORM_MAX_WIDTH, ...props }) => {
    return (
        <Flex width="100%" direction="column" align="flex-start" gap="16px" {...props} maxWidth={maxWidth}>
            {children}
        </Flex>
    )
}
