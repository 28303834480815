import { Separator, SeparatorVariant } from '@/components/@misc'
import { useQueryFindByIdPaymentFile } from '@/hooks'
import { PageHeaderLayout } from '@/layouts'
import { Text } from '@chakra-ui/react'
import { ReactNode, useMemo } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

export const PaymentFileDetailsHeader: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()

    const query = useQueryFindByIdPaymentFile(uuid)
    const title = useMemo<ReactNode>(() => {
        if (!query.data) {
            return null
        }

        const filename = query.data.filename
        const createdAt = query.data.created_at

        return (
            <>
                <Text as="span">
                    <FormattedMessage id="api.object.payment_file" />
                </Text>
                {!!filename && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <Text as="span">{filename}</Text>
                    </>
                )}
                {!!createdAt && (
                    <>
                        <Separator variant={SeparatorVariant.Bullet} />
                        <FormattedDate value={createdAt} />
                    </>
                )}
            </>
        )
    }, [query])

    return <PageHeaderLayout title={title} isLoading={query.isLoading} />
}
