import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Text } from '@chakra-ui/react'
import { Direction, PaymentOrderType } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getDefaultPaymentTypeMessage, getPaymentTypeMessageKey } from './PaymentType.utils'

export interface PaymentTypeProps {
    type?: PaymentOrderType
    direction?: Direction
    isAbbreviated?: boolean
}

export const PaymentType: React.FC<PaymentTypeProps> = memo(({ type, direction, isAbbreviated = false }) => {
    if (!type) {
        return EMPTY_VALUE_PLACEHOLDER
    }

    return (
        <Text as="span">
            <FormattedMessage
                id={getPaymentTypeMessageKey(type, direction, isAbbreviated)}
                defaultMessage={getDefaultPaymentTypeMessage(type, direction)}
            />
        </Text>
    )
})
