import { isSDDPayment } from '@/services'
import {
    IncomingPayment,
    IncomingPaymentReturnReasonSchema,
    IncomingPaymentSchema,
    IncomingPaymentStatusSchema,
    SepaReturnReasonSchema
} from '@webapps/numeral-ui-core'

export function incomingPaymentReceivedSDDFilter(item?: IncomingPayment, index?: number): boolean {
    const isValidIncomingPayment = IncomingPaymentSchema.safeParse(item).success
    const isReceived = item?.status === IncomingPaymentStatusSchema.enum.received

    if (!isValidIncomingPayment) {
        return false
    }

    return isReceived && isSDDPayment(item.type, item.direction)
}

export function getIncomingPaymentReturnReasonOptions(item?: IncomingPayment) {
    switch (true) {
        case incomingPaymentReceivedSDDFilter(item): {
            return [
                SepaReturnReasonSchema.enum.MD01,
                SepaReturnReasonSchema.enum.AC01,
                SepaReturnReasonSchema.enum.AC04,
                SepaReturnReasonSchema.enum.AC06,
                SepaReturnReasonSchema.enum.AC13,
                SepaReturnReasonSchema.enum.AG01,
                SepaReturnReasonSchema.enum.AG02,
                SepaReturnReasonSchema.enum.AM04,
                SepaReturnReasonSchema.enum.BE05,
                SepaReturnReasonSchema.enum.SL01
            ]
        }

        default: {
            return IncomingPaymentReturnReasonSchema.options
        }
    }
}
