import { chain, pick } from 'lodash'
import {
    Account,
    ConnectedAccount,
    CurrencyCode,
    Direction,
    ExpectedPaymentCreateData,
    ServiceName,
    VirtualAccount
} from '@webapps/numeral-ui-core'
import {
    computePaymentPropertiesByServiceName,
    counterpartyAccountFactory,
    enabledConnectedAccountsFilter
} from '@/services'
import { DEFAULT_CURRENCY_CODE } from '@/constants'

export function partialCounterpartyAccountFactory(): Account {
    const counterpartyAccount = counterpartyAccountFactory()
    const item = pick(counterpartyAccount, 'holder_name', 'account_number')

    item.holder_name = ''
    item.account_number = ''

    return item
}

export function computeExpectedPaymentFormDerivedFields(
    account?: ConnectedAccount
): Partial<ExpectedPaymentCreateData> {
    const item: Partial<ExpectedPaymentCreateData> = Object.create(null)
    const serviceName = chain(account).get('services_activated').head().value()
    const { direction } = computePaymentPropertiesByServiceName(serviceName as ServiceName)

    item.direction = direction
    item.connected_account_id = account?.id

    return item
}

export function getExpectedPaymentFormInitialValues(data?: ConnectedAccount[]): ExpectedPaymentCreateData {
    const item: ExpectedPaymentCreateData = Object.create(null)
    const filteredData = chain(data).filter(enabledConnectedAccountsFilter).value()
    const account = chain<ConnectedAccount>(filteredData).head().value()
    const { connected_account_id, direction } = computeExpectedPaymentFormDerivedFields(account)

    item.connected_account_id = connected_account_id as string
    item.direction = direction as Direction
    item.currency = DEFAULT_CURRENCY_CODE as CurrencyCode

    item.amount_from = undefined as unknown as number
    item.amount_to = undefined as unknown as number

    item.start_date = ''
    item.end_date = ''

    item.counterparty_account = partialCounterpartyAccountFactory()
    item.virtual_account = undefined as unknown as VirtualAccount
    item.descriptions = ['']

    return item
}
