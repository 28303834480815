import { EntityActionsMenu } from '@/components'
import { useUserActions } from 'src/pages/Settings/Users/@hooks'
import { UserDisableConfirmationModal } from '@/pages/Settings/Users/@components'
import { useDisclosure } from '@chakra-ui/react'
import { User } from '@/services'

interface UserTableRowOptionsProps {
    item: User
}

export const UserTableRowOptions: React.FC<UserTableRowOptionsProps> = ({ item }) => {
    const disclosure = useDisclosure()
    const actions = useUserActions(item, disclosure)
    return (
        <>
            <EntityActionsMenu actions={actions} />
            <UserDisableConfirmationModal {...disclosure} uuid={item?.id} />
        </>
    )
}
