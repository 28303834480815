import { getFormButtonLoadingLeftIcon, StickyFooter } from '@/components'
import { useMutationApproveByIdPaymentOrder, useMutationCancelByIdPaymentOrder } from '@/hooks'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { canApproveOrCancelPaymentOrderByStatus } from './PaymentOrderApproval.utils'

interface PaymentOrderApprovalProps {
    data?: PaymentOrder
}

export function PaymentOrderApproval({ data }: PaymentOrderApprovalProps) {
    const mutationApprove = useMutationApproveByIdPaymentOrder(data?.id)
    const mutationCancel = useMutationCancelByIdPaymentOrder(data?.id)
    const isVisible = useMemo<boolean>(() => canApproveOrCancelPaymentOrderByStatus(data?.status), [data])
    const onApprove = useCallback(() => {
        mutationApprove.mutate()
    }, [mutationApprove])
    const onCancel = useCallback(() => {
        mutationCancel.mutate()
    }, [mutationCancel])
    const isLoading = useMemo<boolean>(() => {
        return mutationApprove.isPending || mutationCancel.isPending
    }, [mutationApprove, mutationCancel])
    const [marginBottom, setMarginBottom] = useState<number>(0)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="PaymentOrderApproval" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status="info" variant="numeralAccent">
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">
                                <FormattedMessage id="app.payments.payment_orders.approval.title" />
                            </Text>
                            <Text>
                                <FormattedMessage id="app.payments.payment_orders.approval.description" />
                            </Text>
                        </Stack>
                        <Flex gap="16px">
                            <Button
                                colorScheme="gray"
                                onClick={onCancel}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationCancel.isPending)}
                                isDisabled={isLoading}
                                variant="outline"
                                backgroundColor="white">
                                <FormattedMessage id="app.payments.payment_orders.approval.actions.cancel.label" />
                            </Button>
                            <Button
                                onClick={onApprove}
                                leftIcon={getFormButtonLoadingLeftIcon(mutationApprove.isPending)}
                                isDisabled={isLoading}
                                variant="solid">
                                <FormattedMessage id="app.payments.payment_orders.approval.actions.approve.label" />
                            </Button>
                        </Flex>
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
