import { EMPTY_CHAR_SYMBOL } from '@/constants'
import { addToast, TOAST_VARIANTS } from '@/providers'
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import { chain } from 'lodash'
import { PropsWithChildren, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { QUERY_CLIENT_CONFIGURATION } from './CustomQueryClientProvider.const'
import { CustomQueryClientContext } from './CustomQueryClientProvider.context'

export const CustomQueryClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const intl = useIntl()
    const queryClient = useMemo(() => {
        const queryClientConfig: QueryClientConfig = chain(QUERY_CLIENT_CONFIGURATION)
            .merge({
                defaultOptions: {
                    mutations: {
                        onError(data: unknown, variables: unknown, context: unknown) {
                            addToast({
                                variant: TOAST_VARIANTS.ERROR,
                                status: 'error',
                                title: intl.formatMessage({ id: 'app.common.error.title' }),
                                description: intl.formatMessage(
                                    { id: 'app.common.error.description' },
                                    {
                                        /**
                                         * @description
                                         * Optionally expose information about the error here.
                                         */
                                        errorContext: EMPTY_CHAR_SYMBOL
                                    }
                                )
                            })
                        }
                    }
                }
            })
            .value()

        return new QueryClient(queryClientConfig)
    }, [intl])

    return (
        <CustomQueryClientContext.Provider value={null}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </CustomQueryClientContext.Provider>
    )
}
