import { UpArrowIcon } from '@/components/@icons'
import { Button, Flex, SlideFade, SlideFadeProps } from '@chakra-ui/react'
import { chain } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { isTableScrollToTopVisible, scrollToTop } from './TableScrollToTop.utils'

export const TableScrollToTop: React.FC = () => {
    const intl = useIntl()
    const [isScrollToTopVisible, setScrollToTopVisibility] = useState<boolean>(false)
    const scrollListener = useMemo(() => {
        return chain(() => {
            const isVisible = isTableScrollToTopVisible()

            setScrollToTopVisibility(isVisible)
        })
            .throttle(300)
            .value()
    }, [])
    const onScrollToTop = useCallback(scrollToTop, [])
    const propsButton = useRef({
        onClick: onScrollToTop,
        leftIcon: <UpArrowIcon />,
        _hover: {
            backgroundColor: 'gray.400'
        },
        _active: {
            backgroundColor: 'gray.400'
        },
        backgroundColor: 'gray.500',
        boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
        borderRadius: 'var(--numeral-ui-primary-border-radius)',
        children: intl.formatMessage({
            id: 'app.table.scroll_to_top.label'
        })
    }).current
    const propsSlideFade = useMemo<SlideFadeProps>(() => {
        return {
            in: isScrollToTopVisible,
            unmountOnExit: true,
            style: { margin: '0 auto' }
        }
    }, [isScrollToTopVisible])

    useEffect(() => {
        const isVisible = isTableScrollToTopVisible()

        setScrollToTopVisibility(isVisible)
        globalThis.window.addEventListener('scroll', scrollListener)

        return () => {
            globalThis.window.removeEventListener('scroll', scrollListener)
        }
    }, [scrollListener])

    return (
        <Flex position="sticky" bottom="16px" marginTop="32px" zIndex="1">
            <SlideFade {...propsSlideFade}>
                <Button {...propsButton} />
            </SlideFade>
        </Flex>
    )
}
