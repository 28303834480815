import { DEFAULT_INPUT_TEXT_MAX_LENGTH, DEFAULT_INPUT_TEXT_MIN_LENGTH } from '@/constants'
import { APIKey } from '@/services'
import { APIKeySchema } from '@/services/APIKeys/APIKey.schema'
import { IntlShape } from 'react-intl'
import { z, ZodSchema } from 'zod'

/**
 * @todo
 * Add tests
 */
export function getAPIKeyFormInitialValues(data?: Partial<APIKey>): APIKey {
    return {
        name: '',
        permission: 'read',
        /* @todo Retouch this block on V2 implementation
         * ips: []
         * */
        ...data
    }
}

/**
 * @todo
 * - Update strings based on product proposal
 * - Retouch this block on V2 implementation
 */
export function getAPIKeyFormValidationSchemaWithIntl(intl: IntlShape): ZodSchema {
    const minMessage = intl.formatMessage(
        {
            id: 'app.developers.api_keys.form.name.validation.min.message'
        },
        {
            size: DEFAULT_INPUT_TEXT_MIN_LENGTH
        }
    )
    const maxMessage = intl.formatMessage(
        {
            id: 'app.developers.api_keys.form.name.validation.max.message'
        },
        {
            size: DEFAULT_INPUT_TEXT_MAX_LENGTH
        }
    )

    return APIKeySchema.extend({
        name: z
            .string()
            .trim()
            .min(DEFAULT_INPUT_TEXT_MIN_LENGTH, minMessage)
            .max(DEFAULT_INPUT_TEXT_MAX_LENGTH, maxMessage)
    })
}
