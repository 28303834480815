import { ConnectedAccountsInput } from '@/components/@inputs'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { createPaymentOrderConnectedAccountsCombinedFilter } from '@/services'
import { FormikInput } from '@/types'
import { PaymentOrder, Uuid } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { computePartialPaymentOrderFormFields } from '../PaymentOrderCreate.form.utils'

export const PaymentOrderConnectedAccountInput: React.FC<FormikInput> = ({ name, isDisabled, ...inputProps }) => {
    const { getFieldProps, setFieldValue } = useFormikContext<PaymentOrder>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const hasConnectedAccountId = useMemo(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const query = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })

    const onChange = useCallback(
        (value: Uuid) => {
            const { type, direction, currency } = computePartialPaymentOrderFormFields(query.data)

            setFieldValue('connected_account_id', value)

            // Derived fields
            setFieldValue('type', type)
            setFieldValue('direction', direction)
            setFieldValue('currency', currency)

            setFieldValue('treasury_option', '', false)
        },
        [query, setFieldValue]
    )

    return (
        <ConnectedAccountsInput
            {...inputProps}
            name={name}
            onChange={onChange}
            isLoading={query.isLoading}
            isDisabled={isDisabled}
            isPlaceholderDisplayed={false}
            queryDataFilter={createPaymentOrderConnectedAccountsCombinedFilter}
        />
    )
}
