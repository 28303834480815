import { BaseHttpClientService, extractResponseData, ServicesConfiguration } from '@webapps/numeral-ui-core'
import { resolverLimitCheckInterceptor } from '../@responseInterceptors'
import { Resolver, ResolverAllQueryOptions } from './IdsResolver.service.types'

export class IdsResolverService extends BaseHttpClientService {
    get url() {
        return '/v1/ids_resolver'
    }

    constructor(configuration?: ServicesConfiguration) {
        super(configuration)

        this.getResponseInterceptorsManager().use(resolverLimitCheckInterceptor)
    }

    async resolveIds<T, Q = void>(
        params: ResolverAllQueryOptions<Q>,
        config?: ServicesConfiguration
    ): Promise<Resolver<T>> {
        return this.httpClient
            .get<Resolver<T>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }
}
