import { FieldValidator } from 'formik'
import { isEmpty } from 'lodash'
import { IntlShape } from 'react-intl'
import { SafeParseReturnType } from 'zod'
import { asOptionalField, sanitiseValidatorValue } from '../validators.utils'
import { BICSchema } from './bic.schema'

export function bicValidator(intl: IntlShape, isRequired?: boolean): FieldValidator {
    return (value: string) => {
        const sanitisedValue = sanitiseValidatorValue(value)
        let validationResult: SafeParseReturnType<typeof value, string | void>

        if (isRequired) {
            validationResult = BICSchema.safeParse(sanitisedValue)
        } else {
            validationResult = asOptionalField(BICSchema).safeParse(sanitisedValue)
        }

        if (isRequired && isEmpty(sanitisedValue)) {
            return intl.formatMessage({ id: 'app.common.form.validation.required' })
        }

        if (!validationResult.success) {
            return intl.formatMessage({ id: 'app.common.form.validation.bank_code.invalid' })
        }
    }
}
