import {
    BaseHttpClientService,
    extractResponseData,
    List,
    Pagination,
    ServicesConfiguration,
    Uuid
} from '@webapps/numeral-ui-core'
import { APIKey } from './APIKeys.service.types'

export class APIKeysService extends BaseHttpClientService {
    get url() {
        return '/v1/api_keys'
    }

    async findAll(params: Partial<Pagination>, config?: ServicesConfiguration): Promise<List<APIKey>> {
        return this.httpClient
            .get<List<APIKey>>(this.url, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async findById(id: Uuid, config?: ServicesConfiguration): Promise<APIKey> {
        return this.httpClient.get<APIKey>(`${this.url}/${id}`, config).then(extractResponseData)
    }

    async createAPIKey(data: APIKey, config?: ServicesConfiguration): Promise<APIKey> {
        return this.httpClient.post<APIKey>(this.url, data, config).then(extractResponseData)
    }

    async updateById(id: Uuid, data: Partial<APIKey>, config?: ServicesConfiguration): Promise<APIKey> {
        return this.httpClient
            .post<APIKey>(
                `${this.url}/${id}`,
                {
                    ...data
                },
                config
            )
            .then(extractResponseData)
    }

    async revokeById(id: Uuid, config?: ServicesConfiguration): Promise<APIKey> {
        return this.httpClient.post<APIKey>(`${this.url}/${id}/revoke`, {}, config).then(extractResponseData)
    }
}
