import { useAuth, useServices } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { BankCodeLookupPayload, BankCodeLookupResponse } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationBankCodeLookup(
    options?: MutationOptions<BankCodeLookupResponse, Error, BankCodeLookupPayload>
) {
    const { selectedUserAccess } = useAuth()
    const { bankCodeLookupService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(selectedUserAccess)
    }

    return useMutation<BankCodeLookupResponse, Error, BankCodeLookupPayload>({
        ...options,
        mutationKey: [bankCodeLookupService.url],
        mutationFn(data) {
            return bankCodeLookupService.lookup(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [bankCodeLookupService.url]
                })
                .catch(noop)
        }
    })
}
